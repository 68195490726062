import {Component} from '@angular/core';
import {ForgotPwd} from "../../services/ForgotPwd";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-pwd.html',
  viewProviders: [ForgotPwd]
})
export class ForgotPasswordCmp {
  public email: string = '';
  public isSent = false;
  public sending = false;
  public error = 0;
  public notFound = false;
  public isAccountSubdomainViewMode;

  constructor(private forgotPwdService: ForgotPwd, public session: Session, public config: Config) {
    this.isAccountSubdomainViewMode = this.config.isAccountSubDomain();
  }

  remind() {
    if (!this.email) {
      return;
    }
    this.sending = true;
    this.error = 0;
    this.forgotPwdService.sendCode(this.email).subscribe(() => {
      this.isSent = true;
      this.sending = false;
    }, (response) => {
      this.sending = false;
      this.error = response.status;
      this.notFound = true;
      console.error(response);
    });
  }
}
