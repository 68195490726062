import {Component} from '@angular/core';
import {Questions} from "../../services/Questions";
import {Specialties} from "../../services/Specialties";
import {Router} from '@angular/router';
import {IReviewSession} from "../../interfaces/IReviewSession";
import {IReviewAnswer} from "../../interfaces/IReviewAnswer";
import {Config} from "../../services/Config";
import {ISubQuestionOption} from "../../interfaces/ISubQuestionOption";
import {Observable, from as ObservableFrom} from "rxjs";
import {HttpResponse} from '@angular/common/http';
import {ISpecialty} from "../../interfaces/ISpecialty";
import {Session} from "../../services/Session";
declare var $:any;
declare var _:any;
declare var analytics:any;

@Component({
  selector: 'savings-report',
  templateUrl: './questions.html',
})
export class SavingsReportCmp {
  public pageIndex = 0;
  public specialty_id = 0;
  public cot_id = 0;
  public specialtiesMap = {};
  public specialtiesList = [];
  public pages = [];
  public pagesRaw = [];
  public reviewSession:IReviewSession = new IReviewSession();
  public saving = false;
  public answers:IReviewAnswer[] = [];
  public prevQuestionsCount = 0;

  constructor(private questionsService:Questions, private specialties:Specialties,
    public router:Router, private config:Config, private session:Session) {
    this.session.redirectIfNotAuth();
    this.specialties.load().then((specs:ISpecialty[])=> {
      if (specs && specs.length) {
        specs.forEach((spec)=> {
          if (spec.is_popular > 0) {
            this.specialtiesList.push(spec);
            this.specialtiesMap[spec.id] = spec;
          }
        });
      }
    }, ()=> {});
    this.questionsService.loadPages().subscribe((pages)=> {
      this.pagesRaw = pages;
      this.renderPages();
    }, ()=> {});
  }

  setSpecialty($v) {
    if (!this.pagesRaw || !this.pagesRaw.length) {
      return;
    }
    if (this.specialty_id === parseInt($v)) {
      return;
    }
    this.specialty_id = parseInt($v);
    this.renderPages();
  }

  renderPages() {
    this.pages = [];
    this.pagesRaw.forEach((p)=> {
      //noinspection TypeScriptValidateJSTypes
      let page = $.extend(true, {}, p);
      if (page.__questions && page.__questions.length) {
        let filtered_questions = [];
        page.__questions.forEach((question)=> {
          if (question.id === 1) {
            this.prevQuestionsCount = 1;
            return;
          }
          if (question.__questions && question.__questions.length) {
            let filtered_sub_questions = [];
            question.__questions.forEach((sq)=> {
              if (sq.specialty_id === 0 || sq.specialty_id === this.specialty_id) {
                if (sq.__options && sq.__options.length) {
                  let filtered_options = [];
                  sq.__options.forEach((option)=> {
                    if (option.specialty_id === 0 || option.specialty_id === this.specialty_id) {
                      filtered_options.push(option);
                    }
                  });
                  //noinspection TypeScriptUnresolvedVariable
                  sq.__options = filtered_options;
                }
                filtered_sub_questions.push(sq);
              }
            });
            //noinspection TypeScriptUnresolvedVariable
            question.__questions = filtered_sub_questions;
            if (question.__questions.length) {
              filtered_questions.push(question);
            }
          }
        });
        page.__questions = filtered_questions;
      }
      this.pages.push(page);
    });
  }

  nextPage() {
    this.renderPages();
    this.saving = true;
    if (!this.reviewSession.id) {
      this.reviewSession.specialty_id = this.specialty_id;
      this.reviewSession.class_of_trade_id = this.cot_id;
      this.questionsService.getNewReviewAnswersSession(this.reviewSession).subscribe((id) => {
        this.reviewSession.id = id;
        this.questionsService.sessions[id] = this.reviewSession;
        this.saveAnswers().subscribe(()=> {
          this.saving = false;
        }, (response)=> {
          this.saving = false;
          console.error(response);
        });
      }, (response)=> {
        this.saving = false;
        console.error(response);
      });
    } else {
      this.saveAnswers().subscribe(()=> {
        this.saving = false;
      }, (response)=> {
        this.saving = false;
        console.error(response);
      });
    }
    this.trackButtonClick();
    if ((this.pageIndex + 1) < this.pages.length) {
      this.pageIndex++;
      this.prevQuestionsCount = 1;
      try {
        for (let i = 0; i < this.pageIndex; ++i) {
          this.prevQuestionsCount += this.pages[i].__questions.length;
        }
      } catch (e) {}
      //noinspection TypeScriptValidateJSTypes
      $('html,body').animate({scrollTop: 0}, 1);
    } else {
      this.router.navigate(['/savings_signup', this.reviewSession.id]);
    }
  }

  saveAnswers() {
    if (this.answers) {
      let bucket:IReviewAnswer[] = [];
      this.answers.forEach((a:IReviewAnswer) => {
        a.review_session_id = this.reviewSession.id;
        bucket.push(a);
      });
      this.answers = [];
      return this.questionsService.saveAnswers(bucket);
    } else {
      return ObservableFrom([]);
    }
  }

  trackButtonClick() {
    try {
      if (analytics && analytics.track) {
        analytics.track("SavingsQuestionsPage", {
          "page": this.pageIndex,
          "reviewSessionID": this.reviewSession
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  setAnswer($event:ISubQuestionOption[], sq) {
    try {
      if (!this.answers) {
        this.answers = [];
      }
      let newAnswers = $event;
      let answers:IReviewAnswer[] = _.filter(this.answers, {__sq_id: sq.id});
      if (answers && answers.length > 0) {
        answers.forEach((a)=> {
          let i = this.answers.indexOf(a);
          if (i > -1) {
            this.answers.splice(i, 1);
          }
        });
      }
      if (sq.question_id === this.config.distributors_question_id) {
        this.reviewSession.__ask_distributor_email = false;
      }
      if (newAnswers && newAnswers.length > 0) {
        newAnswers.forEach((a)=> {
          let answer:IReviewAnswer = <IReviewAnswer>{
            sub_question_option_id: a.id,
            user_input: a.user_input,
            review_session_id: this.reviewSession.id,
            __sq_id: sq.id
          };
          this.answers.push(answer);
          // if ask "send email to distributor"
          if (sq.question_id === this.config.distributors_question_id) {
            if (this.config.distributors_id_to_send_email.indexOf(a.id) > -1) {
              this.reviewSession.__ask_distributor_email = true;
            }
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
