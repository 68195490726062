import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Contracts} from "../../services/Contracts";
import {IContract} from "../../interfaces/IContract";
import {Session} from "../../services/Session";

@Component({
  templateUrl: './search-results.html',
  selector: 'search-results',
})
export class SearchResultsCmp {
  @Input() searchTerm:string;
  public results:IContract[] = [];
  public loading = false;

  constructor(private contracts:Contracts, private routeParams:ActivatedRoute, private session:Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      if (!this.searchTerm || (this.searchTerm != params['search'])) {
        if (params['search']) {
          this.searchTerm = params['search'];
          this.ngOnChanges();
        } else {
          this.routeParams.queryParams.subscribe((qparams) => {
            if (qparams['search']) {
              this.searchTerm = qparams['search'];
              this.ngOnChanges();
            }
          }, () => {});
        }
      }
    });
  }

  ngOnChanges() {
    if (!this.searchTerm) {
      return;
    }
    this.results = [];
    this.loading = true;
    this.contracts.getSearchFiltered(this.searchTerm, 100, true).subscribe(
      (r) => {
        this.results = r;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
