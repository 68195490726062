<div class="text-center pc2-form-group" *ngIf="userID">
  <button class="btn btn-sm btn-info" type="button" (click)="displayNewContactForm=!displayNewContactForm">
    I’m not the one who should be dealing with
    <i *ngIf="!displayNewContactForm" class="material-icons sm material-icons-in-link">expand_more</i>
    <i *ngIf="displayNewContactForm" class="material-icons sm material-icons-in-link">expand_less</i>
  </button>
</div>
<div class="row pc2-form-group" *ngIf="displayNewContactForm">
  <div class="col-md-4 offset-md-4">
    <div class="card">
      <div class="card-header">Please send it to:</div>
      <div class="card-body">
        <div class="row form-group">
          <div class="col-sm-3">
            <label class="col-form-label float-right">Email <sup>*</sup></label>
          </div>
          <div class="col-sm-9">
            <input type="email" [(ngModel)]="newContactEmail" class="form-control"/>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3">
            <label class="col-form-label float-right">Name</label>
          </div>
          <div class="col-sm-9">
            <input type="email" [(ngModel)]="newContactName" class="form-control"/>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 offset-sm-9">
            <button type="button" class="btn btn-sm btn-primary btn-block" (click)="addNewContact()" [disabled]="sending">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="row-iframe-container">
  <div class="iframe-container" *ngIf="letterURL">
    <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(letterURL)" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>
</section>
