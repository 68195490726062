<section class="main-content contract-layout">
  <div class="container">

    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" *ngIf="contract?.contract_number">Contract #{{contract.contract_number}}<span *ngIf="contract.vendor_name"> ({{contract.vendor_name}})</span></li>
    </ol>

    <div class="row">
      <div class="col-sm-4">
        <div class="img-holder">
          <img src="{{contract?.__image_url}}"
               alt="{{contract?.vendor_name}} image."
               class="img-fluid"/>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="description-holder">
          <h2 class="headline">{{contract?.vendor_name}}</h2>
          <h3 class="headline-medium"></h3>
          <ul class="contract-details">
            <li>
              <strong>Contract Number:</strong>
              {{contract?.contract_number}}
            </li>

            <li>
              <strong>Contract Expiration:</strong>
              {{contract?.expiration_date}}
            </li>

            <li>
              <strong>Department:</strong>
              {{contract?.department}}
            </li>
            <li>
              <strong>Status:</strong>
              {{contract?.contract_status}}
            </li>
            <li>
              <strong>Order From:</strong>
              <span *ngIf="contract?.order_from!='Both'"> {{contract?.order_from}} </span>
              <span *ngIf="contract?.order_from=='Both'"> Direct or Distribution, this contract should be connected at your distributor,
                please <have-a-question [displayButton]="!1" linkText="contact us" [contractNumber]="contract?.contract_number" [vendorName]="contract?.vendor_name"></have-a-question> if you
                have any questions or need confirmation.</span>
            </li>

            <li class="cd-source"
                *ngIf="contract?.minority == 'Y' || contract?.rebate == 'Y'">
              <strong>Other:</strong>
              <span *ngIf="contract?.minority == 'Y'"> Minority Owner </span>
              <span *ngIf="contract?.rebate == 'Y'"> Rebate Available </span>
            </li>
            <li class="dropdown-divider"></li>
          </ul>
          <div class="card" *ngIf="contract?.order_from=='Distributed'">
            <div class="card-header solo">
              This product is ordered through a medical supply distributor. Please contact your distributor or click below to receive pricing support.
            </div>
          </div>

          <div class="contract-actions">
            <have-a-question [contractNumber]="contract?.contract_number" [vendorName]="contract?.vendor_name"></have-a-question>
            <help-access-pricing *ngIf="contract?.order_from!='Distributed'" [contractNumber]="contract?.contract_number" [vendorName]="contract?.vendor_name"></help-access-pricing>
            <btn-distr-pricing *ngIf="contract?.order_from=='Distributed'" [contractNumber]="contract?.contract_number" [vendorName]="contract?.vendor_name"></btn-distr-pricing>
          </div>
        </div>
      </div>
    </div>

    <div class="row pc2-row-pad" *ngIf="sameVendorContracts?.length>0">
      <div class="col-12">
        <div class="card" style="background-color: #f7f7f9">
          <div class="card-body">
<!--            Also from {{contract.vendor_name}}:-->
            Others have also reviewed these contracts:
            <span *ngIf="!sameVContractsExpanded">
              <span *ngFor="let sc of sameVendorContracts.slice(0,3); let ls = last"><a routerLink="/contract/{{sc.contract_number}}" target="_blank" rel="noopener">{{sc.contract_number}} ({{sc.description}})</a><span *ngIf="!ls">, </span></span>
              <span *ngIf="sameVendorContracts.length>3" class="btn btn-sm btn-outline-secondary dropdown-triangle-down float-right" (click)="sameVContractsExpanded=true"></span>
            </span>
            <span *ngIf="sameVContractsExpanded" class="btn btn-sm btn-outline-secondary dropdown-triangle-up float-right" (click)="sameVContractsExpanded=false"></span>
            <ul *ngIf="sameVContractsExpanded" class="list-group pc2-row-pad">
              <li *ngFor="let sc of sameVendorContracts" class="list-group-item"><a routerLink="/contract/{{sc.contract_number}}" target="_blank">{{sc.contract_number}}: {{sc.description}}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row pc2-row-pad" *ngIf="items.length>0">
      <div class="col-12" style="padding-right: 2.8rem;margin-bottom: .5rem;">
        <input type="text" [ngModel]="search" (ngModelChange)="setSearchTerm($event)" placeholder="Search..." class="form-control"/>
      </div>
    </div>
    <div class="row form-group" *ngIf="items.length>0">
      <div class="col-12">
        <table class="table table-striped table-sm table-hover">
          <thead class="pc2-bgcolor-x text-inverse">
          <tr>
            <th>Catalog Number</th>
            <th>Item Description</th>
            <th>Quantity</th>
            <th *ngIf="hasPrices && displayPrices" class="text-right">Price</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of items; let fi = first">
            <td>
              <highlight-text [search]="search" text="{{item.CatalogNumber}}"></highlight-text>
            </td>
            <td>
              <highlight-text [search]="search" text="{{item.ItemShortDescription}}"></highlight-text>
            </td>
            <td>{{item.Quantity}}</td>
            <td *ngIf="hasPrices && displayPrices" class="text-right">
              <span *ngIf="fi">$&nbsp;&nbsp;</span>{{item.ManufacturerPriceAmount.toFixed(2)}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row pc2-row-pad" *ngIf="contract.__items && (contract.__items.length > items.length)" style="padding-right: 2.8rem;margin-top: .5rem;">
      <div class="col-6 col-sm-1">
        <button class="btn btn-block btn-outline-secondary btn-sm" (click)="pageAdd(-1)">&lt;&lt;</button>
      </div>
      <div class="col-sm-10 d-none d-sm-block text-center">
        Page {{page}} of {{pagesTotal}}
      </div>
      <div class="col-6 col-sm-1">
        <button class="btn btn-block btn-outline-secondary btn-sm" (click)="pageAdd(1)">&gt;&gt;</button>
      </div>
    </div>
  </div>
</section>
