import {Component} from '@angular/core';
import {UrlParser} from "../../services/UrlParser";
import {Config} from "../../services/Config";
import {FDTickets} from "../../services/FDTickets";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'survey-ext-thanks',
  templateUrl: 'survey_ext_thanks.html'
})
export class SurveyExtThanksCmp {
  public channelEmail = 'support@purchaseclinic.com';
  public channelPhone = '877.659.2159';
  public email = '';
  public message = '';
  public sending = false;
  public sent = false;

  constructor(private urlParser: UrlParser, private config: Config, private tickets: FDTickets) {
    let theme = this.config.defaultTheme;
    if (theme) {
      if (theme.email_from_address) {
        this.channelEmail = theme.email_from_address;
      } else {
        if (theme.contact_email) {
          this.channelEmail = theme.contact_email;
        }
      }
      if (theme.phone) {
        this.channelPhone = theme.phone;
      }
    }
  }

  ngOnInit() {
    let params: any = this.urlParser.getParameters();
    if (params) {
      if (params.email && params.email.indexOf('@') > 0) {
        this.email = params.email;
      }
    }
  }

  send() {
    if (!this.message) {
      return;
    }
    let n = new Notify();
    if (!this.email) {
      n.error(this.config.txt.tmpAuthEmailRequired);
      return;
    }
    this.sending = true;
    this.tickets.create(this.email, 'Survey Questions/Updates', this.message, {}).subscribe(() => {
      this.sending = false;
      this.sent = true;
      n.success(this.config.txt.successfully);
    }, () => {
      this.sending = false;
      n.error(this.config.txt.emailSendingError);
    });
  }
}
