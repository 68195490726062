<div class="customize-exp" *ngIf="!session?.user?.class_of_trade_id || !session?.user?.specialty_id">
  <div class="row">
    <div class="col-md-5 col-xl-6 header-holder">
      <h4 class="header" *ngIf="!session?.user?.__theme?.specs_widget_caption && !session?.user?.__theme?.specs_widget_img_url">Please customize your experience by identifying:</h4>
      <h4 class="header" *ngIf="session?.user?.__theme?.specs_widget_img_url" style="height: 1.5em; width: 100%;">
        <img src="{{session?.user?.__theme?.specs_widget_img_url}}" class="pc2-logo-embedded" alt=""/>
      </h4>
      <h4 class="header" *ngIf="!session?.user?.__theme?.specs_widget_img_url && session?.user?.__theme?.specs_widget_caption">{{session.user.__theme.specs_widget_caption}}</h4>
    </div>
    <div class="col-md-7 col-xl-6 controls-holder">
      <div class="select-holder">
        <div class="custom-input-group label-left select-group">
          <select class="form-control custom-form-control" [ngModel]="cot_id" (change)="cot_id = $event.target.value" [ngModelOptions]="{standalone: true}">
            <option value="0" disabled>Class of Trade</option>
            <option *ngFor="let cot of cotsList" value="{{cot.id}}">{{cot.name}}</option>
          </select>
        </div>

        <div class="custom-input-group label-left select-group">
          <select class="form-control custom-form-control" [ngModel]="specialty_id" (change)="specialty_id = $event.target.value" [ngModelOptions]="{standalone: true}">
            <option value="0" disabled>Your Specialty</option>
            <option *ngFor="let spec of specialtiesList" value="{{spec.id}}">{{spec.name}}</option>
          </select>
        </div>
      </div>

      <div class="btn-holder">
        <button (click)="save()" class="btn btn-primary-action btn-apply btn-primary">Apply</button>
      </div>
    </div>
  </div>
</div>
