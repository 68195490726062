<table class="table table-sm table-hover">
  <thead>
    <tr>
      <th width="1%"></th>
      <th>Facility name</th>
      <th>DBA</th>
      <th>GPO ID</th>
      <th>Address</th>
      <th>City, State, ZIP</th>
      <th>Class of Trade</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let rloc of locs">
      <td width="1%"><i *ngIf="rloc.id==rloc.__group_primary_location_id" class="material-icons color-success">star</i></td>
      <td>{{rloc.facility_name}}</td>
      <td>{{rloc.dba}}</td>
      <td>{{rloc.zimmer_id}}</td>
      <td>{{rloc.address}} {{rloc.address2}}</td>
      <td>{{rloc.city}}, {{rloc.state}} {{rloc.zip}}</td>
      <td>{{cotsInternalMap[rloc.class_of_trade_internal_sub_id]?.label}}</td>
      <td><button type="button" (click)="setEditLoc(rloc)" data-toggle="modal" data-target="#editAccLocModal" class="btn btn-sm btn-outline-primary">Edit</button></td>
    </tr>
  </tbody>
</table>
<small><i class="material-icons color-success">star</i> Primary location</small>
<div class="row">
  <div class="col-sm-4 col-md-3 col-lg-2 offset-sm-4 offset-md-6 offset-lg-7 no-pad-right">
    <button type="button" class="btn btn-outline-danger btn-sm btn-block" data-toggle="modal" data-target="#editAccLocRemoveModal">Remove Location</button>
  </div>
  <div class="col-sm-4 col-md-3">
  <button type="button" class="btn btn-primary btn-sm btn-block"
    (click)="newLocation()" data-toggle="modal" data-target="#editAccLocModal">Add New Location</button>
  </div>
</div>
<div class="modal fade" id="editAccLocModal" tabindex="-1" role="dialog" aria-labelledby="Edit Location" aria-hidden="true" #accLocEdit>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="editLoc && editLoc.id">Edit {{editLoc.facility_name}}</h4>
        <h4 class="modal-title" *ngIf="!editLoc || !editLoc.id">New Facility</h4>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body high-modal no-pad-right">
        <div class="container no-pad-right">
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Facility Name</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.facility_name" placeholder="Facility Name" class="form-control"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">DBA</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.dba" placeholder="DBA" class="form-control"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Address</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.address" placeholder="Address" class="form-control"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Address 2</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.address2" placeholder="Address 2" class="form-control">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">City</label>
            <div class="col-sm-9"><input class="form-control" [(ngModel)]="editLoc.city" type="text" placeholder="City"/></div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">State</label>
            <div class="col-sm-9"><input class="form-control" [(ngModel)]="editLoc.state" type="text" placeholder="state"/></div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Postal Code</label>
            <div class="col-sm-9"><input class="form-control" [(ngModel)]="editLoc.zip" type="text" placeholder="ZIP"/></div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Distributor</label>
            <div class="col-sm-9">
              <select class="form-control" [(ngModel)]="editLoc.__distributor_id">
                <option *ngFor="let distr of distributorsList" value="{{distr.id}}">{{distr.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Specialty</label>
            <div class="col-sm-9">
              <select class="form-control" [(ngModel)]="editLoc.specialty_id">
                <option *ngFor="let spec of specialtiesList" value="{{spec.id}}">{{spec.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Website</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.website" placeholder="Website" class="form-control"/>
            </div>
          </div>
          <div class="form-group row" *ngIf="editLoc.id">
            <label class="col-form-label col-sm-3">Doctors Count</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.doctors_count" placeholder="Doctors Count" class="form-control"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Phone</label>
            <div class="col-sm-9">
              <input type="text" [(ngModel)]="editLoc.phone" placeholder="Phone" class="form-control"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="editAccLocRemoveModal" tabindex="-1" role="dialog" aria-labelledby="Remove Location" aria-hidden="true" #accLocRemove>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Remove Location</h4>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Facility Name</label>
            <div class="col-sm-9">
              <select [(ngModel)]="removeLocID" class="form-control">
                <option value="0" disabled>Select facility</option>
                <option *ngFor="let loc of locs" [attr.value]="loc.id">{{loc.facility_name}}{{[loc.address, loc.address2, loc.city, loc.state, loc.zip]|arr_join:', ':' - '}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-sm-3">Reason</label>
            <div class="col-sm-9">
              <textarea [(ngModel)]="removalReason" rows="3" class="form-control"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger" (click)="remove()" [disabled]="saving">Remove</button>
      </div>
    </div>
  </div>
</div>
