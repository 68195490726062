<div #box class="sb-select" [ngClass]="selectClass" (click)="toggleList()" (blur)="blur($event)">
  <span class="sb-select-label" *ngIf="selectedItem">{{selectedItem.element?.nativeElement?.innerText}}</span>
  <span class="sb-select-label sb-placeholder" *ngIf="!selectedItem">{{placeholder}}</span>
  <span class="sb-arrow-down"></span>
</div>
<div #list class="sb-select-container" [ngStyle]="{top: top, left: left}" [ngClass]="{'hidden':!listVisible}">
  <div class="sb-click-catcher" (click)="hideList()"></div>
  <div #scrollbox [ngClass]="dropdownClass" class="sb-dropdown-menu" [ngStyle]="{maxHeight: height, minWidth: width}">
    <ng-content></ng-content>
  </div>
</div>
