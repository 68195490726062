import {Component} from '@angular/core';
declare var $:any;

@Component({
  template: `<a (click)="up()"><span class="icon-chevron-up"></span></a>`,
  selector: 'scroll-up'
})
export class ScrollUpCmp {
  up() {
    $('html,body').animate({scrollTop: 0}, 500);
  }
}
