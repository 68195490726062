import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from "../../services/Config";
declare var $:any;

@Component({
  selector: 'capital-equipment',
  templateUrl: './capital-equipment.html',
})

export class CapitalEquipmentCmp {
  public formData: any = {}
  public messageSent = false;
  private http: HttpClient;
  private config: Config;

  constructor(http: HttpClient, config: Config) {
    this.http = http;
    this.config = config;
  }

  submit() {
    let data = {
      'first_name': this.formData.Field1,
      'last_name': this.formData.Field2,
      'email': this.formData.Field3,
      'in_market_for': this.formData.Field4,
    }
    let url = 'https://api.purchase.guru/_capital_equipment';
    this.http.post(url, data).subscribe((_) => {
      this.messageSent = true;
      $('html,body').animate({scrollTop: 0}, 100);
    });
  }
}
