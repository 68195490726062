<div class="row contract-item" *ngIf="contract && contract.contract_number">
  <div class="col-sm-2 pointer" [routerLink]="['/contract', contract?.contract_number]" [queryParams]="{search: searchTerm}">
    <div class="img-holder">
      <img src="{{contract?.__image_url || '//purchaseclinic.s3.amazonaws.com/placeholder.jpg'}}" class="img-fluid" alt="">
    </div>
  </div>
  <div class="col-sm-10">
    <div class="description-holder">
      <h3 class="headline pointer" [routerLink]="['/contract', contract?.contract_number]" [queryParams]="{search: searchTerm}"><highlight-text [search]="searchTerm" text="{{contract?.vendor_name}}"></highlight-text></h3>
      <ul class="category list-unstyled">
        <li><strong>Contract:&nbsp;</strong> <highlight-text [search]="searchTerm" text="{{contract?.contract_number}}"></highlight-text></li>
        <li *ngIf="contract?.product_category"><strong>Categories:&nbsp;</strong> <highlight-text [search]="searchTerm" text="{{contract?.product_category}}"></highlight-text></li>
        <li *ngIf="subcategory"><strong>Subcategories:&nbsp;</strong> <highlight-text [search]="searchTerm" text="{{subcategory}}"></highlight-text></li>
      </ul>
      <ul *ngIf="contract?.__found_items?.length" class="category list-unstyled">
        <li *ngFor="let cfs of contract.__found_items" [routerLink]="['/contract', contract?.contract_number]" [queryParams]="{search: searchTerm}" class="pointer">
          <highlight-text [search]="searchTerm" text="{{cfs.description}}"></highlight-text><span *ngIf="hasPrices && displayPrices && cfs.price">, ${{cfs.price.toFixed(2)}}</span><span *ngIf="cfs.quantity">, {{cfs.quantity}}</span>
        </li>
      </ul>
      <div class="item-footer" *ngIf="contract?.contract_number">
        <a class="btn btn-info submit-btn btn-primary-action" [routerLink]="['/contract', contract?.contract_number]" [queryParams]="{search: searchTerm}">Learn more</a>
      </div>
    </div>
  </div>
</div>
