import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';

@Component({
  selector: 'form-field',
  template: `<fieldset class="form-group" [ngClass]="{'required':required}">
        <label class="form-control-label" *ngIf="label">{{label}}</label>
        <input type="text" [ngModel]="model" #field (keyup)="updateModel(field.value)"
         [attr.required]="required" autocomplete="new-password"
        class="form-control" placeholder="{{placeholder||label}}">
      </fieldset>`
})
export class FieldCmp {
  @Input() label = '';
  @Input() placeholder:any = false;
  @Input() model;
  @Input() required;
  @Output('modelChange') update = new EventEmitter();

  updateModel(v) {
    this.update.emit(v);
  }
}
