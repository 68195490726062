import {Component} from '@angular/core';
import {FDTickets} from "../../services/FDTickets";
import {Config} from "../../services/Config";
import {UrlParser} from "../../services/UrlParser";
import {ITheme} from "../../interfaces/ITheme";

@Component({
  templateUrl: 'no_thank_you.html',
  selector: 'no-thank-you-page'
})
export class NoThankYouPageCmp {
  public displayThanks = false;
  public practice: string = '';
  public otherText: string = '';
  public sending = false;
  public selectedPractice = '';
  public ticketID: number;
  public fname: string;
  public lname: string;
  public email: string;
  public phone: string;
  public fnameNew: string;
  public lnameNew: string;
  public emailNew: string;
  public phoneNew: string;
  public additionalInfo: string;
  public themeName = 'Purchase Clinic';
  public p = [
    'My organization is already a Purchase Clinic member',
    'My organization is part of or owned by an IDN/Health System',
    'Please provide more information on how the program works',
    'I am not the decision maker',
    'Other'
  ];

  constructor(private tickets: FDTickets, private config: Config, private urlParser: UrlParser) {
    let tID = this.config.getThemeIDForHost();
    if (this.config.themesMap[tID]) {
      let theme: ITheme = this.config.themesMap[tID];
      if (theme.name) {
        this.themeName = theme.name;
        let fl = theme.name.trim()[0].toLowerCase();
        let an = ['e', 'u', 'i', 'o', 'a'];
        if (an.indexOf(fl) > -1) {
          this.p[0] = this.p[0].replace('is already a ', 'is already an ');
        }
        if (this.themeName != 'Purchase Clinic') {
          this.p[0] = this.p[0].replace('Purchase Clinic', theme.name);
        }
      }
    }
  }

  ngOnInit() {
    let params = this.urlParser.getParameters();
    if (params['email']) {
      this.email = params['email'];
    }
    if (params['fname']) {
      this.fname = params['fname'];
    }
    if (params['lname']) {
      this.lname = params['lname'];
    }
    if (params['phone']) {
      this.phone = params['phone'];
    }
  }

  submit() {
    if (!this.practice) {
      return;
    }
    this.sending = true;
    try {
      let params = this.urlParser.getParameters();
      let sp = '';
      for (let k in params) {
        sp = sp + `<div>${k}: ${params[k]}</div>`;
      }
      let body = sp + `<br/><div><strong>Tell us more about your practice:</strong></div>
               <div>${this.practice}</div>`;
      if (this.otherText) {
        body = body + `<div>${this.otherText}</div>`
      }
      let from = this.config.defaultTheme.email_from_address;
      if (this.email) {
        from = this.email;
      }
      let customFields = {};
      if (this.config.defaultTheme && this.config.defaultTheme.name) {
        customFields['brand'] = this.config.defaultTheme.name;
      }
      this.tickets.create(from, "User Did Not Sign Up", body, customFields).subscribe((r) => {
        this.sending = false;
        this.selectedPractice = this.practice;
        if (this.selectedPractice == 'Other') {
          this.displayThanks = true;
        }
        let id = parseInt(r);
        if (id > 0) {
          this.ticketID = id;
        } else {
          this.displayThanks = true;
          console.error(`FD ticket ID is empty: [${r}:${id}]`);
        }
      }, () => {
        this.sending = false;
        this.displayThanks = true;
      });
    } catch (e) {
      this.sending = false;
      this.displayThanks = true;
    }
  }

  sendNote() {
    if (!this.ticketID) {
      this.displayThanks = true;
      return;
    }
    let note = `<div><i>Additional data for:</i></div><div>${this.selectedPractice}</div><hr/>`;
    switch (this.selectedPractice) {
      case this.p[3]:
        note = note + `<p><em>With whom in your office should we work with?</em></p>
              <div>First Name: <strong>${this.fnameNew}</strong></div>
              <div>Last Name: <strong>${this.lnameNew}</strong></div>
              <div>Email: <strong>${this.emailNew}</strong></div>
              <div>Phone: <strong>${this.phoneNew}</strong></div>`;
        break;
      case this.p[2]:
        note = note + `<p><em>Please confirm the best method of communication:</em></p>
              <div>First Name: <strong>${this.fname}</strong></div>
              <div>Last Name: <strong>${this.lname}</strong></div>
              <div>Email: <strong>${this.email}</strong></div>
              <div>Phone: <strong>${this.phone}</strong></div>
              <br/>
              <p><em>What is the best time of day to contact you about our program? (Please include timezone)</em></p>
              <div><strong>${this.additionalInfo}</strong></div>`;
        break;
      case this.p[1]:
        note = note + `<p><em>Do you have independent or affiliated physician offices and surgery centers that are outside the health system?</em></p><div><strong>${this.additionalInfo}</strong></div>`;
        break;
      case this.p[0]:
        note = note + `<p><em>Please confirm the best method of communication to discuss the benefits of the new program.</em></p>
              <div>First Name: <strong>${this.fname}</strong></div>
              <div>Last Name: <strong>${this.lname}</strong></div>
              <div>Email: <strong>${this.email}</strong></div>
              <div>Phone: <strong>${this.phone}</strong></div>
              <br/>
              <p><em>What is the best time of day to contact you about our program? (Please include timezone)</em></p>
              <div><strong>${this.additionalInfo}</strong></div>`;
        break;
      default:
        note = note + `<div><strong>${this.additionalInfo}</strong></div>`;
    }
    this.sending = true;
    this.tickets.addNote(this.ticketID, note).subscribe(() => {
      this.sending = false;
      this.displayThanks = true;
    }, () => {
      this.sending = false;
      this.displayThanks = true;
    });
  }
}
