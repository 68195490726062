import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ILocation} from '../interfaces/ILocation';
import {ItemsRange} from './ItemsRange';
import {Observable, Observer} from 'rxjs';
import {ITemplateVar} from "../interfaces/ITemplateVar";
import {ILocationContact} from "../interfaces/ILocationContact";
import {ILocGroupSales} from "../interfaces/ILocGroupSales";
import {ILocGroupFile} from "../interfaces/ILocGroupFile";
import {ILocationWithLC} from "../interfaces/ILocationWithLC";
import {ILocGroupSalesCycle} from "../interfaces/ILocGroupSales";
import {ISignupAnswer, ISignupInfoEdit} from "../interfaces/ISignupWithPA";

declare var _;

@Injectable()
export class Locations {
  public types = {leads: 'leads', prospects: 'prospects', customers: 'customers', total: 'total'};
  public counters = {leads: 0, prospects: 0, customers: 0, total: 0};
  private list = {leads: [], prospects: [], customers: [], total: []};
  private loadingPromises = {};

  constructor(private config: Config, private http: HttpClient, private session: Session, private itemsRange: ItemsRange) {}

  getLocation(id) {
    return new Promise((resolve, reject) => {
      if (this.list) {
        for (let kind in this.list) {
          if (this.list.hasOwnProperty(kind) && this.list[kind].length > 0) {
            let i = _.findIndex(this.list[kind], {id: id});
            if (i > -1 && i !== false && this.list[kind][i].id === id) {
              resolve(this.list[kind][i]);
              return;
            }
          }
        }
      }
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get<ILocation>(this.config.apiUrl + '/location/' + id, {headers: headers}).subscribe(resolve, reject);
    });
  }

  updateLocation(location: ILocation): Promise<number> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.put(this.config.apiUrl + '/location', JSON.stringify(location), {headers: headers, responseType: 'text'}).subscribe((id) => {
        resolve(parseInt(id));
      }, reject);
    });
  }

  sendMiniSurvey(q, token: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = headers.append('Authorization', 'Bearer ' + token);
    return this.http.put(this.config.apiUrl + '/mini_survey', JSON.stringify(q), {headers: headers, responseType: 'text'});
  }

  getRelatedLocationsWithLC(id: number): Observable<ILocationWithLC[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocationWithLC[]>(this.config.apiUrl + '/locations_related/' + id + '/with_lifecycle', {headers: headers});
  }

  uploadFile(locationID: number, filename: string, data, fileTypeID?: number): Observable<number> {
    let fileNameSanitized = filename.replace(/[^a-z0-9.]/gi, '_');
    let url = '/location/file/' + locationID + '/' + fileNameSanitized;
    if (fileTypeID) {
      url = url + '/' + fileTypeID;
    }
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      r.open("POST", this.config.apiUrl + url, true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  updateByUser(location): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/location/by_user', JSON.stringify(location), {headers: headers});
  }

  sendCrmMessage(location_pcid: string, message: string, email_template: string, email_vars: ITemplateVar[], email_subject: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/crm_message', JSON.stringify({
      location_pcid: location_pcid,
      message: message,
      email_template: email_template,
      email_vars: email_vars,
      email_subject: email_subject,
    }), {headers: headers}).toPromise().then(() => {}, () => {});
  }

  setVendorInterested(location_id: number, vendorID: number) {
    this.http.post(this.config.apiUrl + '/implementation/vendor/interested', JSON.stringify({
      impl_vendor_id: vendorID,
      location_id: location_id
    })).subscribe(() => {}, () => {});
  }

  getContactsOfGroup(location_group_id: number): Observable<ILocationContact[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocationContact[]>(this.config.apiUrl + '/location_group/' + location_group_id + '/contacts', {headers: headers});
  }

  getSalesOfGroup(location_group_id: number): Observable<ILocGroupSales[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocGroupSales[]>(this.config.apiUrl + '/report/location_group_sales/' + location_group_id, {headers: headers});
  }

  getSalesCycleOfGroup(location_group_id: number): Observable<ILocGroupSalesCycle[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocGroupSalesCycle[]>(this.config.apiUrl + '/report/location_group_sales_cycle/' + location_group_id, {headers: headers});
  }

  getRebatesOfGroup(location_group_id: number): Observable<ILocGroupSales[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocGroupSales[]>(this.config.apiUrl + '/report/location_group_rebates/' + location_group_id, {headers: headers});
  }

  getFilesOfGroup(location_group_id: number): Observable<ILocGroupFile[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocGroupFile[]>(this.config.apiUrl + '/files_of_location_group/' + location_group_id, {headers: headers});
  }

  sendSiimCompletedEmail(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/siim/email_completed', '', {headers: headers});
  }

  recordSignupAnswers(answers: ISignupAnswer[]): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/signup_answers', JSON.stringify(answers), {headers: headers});
  }

  recordSignupInfoEdit(edits: ISignupInfoEdit[]): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/signup_info_edit', JSON.stringify(edits), {headers: headers});
  }
}
