import {Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {Session} from "../../services/Session";
import {EditRequests} from "../../services/EditRequests";
import {IUser} from "../../interfaces/IUser";
import {ILocation} from "../../interfaces/ILocation";
import {Notify} from "../../services/Notify";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {IDistributor} from "../../interfaces/IDistributor";
import {ICot} from "../../interfaces/ICot";
import {Cots} from "../../services/Cots";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {User} from "../../services/User";
import {IEditRequest} from "../../interfaces/IEditRequest";
import {ILocationContact} from "../../interfaces/ILocationContact";
declare var $:any;

@Component({
  selector: 'account-contacts',
  templateUrl: './account-contacts.html',
})
export class AccountContactsCmp {
  @Input() contacts:ILocationContact[];
  @Input() location:ILocation;
  @Input() cmUserId:number = 0;
  @Output() contactsChange = new EventEmitter();
  @ViewChild('accContactEdit') modal:ElementRef;
  @ViewChild('accPrimaryContactChange') modalPrimaryChange:ElementRef;
  @ViewChild('accPurchasingContactChange') modalPurchasingChange:ElementRef;
  public editContact:IUser = <IUser>{};
  public saving = false;
  public savingPrimaryContact = false;
  public savingPurchasingContact = false;
  public distributorsList:IDistributor[] = [];
  public specialtiesList:ISpecialty[] = [];
  public cotChangeInfo:string;
  public user:IUser;
  public sendNewsletter:boolean = null;
  public isPhysician:boolean = null;
  public savingNewsletter:boolean = false;
  public togglingPhysician:boolean = false;
  public newPrimaryContactID:number;
  public newPurchasingContactID:number;
  private originalContact:IUser;
  private notify:Notify;

  constructor(private session:Session, private editReqSrv:EditRequests, public distributors:Distributors,
    public specialties:Specialties, private usrSrv:User, private changeDetector:ChangeDetectorRef) {
    this.notify = new Notify();
    this.distributors.loadToList(this.distributorsList);
    this.specialties.loadToList(this.specialtiesList);
    this.session.addOnLoadHandler(()=> {
      if (this.session.user) {
        this.user = this.session.user;
        this.sendNewsletter = this.user.send_newsletter;
        this.isPhysician = (this.user.job_type_id == 1);
      }
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (this.location && this.location.id) {
      this.newPrimaryContactID = this.location.primary_contact_user_id;
      this.newPurchasingContactID = this.location.__purchasing_contact_user_id;
    }
  }

  setEditContact(contact:IUser) {
    if (!this.user) {
      return;
    }
    this.editContact = $.extend(true, {}, contact);
    this.originalContact = $.extend(true, {}, contact);
    setTimeout(()=> {
      this.modalShow();
    }, 1);
  }

  newContact() {
    if (this.session && this.session.user) {
      this.editContact = <IUser>{};
      this.editContact.theme_id = this.session.user.theme_id;
      if (this.location && this.location.id) {
        this.editContact.location_id = this.location.id;
        this.editContact.manager_id = this.location.__manager_id;
      } else {
        this.editContact.location_id = this.session.user.location_id;
        this.editContact.manager_id = this.session.user.manager_id;
      }
      this.editContact.specialty_id = this.session.user.specialty_id;
      this.editContact.class_of_trade_id = this.session.user.class_of_trade_id;
      setTimeout(()=> {
        this.modalShow();
      }, 1);
    }
  }

  save() {
    if (this.editContact) {
      if (!this.editContact.email) {
        this.notify.error('Email is required field');
        return false;
      }
      if (this.editContact.id) {
        if (!this.originalContact) {
          console.error('No contact to compare with');
          return false;
        }
        this.saving = true;
        this.editReqSrv.saveRecordEdits(this.editContact, this.originalContact, 'user', 'id', this.cmUserId).subscribe(()=> {
          this.saving = false;
          if (this.contacts && this.contacts.length) {
            this.contacts.forEach((contact, i)=> {
              if (contact.id === this.editContact.id) {
                let ec = this.editContact;
                this.contacts[i] = {
                  id: ec.id,
                  contact_note: ec.contact_note,
                  email: ec.email,
                  fname: ec.fname,
                  lname: ec.lname,
                  send_newsletter: ec.send_newsletter,
                  is_email_suppressed: ec.is_email_suppressed,
                  phone: ec.phone,
                  type_id: ec.type_id,
                  is_not_usable_as_contact: ec.is_not_usable_as_contact
                };
              }
            });
          }
          this.modalHide();
          this.notify.success('Edit request has been sent');
        }, (err)=> {
          this.saving = false;
          this.notify.error('Error');
          console.error(err);
        });
      } else {
        this.saving = true;
        this.usrSrv.updateUser(this.editContact).then((id)=> {
          this.editContact.id = id;
          if (!this.contacts) {
            this.contacts = [];
          }
          this.contacts.push($.extend(true, {}, this.editContact));
          let req = <IEditRequest>{is_new_record: true};
          req.from_user_id = this.session.user.id;
          req.cm_user_id = this.cmUserId;
          req.edit_table = 'user';
          req.record_id_field = 'id';
          req.record_id_value = id.toString();
          this.editReqSrv.addRequest(req).subscribe(()=> {
            this.saving = false;
            this.modalHide();
            this.notify.success('Successfully!');
          }, (err)=> {
            this.saving = false;
            this.notify.error('Error');
            console.error(err);
          });
        }, (err)=> {
          this.saving = false;
          this.notify.error('Error');
          console.error(err);
        });
      }
    }
  }

  modalHide() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('hide');
    }
  }

  modalShow() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('show');
    }
  }

  modalPrimaryHide() {
    if (this.modalPrimaryChange && this.modalPrimaryChange.nativeElement) {
      $(this.modalPrimaryChange.nativeElement).modal('hide');
    }
  }

  modalPurchasingHide() {
    if (this.modalPurchasingChange && this.modalPurchasingChange.nativeElement) {
      $(this.modalPurchasingChange.nativeElement).modal('hide');
    }
  }

  setNewPrimaryContact() {
    if (!this.newPrimaryContactID || !this.location || !this.user) {
      return false;
    }
    let r = <IEditRequest>{};
    r.from_user_id = this.user.id;
    r.cm_user_id = this.cmUserId;
    r.edit_table = 'location';
    r.edit_field = 'primary_contact_user_id';
    r.record_id_field = 'id';
    r.record_id_value = this.location.id.toString();
    r.is_new_record = false;
    r.old_value = this.location.primary_contact_user_id.toString();
    r.new_value = this.newPrimaryContactID.toString();
    this.savingPrimaryContact = true;
    let n = new Notify();
    this.editReqSrv.addRequest(r).subscribe(()=> {
      this.savingPrimaryContact = false;
      n.success('Successful!');
      this.modalPrimaryHide();
    }, (err)=> {
      this.savingPrimaryContact = false;
      console.error(err);
      n.error(JSON.stringify(err), 'Error');
    });
  }

  setNewPurchasingContact() {
    if (!this.newPurchasingContactID || !this.location || !this.user) {
      return false;
    }
    let r = <IEditRequest>{};
    r.from_user_id = this.user.id;
    r.cm_user_id = this.cmUserId;
    r.edit_table = 'location_group';
    r.edit_field = 'purchasing_contact_user_id';
    r.record_id_field = 'id';
    r.record_id_value = this.location.location_group_id.toString();
    r.is_new_record = false;
    r.old_value = this.location.__purchasing_contact_user_id.toString();
    r.new_value = this.newPurchasingContactID.toString();
    this.savingPurchasingContact = true;
    let n = new Notify();
    this.editReqSrv.addRequest(r).subscribe(()=> {
      this.savingPurchasingContact = false;
      n.success('Successful!');
      this.modalPurchasingHide();
    }, (err)=> {
      this.savingPurchasingContact = false;
      console.error(err);
      n.error(JSON.stringify(err), 'Error');
    });
  }

  toggleSendNewsletter($event) {
    if ($event.stopPropagation) {
      $event.stopPropagation();
    }
    if ($event.preventDefault) {
      $event.preventDefault();
    }
    if (!this.user || !this.user.id) {
      return;
    }
    setTimeout(()=> {
      if (this.sendNewsletter === true) {
        this.sendNewsletter = false;
      } else {
        this.sendNewsletter = true;
      }
      if (this.user.send_newsletter !== this.sendNewsletter) {
        this.savingNewsletter = true;
        let ex = this.user.send_newsletter;
        this.user.send_newsletter = this.sendNewsletter;
        this.usrSrv.updateUser(this.user).then(()=> {
          this.savingNewsletter = false;
          this.sendNewsletter = this.user.send_newsletter;
          this.session.loadUser(()=> {
            this.user = this.session.user;
            this.sendNewsletter = this.user.send_newsletter;
          });
        }, ()=> {
          this.savingNewsletter = false;
          this.user.send_newsletter = ex;
          this.sendNewsletter = ex;
        });
      }
    }, 1);
    return false;
  }

  togglePhysician($event) {
    if ($event.stopPropagation) {
      $event.stopPropagation();
    }
    if ($event.preventDefault) {
      $event.preventDefault();
    }
    if (!this.user || !this.user.id) {
      return;
    }
    setTimeout(()=> {
      if (this.isPhysician === true) {
        this.isPhysician = false;
      } else {
        this.isPhysician = true;
      }
      if ((this.user.job_type_id == 1) !== this.isPhysician) {
        this.togglingPhysician = true;
        let ex = this.user.job_type_id;
        this.user.job_type_id = (this.isPhysician ? 1 : 0);
        this.usrSrv.updateUser(this.user).then(()=> {
          this.togglingPhysician = false;
          this.isPhysician = (this.user.job_type_id == 1);
          this.session.loadUser(()=> {
            this.user = this.session.user;
            this.isPhysician = (this.user.job_type_id == 1);
          });
        }, ()=> {
          this.togglingPhysician = false;
          this.user.job_type_id = ex;
          this.isPhysician = (ex == 1);
        });
      }
    }, 1);
    return false;
  }

  removeContact(contact:ILocationContact) {
    if (!this.user || !this.user.id || !contact || !contact.id || contact.is_not_usable_as_contact || contact.id == this.user.id || contact['__removing']) {
      return;
    }
    let req:IEditRequest = {
      from_user_id: this.user.id,
      edit_table: 'user',
      edit_field: 'is_not_usable_as_contact',
      record_id_field: 'id',
      record_id_value: contact.id.toString(),
      is_new_record: false,
      old_value: "0",
      new_value: "1"
    };
    contact['__removing'] = true;
    this.editReqSrv.addRequest(req).subscribe(()=> {
      contact.is_not_usable_as_contact = true;
      contact['__removing'] = false;
      this.notify.success('Edit request has been sent');
    }, (err)=> {
      contact['__removing'] = false;
      this.notify.error('Error');
      console.error(err);
    });
  }
}
