import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {ITheme} from "../../interfaces/ITheme";

@Component({
  selector: 'faq',
  templateUrl: './faq.html'
})
export class FAQCmp {
  public themeID = 0;
  public themeName = 'Purchase Clinic';
  public theme:ITheme;
  public themeHost = 'PurchaseClinic.com';

  constructor(private session:Session) {
    this.theme = this.session.getCurrentTheme();
    this.findThemeID();
    this.session.addOnLoadHandler(()=> {
      this.findThemeID();
    });
  }

  ngAfterViewInit() {
    this.findThemeID();
  }

  findThemeID() {
    let t = this.session.getCurrentTheme();
    if (t && t.id) {
      this.themeID = t.id;
    }
    if (t && t.name) {
      this.themeName = t.name;
    }
    if (t && t.landing_domain) {
      let h = t.landing_domain.replace('savings.', '').replace('communitypurchasingprogram.com', 'CommunityPurchasingProgram.com').replace('purchaseclinic.com', 'PurchaseClinic.com');
      if (h && h.length > 4) {
        this.themeHost = h;
      }
    }
  }
}
