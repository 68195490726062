<section class="main-content content-general">
  <div class="container">
    <div class="row sec-1">
      <div class="col-12 bordered-container">
        <h1 class="headline">Privacy Policy</h1>
        <div class="bordered-content clearfix">

          <p>Last revised: February 1, 2023</p>
          <amp-pc-tos-line></amp-pc-tos-line>
          <p>This Privacy Policy applies to the <a href="http://www.PurchaseClinic.com">www.PurchaseClinic.com</a> website (the "Website") of Expansion, LLC, a Maryland limited liability company doing business as PurchaseClinic.com ("we," "our" or "us"). This Privacy Policy describes the information that we collect through the Website and how we use it, the circumstances in which we may share it, how we protect it, and the choices you can make about your information.</p>

          <p>If you do not agree with the terms of this Privacy Policy, the Website Terms of Use (the "Terms of Use"), or the Terms of Service (the "Terms of Service"), you should immediately stop using or visiting the Website.</p>

          <p><strong>CONSENT:</strong></p>

          <p>The Website and its services (collectively, the "Services") are intended for users located in the United States. If you are located outside of the United States, please be aware that the information we collect is stored on servers in the United States. The data protection laws in the United States may differ from those of the country in which you are located. By using the Website and the Services or providing us with any information, you consent to the transfer to, and processing, usage, sharing, and storage of your information in the United States as set forth in this Privacy Policy.</p>

          <p><strong>Information We Collect</strong></p>

          <p>We collect the following types of information in the manner described below.</p>
          <ol>
            <li>You may voluntarily provide us with information, including:
              <ul>
                <li>Your name, organization, title, postal address, email address, telephone number, user name, password, credit card and other payment information as well as other information to use the Website and the Services, including registering;</li>
                <li>Information when you contact or interact with us; and</li>
                <li>Any other information you voluntarily provide us with in connection with using the Website and the Services (as defined in the Terms of Service), including your comments and other content.</li>
                <li>Your Internet Protocol (IP) address;</li>
                <li>Domain name of your Internet service provider;</li>
                <li>Your geographic location;</li>
                <li>The Website pages that you visit and the time of your visits; and</li>
                <li>Aggregated information that cannot be used to specifically identify you when you use or visit the Website and the Services.</li>
              </ul>
            </li>
            <li>We may automatically collect other information about you, including:
              <ul>
                <li>Interaction with our sites including page views and content views</li>
                <li>Information about browsers and devices user access with our sites</li>
                <li>Browsing activities across our sites</li>
              </ul>
            </li>
            <li>We may collect information about you from commercially available third-party sources.</li>
            <li>We may collect information about you as permitted or required by law.</li>
            <li>We may combine all of the information we collect from or about you and use it in the manner described in this Privacy Policy.</li>
          </ol>

          <p><strong>How We Use Your Information</strong></p>

          <p>We use the information that we collect for the following purposes, including:</p>
          <ul>
            <li>The purposes for which you provided it;</li>
            <li>To process and respond to your inquiries;</li>
            <li>For fulfillment of orders and requests;</li>
            <li>To identify you as a user of the Website and the Services;</li>
            <li>To send you information about your relationship with us;</li>
            <li>To contact you with information, including promotional information, that we believe will be of interest to you;</li>
            <li>To provide the services you request, including the Website and the Services;</li>
            <li>To create anonymous data, which we may use for any purpose;</li>
            <li>To analyze the Website and the Services;</li>
            <li>To allow us to personalize and enhance your experience using the Website and the Services;</li>
            <li>To generate and review reports and data about our user base and service usage patterns;</li>
            <li>To analyze the accuracy, effectiveness, usability, or popularity of the Website and the Services;</li>
            <li>To improve the Website and the Services or develop new products and services;</li>
            <li>To compile aggregate data for internal and external business purposes;</li>
            <li>To prevent fraudulent transactions, monitor against theft, and otherwise protect our users and our business;</li>
            <li>To administer and troubleshoot the Website and the Services; and</li>
            <li>As otherwise stated in this Privacy Policy, the Terms of Use or the Terms of Service or as permitted or required by law.</li>
          </ul>

         <p>PLEASE NOTE THAT YOU MAY CHOOSE TO NOT TO ALLOW US TO USE YOUR INFORMATION FOR CERTAIN PURPOSES (SUCH AS TO SEND YOU PROMOTIONAL INFORMATION) AS DESCRIBED IN YOUR CHOICES REGARDING YOUR INFORMATION BELOW.</p>

		<p><strong>How Your Information is Disclosed</strong></p>

          <p>Information may be disclosed by us to third parties in accordance with our Privacy Policy. Please note that a user may choose not to share certain information as described in Your Choices Regarding Your Information below.</p>

          <ul>
            <li><u>Affiliated GPO Suppliers</u>. We may disclose your information to one or more GPOs so that you may enter into Participation Agreements (as defined in the Terms of Service) and to suppliers affiliated with such GPOs so that you may complete transactions contemplated under the Participation Agreements.</li>
            <li><u>Third-Party Service Providers</u>. Our site may use technologies of third parties to help us recognize your device and understand how you use our Website so that we can improve our services to reflect your interests and provide you with advertisements about the products and/or services that are likely to be of interest to you.  We may use these third parties to perform functions in connection with the Website and the Services (for example, postal mail, e-mail, customer lists, data analysis, data hosting, marketing, credit card and other payment processing, order fulfillment, customer service, etc.). We may share information about you with these third parties, or allow these third parties to collect information about you on our behalf, that they need to perform their functions and in accordance with our agreements with them.  Information collected by third parties about your activity may enable us to:.</li>
               <ul>
                	<li>Measure and analyze traffic and browsing activity on our Website</li>
               	<li>Show advertisements for our products and/or services to you on third-party sites</li>
               	<li>Measure and analyze the performance of our advertising campaigns</li>
                </ul>
           <li><u>Cross-Device</u>. We may share data, such as hashed email derived from emails or other online identifiers collected on our Website with third parties, including NextRoll Platform Services.  This allows our partners to recognize and deliver you relevant advertisements across devices and browsers and to provide measurement and analytic information.  To read more about the technologies used by NextRoll and their cross-device capabilities, please refer to <a href="https://www.nextroll.com/privacy">NextRoll's Privacy Notice</a>.  Instructions for opting out from receiving cross-device site advertising are available by accessing your device settings or by visiting and employing the controls described on the <a href="https://www.networkadvertising.org/mobile-choice/"> NAI's Mobile Choices page</a>.</li>
           <li><u>Third Parties for Their Direct Marketing and Other Promotional Purposes</u>.We may share your information with third parties for their direct marketing and other promotional purposes. Please see Your California Privacy Rights below.</li>
	             <li><u>Business Changes</u>. If we become involved in a merger, acquisition, sale of assets, joint ven
            <li><u>Business Changes</u>. If we become involved in a merger, acquisition, sale of assets, joint venture, securities offering, bankruptcy, reorganization, liquidation, dissolution, or other transaction or if the ownership of all or substantially all of our business otherwise changes, we may share or transfer your information to a third party or parties in connection therewith.</li>
            <li><u>Subsidiaries and Affiliates.</u>We may also share your information with our subsidiaries and affiliates for purposes consistent with this Privacy Policy. Our subsidiaries and affiliates will be required to maintain that information in accordance with this Privacy Policy. We may also share your information with our subsidiaries and affiliates so that they can contact you with information, including promotional information, that we or they believe will be of interest to you.</li>
            <li><u>Other Disclosures</u>. Regardless of the choices you make regarding your information (as described below), we may disclose information about you to third parties to:
              <ul>
                <li>Enforce or apply the Terms of Use or the Terms of Service;</li>
                <li>Comply with law, in response to subpoenas, warrants, or court orders, or in connection with any legal process or cooperate with government or law enforcement officials or private parties;</li>
                <li>Protect our rights, reputation, safety and property, or that of our users or others (for example, protecting the security of the Website);</li>
                <li>Protect against potential liability;</li>
                <li>Establish or exercise our rights to defend against legal claims involving your information and/or in which you are a party; or; or</li>
                <li>To investigate, prevent, or take action regarding suspected illegal activities, suspected fraud, the rights, reputation, safety or property of us, users or others, violation of the Terms of Use or the Terms of Service, our policies or agreements or as otherwise permitted or required by law.</li>
              </ul>
            </li>
            <li><u>Aggregated Information</u>. We may share aggregated, anonymized information relating to visitors to and users of the Website and the Services with affiliated or unaffiliated third parties..</li>
            <li><u>Cookies and Other Technologies</u>. Please see Cookies and Other Technologies below.</li>

          </ul>

          <p><strong>Online Tracking</strong></p>

          <p>
            Currently, please note our Sites do not support "Do Not Track" browser settings and do not currently participate in any
            "Do No Track" frameworks that would allow us to respond to signals or other mechanisms from you regarding the collection
            of your personal or non-personally identifiable information.
          </p>

          <p><strong>Nevada Privacy Rights</strong></p>

          <p>
            We comply with the requirements of the Nevada Privacy law, which provides residents with choices regarding how we share
            information. Nevada Covered Personal Information (“Nevada Personal Information”) includes personally identifiable
            information about a Nevada consumer collected online, such as an identifier that allows the specific individual to be
            contacted. Nevada Personal Information also includes any other information about a Nevada consumer collected online that
            can be combined with an identifier to identify the specific individual.
          </p>

          <p>
            Information may be disclosed to third parties in accordance with our Privacy Policy. Please note that a user may choose
            not to share certain information as described in Your Choices Regarding Your Information which is listed in “How Your
            Information is Disclosed”. Third parties may collect covered information about your online activities over time and across
            different Internet websites or online services when you use. The definitions of "personal information," "share," and
            "sale" under the law are broad. Because of the breadth of these definitions under the law, we have provided the below
            choices. You have the right to direct us not to sell or share your Nevada Personal Information.
          </p>

          <p>You have choices regarding the use and disclosure of information on the Website and the Services.</p>

          <ul>
            <li>
              <u>Changing Your Information.</u> You may make changes to your information, including keeping your information accurate,
              by contacting us at <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com.</a>
            </li>
            <li>
              <u>Email Communications.</u> You can make changes regarding receiving email communications from us by contacting us at
              <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com.</a>
            </li>
            <li><u>Cookies and Other Technologies.</u> Please see Cookies and Other Technologies above.</li>
          </ul>

          <p><strong>Cookies and Other Technologies</strong></p>

          <p>We use persistent and session cookies and other technologies. These technologies are used for tracking, analytics, Website optimization and to personalize our and third-party interactions with you. These technologies may also be used by third-party advertisers that place ads on our Website.</p>

          <p>Cookies are small bits of information that are transferred to and stored on your computer’s hard drive in separate files within your computer’s browser. You can instruct your browser to stop accepting cookies. But if you do not accept cookies, you may not be able to use all portions or all functionality of the Website.</p>
          <ul>
            <li>Persistent cookies remain on the visitor&#8217;s computer after the browser has been closed. For example, we use persistent cookies to remember a visitor when the visitor returns to the Website after visiting another website.</li>
            <li>Session cookies exist only during a visitor&#8217;s online session and disappear from the visitor&#8217;s computer when the visitor closes the browser software. For example, we may use session cookies to remember what a visitor placed in the visitor's cart.</li>
          </ul>

          <p>To opt-out of Google Analytics:<br/>
            <a href="http://www.google.com/settings/ads?hl=en&sig=ACi0TChk26H-DmBPOOQ6QrKD9pPnECQr3joxphMpG7iSmmqmobB9muCZIwHv0-R9-m7A2tY2ZKAKqU4B2FIn-tMHPTNBqdyOy_DQOf3Pw7diFoM3O0-zNrwJQG4DfjOISMYas-4t6cDEPSoBhLBqVy3o3wp6N3BeqOiirKAGhTTIj051WvMCgGYWSiU8b1QbN-Aq6QhWIzq8_81VD91HZxAQzxmnaPl1NA">http://www.google.com/settings/ads?hl=en&sig=ACi0TChk26H-DmBPOOQ6QrKD9pPnECQr3joxphMpG7iSmmqmobB9muCZIwHv0-R9-m7A2tY2ZKAKqU4B2FIn-tMHPTNBqdyOy_DQOf3Pw7diFoM3O0-zNrwJQG4DfjOISMYas-4t6cDEPSoBhLBqVy3o3wp6N3BeqOiirKAGhTTIj051WvMCgGYWSiU8b1QbN-Aq6QhWIzq8_81VD91HZxAQzxmnaPl1NA</a></p>

          <p>For the Google Analytics Opt-out Browser Add-on:<br />
            <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a></p>

          <p>Managing your Cookies in Internet Explorer:<br />
            <a href="https://support.microsoft.com/en-us/kb/196955">https://support.microsoft.com/en-us/kb/196955</a></p>

          <p>Managing your Cookies in Firefox:<br />
            <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies</a></p>

          <p>Managing your Cookies in Chrome:<br />
            <a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a></p>

          <p>Managing your in Safari:<br />
            <a href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a></p>

           <p>We may allow third parties, including third-party ad networks, to display advertising on the Website. These third parties may use cookies, web beacons, and other technologies to display advertising and to track visitor behavior on the Website and other websites. Some of these technologies may not be impacted by browser settings that block cookies, and your browser may not permit you to block them.  You can use the following third-party tools to opt out of the collection and use of information for the purpose of providing you interest-based advertising:</p>

          <p>Network Advertising Initiative:<br />
            <a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a></p>

          <p>Digital Advertising Alliance:<br />
            <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a></p>

          <p><strong>Security</strong></p>

          <p>While we take reasonable measures to protect the information you submit via the Website and the Services against loss, theft, and unauthorized use, access, disclosure, or modification, we cannot guarantee the security of personal and other information you may submit via the WebSite. We periodically review these security measures. If you are logged into the Website, we use Secure Sockets Layer (SSL) software to encrypt the information you input. No Internet or email transmission is ever fully secure or error-free. Email sent to or from the Website may not be secure. You should use caution whenever submitting information online and take special care in deciding what information you send to us via email. </p>

          <p><strong>Links to Other Websites</strong></p>

           <p>This Privacy Policy applies only to the Website. The Website may contain links to other websites to which this Privacy Policy does not apply. The links from the Website do not imply that we endorse or have reviewed these websites. The policies and procedures we describe here do not apply to these websites. We neither can control nor are responsible for the privacy practices or content of these websites. You should contact these websites directly for information on their privacy policies. </p>

          <p><strong>Your Choices Regarding Your Information</strong></p>

          <p>You have choices regarding the use and disclosure of information on the Website and the Services.</p>
          <ol>
            <li><u>Changing Your Information</u>. You may make changes to your information, including keeping your information accurate, by contacting us at <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com</a>.</li>
            <li><u>Email Communications</u>. You can make changes regarding receiving email communications from us by contacting us at <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com</a>.</li>
            <li><u>Cookies and Other Technologies</u>. Please see Cookies and Other Technologies above.</li>
          </ol>

          <p><strong>Children</strong></p>

         <p>We do not knowingly collect personal information from children under age 18. If you are under age 18, please do not submit any personal information through the Website. If you have reason to believe that we may have accidentally received personal information from a child under age 18, please contact us immediately at <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com</a>.</p>

          <p><strong>Changes to Privacy Policy</strong></p>

          <p>We reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this Privacy Policy periodically, and especially before you provide any information. This Privacy Policy was made effective on the date indicated above.</p>

          <p><strong>How to Contact Us</strong></p>

          <p><strong>Information for California Residents Only - California Privacy Rights</strong></p>

          <p>This section supplements the information contained in the Privacy Policy and applies solely for visitors, users, and others who are residents of the State of California, as defined in Section 17014 of Title 18 of the California Code of Regulations, for the <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180SB1121">California Consumer Privacy Act of 2018 (CCPA)</a>.</p>

          <p>Please see the <a href="https://www.nextroll.com/privacy#service-13">California Residents section of NextRoll's Service Privacy Notice</a> concerning services provided, and information collected and used by NextRoll.</p>

          <p>Section 1798.83 of the California Civil Code permits California residents to request from a business, with whom the California resident has an established business relationship, certain information about the types of personal information the business has shared with third parties for those third parties’ direct marketing purposes and the names and addresses of the third parties with whom the business has shared such information during the immediately preceding calendar year. You may make one request each year by emailing us at <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com</a> or writing us at PurchaseClinic, 10221 River Road #60053, Potomac, MD 20854.</p>

          <p><strong>How to Contact Us</strong></p>

          <p>If you have any questions about this Privacy Policy, you may contact us as follows: <a href="mailto:Support@PurchaseClinic.com">Support@PurchaseClinic.com</a>.</p>
          </div>

        </div>

      </div>

    </div>

  </section>
