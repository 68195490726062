<div class="container">
  <div class="row jc-center">
    <div class="col-sm-10 col-md-8" *ngIf="published">
      <div class="row" style="padding: 2rem 1rem 3rem 1rem">
        <div class="col-sm-6 text-center">
          <img class="logo-holder" src="/assets/img/national_car_rental.png" alt="">
        </div>
        <div class="col-sm-6 text-center">
          <img class="logo-holder" src="/assets/img/logo-enterprise-rent-a-car.png" alt="">
        </div>
      </div>

      <div class="bordered">
        <div class="form-section">
          <h1 class="headline text-center mb20">Business and Leisure Travelers: <br>Purchase Clinic Members Can Access Rental Car Savings</h1>
          <p>National Car Rental has long been favored by frequent renters at airports for speed and vehicle choice. Enterprise Rent-A-Car is the first choice for business travelers who need to rent near their home or office while providing savings to their company.</p>
          <p>The rental companies offer both business and leisure rates through Purchase Clinic’s AdvantageTrust program. The business rates include full coverage insurance for worry-free travel. Please note that personal insurance does NOT cover accidents while on business travel. Please confirm this with your insurance company.</p>

          <!-- Nav tabs -->
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#national" role="tab">National Car Rental</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://partners.rentalcar.com/purchase-clinic">Enterprise</a>
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div class="tab-pane active" id="national" role="tabpanel">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="vam" width="20%">
                      <div class="logo-holder">
                        <img class="img-fluid" src="/assets/img/national_car_rental.png"/>
                      </div>
                    </th>
                    <th>Leisure<br>Contract Code:
                      <span class="ubold fz-1-2">{{leisureCode}}</span>
                    </th>
                    <th>Business<br>Contract Code:
                      <span class="ubold fz-1-2">{{businessCode}}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Economy</td>
                    <td>&#36;26.84</td>
                    <td>&#36;28.84</td>
                  </tr>
                  <tr>
                    <td>Compact</td>
                    <td>&#36;28.74</td>
                    <td>&#36;30.74</td>
                  </tr>
                  <tr>
                    <td>Mid-size</td>
                    <td>&#36;30.64</td>
                    <td>&#36;32.54</td>
                  </tr>
                  <tr>
                    <td>Standard</td>
                    <td>&#36;32.54</td>
                    <td>&#36;34.54</td>
                  </tr>
                  <tr>
                    <td>Full-size</td>
                    <td>&#36;33.49</td>
                    <td>&#36;35.49</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-pane" id="enterprise" role="tabpanel">
              <table class="table table-bordered table-responsive- ">
                <thead>
                  <tr>
                    <th class="vam" width="20%">
                      <div class="logo-holder">
                        <img class="img-fluid" src="/assets/img/logo-enterprise-rent-a-car.png"/>
                      </div>
                    </th>
                    <th>Leisure<br>Promotion Code:
                      <span class="ubold fz-1-2">{{leisureCode}}</span>
                    </th>
                    <th>Business<br>Promotion Code:
                      <span class="ubold fz-1-2">{{businessCode}}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Economy</td>
                    <td>&#36;24.94</td>
                    <td>&#36;26.94</td>
                  </tr>
                  <tr>
                    <td>Compact</td>
                    <td>&#36;26.84</td>
                    <td>&#36;28.84</td>
                  </tr>
                  <tr>
                    <td>Mid-size</td>
                    <td>&#36;28.74</td>
                    <td>&#36;30.74</td>
                  </tr>
                  <tr>
                    <td>Standard</td>
                    <td>&#36;30.64</td>
                    <td>&#36;32.64</td>
                  </tr>
                  <tr>
                    <td>Full-size</td>
                    <td>&#36;31.59</td>
                    <td>&#36;33.59</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p>Rates are subject to change based on vehicle size and city.</p>
          <p class="no-mb">
            Become a member of the Emerald Club, and don’t worry about remembering your discount code. Members enjoy expedited service, greater ease and exclusive rewards when renting at both National and Enterprise worldwide. Benefits of Emerald Club:
          </p>
          <ul class="mb2 circled">
            <li>Bypass the counter at select National locations and choose your own car.</li>
            <li>E-receipts for faster rental return.</li>
            <li>Earn rental credits towards Free Rental Days.</li>
            <li>Earn Emerald Club rental credits at participating Enterprise Rent-A-Car locations.*</li>
          </ul>

          <p class="text-center">
            <a class="btn btn-primary-action btn-primary" target="_blank" rel="noopener" href="http://www.nationalcar.com/offer/{{businessCode}}">ENROLL IN EMERALD CLUB</a>
          </p>

          <p>To access the savings with National Car Rental and Enterprise Rent-A-Car, visit the following websites and enter the promotion/contract codes where prompted:</p>
          <p>National Car Rental <br> <a href="https://www.nationalcar.com/en_US/car-rental/reservation/startReservation.html" target="_blank" rel="noopener">https://www.nationalcar.com/en_US/car-rental/reservation/startReservation.html</a></p>
          <p>Enterprise Rent-A-Car <br> <a href="https://partners.rentalcar.com/purchase-clinic" target="_blank" rel="noopener">https://partners.rentalcar.com/purchase-clinic</a></p>
          <p>LEISURE TRAVEL - Contract Code
            <span class="ubold fz-1-2">{{leisureCode}}</span>
            <br>BUSINESS TRAVEL - Contract Code
            <span class="ubold fz-1-2">{{businessCode}}</span>
          </p>

          <p class="comment">*Distributing these codes outside of your facility and its employees is forbidden. Discounts are only available for facilities and their employees affiliated with the Purchase Clinic’s AdvantageTrust program.</p>
        </div>
      </div>

    </div>
    <div class="col-sm-10 col-md-8 text-center" *ngIf="!published">
      <h2>Coming Soon</h2>
    </div>
  </div>
</div>
