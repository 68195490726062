<section class="main-content contract-layout">
  <div class="container">

    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active">{{label}}</li>
    </ol>

    <div class="row" *ngFor="let category of categories">
      <div class="col-12">
        <h3 class="headline">
          {{category?.label}}
        </h3>
      </div>

      <contract-list-item class="col-12"
        [contractId]="contractId"
        [category]="category?.label"
        *ngFor="let contractId of category?.contracts">
      </contract-list-item>

      <div class="col-12">
        <h4 class="headline">
          Travel
        </h4>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/hotelscom.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Hotels.com</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 8% off Hotels.com</li>
                <li><strong>Description :</strong> Hotels.com has over 145,000 hotels in more than 60 countries. Whether you are travelling last minute as a family or need a hotel for business, we have the right hotel deal for you.</li>
                <li><strong>Code :</strong> CORPSHOP8</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://www.jdoqocy.com/click-3747181-12468623-1452124004000?sid=purchaseclinic">Access Hotels.com savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <h4 class="headline">
          Home &amp; Lifestyle
        </h4>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/riteaid.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">RiteAid.com</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> $15 OFF $75</li>
                <li><strong>Description :</strong> At Rite Aid, we have a personal interest in your health and wellness. That’s why we deliver the products and services that you, our valued customer, need to lead a healthier, happier life.</li>
                <li><strong>Code :</strong> GET15OFF</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://www.pjatr.com/t/SUJGR01OTUVCSUtMSUpCRkVOTkpF?sid=purchaseclinic">Access RiteAid.com savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/restaurant-com.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Restaurant.com</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> $10 for $25 Gift Cards</li>
                <li>
                  <strong>Description :</strong> Restaurant.com offers a national database of more than 18,000 restaurants and a number of online merchants. Customers may search by state, city, neighborhood, zip code and cuisine type to find the perfect restaurant, plus $25 dining certificates are offered at a purchase price of $10.
                </li>
                <li><strong>Code :</strong> Automatically applied via coded link below</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://www.dpbolvw.net/click-3747181-10404305?SID=purchaseclinic">Access Restaurant.com savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/ticket-monster.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Ticket Monster</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 10% OFF</li>
                <li>
                  <strong>Description :</strong> Ticket Monster is offering 10% off Sports, Concert and Theater Events Nationwide at TicketMonster.com.
                </li>
                <li><strong>Code :</strong> Automatically applied via below link</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://corporateshopping.ticketmonster.com">Access Ticket Monster savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <h4 class="headline">
          Flowers
        </h4>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/1-800-flowers.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">1-800-Flowers.com</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 15% off</li>
                <li>
                  <strong>Description :</strong> There's no better place to order flowers online than 1-800-Flowers.com. Whether you're looking for roses, orchids, gift baskets, bonsai trees, flowering plants or wedding bouquets, we have the highest quality blooms and the most talented florists who can create exactly what you order. Dazzle and delight your loved ones wherever they may be with a fresh flowers and gifts from 1-800-Flowers.com.
                </li>
                <li><strong>Code :</strong> CORPSHOP</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://track.flexlinks.com/a.ashx?foid=1047755.217425&fot=9999&foc=1&foc2=588016&url=http%3a%2f%2fwww.1800flowers.com%2frefer.do%3fr%3dcscfy12std%26cm_mmc%3dBGS-_-corpshop-_-stdcode-_-15p">Access 1-800-Flowers.com savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/from-you-flowers.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Flowers®</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 25% off</li>
                <li>
                  <strong>Description :</strong> From You Flowers® offers a complete selection of personal and corporate floral and gift items appropriate for any occasion and every sentiment. This includes fresh flowers, plants, balloons and gift baskets. Choose from over 2,000 exquisite gift items like our bouquet of roses, voted as a CBS Morning show Best Value, or select any of our florist designed arrangements, all available nationwide for same day delivery.
                </li>
                <li><strong>Code :</strong> 60X</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://www.fromyouflowers.com/corpshopping4">Access Flowers® savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/sharis-berries.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Shari’s Berries</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 15% OFF $29+</li>
                <li><strong>Description :</strong> Shari’s Berries offers nearly 200 products ranging from our signature hand-dipped berries, to bakery fresh hand-dipped cookies, to truffles, to luxurious cheesecakes and much more.</li>
                <li><strong>Code :</strong> Automatically applied via link below</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://www.jdoqocy.com/click-3747181-11427228?SID=purchaseclinic">Access Flowers® savings</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" style="display: none">
        <h4 class="headline">
          Electronics &amp; Computers
        </h4>
      </div>

      <div class="col-12" style="display: none">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/microsoft-office.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Microsoft Office</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> $9.95 For Microsoft Office</li>
                <li>
                  <strong>Description :</strong> The Power of Office in Your Home Only $9.95. Exclusive for your company, get the complete Microsoft Office Professional Plus 2016 suite for your home. Or if you use a Mac, get Office Home and Business 2016 for Mac for the same low price.
                </li>
                <li><strong>Code :</strong> Automatically applied via link below</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://send.onenetworkdirect.net/z/528486/CD201819">Access Microsoft Office savings</a>

                <div style="font-size: 90%; margin: 0.5em 0 0 0">*Eligibility to this offer is restricted. The Microsoft Home Use Program is only available to eligible employees actively employed by companies with the Home Use Program included and activated in their Software Assurance agreement. At this time a work or corporate email address is required to access this offer.</div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <h4 class="headline">
          Apparel
        </h4>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/brooks-brothers.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Brooks Brothers</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 15% off</li>
                <li>
                  <strong>Description :</strong> ENROLL NOW for your FREE Brooks Brothers Corporate Membership Card and Save 15% OFF on regular price merchandise at Brooks Brothers U.S. and Canadian stores (including Outlets), by phone or online at BrooksBrothers.com.
                </li>
                <li><strong>Code :</strong> see below</li>
                <li><strong>Organization ID #:</strong> 06001</li>
                <li><strong>Organization Enrollment Pin:</strong> 72644</li>
                <li><strong>Organization Name:</strong> Corporate Shopping Company</li>
                <li>Click on link below to register</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://membership.brooksbrothers.com">Access Brooks Brothers savings</a>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/shoebuy.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Shoebuy</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> 20% off</li>
                <li><strong>Description :</strong> At Shoebuy, we offer the largest selection anywhere, online or offline. And we add new styles every day, so you can always choose from the most up to date fashions.</li>
                <li><strong>Code :</strong> see below</li>
                <li>Click on link below to register</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://click.linksynergy.com/fs-bin/click?id=*5140cXfCBE&offerid=401480.9&type=3&subid=0&u1=purchaseclinic">Access Shoebuy savings</a>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row contract-item">
          <div class="col-sm-2">
            <div class="img-holder">
              <img alt="" class="img-fluid" src="//purchaseclinic.s3.amazonaws.com/images/last-call.png">
            </div>
          </div>
          <div class="col-sm-10">
            <div class="description-holder">
              <h3 class="headline">Last Call</h3>
              <ul class="category list-unstyled">
                <li><strong>Offer :</strong> Rotating Discounts</li>
                <li>
                  <strong>Description :</strong> Last Call has long been an icon for stylish savings, but LastCall.com is a brand-new deal! Expertly edited current fashion is at your fingertips 24/7. Browse with ease through a full spectrum of in-season selections from some of the most compelling designers on the scene. Need help pulling it all together? Experience head-to-toe value with intuitive wardrobing ideas—assembling a complete look has never been so simple.
                </li>
                <li><strong>Code :</strong> Click on link below for to view current offers</li>
                <li>Click on link below to register</li>
              </ul>

              <div class="item-footer">
                <a class="btn submit-btn btn-info btn-primary-action" href="http://click.linksynergy.com/fs-bin/click?id=*5140cXfCBE&offerid=279718.10000001&type=3&subid=0&u1=purchaseclinic">Access Last Call savings</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 w-100 ov-h">
        <specialty-slider id="999"></specialty-slider>

      </div>
    </div>
  </div>
  <!-- EOF Container-->
</section>
