import {Component} from '@angular/core';
import {Config} from '../../services/Config';
import {Contracts} from '../../services/Contracts';
import {IFeaturedContractsSet} from '../../interfaces/IFeaturedContractsSet';
import {Router} from '@angular/router';
import {Categories} from "../../services/Categories";
import {Session} from "../../services/Session";
declare var _:any;

@Component({
  selector: 'pharmacy',
  templateUrl: './pharmacy.html',
})
export class PharmacyCmp {
  public featured:IFeaturedContractsSet;
  public categories = [];
  public specialtyId;
  public label;

  constructor(private session:Session, private categoriesService:Categories, private contracts:Contracts, private router:Router, private config:Config) {
    this.session.addOnLoadHandler(()=> {
      this.specialtyId = config.pharmacySpecialtyID;
      this.load(this.specialtyId);
    });
    this.session.redirectIfNotAuth();
  }

  load(specialtyId) {
    this.categoriesService.getCategoryBySlug('pharmaceuticals').subscribe((categories)=> {
      this.categories = categories;
      if (categories && categories.length) {
        this.label = categories[0].label;
      }
    }, ()=> {});
    this.contracts.listFeatured(specialtyId).subscribe((result)=> {
      this.featured = result;
    }, ()=> {});
  }
}
