import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {User} from "../../../services/User";
import {Locations} from "../../../services/Locations";
import {Distributors} from "../../../services/Distributors";
import {Session} from "../../../services/Session";
import {ISignupWithPA} from "../../../interfaces/ISignupWithPA";
import {IDistributor} from "../../../interfaces/IDistributor";
import {Notify} from "../../../services/Notify";
import {Config} from "../../../services/Config";
import {Questions} from "../../../services/Questions";

@Component({
  selector: 'signup-mgma',
  templateUrl: './mgma.html',
})
export class SignupMgmaCmp {
  public signup = new ISignupWithPA();
  public currentDate = '';
  public distributorsMap:{[id: number]: IDistributor;} = {};
  public distributorsList:IDistributor[] = [];
  public saving = false;
  public isError = false;
  public errMsg;
  public notify;

  constructor(private users:User, private locations:Locations, private distributors:Distributors, private router:Router, public session:Session, private config:Config, private qSrv: Questions) {
    this.setCurrentDate();
    this.distributors.loadToList(this.distributorsList);
    this.notify = new Notify();
  }

  setCurrentDate() {
    let d = new Date();
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var formattedDate = months[d.getMonth()] + " " + d.getDate() + "th, " + d.getFullYear() + " at " + d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
    this.currentDate = formattedDate;
    setTimeout(()=> {this.setCurrentDate();}, 1000);
  }

  submit() {
    if (!this.signup.user.fname) {
      this.notify.error('Sorry, field First Name is required');
      return;
    }
    if (!this.signup.user.lname) {
      this.notify.error('Sorry, field Last Name is required');
      return;
    }
    if (!this.signup.user.email) {
      this.notify.error('Sorry, field Email is required');
      return;
    }
    if (this.signup.user.email.indexOf('@') < 1 || this.signup.user.email.length < 6) {
      this.notify.error('Please double-check the email address that you entered. There appears to be a typo.');
      return;
    }
    if (!this.signup.user.phone) {
      this.notify.error('Sorry, field Phone is required');
      return;
    }
    if (!this.signup.location.facility_name) {
      this.notify.error('Sorry, field Organization’s Legal Name is required');
      return;
    }
    if (!this.signup.location.zip) {
      this.notify.error('Sorry, field Postal Code is required');
      return;
    }
    if (!this.signup.signature) {
      this.notify.error('Please accept Terms of Service');
      return;
    }
    this.signup.user.theme_id = this.config.getThemeIDForHost();
    this.signup.location.theme_id = this.config.getThemeIDForHost();
    this.saving = true;
    this.users.signupWithPA(this.signup).subscribe((response)=> {
      this.session.setToken(response.token, ()=> {
        this.saving = false;
        this.session.signupUrl = window.location.hostname + window.location.pathname;
        this.router.navigate(['/signup_benefit_review']);
      }, ()=> {
        this.saving = false;
        this.router.navigate(['/login']);
      });
    }, (response)=> {
      this.isError = true;
      this.saving = false;
      console.error(response);
      if (response && response.status) {
        switch (response.status) {
          case 417:
            this.errMsg = 'Please double-check the name that you entered. There appears to be a typo.';
            break;
          case 409:
            this.errMsg = 'User with this email is already registered';
            break;
          case 406:
            this.errMsg = 'Please double-check the email address that you entered. There appears to be a typo.';
            break;
          case 400:
            this.errMsg = 'Please fill required fields.';
            break;
          default:
            this.errMsg = '';
        }
        if (this.errMsg) {
          this.notify.error(this.errMsg);
        }
      }
    });
  }
}
