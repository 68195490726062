import { Component, Input } from '@angular/core';
import { ILocation } from "../../interfaces/ILocation";
import { IUser } from "../../interfaces/IUser";
import { Config } from "../../services/Config";
import { FDTickets } from "../../services/FDTickets";
import { Notify } from "../../services/Notify";
import { User } from "../../services/User";

@Component({
  selector: 'account-contact-us',
  templateUrl: './contact-us.html',
})
export class AccountContactUsCmp {
  @Input() user:IUser;
  @Input() location:ILocation;
  public newEmail:string;
  public question:string;
  public saving = false;

  constructor(private tickets:FDTickets, private config:Config, private userSrv:User) {
  }

  ngOnChanges() {
    if (this.user) {
      this.newEmail = this.user.email;
    }
  }

  send() {
    let n = new Notify();
    let email = this.newEmail;
    if (!email && this.user && this.user.email) {
      email = this.user.email;
    }
    if (!email || email.indexOf('@') < 1) {
      n.warning('Please input your email');
      return false;
    }
    let customFields = {};
    if (this.user && this.user.__theme && this.user.__theme.name) {
      customFields['brand'] = this.user.__theme.name;
    }
    if (this.location && this.location.pc_id) {
      customFields['pc_id'] = this.location.pc_id;
    }
    this.saving = true;
    let msg = `<hr><div>Question: <strong>${this.question}</strong></div>`;
    if (this.location) {
      msg = `<div>Location: <a href="https://crm.purchase.guru/location/${this.location.id}" target="_blank">${this.location.facility_name} (${this.location.pc_id})</a></div>${msg}`;
    }
    if (this.user) {
      msg = `<div>User: <a href="https://crm.purchase.guru/user/${this.user.id}" target="_blank">${this.user.fname} ${this.user.lname}</a>, email: ${this.user.email}</div>${msg}`;
    }
    this.tickets.create(email, 'Account:Contact Us', msg, customFields).subscribe(() => {
      this.saving = false;
      this.question = '';
      n.success('Successfully!');
      if (this.config.isAccountSubDomain()) {
        let x_location_id = null;
        if (this.location && this.location.id) {
          x_location_id = this.location.id;
        }
        let usage = this.userSrv.getAuthenticatedAnnualDisclosureReq(x_location_id);
        usage.contact_page = true;
        this.userSrv.writeAnnualDisclosureCodeUsage(usage);
      }
    }, (error) => {
      this.saving = false;
      console.error(error);
      n.error('Error, sorry...');
    });
  }
}
