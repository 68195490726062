import {Component, Input} from '@angular/core';

@Component({
  selector: 'circle-progress',
  template: `
    <span class="circle-progress-val" *ngIf="displayValue"
          [ngStyle]="{'width.px': size, 'font-size.px':(size/3.15)}"
    >{{percent}}%</span>
    <svg class="circle-progress" [attr.width]="size" [attr.height]="size" viewBox="0 0 120 120">
      <circle class="progress__meter" cx="60" cy="60" r="54" stroke-width="12"/>
      <circle class="progress__value" cx="60" cy="60" r="54" stroke-width="12"
              [ngStyle]="{'strokeDasharray':dasharray,'strokeDashoffset':dashoffset, 'stroke': color}"/>
    </svg>
  `,
  styleUrls: ['circle_progress.css'],
})
export class CircleProgressCmp {
  @Input() percent: any;
  @Input() size: number = 120;
  @Input() color: string = '#0275d8';
  @Input() displayValue: boolean = true;
  public dashoffset;
  public dasharray;
  private radius = 54;
  private c = 2 * Math.PI * this.radius;

  ngOnInit() {
    this.draw();
  }

  ngOnChanges() {
    this.draw();
  }

  draw() {
    if (this.percent < 1) {
      this.percent = 0;
    }
    if (this.percent > 100) {
      this.percent = 100;
    }
    let progressValue = parseFloat(this.percent);
    let progress: number = this.percent / 100;
    this.dashoffset = this.c * (1 - progress);
    this.dasharray = this.c;
  }
}
