import {Component} from '@angular/core';
import {Router, ActivatedRoute, NavigationStart, UrlSegment} from '@angular/router';
import {Session} from './services/Session';
import {Config} from './services/Config';
import {UrlParser} from './services/UrlParser';
import {Specialties} from './services/Specialties';
import {Distributors} from './services/Distributors';
import {User} from './services/User';
import {Cots} from "./services/Cots";
import {Locations} from "./services/Locations";
import {ItemsRange} from "./services/ItemsRange";
import {Contracts} from "./services/Contracts";
import {Questions} from "./services/Questions";
import {Categories} from "./services/Categories";
import {CellphoneSignup} from "./services/CellphoneSignup";
import {Implementations} from "./services/Implementations";
import {LocationCustomSettings} from "./services/LocationCustomSettings";
import {Themes} from "./services/Themes";
import {CategoryPipe} from "./services/category-pipe/CategoryPipe";
import {FilterPipe} from "./pipes/FilterPipe";
import {FDTickets} from "./services/FDTickets";
import {Testimonials} from "./services/Testimonials";
import {EditRequests} from "./services/EditRequests";
import {Formatters} from "./services/Formatters";
import {Newsletter} from "./services/Newsletter";
import {RxSrv} from "./services/RxSrv";
import {Helpers} from "./services/Helpers";
import {SiimSrv} from "./services/SiimSrv";
import {OrderByPipe} from "./services/OrderByPipe";

declare var $: any;
declare var analytics: any;
declare var module: any;

@Component({
  selector: 'pc2-app',
  templateUrl: './app.component.html',
  providers: [Session, CategoryPipe, EditRequests, FilterPipe, Config, UrlParser, Specialties, Helpers,
    Distributors, User, Cots, Locations, RxSrv, SiimSrv, ItemsRange, Contracts, Questions, Categories, CellphoneSignup,
    Implementations, Formatters, LocationCustomSettings, Themes, FDTickets, Testimonials, Newsletter, OrderByPipe]
})

export class AppComponent {
  public showNewsTicker = false;
  private autoScrolling = false;

  constructor(private contracts: Contracts, private router: Router, private session: Session, private activeRoute: ActivatedRoute, private config: Config) {
    this.session.addOnLoadHandler(() => {
      this.personalizeAnalytics();
      if (this.session.user && this.session.user.id) {
        this.contracts.loadAll();
      }
    });
    this.session.loadUser();
    this.initiateAnalytics();
    this.scrollOnRouteChange();
    this.sendLogOnRouteChange();
  }

  scrollOnRouteChange() {
    try {
      this.router.events.subscribe((page: any) => {
        try {
          if (page instanceof NavigationStart) {
            if (this.activeRoute.firstChild && this.activeRoute.firstChild.url) {
              let p: any = this.activeRoute.firstChild.url;
              if (p._value && p._value[0]) {
                let prev_route = p._value[0];
                if (prev_route instanceof UrlSegment) {
                  prev_route = prev_route.path;
                }
                if (prev_route && !(prev_route === 'login' || prev_route === 'logout' || prev_route === 'forgot_pwd' || prev_route === 'new_pwd')){
                  this.session.setPrevUrl(location.pathname + location.search,prev_route);
                }
              }
            }
          }
          if (page.url) {
            this.showNewsTicker = ((page.url.indexOf("/signup") === 0) && page.url.indexOf("signup_benefit_review") < 0);
          }
        } catch (e) {
          console.error(e);
        }
        if (this.autoScrolling) {
          return;
        }
        this.autoScrolling = true;
        $('html,body').animate({scrollTop: 0}, 100);
        setTimeout(() => {
          this.autoScrolling = false;
        }, 101);
      });
    } catch (e) {
      console.error(e);
    }
  }

  sendLogOnRouteChange() {
    this.router.events.subscribe((page: any) => {
      if (analytics && analytics.page) {
        try {
          analytics.page(page.url);
        } catch (e) {}
      }
    });
  }

  initiateAnalytics() {
    try {
      if (analytics && analytics.load) {
        if (this.session && this.session.user && this.session.user.__theme && this.session.user.__theme.analytics_js_id) {
          analytics.load(this.session.user.__theme.analytics_js_id);
        } else {
          analytics.load("vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI");
        }
      }
    } catch (e) {}
  }

  personalizeAnalytics() {
    try {
      if (analytics && analytics.identify) {
        analytics.identify(this.session.user.id, {
          name: this.session.user.fname + ' ' + this.session.user.lname,
          email: this.session.user.email
        });
      }
    } catch (e) {}
  }
}
