import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";

@Component({
  selector: 'rental-cars',
  templateUrl: './car-rental.html',
  styleUrls: ['./car-rental.css'],
})
export class CarRentalCmp {
  public saving = false;
  public published = true; //pc2-tmp-hide
  public leisureCode = '';
  public businessCode = '';

  constructor(private session:Session, private config:Config) {
    this.session.addOnLoadHandler(() => {
      this.init();
      this.leisureCode = this.config.getRentalCodeLeisure(0);
      this.businessCode = this.config.getRentalCodeBusiness(0);
    });
    this.session.redirectIfNotAuth();
  }

  init() {
    if (this.session.user.theme_id === this.config.ampThemeID || (this.session.user.__theme && this.session.user.__theme.id === this.config.ampThemeID)) {
      this.leisureCode = this.config.getRentalCodeLeisure(this.config.ampThemeID);
      this.businessCode = this.config.getRentalCodeBusiness(this.config.ampThemeID);
    }
    if (this.session.user.theme_id === this.config.capThemeID || (this.session.user.__theme && this.session.user.__theme.id === this.config.capThemeID)) {
      this.leisureCode = this.config.getRentalCodeLeisure(this.config.capThemeID);
      this.businessCode = this.config.getRentalCodeBusiness(this.config.capThemeID);
    }
    if (this.session.user.theme_id === this.config.mgmaThemeID || (this.session.user.__theme && this.session.user.__theme.id === this.config.mgmaThemeID)) {
      this.leisureCode = this.config.getRentalCodeLeisure(this.config.mgmaThemeID);
      this.businessCode = this.config.getRentalCodeBusiness(this.config.mgmaThemeID);
    }
  }

  ngOnChanges() {
    this.init();
  }
}
