<div class="container">
  <div class="thin-top">
    <div class="row jc-center">
      <div class="col-sm-8 col-md-6">
        <img class="img-logo" src="/assets/img/fedex-logo.png"/>
        <div class="bordered">
          <div class="form-section">
            <h3 class="headline text-center mb20">Save with FedEx</h3>
            <p>Getting connected with savings from FedEx is simple. Enter in your Facility Name and FedEx account number below, and a Client Manager will connect your account, saving you an average of 40% on all your shipping needs.</p>

            <form name="form" (ngSubmit)="save()" *ngIf="!newContactThanks">
              <div class="form-group custom" *ngIf="isFedexCustomer">
                <label class="col-form-label">FedEx Account Number (s):</label>
                <input [(ngModel)]="fedexAccountNumber" type="text" class="field text medium" [ngModelOptions]="{standalone: true}"/>
                <p class="note">*numbers can be separated by a comma</p>
              </div>
              <div *ngIf="!isFedexCustomer && !numberIsSet">
                <p>Don’t have an existing FedEx account? No problem! A Client Manager will create your account, connect you with savings, and send you your new account number via e-mail.</p>
              </div>

              <div class="form-group custom checkbox-group" *ngIf="!fedexAccountNumber && !numberIsSet">
                <div class="col-12">
                  <label class="checkbox-inline"><input type="checkbox" #cb (change)="toggleHaveFedexAccount(cb)"/>I don’t have an existing FedEx account</label>
                </div>
              </div>

              <div class="form-group custom" *ngIf="!fedexAccountNumber">
                <p>If you’re not the right person to fill out this information, please enter the correct contact’s name and e-mail address in the fields below:</p>
                <label class="col-form-label">New Contact Name:</label>
                <input [(ngModel)]="officeContactUser.name" type="text" class="field text medium" [ngModelOptions]="{standalone: true}"/>
              </div>

              <div class="form-group custom" *ngIf="!fedexAccountNumber">
                <label class="col-form-label">New Contact E-mail:</label>
                <input [(ngModel)]="officeContactUser.email" type="text" class="field text medium" [ngModelOptions]="{standalone: true}"/>
              </div>

              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #disclaim (change)="disclaimed = disclaim.checked"/>
                    Thank you, but I'm not interested in taking advantage of this money-saving contract at this time.
                  </label>
                </div>
              </div>

              <div class="form-group text-end mt2">
                <button type="submit" class="btn btn-primary-action btn-primary" [disabled]="saving">
                  <span *ngIf="!numberIsSet">Submit</span>
                  <span *ngIf="numberIsSet">Update</span>
                </button>
              </div>
            </form>
            <div *ngIf="newContactThanks">
              <p>Thank you for providing the contact information for {{officeContactUser.name}}. We have sent an e-mail to {{officeContactUser.email}} with instructions for completing this portion of the setup process.</p>
              <div class="pc2-row-pad text-center">
                <a class="btn btn-primary" routerLink="/non_med_savings">Go to main Non Medical Savings Page</a>
                <a class="btn btn-primary" *ngIf="displayOfficeSuppliesPage" routerLink="/non_med_savings/office_supplies">Next Step</a>
                <a class="btn btn-primary" *ngIf="!displayOfficeSuppliesPage" routerLink="/non_med_savings/wireless">Next Step</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
