import {Component, Input} from '@angular/core';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Locations} from "../../services/Locations";
import {ILocGroupSales} from "../../interfaces/ILocGroupSales";
import {ILocGroupSalesCycle} from "../../interfaces/ILocGroupSales";

@Component({
  selector: 'account-sales-cycle',
  templateUrl: './account-sales.html',
})
export class AccountSalesCycleCmp {
  @Input() locGroupID:number;
  public salesCSV:string;
  public sales:ILocGroupSalesCycle[];
  public loading = false;

  constructor(private config:Config, private session:Session, private locSrv:Locations) {
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (this.locGroupID) {
      this.salesCSV = this.config.apiUrl + `/report/location_group_sales_cycle/csv/${this.session.getToken()}/${this.locGroupID}`;
      this.loading = true;
      this.locSrv.getSalesCycleOfGroup(this.locGroupID).subscribe((sales)=> {
        this.loading = false;
        this.sales = sales;
      }, (err)=> {
        this.loading = false;
        console.error(err);
      });
    }
  }
}
