import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ISpecialty} from '../interfaces/ISpecialty';
import {Observable} from 'rxjs';
import {ISpecialtySlide} from "../interfaces/ISpecialtySlide";

@Injectable()
export class Specialties {
  private config:Config;
  private session:Session;
  private http:HttpClient;
  private list:ISpecialty[] = [];
  private loadingPromise;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  load():Promise<ISpecialty[]> {
    if (this.list && this.list.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.list);
      });
    }
    if (this.loadingPromise) {
      return this.loadingPromise;
    }
    this.loadingPromise = new Promise((resolve, reject)=> {
      this.http.get<ISpecialty[]>(this.config.apiUrl + '/specialty').subscribe((objects)=> {
        this.loadingPromise = null;
        if (objects instanceof Array) {
          this.list = objects;
          resolve(this.list);
        } else {
          reject();
        }
      }, ()=> {
        this.loadingPromise = null;
        reject();
      });
    });
    return this.loadingPromise;
  }

  loadToMap(map:{[id:number]:ISpecialty;}) {
    this.load().then((specialties)=> {
      if (specialties && specialties.length > 0) {
        specialties.forEach((s)=> {
          map[s.id] = s;
        });
      }
    }, ()=> {});
  }

  loadToList(list:ISpecialty[]) {
    this.load().then((specialties)=> {
      if (specialties && specialties.length > 0) {
        specialties.forEach((s)=> {
          if (s.is_popular) {
            list.push(s);
          }
        });
      }
    }, ()=> {});
  }

  loadSlides(specialtyID):Observable<[ISpecialtySlide]> {
    return this.http.get<[ISpecialtySlide]>(this.config.apiUrl + '/specialty/' + specialtyID + '/slides');
  }
}
