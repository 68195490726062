<div class="container">
  <div class="thin-top">
    <div class="row jc-center">
      <div class="col-sm-8 col-md-6">
        <img class="img-logo" src="/assets/img/staples-logo.png"/>
        <div class="bordered">
          <div class="form-section">
            <h3 class="headline text-center mb20">Save with Staples</h3>
            <p>Now that you’re a part of our purchasing community,
              <strong>you and your employees</strong> can access savings of up to 23% on office supplies. To help connect you to these savings, please let us know if you are currently a Staples Advantage customer. We will talk to a Staples representative and they will be in contact with you to make sure you are properly connected.
            </p>

            <form name="form" (ngSubmit)="save()" *ngIf="!newContactThanks">
              <div class="form-group" *ngIf="!numberIsSet">
                <label class="col-form-label">I am currently a Staples Advantage сustomer:</label>
                <label class="checkbox-inline">
                  <input type="radio" name="isStaplesAdvCustomer" (change)="isStaplesAdvCustomer = 1"/>
                  Yes
                </label>
                <label class="checkbox-inline">
                  <input type="radio" name="isStaplesAdvCustomer" (change)="isStaplesAdvCustomer = 0"/>
                  No
                </label>
              </div>
              <div class="form-group alert alert-info" *ngIf="isStaplesAdvCustomer === 0">
                Upon clicking submit, a Client Manager will set up your account and a Staples representative will be in touch with information on how to access your benefits in-store at any Staples location and online at StaplesAdvantage.com.
              </div>
              <div class="form-group custom" *ngIf="isStaplesAdvCustomer">
                <label class="col-form-label">Staples Advantage Account Number:</label>
                <input [(ngModel)]="staplesAccountNumber" type="text" class="field text medium" [ngModelOptions]="{standalone: true}"/>
              </div>
              <div class="form-group" *ngIf="isStaplesAdvCustomer">
                <div class="checkbox">
                  <label>
                    <input type="checkbox"/>
                    I don't know my account number. Please proceed without it for now. I understand this will delay the process.
                  </label>
                </div>
              </div>

              <div class="form-group custom" *ngIf="!staplesAccountNumber">
                <p>If you’re not the right person to fill out this information, please enter the correct contact’s name and e-mail address in the fields below:</p>
                <label class="col-form-label">New Contact Name:</label>
                <input [(ngModel)]="officeContactUser.name" type="text" class="field text medium" [ngModelOptions]="{standalone: true}"/>
              </div>

              <div class="form-group custom" *ngIf="!staplesAccountNumber">
                <label class="col-form-label">New Contact E-mail:</label>
                <input [(ngModel)]="officeContactUser.email" type="text" class="field text medium" [ngModelOptions]="{standalone: true}"/>
              </div>

              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #disclaim (change)="disclaimed = disclaim.checked"/>
                    Thank you, but I'm not interested in taking advantage of this money-saving contract at this time.
                  </label>
                </div>
              </div>

              <div class="form-group text-end mt2">
                <button type="submit" class="btn btn-primary-action btn-primary" [disabled]="saving">
                  <span *ngIf="!numberIsSet">Submit</span>
                  <span *ngIf="numberIsSet">Update</span>
                </button>
              </div>
            </form>
            <div *ngIf="newContactThanks">
              <p>Thank you for providing the contact information for {{officeContactUser.name}}. We have sent an e-mail to {{officeContactUser.email}} with instructions for completing this portion of the setup process.</p>
              <div class="pc2-row-pad text-center">
                <a class="btn btn-primary" routerLink="/non_med_savings">Go to main Non Medical Savings Page</a>
                <a class="btn btn-primary" routerLink="/non_med_savings/wireless">Next Step</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
