<div class="TickerNews" #slide *ngIf="news?.length" [style.background]="bgColor">
  <div class="ti_wrapper">
    <div class="ti_slide" #target></div>
  </div>
  <div #source style="display: none">
    <div class="ti_content">
      <div class="ti_news" *ngFor="let str of news">{{str}}</div>
    </div>
  </div>
</div>
