import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Session} from "../../services/Session";
import {User} from "../../services/User";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {IDistributor} from "../../interfaces/IDistributor";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {ICot} from "../../interfaces/ICot";
import {Cots} from "../../services/Cots";

@Component({
  selector: 'new-member',
  templateUrl: './new-member.html',
})
export class NewMemberCmp {
  public id:number;
  public authCode:string;
  public redirectStarted = false;
  public miniSurveyCompleted = false;
  public locUpdReq = {distributor_id: null, rep_name: null, specialty_id: null, cot_id: null, loc_id: null};
  public distributorsList:IDistributor[] = [];
  public specialtiesList:ISpecialty[] = [];
  public cotsList:ICot[] = [];
  public sendingSurvey = false;
  public isSurveySent = false;
  public isAlreadyMember = false;
  public loading = false;
  public rxCode:string;

  public MSG_ID_WELCOME_PA = 1;
  public MSG_ID_WELCOME_DEFAULT = 2;
  public MSG_ID_DISTR1 = 3;
  public MSG_ID_DISTR2 = 4;
  public MSG_ID_DISTR3 = 5;
  public MSG_ID_DISTR4 = 6;
  public MSG_ID_WELCOME_SIGNUP = 7;
  public MSG_ID_WELCOME_MEMBERSHIP = 8;
  public MSG_ID_SURVEY = 9;
  public MSG_ID_REVIEW_THANKS:string = '842657';
  public MSG_ID_ASK_VAC = 10;

  constructor(private routeParams:ActivatedRoute, public session:Session, private router:Router,
    private users:User, private locs:Locations, private srvDistr:Distributors, private srvSpec:Specialties, private srvCots:Cots) {}

  ngOnInit() {
    this.routeParams.queryParams.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (params['rx']) {
      if (!params['ask_vac']) {
        this.session.crossPageArgs['rx_redirected_new_member'] = 1;
        this.router.navigateByUrl('/rx/' + params['rx']);
        return;
      }
      this.rxCode = params['rx'];
    }
    let id = params['msg_id'];
    if (id) {
      this.id = parseInt(id);
    } else {
      this.id = 0;
    }
    if (params['skip_q_other_distr']) {
      this.session.crossPageArgs['skip_q_other_distr'] = 1;
    }
    if (params['skip_q_rep']) {
      this.session.crossPageArgs['skip_q_rep'] = 1;
    }
    if (params['skip_q_specialty']) {
      this.session.crossPageArgs['skip_q_specialty'] = 1;
    }
    if (params['skip_q_specialty']) {
      this.session.crossPageArgs['skip_q_specialty'] = 1;
    }
    if (params['skip_q_cot']) {
      this.session.crossPageArgs['skip_q_cot'] = 1;
    }
    if (params['set_answer_cot_id']) {
      this.session.crossPageArgs['set_answer_cot_id'] = params['set_answer_cot_id'];
    }
    if (params['set_answer_specialty_id']) {
      this.session.crossPageArgs['set_answer_specialty_id'] = params['set_answer_specialty_id'];
    }
    if (params['from']) {
      this.session.signupUrl = params['from'];
    }
    this.authCode = params['code'] || '';
    // if (params['ask_vac']) {
    //   if (!this.rxCode) {
    //     params['ask_vac'] = null;
    //   } else {
    //     this.id = this.MSG_ID_ASK_VAC;
    //     return;
    //   }
    // }
    if (id == this.MSG_ID_SURVEY) {
      try {
        let fallback = ()=> {
          this.miniSurveyCompleted = true;
          return;
        };
        if (!this.authCode) {
          return fallback();
        }
        this.users.getUserInfoByToken(this.authCode).subscribe((user)=> {
          if (!user || !user.id || !user.location_id || !user.__location) {
            return fallback();
          }
          if (!user.__location.__tags_clist) {
            return fallback();
          }
          let tags = user.__location.__tags_clist.split(',');
          if (tags.indexOf('39') > -1) {
            this.isAlreadyMember = true;
          }
          if (tags.indexOf('42') > -1 || tags.indexOf('43') > -1) {
            this.locUpdReq.loc_id = user.location_id;
            if (user.__location.__distributor_id > 0) {
              this.locUpdReq.distributor_id = user.__location.__distributor_id;
            } else {
              if (user.distributor_id > 0) {
                this.locUpdReq.distributor_id = user.distributor_id;
              }
            }
            if (user.__location.specialty_id > 0) {
              this.locUpdReq.specialty_id = user.__location.specialty_id;
            } else {
              if (user.specialty_id > 0) {
                this.locUpdReq.specialty_id = user.specialty_id;
              }
            }
            if (user.__location.class_of_trade_id > 0) {
              this.locUpdReq.cot_id = user.__location.class_of_trade_id;
            } else {
              if (user.class_of_trade_id > 0) {
                this.locUpdReq.cot_id = user.class_of_trade_id;
              }
            }
          } else {
            return fallback();
          }
        }, ()=> {
          return fallback();
        });
        this.srvDistr.loadToList(this.distributorsList);
        this.srvSpec.load().then((specs:ISpecialty[]) => {
          if (specs && specs.length) {
            specs.forEach((spec) => {
              if (spec.is_popular > 0) {
                this.specialtiesList.push(spec);
              }
            });
          }
        }, () => {});
        this.srvCots.loadToList(this.cotsList);
      } catch (e) {
        this.miniSurveyCompleted = true;
      }
    } else {
      this.loadToken(()=> {
        if (id.toString() === this.MSG_ID_REVIEW_THANKS) {
          this.router.navigateByUrl('/signup_benefit_review_thanks');
        } else {
          if (this.id < this.MSG_ID_WELCOME_SIGNUP) {
            this.router.navigateByUrl('/signup_benefit_review');
          }
        }
      });
    }
  }

  loadToken(cb?) {
    if (this.authCode && !this.redirectStarted) { // need to fetch code first, but once
      this.loading = true;
      this.redirectStarted = true;
      this.session.setToken(this.authCode, ()=> {
        this.loading = false;
        if (cb) {
          cb();
        }
      }, ()=> {
        this.loading = false;
        this.router.navigate(['/login']);
      });
    } else {
      if (this.id < this.MSG_ID_WELCOME_SIGNUP) {
        this.router.navigate(['/login']);
      }
    }
  };

  sendSurvey() {
    if (!this.locUpdReq || !this.locUpdReq.loc_id || !this.authCode) {
      return;
    }
    if (!this.locUpdReq.distributor_id && !this.locUpdReq.cot_id && !this.locUpdReq.rep_name && !this.locUpdReq.specialty_id) {
      return;
    }
    this.sendingSurvey = true;
    this.locs.sendMiniSurvey(this.locUpdReq, this.authCode).subscribe(()=> {
      this.miniSurveyCompleted = true;
      this.sendingSurvey = false;
      this.isSurveySent = true;
    }, ()=> {
      this.miniSurveyCompleted = true;
      this.sendingSurvey = false;
    });
  }
}
