import {Component} from '@angular/core';
import {Contracts} from '../../services/Contracts';
import {IFeaturedContractsSet} from '../../interfaces/IFeaturedContractsSet';
import {Router, ActivatedRoute} from '@angular/router';
import {Session} from "../../services/Session";
declare var _:any;

@Component({
  selector: 'specialty',
  templateUrl: './specialty.html',
})
export class SpecialtyCmp {
  public featured:IFeaturedContractsSet;
  public specialtyID;

  constructor(private contracts:Contracts, private routeParams:ActivatedRoute,
    public router:Router, public session:Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.specialtyID = parseInt(params['id']);
    if (!this.specialtyID) {
      this.router.navigate(['']);
      return;
    }
    this.load(this.specialtyID);
  }

  load(specialtyId) {
    this.contracts.listFeatured(specialtyId).subscribe((result) => {
      this.featured = result;
      if (this.featured.contracts && this.featured.contracts.length > 0) {
        this.featured.contracts = _.uniq(this.featured.contracts, 'contract_number');
        this.featured.contracts.forEach((contract) => {
          contract.__subcategories = _(contract.product_category.split(',')).uniq().sort().map((name) => {
            return name.trim();
          }).value().sort();
        });
      }
    }, () => {});
  }
}
