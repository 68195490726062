declare var $;

export class SendPostForm {
  send(url, fields) {
    var formId = 'form' + ((new Date().getTime()).toString() + (Math.ceil(Math.random() * 999999)).toString());
    var form = '<iframe name="frame' + formId + '" id="frame' + formId + '" width="0" height="0" border="0" style="display: none;"></iframe>' +
      '<form id="' + formId + '" method="post" action="' + url + '" target="frame' + formId + '">';
    if (fields) {
      for (var field in fields) {
        if (fields[field] !== null && fields.hasOwnProperty(field)) {
          form += '<input name="' + field + '" type="hidden" value="' + fields[field] + '">';
        }
      }
    }
    form += '</form>';
    $('body').append(form);
    $('#' + formId).submit();
    setTimeout(()=> {
      $('#' + formId).remove();
      $('#frame' + formId).remove();
    }, 10000);
  }
}
