<div class="row sec-1">
  <div class="col-12 bordered-container">
    <h1 class="headline">FAQ</h1>
    <div class="bordered-content clearfix">
      <h2>Quick FAQs</h2>

      <p><strong>What is Tenet’s group purchasing program?</strong></p>

      <p>Tenet’s group purchasing program is utilizing Purchase Clinic as their online contract marketplace leveraging the HealthTrust portfolio. The marketplace provides a place for independent, alternate-care site providers to access medical, surgical and non-medical supplies.  </p>

      <p><strong>How do I sign up?</strong></p>

      <p>Signing up is easy. Simply go to tenetgpo.com and fill out a quick enrollment form. You are able to immediately access the HealthTrust portfolio and begin making savings simple and realizing savings.</p>

      <p><strong>Does the Tenet’s group purchasing program replace procurement staff, contract negotiators, supply chain personnel or materials managers?</strong></p>

      <p>Absolutely not. Tenet’s group purchasing program, utilizing Purchase Clinic technology is designed by procurement professionals to support both procurement professionals and procurement-related job functions. We work with administrators, owners, executives, purchase managers, office managers and anyone else involved in the purchasing process. </p>

      <p><strong>How much does Tenet’s group purchasing program cost?</strong></p>

      <p>Membership is free. When signing up with Tenet’s group purchasing program utilizing Purchase Clinic technology, you are receiving best in class pricing, and with that we ask that you participate in 80% of your contracted purchases through the HealthTrust portfolio. </p>

      <p><strong>How does Tenet’s group purchasing program provide incredible value?</strong></p>

      <p>Through our partnership with HealthTrust, Tenet’s group purchasing program utilizing Purchase Clinic technology offers customers the most competitive and comprehensive source for products and services through the HealthTrust portfolio for physicians and other non-acute providers. With a total committed purchasing power of over $47 billion, HealthTrust is one of the largest and most competitive GPOs in the industry.</p>

      <p><strong>How much money can I save by switching to the HealthTrust contract portfolio with Tenet’s group purchasing program?</strong></p>

      <p>Without detailed analysis, the exact amount of savings is difficult to identify. With the HealthTrust portfolio, members have realized an average of 10-20 percent savings, depending on the category and any locally-negotiated contracts.</p>

      <p><strong>Who is eligible to join the program?</strong></p>

      <p>Almost any independent alternate-care site provider is eligible to participate. When you join, you agree that membership in the program is exclusive and prohibits you from participating in similar programs offered by other GPOs.</p>

      <p><strong>What if the program doesn’t end up working for me?</strong></p>
      <p>Our objective is always to help create savings on at least 80% of your spending where we have contracts. If we are unable to achieve that, our program may not be right for you.  If this does happen, Tenet’s group purchasing program and HealthTrust will release you from membership with a 60-day notice.</p>

      <p><strong>What happens if I drop below 80% compliance, and how is compliance measured?</strong></p>

      <p>We support providers who need assistance by proactively monitoring contract utilization. Any GPO you use should cover at least 80% of your spending. If we are unable to do that, we are not a good fit for your organization. If at any time this becomes a problem, Tenet’s group purchasing program and HealthTrust will release you from membership with a 60-day notice.</p>

      <p><strong>Why can’t my organization belong to more than one group purchasing organization?</strong></p>

      <p>When you enroll with Tenet’s group purchasing program and access the HealthTrust portfolio, you are agreeing to a committed model. With this model, we are able to offer pricing that is recognized as the most competitive in the healthcare industry. If at any time you feel we aren’t the right fit, Tenet’s group purchasing program and HealthTrust will release you from membership with a 60-day notice.</p>

      <p><strong>How are contracts awarded?</strong></p>

      <p>The HealthTrust portfolio includes sole, dual and multi-source awards that result from a thorough contracting process. Physician engagement and evidence-based approaches are combined with optimized order sets, protocols and best practices through clinically managed advisory boards for each specialty, resulting in supplies qualified to provide product breadth and clinical acceptability.</p>

      <p>For a more detailed explanation of how our program works, please see extended FAQs below. </p>

      <h2>Extended FAQs</h2>

      <p><strong>What if a supplier I currently use is not on your list of contracted suppliers?</strong></p>

      <p>Suppliers are awarded a contract based on several factors that include, but are not limited, to:</p>

      <ul>
        <li>Product acceptability</li>
        <li>Breadth of product line</li>
        <li>Ability to distribute nationwide</li>
        <li>Pricing</li>
        <li>Price Protection</li>
        <li>Payment Terms</li>
        <li>Freight Terms</li>
        <li>Other varying factors</li>
      </ul>

      <p>Due to these factors, not all suppliers are awarded contracts. If you current supplier is not on a contract, it could mean that they did not receive a contract award for one or more of the above mentioned items. </p>

      <p>If your existing supplier does not match a contracted supplier, a few things should be considered.</p>
      <ol>
        <li>In order to achieve maximum savings with the least effort (negotiating and contracting locally), the contracted supplier is most likely the best option.</li>
        <li>Membership in our program includes confidentiality, and therefore, your existing suppliers should not be provided with our contract pricing in an effort to “meet or beat” it. (We have found that suppliers in fear of losing business are anxious to lower their pricing when their business is threatened, rather than have given you lower pricing all along).</li>
        <li>Because we provide high purchase volume to our contracted suppliers, they are able to reduce margins while maintaining a profitable program. When local negotiations take place, that market share is not present, and therefore the long-term viability of the pricing for the supplier is not established. In other words, the supplier may only offer lower pricing in the short term.</li>
      </ol>

      <p><strong>Why are commitment and compliance required to participate?</strong></p>

      <p>All GPOs are not created equal. Our committed purchasing model drives a superior value over voluntary GPOs. The foundation of our success is member alignment around two key principles:</p>

      <ol>
        <li>Product evaluation and contract awards, and</li>
        <li>Contract adoption (compliance)</li>
      </ol>

      <p>High compliance is only possible because our advisory boards are aligned in setting a strategy and approving all contract awards. The consistent adoption of those contracts across our membership is the reason our pricing is dramatically better than all other GPOs. Therefore, to participate in the HealthTrust program, participants must be willing to commit 80 percent of their annual purchases through our contract portfolio, where category coverage exists.</p>

      <p><em>Examples:</em></p>

      <p>Total Annual Spending for Facility ABC = $100,000<br/>
        Total Annual Spending with HealthTrust contract coverage for Facility ABC = $80,000<br/>
        80 percent Commitment = $80,000 X 80 percent ≅ $64,000*<br/>
        Total Annual Spending for Facility XYZ = $50,000<br/>
        Total Annual Spending with HealthTrust contract coverage for Facility XYZ = $40,000<br/>
        80 percent Commitment = $40,000 X 80 percent ≅ $32,000*</p>

      <p class="fz09">* Facility’s approximate dollar volume commitment to participate</p>

      <p>For most healthcare facilities, this equates to using the medical distributor and office supply contracts. Typically, members use many other contracts because they realize significant savings, but when members are accessing the contracts for their two largest categories of expenditures, compliance is usually achieved.</p>

      <p><strong>Who decides which supplier is awarded a contract?</strong></p>

      <p>The process includes extensive input from HealthTrust members through participation committees. These subcommittees represent the major categories we contract for, including medical, surgical, alternate site, laboratory and also ad-hoc groups, as necessary.</p>

      <p>These committees are involved in the initial steps in the process, including strategy, supplier eligibility, review of analysis, review of proposals and final contract award(s).</p>

      <p><strong>I think I can do this myself. What are the benefits of using a GPO?</strong></p>

      <p>Most members have negotiated local contracts in the past, but when they do this they only have their volume to offer the supplier. In addition, staff negotiating these contracts are typically not purchasing professionals and don’t have the market intelligence of a GPO. Many local contracts are negotiated based on a suppliers’ indication that the best price has been provided. GPOs have member data and proposal pricing to analyze and substantiate the claims about pricing and discounts a supplier might say are in place.</p>

      <p>Also, most members (and other GPOs’ members) have concluded that their staff time is too valuable to be spent trying to negotiate with suppliers. Instead, using a GPO provides substantial discounts while allowing staff to focus on the core mission of the member’s organization.</p>
    </div>
  </div>
</div>
