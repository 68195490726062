<a class="btn btn-success btn-custom btn-primary-action" data-toggle="modal" data-target="#modalPriceQuestion">
  <i class="material-icons" style="font-size: 18px; margin-right: 4px;">local_offer</i>
  <span style="position: relative; top: -0.25rem">Please contact me, I'd like to learn more about this contract and pricing</span>
</a>

<div class="modal fade" id="modalPriceQuestion" tabindex="-1" role="dialog" aria-hidden="true" #haq>
  <form class="form-validate body-form" (ngSubmit)="send()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Interested in pricing for Contract #{{contractNumber}}</h4>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>{{session.user?.fname}} {{session.user?.lname}}</h5>
          <h6>{{session.user?.email}}</h6>
          <div class="input-group vertical custom-input-group required">
            <h6 class="group-header">Question or comment:</h6>
            <textarea class="form-control custom-form-control ta-width-100" [(ngModel)]="question" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="send()" [disabled]="saving">Please connect me</button>
        </div>
      </div>
    </div>
  </form>
</div>
