<div id="header" header [ngClass]="{'header-wb':themeID==config.tenetThemeID, 'mgma': themeID == 4}">
  <div class="container w-100">

    <div class="header-content">
      <div class="logo-holder">
        <a *ngIf="themeID == 4" href="https://www.mgma.com/membership/vendors/mgma-best-price">
          <img class="mgma-theme-logo" style="height: 48px; width: 197px;" src="https://purchaseclinic.s3.amazonaws.com/logo/mgma-640x160-gw.png"/>
        </a>
          <a *ngIf="session.user?.__theme?.header_logo_link" href="{{session.user?.__theme?.header_logo_link}}">
            <img [ngClass]="{'mgma-svg': themeID == 4}" src="{{logo_img_url}}"/>
          </a>
        <a *ngIf="session.user?.id && !session.user?.__theme?.header_logo_link" routerLink="/">
          <img src="{{logo_img_url}}" alt="Purchase Clinic"/>
        </a>
        <a *ngIf="!session.user?.id && !session.user?.__theme?.header_logo_link" href="http://purchaseclinic.com/">
          <img src="{{logo_img_url}}" alt="Purchase Clinic"/>
        </a>
      </div>

      <div class="actions-wrapper">
        <header-search-box *ngIf="session.user?.id"></header-search-box>
        <div class="phone-holder">
          <h3 class="header">Customer Support</h3>
          <a class="phone" href="tel:{{session.user?.__theme?.phone ||'1.877.659.2159'}}">{{session.user?.__theme?.phone || '1.877.659.2159'}}</a>
        </div>

        <div class="account-dropdown" *ngIf="session.user?.id">
          <div class="dropdown">
            <a class="dropdown-toggle" id="accountDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="media account-content">
              <span class="media-left avatar-holder">
                <img src="assets/img/profile_picture.svg"/>
              </span>
              <span class="media-body">
                <span class="d-none d-md-block">{{session.user?.fname}} {{session.user?.lname}}</span>
              </span>
            </span>
            </a>
            <div class="dropdown-menu" aria-labelledby="accountDropdown">
              <p class="d-md-none">
                <span><b>{{session.user?.fname}} {{session.user?.lname}}</b></span>
              </p>
              <a class="dropdown-item" routerLink="/account" *ngIf="session?.user?.__location?.zimmer_id"><span class="view-acc"><i class="material-icons sm material-icons-in-link">person</i>&nbsp;View Account</span></a>
              <span class="dropdown-item color-disabled pointer" *ngIf="!session?.user?.__location?.zimmer_id"><span class="view-acc"><i class="material-icons sm material-icons-in-link">person</i>&nbsp;View Account</span></span>
              <a class="dropdown-item" routerLink="/security"><span class="view-acc"><i class="material-icons sm material-icons-in-link">security</i>&nbsp;Security settings</span></a>
              <a class="dropdown-item" routerLink="/account" [queryParams]="{'tab':8}"><span class="view-acc"><i class="material-icons sm material-icons-in-link">comment</i>&nbsp;Contact Us</span></a>
              <a class="dropdown-item" routerLink="/logout"><span class="logout"><i class="material-icons sm material-icons-in-link">exit_to_app</i>&nbsp;Logout</span></a>
            </div>
          </div>
        </div>

        <div class="btn-holder" *ngIf="!session.user?.id">
          <a *ngIf="session.user?.__theme?.signup_url" href="{{session.user?.__theme?.signup_url}}"
            class="btn btn-custom btn-signup">Sign up</a>
          <a *ngIf="!session.user?.__theme?.signup_url" routerLink="/signup"
            class="btn btn-custom btn-signup">Sign up</a>
          <a routerLink="/login" class="btn btn-custom btn-login">Login</a>
        </div>
      </div>
    </div>

  </div><!--EOF container-->
</div>

<nav class="navbar navbar-expand-md navbar-dark" [ngClass]="{'mgma-nav': themeID == 4}">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#exCollapsingNavbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="exCollapsingNavbar" style="padding-left: 6%;">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active" *ngIf="!isAccountSubdomainViewMode">
        <a class="nav-link" routerLink="/"><span class="icon-home d-lg-none d-none d-md-block"></span><span class="d-none d-lg-block span-home">Home</span> <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" *ngIf="!isAccountSubdomainViewMode">
        <a class="nav-link dropdown-toggle pointer" data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">Specialities</a>
        <div class="dropdown-menu">
          <a class="dropdown-item" *ngFor="let specialty of specialtiesList"
              [attr.data-hidden]="!specialty.is_popular"
              [routerLink]="['/specialty', specialty.id]">
            {{specialty.name}}
          </a>
        </div>
      </li>
      <li class="nav-item" *ngIf="!isAccountSubdomainViewMode">
        <a class="nav-link" routerLink="/employee-perk">Employee Perks</a>
      </li>
      <li class="nav-item" *ngIf="!isAccountSubdomainViewMode">
        <a class="nav-link" routerLink="/office-supplies">
          Office/Facility Supplies
        </a>
      </li>
      <li class="nav-item" *ngIf="!isAccountSubdomainViewMode">
        <a class="nav-link" routerLink="/capital-equipment">Capital Equipment</a>
      </li>
      <li class="nav-item" *ngIf="session.user?.id">
        <a class="nav-link" routerLink="/account" *ngIf="session?.user?.__location?.zimmer_id">View Account</a>
        <button type="button" class="btn btn-link color-disabled pointer" *ngIf="!session?.user?.__location?.zimmer_id" (click)="noGpoWarning()">View Account</button>
      </li>
      <li class="nav-item" *ngIf="session.user?.id">
        <a class="nav-link" href="/account?tab=8">Contact Us</a>
      </li>
    </ul>
  </div>
</nav>
