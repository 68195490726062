import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Session} from "../../services/Session";

@Component({
  selector: 'redir-auth',
  template: '<div>Welcome! <br/> Loading...</div>'
})
export class RedirAuthCmp {
  constructor(private router: Router, private routeParams: ActivatedRoute, private session: Session) {}

  ngOnInit() {
    this.routeParams.queryParams.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    let token = params['token'];
    if (!token) {
      this.router.navigate(['/login']);
    } else {
      this.session.setToken(token, () => {
        let redir_path = params['path'];
        if (redir_path && redir_path.length > 0) {
          this.router.navigate(['/' + redir_path]);
        } else {
          this.session.navigateToPrevNonLoginPage(true);
        }
      }, () => {
        this.router.navigate(['/login']);
      });
    }
  }
}
