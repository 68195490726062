import {Component} from '@angular/core';
import {ISignupAnswer, ISignupInfoEdit, ISignupWithPA} from "../../interfaces/ISignupWithPA";
import {IDistributor} from "../../interfaces/IDistributor";
import {User} from "../../services/User";
import {Locations} from "../../services/Locations";
import {Distributors} from "../../services/Distributors";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {Router, ActivatedRoute} from '@angular/router';
import {Helpers} from "../../services/Helpers";
import {ITheme} from "../../interfaces/ITheme";
import {UrlParser} from "../../services/UrlParser";
import {IUser} from "../../interfaces/IUser";

@Component({
  selector: 'get-started-page',
  templateUrl: 'get_started.html'
})
export class GetStartedPageCmp {
  public step = 0;
  public lastStep = 4;
  public lastReachedStep = 0;
  public signup = new ISignupWithPA();
  public currentDate = '';
  public distributorsMap: { [id: number]: IDistributor } = {};
  public distributorsList: IDistributor[] = [];
  public distributorsSecondaryList: IDistributor[] = [];
  public saving = false;
  public loading = false;
  public isError = false;
  public errMsg = '';
  public notify;
  public repName = '';
  public distr_id = 0;
  public secondary_distr_id = 0;
  public sendToSomeoneElse = false;
  public displaySomeoneElseThanks = false;
  public otherContact: IUser;
  public displayThanks: boolean = false;
  public displayUsedLink: boolean = false;
  public detailsEdit = false;
  public themeName = 'Purchase Clinic';
  public themeEmail = 'support@purchaseclinic.com';
  public themePhone = '877.659.2159';
  private defaultThemeName = 'Purchase Clinic';
  private defaultThemeEmail = 'support@purchaseclinic.com';
  private defaultThemePhone = '877.659.2159';
  private signupResponse: any;
  private someoneElseID: number = 0;
  private answerPerPage: { [id: number]: ISignupAnswer } = {
    1: {question: "Who in your office should we work with:", answer: null, is_skipped: false, is_displayed: false},
    2: {question: "Please select your practice’s primary medical supply:", answer: null, is_skipped: false, is_displayed: false},
    3: {question: "Does your practice also use any of the distributors below?", answer: null, is_skipped: false, is_displayed: false},
    4: {question: "Please provide your primary distributor rep name below:", answer: null, is_skipped: false, is_displayed: false},
  };
  private infoEdits: ISignupInfoEdit[] = [];
  private infoBeforeEdit: string;

  constructor(private users: User, private locations: Locations, private distributors: Distributors, private router: Router,
    public session: Session, private config: Config, private h: Helpers, public routeParams: ActivatedRoute, private urlParser: UrlParser) {
    let prelist = [];
    this.distributors.loadToList(prelist, () => {
      if (prelist && prelist.length) {
        this.distributorsList = [];
        prelist.forEach((d) => {
          if (d.survey_order > 0) {
            this.distributorsList.push(d);
          }
          if (d.survey_order_secondary > 0) {
            this.distributorsSecondaryList.push(d);
          }
        });
      }
    });
    this.distributors.loadToMap(this.distributorsMap);
    this.notify = new Notify();
    this.lastReachedStep = 0;
    this.setTheme(this.config.getThemeIDForHost());
  }

  setTheme(tID) {
    if (this.config.themesMap[tID]) {
      let theme: ITheme = this.config.themesMap[tID];
      if (theme.name) {
        this.defaultThemeName = theme.name;
      }
      this.themeName = this.defaultThemeName;

      if (theme.email_from_address || theme.contact_email) {
        this.defaultThemeEmail = theme.email_from_address || theme.contact_email;
      }
      this.themeEmail = this.defaultThemeEmail;

      if (theme.phone) {
        this.defaultThemePhone = theme.phone || this.defaultThemePhone;
      }
      this.themePhone = this.defaultThemePhone;
      if (this.signup && this.signup.user) {
        this.signup.user.theme_id = theme.id;
      }
      if (this.signup && this.signup.location) {
        this.signup.location.theme_id = theme.id;
      }
    }
  }

  ngOnInit() {
    try {
      let tags = this.urlParser.getParameter('tag_ids');
      if (tags) {
        let ids = tags.split(',');
        if (ids && ids.length) {
          let tag_ids = [];
          for (let id_s of ids) {
            let id = parseInt(id_s.trim());
            if (id > 0) {
              tag_ids.push(id);
            }
          }
          this.signup.tags = tag_ids;
        }
      }
    } catch (e) {}
    this.routeParams.params.subscribe(params => {
      let code = params['code'];
      if (code) {
        this.load(code);
      } else {
        this.useUrlParams();
      }
    });
  }

  useUrlParams() {
    let params = this.urlParser.getParameters();
    if (!params || !params['email'] || (!params['fname'] && !params['fname'])) {
      this.router.navigateByUrl('/enroll');
      return;
    } else {
      this.signup.user.email = params['email'];
      if (params['fname']) {
        this.signup.user.fname = params['fname'];
      }
      if (params['lname']) {
        this.signup.user.lname = params['lname'];
      }
      if (params['facility']) {
        this.signup.location.facility_name = params['facility'];
      } else {
        this.signup.location.facility_name = [this.signup.user.fname, this.signup.user.lname].join(' ');
      }
      if (params['phone']) {
        this.signup.user.phone = params['phone'];
      }
      if (params['zip']) {
        this.signup.location.zip = params['zip'];
      }
      if (params['city']) {
        this.signup.location.city = params['city'];
      }
      if (params['state']) {
        this.signup.location.state = params['state'];
      }
      if (params['address']) {
        this.signup.location.address = params['address'];
      }
      if (params['jobtitle']) {
        this.signup.user.job_title = params['jobtitle'];
      }
      let prefilled = {before_edit: "pre-filled", after_edit: JSON.stringify(params)};
      this._signup(true, () => {
        this.locations.recordSignupInfoEdit([prefilled]).subscribe(() => {}, () => {
          // in case of error - send it later
          this.infoEdits.push(prefilled);
        });
      });
    }
  }

  toggleDetailsEdit() {
    this.detailsEdit = !this.detailsEdit;
    if (!this.detailsEdit) {
      this.infoEdits.push({before_edit: this.infoBeforeEdit, after_edit: this.getDetailsSnapshot()});
    } else {
      this.infoBeforeEdit = this.getDetailsSnapshot();
    }
  }

  getDetailsSnapshot() {
    try {
      let u = this.signup.user;
      let loc = this.signup.location;
      return JSON.stringify({
        fname: u.fname, lname: u.lname, email: u.email, phone: u.phone, job_title: u.job_title,
        facility_name: loc.facility_name, city: loc.city, address: loc.address, address2: loc.address2, zip: loc.zip, state: loc.state
      });
    } catch (e) {
      return JSON.stringify({"user": this.signup.user, "location": this.signup.location});
    }
  }

  load(code) {
    if (!code || this.loading) {
      return false;
    }
    this.loading = true;
    this.users.getSignupCodeInfo(code).subscribe((info) => {
      this.loading = false;
      if (info) {
        if (info.user?.id) {
          this.signup.user = info.user;
        }
        if (info.location?.id) {
          this.signup.location = info.location;
          if (info.location.__distributor_id) {
            this.distr_id = info.location.__distributor_id;
          }
          if (info.location.__distributor_secondary_id) {
            this.secondary_distr_id = info.location.__distributor_secondary_id;
          }
          if (info.location.__rep_fname || info.location.__rep_lname) {
            this.repName = [info.location.__rep_fname, info.location.__rep_lname].join(' ');
          }
        }
        this._signup(true);
      } else {
        this.useUrlParams();
      }
    }, () => {
      this.loading = false;
      this.useUrlParams();
    });
    this.users.getSignupCodeSession(code).subscribe((token) => {
      if (token) {
        this.setSessionToken(token);
      }
    }, () => {});
  }

  _signup(is_presignup: boolean, cb?) {
    this.signup.signature = '--electronic signature--';
    this.signupResponse = null;
    this.saving = true;
    this.isError = false;
    this.signup.delayed_pa = is_presignup;
    this.signup.form_actions_id = 9;
    this.users.signupWithPA(this.signup).subscribe((response) => {
      this.signupResponse = response;
      this.saving = false;
      this.session.signupUrl = window.location.hostname + window.location.pathname;
      if (response?.token) {
        this.setSessionToken(response.token, cb);
      } else {
        if (cb) {
          cb();
        }
      }
    }, (response) => {
      this.step = 0;
      this.isError = true;
      this.saving = false;
      console.error(response);
      if (response && response.status) {
        switch (response.status) {
          case 417:
            this.errMsg = 'Please double-check the name that you entered. There appears to be a typo.';
            break;
          case 409:
            this.errMsg = 'User with this email is already registered';
            break;
          case 406:
            this.errMsg = 'Please double-check the email address that you entered. There appears to be a typo.';
            break;
          case 400:
            this.errMsg = 'Please fill required fields.';
            break;
          case 418:
            this.errMsg = 'Please double-check the phone number that you entered. There appears to be a typo.';
            break;
          case 419:
            this.errMsg = 'Please double-check the zip code that you entered. There appears to be a typo.';
            this.signup.user.zip = null;
            break;
          case 429:
            this.errMsg = '';
            this.isError = false;
            break;
          case 430:
            this.displayUsedLink = true;
            this.errMsg = '';
            this.isError = false;
            break;
          default:
            this.errMsg = '';
        }
        if (this.errMsg) {
          this.notify.error(this.errMsg);
        }
      }
    });
  }

  submit() {
    if (this.detailsEdit) {
      this.toggleDetailsEdit();
    }
    if (!this.signup.user.email) {
      this.notify.error('Sorry, field Email is required');
      this.step = 0;
      return;
    }
    if (this.signup.user.email.indexOf('@') < 1 || this.signup.user.email.length < 6) {
      this.notify.error('Please double-check the email address that you entered. There appears to be a typo.');
      this.step = 0;
      return;
    }
    let theme_id = this.config.getThemeIDForHost();
    this.signup.user.theme_id = theme_id;
    this.signup.location.theme_id = theme_id;
    if (theme_id == this.config.defaultThemeID) {
      this.signup.to_generate_rx = true;
    }
    this._signup(false, () => {
      this.nextStep();
    });
  }

  nextStepUI(skip?: boolean) {
    if (this.isNextStepDisabled()) {
      return;
    }
    this.nextStep(skip);
  }

  nextStep(skip?: boolean) {
    if (skip) {
      this.markPageSkipped(this.step);
    }
    this.setAnswerByData(this.step);
    if ((this.step - 0) === this.lastStep) {
      this.lastStepRedirect();
    } else {
      let next = (this.step - 0) + 1;
      if (next === 2 && (!this.distributorsList || !this.distributorsList.length)) {
        next = 4;
      }
      if (next === 3 && (!this.distributorsList || !this.distributorsList.length)) {
        next = 4;
      }
      this.step = next;
      this.markPageDisplayed(this.step);
      if (this.lastReachedStep < this.step) {
        this.lastReachedStep = this.step;
      }
    }
  }

  setAnswerByData(page) {
    switch (page) {
      case 1:
        if (this.sendToSomeoneElse) {
          let name = 'Someone Else';
          if (this.otherContact) {
            if (this.otherContact.fname) {
              name = name + ': ' + this.otherContact.fname;
            }
            if (this.otherContact.lname) {
              name = name + ' ' + this.otherContact.lname;
            }
            if (this.otherContact.email) {
              name = name + ' <' + this.otherContact.email + '>';
            }
          }
          if (this.someoneElseID) {
            name = name + ` [ID: ${this.someoneElseID}]`;
          }
          this.setPageAnswer(page, name);
        } else {
          this.setPageAnswer(page, "Me");
        }
        break;
      case 2:
        if (this.distr_id) {
          let id = this.distr_id - 0;
          if (this.distributorsMap[id]) {
            this.setPageAnswer(page, this.distributorsMap[id].name);
          }
        }
        break;
      case 3:
        if (this.secondary_distr_id) {
          let id = this.secondary_distr_id - 0;
          if (this.distributorsMap[id]) {
            this.setPageAnswer(page, this.distributorsMap[id].name);
          }
        }
        break;
      case 4:
        if (this.repName) {
          this.setPageAnswer(page, this.repName);
        }
        break;
      default:
        return;
    }
  }

  sendCurrentAnswers(onlyDisplayed: boolean) {
    try {
      if (this.session?.user?.id) {
        let answers = [];
        for (let i in this.answerPerPage) {
          if (!onlyDisplayed || this.answerPerPage[i].is_displayed) {
            answers.push(this.answerPerPage[i]);
          }
        }
        this.locations.recordSignupAnswers(answers).subscribe(() => {}, () => {});
      }
    } catch (e) {}
  }

  markPageDisplayed(page: number) {
    let p = (page - 0);
    try {
      if (this.answerPerPage[p]) {
        this.answerPerPage[p].is_displayed = true;
      }
    } catch (e) {
    }
  }

  markPageSkipped(page: number) {
    let p = (page - 0);
    try {
      if (this.answerPerPage[p]) {
        this.answerPerPage[p].is_skipped = true;
      }
    } catch (e) {
    }
  }

  setPageAnswer(page: number, answer) {
    let p = (page - 0);
    try {
      if (this.answerPerPage[p]) {
        this.answerPerPage[p].answer = answer;
        this.answerPerPage[p].is_skipped = false;
        this.answerPerPage[p].is_displayed = true;
        if (this.signup.location.id) {
          this.answerPerPage[p].location_id = this.signup.location.id;
        }
        this.sendCurrentAnswers(true);
      }
    } catch (e) {
    }
  }

  isNextStepDisabled() {
    if (this.displayThanks || this.displaySomeoneElseThanks) {
      return true;
    }
    switch (this.step) {
      case 0: // info
        return true;
      case 1: // Who in your office should we work with
        return false;
      case 2: // prim distr
        return false;
      case 3: // second distr
        return false;
      case 4: // rep
        return false;
      default:
        return false;
    }
    return false;
  }

  prevStep() {
    let prev = this.step - 1;
    if (prev < 0) {
      return;
    }
    this.step = prev;
  }

  lastStepRedirect() {
    let token = this.session.getToken();
    if (!token) {
      if (this.signupResponse && this.signupResponse.token) {
        token = this.signupResponse.token;
        this.setSessionToken(token, () => {
          this.sendAdditionalQuestionsAndRedirect(token);
        }, () => {
          this.redirects();
        });
      } else {
        this.redirects();
      }
    } else {
      this.sendAdditionalQuestionsAndRedirect(token);
    }
  }

  sendAdditionalQuestionsAndRedirect(token) {
    this.sendCurrentAnswers(false);
    try {
      if (this.session?.user?.id) {
        if (this.infoEdits?.length) {
          this.locations.recordSignupInfoEdit(this.infoEdits).subscribe(() => {}, () => {});
        }
      }
    } catch (e) {}
    if (this.signup.location?.id && token && (this.distr_id || this.secondary_distr_id)) {
      this.locations.sendMiniSurvey({
        distributor_id: this.distr_id,
        secondary_distributor_id: this.secondary_distr_id,
        rep_name: this.repName,
        loc_id: this.signup.location.id
      }, token).subscribe(() => {
        this.redirects(token);
      }, () => {
        this.redirects(token);
      });
    } else {
      this.redirects(token);
    }
  }

  redirects(token?: string) {
    let theme_id = this.config.getThemeIDForHost();
    if (token) {
      this.displayThanks = true;
    } else {
      this.router.navigate(['/login']);
    }
  }

  setSessionToken(token, cb?, errCb?) {
    this.session.setToken(token, () => {
      if (!this.signup.location?.id && this.session?.user?.__location?.id) {
        this.signup.location = this.session.user.__location;
      }
      if (this.session.user?.id && this.signup.user && !this.signup.user.id) {
        this.signup.user.id = this.session.user.id;
      }
      if (cb) {
        cb();
      }
    }, errCb, true);
  }

  getProgressPercent() {
    if (this.lastReachedStep < 1 || this.lastStep < 0) {
      return 0;
    }
    return Math.round((this.lastReachedStep / (this.lastStep + 1)) * 100);
  }

  selectContactPerson(createNewContact: boolean) {
    if (!createNewContact) {
      this.sendToSomeoneElse = false;
      this.displaySomeoneElseThanks = false;
      this.nextStep(false);
    } else {
      this.otherContact = new IUser();
      this.sendToSomeoneElse = true;
    }
  }

  addNewContact() {
    if (!this.sendToSomeoneElse || !this.otherContact || !this.session.user || !this.session.user.id) {
      return;
    }
    if (!this.otherContact.email || this.otherContact.email.indexOf('@') < 0) {
      this.notify.warning(this.config.txt.emailRequired);
      return;
    }
    this.saving = true;
    this.users.addContactInSignupSurvey(this.session.user.id, this.otherContact.email, 'get-started',
      this.otherContact.fname, this.otherContact.phone).subscribe((r) => {
      this.saving = false;
      this.displaySomeoneElseThanks = true;
      let id = parseInt(r);
      if (id > 0) {
        this.someoneElseID = id;
      }
      this.setAnswerByData(1);
    }, () => {
      this.saving = false;
      this.displaySomeoneElseThanks = false;
      this.sendToSomeoneElse = false;
      this.nextStep(false);
    });
  }
}
