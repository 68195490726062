import {Component, Input, Output, EventEmitter, ContentChildren, QueryList, ViewChild} from '@angular/core';
import {SelectOptionCmp} from "./SelectOptionCmp";

@Component({
  selector: 'select-box',
  templateUrl: './selectBox.html',
  // uncomment both lines after https://github.com/angular/angular/issues/11408 is fixed
  // encapsulation: ViewEncapsulation.None,
  // styleUrls: ['./sb.css'],
})
export class SelectBoxCmp {
  @Input() model;
  @Output() modelChange = new EventEmitter();
  @Input() placeholder:string;
  @Input() selectClass:string;
  @Input() dropdownClass:string;

  @ContentChildren(SelectOptionCmp) items:QueryList<SelectOptionCmp>;
  @ViewChild('box') box;
  @ViewChild('list') list;
  @ViewChild('scrollbox') scrollbox;

  public selectedItem:SelectOptionCmp;
  public index = 0;
  public listVisible = false;
  public top:string = '0px';
  public left:string = '0px';
  public height:string = '200px';
  public width:string = '0px';
  public keyHandler;
  public focusHandler;
  public scrollHandler;

  ngAfterViewInit() {
    this.items.changes.subscribe(() => {
      this.render();
    }, () => {});
    if (this.list && this.list.nativeElement) {
      document.body.appendChild(this.list.nativeElement);
    }
  }

  ngOnChanges() {
    let newValue = null;
    if (this.model && this.items && this.items.length) {
      this.items.toArray().forEach((selectOption:SelectOptionCmp) => {
        if (selectOption.value == this.model) {
          newValue = selectOption;
        }
      });
    }
    this.selectedItem = newValue;
  }

  render() {
    if (this.items && this.items.length) {
      this.items.toArray().forEach((selectOption:SelectOptionCmp) => {
        if (selectOption.select) {
          if (typeof this.model !== 'undefined' && this.model == selectOption.value) {
            this.selectedItem = selectOption;
          }
          selectOption.select.subscribe(() => {
            this.setValue(selectOption);
          }, () => {});
        }
      });
    }
  }

  toggleList() {
    if (this.listVisible) {
      this.hideList();
    } else {
      this.showList();
      if (this.box && this.box.nativeElement) {
        this.box.nativeElement.focus();
      }
    }
  }

  showList() {
    try {
      let sizes = this.box.nativeElement.getBoundingClientRect();
      this.top = (parseInt(sizes.bottom) + 2).toString() + 'px';
      this.left = (parseInt(sizes.left)).toString() + 'px';
      this.width = sizes.width.toString() + 'px';
      this.height = (Math.round(window.innerHeight - sizes.bottom) - 5).toString() + 'px';
      this.resetSelectedIndex();
      this.listVisible = true;
      this.scrollbox.nativeElement.scrollTop = 0;
      this.keyHandler = ($e) => {
        this.searchKey($e);
      };
      this.disableScrolling();
      document.body.addEventListener('keydown', this.keyHandler);
      this.interceptFocus();
    } catch (e) {}
  }

  prevent($event) {
    if ($event) {
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
    }
    return false;
  }

  disableScrolling() {
    this.scrollHandler = (e) => {
      if (e) {
        try {
          if (e.target.parentElement.parentElement === this.scrollbox.nativeElement) {
            this.scrollbox.nativeElement.scrollTop = (this.scrollbox.nativeElement.scrollTop || 0) + e.deltaY;
          }
        } catch (e) {}
        return this.prevent(e);
      } else {
        return false;
      }
    }
    document.body.addEventListener('scroll', this.scrollHandler, true);
    document.body.addEventListener('mousewheel', this.scrollHandler, true);
    document.body.addEventListener('touchmove', this.scrollHandler, true);
  }

  enableScrolling() {
    if (this.scrollHandler) {
      document.body.removeEventListener('scroll', this.scrollHandler, true);
      document.body.removeEventListener('mousewheel', this.scrollHandler, true);
      document.body.removeEventListener('touchmove', this.scrollHandler, true);
    }
  }

  hideList() {
    this.enableScrolling();
    this.listVisible = false;
    document.body.className = document.body.className.replace(' sb-no-overflow', '');
    if (this.keyHandler) {
      document.body.removeEventListener('keydown', this.keyHandler);
    }
    this.releaseFocus();
  }

  resetActiveOption() {
    if (this.items && this.items.length) {
      this.items.toArray().forEach((selectOption:SelectOptionCmp) => {
        selectOption.active = false;
      });
    }
  }

  resetSelectedIndex() {
    this.resetActiveOption();
    this.index = 0;
  }

  setValue(item:SelectOptionCmp) {
    this.selectedItem = item;
    this.model = item.value;
    this.modelChange.emit(item.value);
    this.hideList();
  }

  blur($event?) {
    setTimeout(() => {
      this.hideList();
    }, 250);
    return this.prevent($event);
  }

  searchKey($event) {
    if (!this.items || !this.items.length) {
      return;
    }
    let selectable = false;
    let scrollDown = false;
    this.items.toArray().forEach((selectOption:SelectOptionCmp) => {
      if (!selectOption.isDisabled) {
        selectable = true;
      }
    });
    if (!selectable) {
      return;
    }
    if (($event.keyCode === 38 || $event.code === 'ArrowUp')) {
      if (this.index > 0) {
        this.index--;
      } else {
        this.index = this.items.length - 1;
      }
    }
    if (($event.keyCode === 40 || $event.code === 'ArrowDown')) {
      if (this.index < (this.items.length - 1)) {
        this.index++;
        scrollDown = true;
      } else {
        this.index = 0;
      }
    }
    let c = this.items.toArray()[this.index];
    if (c) {
      if ($event.keyCode === 13 || $event.code === 'Enter') {
        this.setValue(c);
      } else {
        if (c.isDisabled) {
          this.searchKey($event);
        } else {
          this.resetActiveOption();
          c.active = true;
          try {
            let sb = this.scrollbox.nativeElement;
            let el = c.element.nativeElement;
            let sizes_el = el.getBoundingClientRect();
            let sizes_sb = sb.getBoundingClientRect();
            if (typeof sb.scrollTop === 'undefined') {
              sb.scrollTop = 0;
            }
            if (scrollDown && ((el.offsetTop + sizes_el.height - sb.scrollTop) >= sb.offsetHeight)) {
              sb.scrollTop += sizes_el.height;
            }
            if ((sizes_el.top > sizes_sb.bottom) ||
              sizes_el.top < sizes_sb.top) {
              sb.scrollTop = el.offsetTop;
            }
          } catch (e) {}
        }
      }
    }
    this.prevent($event);
  }

  ngOnDestroy() {
    this.hideList();
  }

  interceptFocus() {
    this.focusHandler = ($e) => {
      this.blur($e);
    };
    document.addEventListener("focus", this.focusHandler, true);
    document.addEventListener("blur", this.focusHandler, true);
    document.addEventListener('focus:in', this.focusHandler);
    document.addEventListener('focus:in', this.focusHandler);
  }

  releaseFocus() {
    if (this.focusHandler) {
      document.removeEventListener("focus", this.focusHandler, true);
      document.removeEventListener("blur", this.focusHandler, true);
      document.removeEventListener('focus:in', this.focusHandler);
      document.removeEventListener('focus:in', this.focusHandler);
    }
  }
}
