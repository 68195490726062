import { IUser } from "./IUser";
import { ILocation } from "./ILocation";

export class ISignupWithPA {
  user: IUser = <IUser>{};
  location: ILocation = <ILocation>{};
  signature: string = "";
  other_locations: string;
  all_locations_managed: boolean;
  review_session_id: number;
  rep_email: string;
  source: string;
  to_generate_rx: boolean;
  delayed_pa: boolean;
  form_actions_id: number;
  tags: number[];
  captcha_token: string;
}

export interface ISignupAnswer {
  question: string;
  answer: string;
  is_skipped: boolean;
  is_displayed: boolean;
  location_id?: number;
  user_email?: string;
}

export interface ISignupInfoEdit {
  before_edit: string;
  after_edit: string;
}
