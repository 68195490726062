import {Component, ChangeDetectorRef, ApplicationRef, ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {User} from "../../../services/User";
import {IUser} from "../../../interfaces/IUser";
import {ILocation} from "../../../interfaces/ILocation";
import {Locations} from "../../../services/Locations";
import {Distributors} from "../../../services/Distributors";
import {Session} from "../../../services/Session";
import {IDistributor} from "../../../interfaces/IDistributor";
import {Notify} from "../../../services/Notify";
import {Config} from "../../../services/Config";
import {Helpers} from "../../../services/Helpers";

@Component({
  selector: 'signup-review',
  templateUrl: './review.html',
})
export class SignupReviewCmp {
  public user:IUser = <IUser>{};
  public loc:ILocation = <ILocation>{};
  public info;
  public custom = {have_other_locations: false, all_locations_managed: true, other_locations: '', signature: ''};
  public currentDate = '';
  public distributorsList:IDistributor[] = [];
  public saving = false;
  public isError = false;
  public signedDate:Date;
  public errMsg = '';
  @ViewChild('elEmail') elEmail:ElementRef;
  public notify;

  constructor(private users:User, private locations:Locations, private distributors:Distributors,
    public routeParams:ActivatedRoute, private router:Router, public session:Session,
    public cdr:ChangeDetectorRef, private ref:ApplicationRef, private config:Config, private h:Helpers) {
    this.notify = new Notify();
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.setCurrentDate();
    let code = params['code'];
    if (!code) {
      this.router.navigateByUrl('/signup');
      return;
    } else {
      this.load(code);
    }
    this.distributors.loadToList(this.distributorsList);
  }

  load(code) {
    if (!code) {
      return false;
    }
    this.users.getSignupCodeInfo(code).subscribe((info) => {
      if (info) {
        this.info = info;
        this.user = info.user || <IUser>{};
        this.loc = info.location || <ILocation>{};
        if (this.loc.pa_date) {
          try {
            this.signedDate = this.h.getDateFromValueSafe(this.loc.pa_date);
          } catch (e) {
            this.router.navigateByUrl('/signup');
          }
        }
      } else {
        this.router.navigateByUrl('/signup');
      }
    }, ()=> {
      this.router.navigateByUrl('/signup');
    });
  }

  setCurrentDate() {
    let d = new Date();
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var formattedDate = months[d.getMonth()] + " " + d.getDate() + "th, " + d.getFullYear() + " at " + d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
    this.currentDate = formattedDate;
    setTimeout(()=> {this.setCurrentDate();}, 1000);
  }

  submit() {
    if (!this.user.email) {
      if (this.elEmail && this.elEmail.nativeElement) {
        try {
          this.user.email = this.elEmail.nativeElement.value;
        }
        catch (e) {}
      }
    }
    this.saving = true;
    let theme_id = this.config.getThemeIDForHost();
    this.user.theme_id = theme_id;
    this.loc.theme_id = theme_id;
    this.users.signupReviewed(this.info.link.code, this.user, this.loc, this.custom.signature,
      this.custom.other_locations, this.custom.all_locations_managed).subscribe((token)=> {
      this.session.setToken(token, ()=> {
        if (theme_id == this.config.defaultThemeID) {
          this.users.getMyRxCode().subscribe((rxCode)=> {
            this.saving = false;
            this.router.navigateByUrl(`/new_member?msg_id=1&code=${token}&ask_vac=1&rx=${rxCode}`);
          }, ()=> {
            this.saving = false;
            this.session.signupUrl = window.location.hostname + window.location.pathname;
            this.router.navigate(['/signup_benefit_review']);
          });
        } else {
          this.saving = false;
          this.session.signupUrl = window.location.hostname + window.location.pathname;
          this.router.navigate(['/signup_benefit_review']);
        }
      }, ()=> {
        this.saving = false;
        this.router.navigate(['/login']);
      });
    }, (response)=> {
      this.isError = true;
      this.saving = false;
      console.error(response);
      if (response && response.status) {
        switch (response.status) {
          case 417:
            this.errMsg = 'Please double-check the name that you entered. There appears to be a typo.';
            break;
          case 409:
            this.errMsg = 'User with this email is already registered';
            break;
          case 406:
            this.errMsg = 'Please double-check the email address that you entered. There appears to be a typo.';
            break;
          case 400:
            this.errMsg = 'Please fill required fields.';
            break;
          default:
            this.errMsg = '';
        }
        if (this.errMsg) {
          this.notify.error(this.errMsg);
        }
      }
    });
  }
}
