<div class="container">
  <spinner [spinnerType]="'throbber-loader'" *ngIf="loading"></spinner>
  <div class="row" [ngClass]="{'hidden':loading}">
    <div class="col-sm-10 offset-sm-1" [ngSwitch]="id">
      <section *ngSwitchDefault class="pc2-bottom-pad">
        <h2 class="pc2-row-pad">Welcome to {{session.user?.__theme?.name||'Purchase Clinic'}}</h2>
        <p>Thank you for completing your Participation Agreement.</p>
        <p>Please check your email inbox for a copy of your agreement.</p>
      </section>
      <section *ngSwitchCase="1" class="pc2-bottom-pad">
        <h2 class="pc2-row-pad">Welcome to {{session.user?.__theme?.name||'Purchase Clinic'}}!</h2>
        <p>With the information you provided, we will work with your distributor to help you discover an easy and proven way to guarantee savings on your medical supplies.
          An e-mail has been sent with your credentials to begin looking at the money saving contracts available through {{session.user?.__theme?.name||'Purchase Clinic'}}.
          If you have any questions prior to us reaching out to you, don’t hesitate to contact one of our Client Managers at <a href="mailto:support@PurchaseClinic.com">support@PurchaseClinic.com</a> or <a href="tel:1-877-659-2159">1.877.659.2159</a>.
        </p>
      </section>
      <section *ngSwitchCase="3" class="pc2-bottom-pad">
        <new-member-distributor [authCode]="authCode"></new-member-distributor>
      </section>
      <section *ngSwitchCase="4" class="pc2-bottom-pad">
        <new-member-distributor [authCode]="authCode"></new-member-distributor>
      </section>
      <section *ngSwitchCase="5" class="pc2-bottom-pad">
        <new-member-distributor [authCode]="authCode"></new-member-distributor>
      </section>
      <section *ngSwitchCase="6" class="pc2-bottom-pad">
        <new-member-distributor [authCode]="authCode"></new-member-distributor>
      </section>
      <section *ngSwitchCase="7" class="pc2-bottom-pad">
        <h2 class="pc2-row-pad">Welcome to {{session.user?.__theme?.name||'Purchase Clinic'}}!</h2>
        <p>Thank you for signing up. A client manager will be in touch with you if we have any additional questions.</p>
      </section>
      <section *ngSwitchCase="8" class="pc2-bottom-pad">
        <h2 class="pc2-row-pad">Thank You</h2>
        <p>Thank you, once your membership is processed we will coordinate with your Henry Schein representative and will reach out regarding next steps.</p>
      </section>
      <section *ngSwitchCase="9" class="pc2-bottom-pad">
        <section *ngIf="miniSurveyCompleted && !isSurveySent">
          <h2 class="pc2-row-pad">Thank You</h2>
          <p>Thank you for entering in the drawing for the gift card. We will randomly select a winner at The Operations Conference and let you know if you’ve won!</p>
        </section>
        <section *ngIf="miniSurveyCompleted && isSurveySent">
          <h2 class="pc2-row-pad">Thank You</h2>
          <p>A MGMA BestPrice Client Manager will be in touch shortly to discuss saving options that are available to you as a MGMA Member.</p>
          <p>You are also entered into the drawing for the gift card. We will randomly select a winner at The Operations Conference and let you know if you’ve won.</p>
        </section>
        <section *ngIf="miniSurveyCompleted && (isSurveySent || isAlreadyMember)">
          <div style="color: red; font-weight: bold; font-size: 125%; padding: 1em">Please stop by Booth 10 and learn about our NEW vaccine pricing.</div>
        </section>
        <section *ngIf="!miniSurveyCompleted && locUpdReq?.loc_id>0">
          <h2 class="pc2-row-pad text-center">Please tell us a little more about your practice.</h2>
          <p class="text-center">Fill out the questions below, and let us work with you and your distributor rep, to see how much MGMA BestPrice can save your practice.</p>
          <div class="row form-group pc2-row-pad" style="margin-top: 3em">
            <label class="col-form-label col-md-4 text-right">Select your primary medical supply distributor</label>
            <div class="col-md-8">
              <select class="form-control" [(ngModel)]="locUpdReq.distributor_id">
                <option *ngFor="let distr of distributorsList" value="{{distr.id}}">{{distr.name}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-form-label col-md-4 text-right">Tell us if you know your rep</label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="locUpdReq.rep_name" class="form-control" placeholder="If you know the name of your distributor rep, please list that here"/>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-form-label col-md-4 text-right">Specialty</label>
            <div class="col-md-8">
              <select class="form-control" [(ngModel)]="locUpdReq.specialty_id">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let spec of specialtiesList" value="{{spec.id}}">{{spec.name}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-form-label col-md-4 text-right">Class of Trade</label>
            <div class="col-md-8">
              <select class="form-control" [(ngModel)]="locUpdReq.cot_id">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let cot of cotsList" value="{{cot.id}}">{{cot.name}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-4 offset-md-8">
              <button type="button" class="btn btn-primary btn-block" (click)="sendSurvey()" [disabled]="sendingSurvey">Submit</button>
            </div>
          </div>
        </section>
      </section>
      <section *ngSwitchCase="10" class="pc2-bottom-pad">
        <pre-vaccines [rxCode]="rxCode" [authCode]="authCode"></pre-vaccines>
      </section>
    </div>
    <div class="col-12 offset-sm-1 col-sm-10" *ngIf="id<7">
      <a routerLink="/signup_benefit_review" class="btn btn-primary btn-action ico-right btn-continue">
        <span>Continue</span>
        <span class="icon-continue_arrow"></span>
      </a>
    </div>
  </div>
</div>
