import {Component} from '@angular/core';
import {Contracts} from '../../services/Contracts';
import {Session} from '../../services/Session';
import {Router, ActivatedRoute} from '@angular/router';
import {IContract} from "../../interfaces/IContract";
import {IContractItem} from "../../interfaces/IContractItem";
import {ISameVendorContract} from "../../interfaces/ISameVendorContract";

@Component({
  selector: 'contract',
  templateUrl: './contract.html',
})
export class ContractCmp {
  public items:IContractItem[] = [];
  public page:number = 1;
  public pagesTotal:number = 1;
  public itemsPerPage:number = 60;
  public displayPrices = false;
  public hasPrices = false;
  public search:string = '';
  public sameVendorContracts:ISameVendorContract[];
  public sameVContractsExpanded:boolean = false;
  public contract:IContract = <IContract>{};
  public category:string;
  public subcategory:string;

  constructor(private contracts:Contracts, private session:Session, private routeParams:ActivatedRoute, private router:Router) {
    this.session.redirectIfNotAuth();
    this.session.addOnLoadHandler(() => {
      this.checkPrices();
    });
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      this.load(params['number']);
    });
    this.routeParams.queryParams.subscribe((params) => {
      if (params && params['search'] && params['search'].length > 0 && !this.search) {
        this.setSearchTerm(params['search']);
      }
    });
  }

  ngOnChanges() {
    setTimeout(() => {
      this.checkPrices();
    }, 1);
  }

  checkPrices() {
    this.displayPrices = false;
    //if (this.session && this.session.user && this.session.user.id && !this.session.user._is_login_auto) {
    //  this.displayPrices = true;
    //  this.displayPricesButtons = true;
    //}
    this.hasPrices = false;
    if (this.contract && this.contract.order_from == 'Direct') {
      this.hasPrices = true;
    }
  }

  load(number) {
    this.contracts.load(number).subscribe((contract) => {
      this.contract = contract;
      if (contract.product_category) {
        this.category = this.contract.product_category.replace(/\|/g, '; ');
      }
      if (contract.product_subcategory) {
        this.subcategory = this.contract.product_subcategory.replace(/\|/g, '; ');
      }
      if (!contract.__items) {
        this.contracts.getItems(number).subscribe((items) => {
          if (items) {
            contract.__items = items;
            this.updateItemsList();
          }
        }, () => {
          contract.__items = <IContractItem[]>[];
        });
      } else {
        this.updateItemsList();
      }
      this.checkPrices();
    }, () => {});
    this.contracts.getSameVendorContracts(number).subscribe((scs) => {
      this.sameVendorContracts = scs;
    }, () => {
      this.sameVendorContracts = null;
    });
  }

  pageAdd(v:number) {
    this.page = Math.max((this.page + Math.ceil(v - 0)), 1);
    this.calculatePages();
    this.updateItemsList();
  }

  setSearchTerm(term:string) {
    this.page = 1;
    this.search = term;
    this.updateItemsList();
  }

  calculatePages() {
    this.pagesTotal = 1;
    if (this.contract && this.contract.__items && this.contract.__items.length) {
      this.pagesTotal = Math.ceil(this.contract.__items.length / this.itemsPerPage);
      if (this.page > this.pagesTotal) {
        this.page = this.pagesTotal;
      }
    }
  }

  updateItemsList() {
    if (!this.contract || !this.contract.__items || this.contract.__items.length < 1) {
      this.items = [];
      this.page = 1;
      this.pagesTotal = 1;
      return;
    }
    let items = [];
    if (this.search) {
      let found_first = [];
      let others = [];
      let t = this.search.toLowerCase().trim();
      this.contract.__items.forEach((fi) => {
        if ((fi.ItemShortDescription.toLowerCase().indexOf(t) > -1) || ((fi.CatalogNumber.toLowerCase().indexOf(t) > -1))) {
          found_first.push(fi);
        } else {
          others.push(fi);
        }
      });
      items = found_first.concat(others);
    } else {
      items = this.contract.__items.slice(0);
    }
    this.items = items.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    this.calculatePages();
  }
}
