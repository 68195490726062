import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Session} from "../../services/Session";
import {User} from "../../services/User";

@Component({
  selector: 'mgma-sso',
  template: '<div class="text-center pc2-row-pad"><h3 class="pc2-row-pad h3 pc2-color-x">Please keep your web browser open while we process your enrollment.</h3></div>'
})
export class MgmaSsoCmp {
  constructor(private router:Router, private routeParams:ActivatedRoute, private session:Session, private users:User) {}

  ngOnInit() {
    this.routeParams.queryParams.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    let token = params['token'];
    if (!token) {
      this.router.navigate(['/login']);
    } else {
      this.users.mgmaSso(token).subscribe((token) => {
        this.session.setToken(token, () => {
          this.router.navigate(['/signup_benefit_review']);
        }, () => {
          this.router.navigate(['/login']);
        });
      }, (err) => {
        console.error(err);
        this.router.navigate(['/login']);
      });
    }
  }
}
