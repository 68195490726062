import {throwError as observableThrowError, Observable, forkJoin} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IEditRequest} from "../interfaces/IEditRequest";
import {IPendingEditRequest} from "../interfaces/IPendingEditRequest";

@Injectable()
export class EditRequests {
  private config:Config;
  private session:Session;
  private http:HttpClient;
  private log = {};

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return headers;
  }

  getPendingRequests():Observable<IPendingEditRequest[]> {
    return this.http.get<IPendingEditRequest[]>(this.config.apiUrl + '/edit_request/pending', {headers: this.getHeaders()});
  }

  addRequest(request:IEditRequest):Observable<string> {
    if (!request.is_new_record && request.record_id_value) {
      try {
        this.log[request.edit_table + '|' + request.record_id_value + '|' + request.edit_field] = request.new_value;
      } catch (e) {}
    }
    return this.http.post(this.config.apiUrl + '/edit_request', JSON.stringify(request), {headers: this.getHeaders(), responseType: 'text'});
  }

  saveRecordEdits(obj:Object, oldObj:Object, table:string, id_field:string, cm_user_id:number):Observable<any> {
    if (!obj || !table || !oldObj) {
      return observableThrowError('');
    }
    if (!this.session || !this.session.user || !this.session.user.id) {
      console.error('not authorized for edits');
      return observableThrowError('');
    }
    try {
      let calls = [];
      for (let p in obj) {
        if (obj.hasOwnProperty(p) && p != id_field) {
          let pending_value = this.log[table + '|' + obj[id_field] + '|' + p];
          if ((obj[p] != oldObj[p]) || (pending_value && pending_value != obj[p])) {
            let req = <IEditRequest>{};
            req.is_new_record = !(obj[id_field] && obj[id_field] != '');
            req.cm_user_id = cm_user_id;
            req.from_user_id = this.session.user.id;
            req.edit_table = table;
            req.record_id_field = id_field;
            req.edit_field = p;
            req.record_id_value = (obj[id_field] || oldObj[id_field]).toString();
            req.old_value = (oldObj[p] || '').toString();
            req.new_value = (obj[p] || '').toString();
            calls.push(this.addRequest(req));
          }
        }
      }
      if (calls.length == 0) {
        return observableThrowError('No edits found');
      }
      return forkJoin(calls);
    } catch (e) {
      console.error(e);
      return observableThrowError('');
    }
  }
}
