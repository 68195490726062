<div *ngIf="question.title" class="answer-header">{{question.title}}</div>
<div class="custom-input-group radio-group">
  <ng-template ngFor let-option [ngForOf]="question?.__options">
    <label>
      <input type="radio" #control name="q{{question?.id}}" (change)="emitUpdate(option)" class="radio"/><span>{{option.title}}</span>
    </label>
    <div *ngIf="option.is_text_input && control.checked" class="row form-group pc2-row-pad">
      <div class="col-12">
        <input type="text" class="form-control" [(ngModel)]="textInput" (change)="emitUpdate(option)" placeholder="{{option.hint||option.title}}" [ngModelOptions]="{standalone: true}"/>
      </div>
    </div>
  </ng-template>
</div>
