import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Contracts} from "../../services/Contracts";
import {IContract} from "../../interfaces/IContract";
import {Observable, Subscription} from "rxjs";
import {debounceTime, startWith, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'header-search-box',
  templateUrl: './header-search-box.html',
})
export class HeaderSearchBoxCmp {
  public isOpen = false;
  public searchInput:UntypedFormControl;
  public searchTerm:string;
  public results:IContract[] = [];
  public listVisible = false;
  public kIndex = 0;
  public loaded = false;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl({disabled: false, value: ''})
  });
  public searchQuery:Subscription;

  constructor(private contracts:Contracts, private router:Router) {
    this.router.events.subscribe(() => {
      this.removeList();
    });
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.loaded = true;
    this.searchInput.enable();
    this.searchInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), startWith('')).subscribe((val) => {
      if (!this.searchTerm && !val) {
        return;
      }
      this.searchTerm = val;
      this.showSearchResults(this.searchTerm);
    }, () => {});
  }

  open() {
    this.isOpen = !this.isOpen;
  }

  showSearchResults(searchTerm) {
    if (this.searchQuery) {
      this.searchQuery.unsubscribe();
    }
    this.loaded = false;
    this.searchQuery = this.contracts.getSearchFiltered(searchTerm, 10).subscribe(
      (r) => {
        this.loaded = true;
        this.results = r;
        this.listVisible = (this.results.length > 0);
        this.kIndex = 0;
      }, () => {
        this.loaded = true;
        this.results = [];
        this.listVisible = false;
        this.kIndex = 0;
      }
    );
  }

  selectContract(contractNumber, $event?) {
    this.removeList();
    this.results = [];
    this.searchTerm = '';
    this.searchInput.setValue('');
    setTimeout(() => {
      this.router.navigate(['/contract', contractNumber]);
    }, 0);
    if ($event) {
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
    }
    return false;
  }

  showAllResults($event?) {
    this.removeList();
    setTimeout(() => {
      this.router.navigate(['/search', this.searchTerm]);
    }, 0);
    if ($event) {
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
    }
    return false;
  }

  removeList() {
    this.listVisible = false;
  };

  blurSearch($event?) {
    setTimeout(() => {
      if (this.listVisible) {
        this.removeList();
      }
    }, 250);
    if ($event) {
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
    }
    return false;
  }

  searchKey($event) {
    if (!this.results || !this.results.length) {
      return;
    }
    if (($event.keyCode === 38 || $event.code === 'ArrowUp')) {
      if (this.kIndex > 0) {
        this.kIndex--;
      } else {
        this.kIndex = this.results.length;
      }
    }
    if (($event.keyCode === 40 || $event.code === 'ArrowDown')) {
      if (this.kIndex < this.results.length) {
        this.kIndex++;
      } else {
        this.kIndex = 0;
      }
    }
    if ($event.keyCode === 13 || $event.code === 'Enter') {
      if (this.kIndex === 0) {
        this.showAllResults();
      } else {
        let c = this.results[(this.kIndex - 1)];
        if (c) {
          this.selectContract(c.contract_number);
        } else {
          this.showAllResults();
        }
      }
    }
  }
}
