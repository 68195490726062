import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Session} from "../../services/Session";
import {IRxAnswers, RxSrv} from "../../services/RxSrv";
import {Notify} from "../../services/Notify";
import {ISubQuestionOption} from "../../interfaces/ISubQuestionOption";
import {IUser} from "../../interfaces/IUser";
import {ILocation} from "../../interfaces/ILocation";
import {Helpers} from "../../services/Helpers";
import {FDTickets} from "../../services/FDTickets";
import {EditRequests} from "../../services/EditRequests";
import {Locations} from "../../services/Locations";
import {Config} from "../../services/Config";
import {UrlParser} from "../../services/UrlParser";

declare function html2canvas(data): any;

@Component({
  selector: 'rx-page',
  templateUrl: 'rx_page.html',
})
export class RxPageCmp {
  // set 'signature' to null if it's optional, otherwise server will decline request with empty signature string
  public data: IRxAnswers = {
    options: [],
    signature: '',
    job_title: '',
    phone: '',
  };
  public ip: string = '';
  public time = '';
  public date = '';
  public saving = false;
  public options: ISubQuestionOption[];
  public donotuse = false;
  public q_title = 'Which vaccine manufacturers do you you want to purchase from?';
  public q_sub_title = 'Vaccine Implementation Module';
  public donotuse_option_id = 420;
  public showDetailsEdit = false;
  private redirectedUser = false;
  public showQuestions = true;
  public detailsEdit = false;
  public user: IUser;
  public location: ILocation;
  private originUser: IUser;
  private originLocation: ILocation;
  private afterVaccinesModule = false;
  private stopTime = false;

  constructor(private routeParams: ActivatedRoute, private session: Session, private router: Router,
    private rxSrv: RxSrv, private helper: Helpers, private fdTickets: FDTickets,
    private editReqSrv: EditRequests, private locSrv: Locations, private config: Config, private urlParser: UrlParser) {}

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      if (this.session.crossPageArgs && this.session.crossPageArgs['rx_vaccines']) {
        this.afterVaccinesModule = true;
      }
      if (this.session.crossPageArgs && this.session.crossPageArgs['rx_redirected_new_member']) {
        this.redirectedUser = true;
        this.showDetailsEdit = false;
      } else {
        this.showDetailsEdit = true;
      }
      this.load(params['code']);
    });
    this.refreshTime();
  }

  load(code: string) {
    this.checkIfLogoutNeeded();
    this.rxSrv.loadCode(code).subscribe((rxResponse) => {
      let showDetailsOrig = this.showDetailsEdit;
      let detailsEditOrig = this.detailsEdit;
      this.showDetailsEdit = true;
      this.detailsEdit = true;
      if (rxResponse) {
        if (rxResponse.token) {
          this.session.setToken(rxResponse.token);
        }
        if (rxResponse.user) {
          if (rxResponse.user.signature) {
            this.data.signature = rxResponse.user.signature;
          }
          if (rxResponse.user.user_ip) {
            this.ip = rxResponse.user.user_ip;
          }
          if (rxResponse.user.user) {
            this.user = rxResponse.user.user;
            this.originUser = this.helper.clone(rxResponse.user.user);
            if (this.user.job_title && this.user.phone) {
              this.showDetailsEdit = showDetailsOrig;
              this.detailsEdit = detailsEditOrig;
            }
          }
          if (rxResponse.user.location) {
            this.location = rxResponse.user.location;
            this.originLocation = this.helper.clone(rxResponse.user.location);
          }
        }
        if (rxResponse.questions) {
          try {
            this.options = rxResponse.questions[0].options;
            this.q_title = rxResponse.questions[0].title;
            this.q_sub_title = rxResponse.questions[0].sub_title;
          } catch (e) {}
        }
        if (rxResponse.answers) {
          this.data.options = rxResponse.answers;
        }
        this.forcedScreenshotHandler();
      }
    }, (err) => {
      let n = new Notify();
      n.httpErr(err);
      this.router.navigateByUrl('/login');
    });
  }

  checkIfLogoutNeeded() {
    try {
      let params = this.urlParser.getParameters();
      if (!params) {
        return;
      }
      if (params.hasOwnProperty('regenscreenshot')) {
        if (this.session.user && this.session.user.id) {
          this.session.logout();
        }
        let n = new Notify();
        n.toastr.error('Please wait, please do not change/click/edit anything and do not submit data', 'Preparing for screenshot', {timeOut: 5000});
      }
    } catch (e) {}
  }

  forcedScreenshotHandler() {
    try {
      let params = this.urlParser.getParameters();
      if (!params) {
        return;
      }
      if (params.hasOwnProperty('regenscreenshot')) {
        if (params['setdatetime']) {
          this.stopTime = true;
          let date = this.helper.getDateFromValueSafe(params['setdatetime']);
          this.time = date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', second: '2-digit'});
          this.date = this.helper.getCurrentDateForUI(params['setdatetime']);
        }
        if (params['setip']) {
          this.ip = params['setip'];
        }
        setTimeout(() => {
          this.takeScreenshot(() => {
            setTimeout(() => {
              let n = new Notify();
              n.success('Done', 'Please close this page and click "Reload" button in CRM');
              this.session.logout();
              setTimeout(() => {
                window.close();
              }, 3000);
            }, 120);
          }, true, true);
        }, 6000);
      }
    } catch (e) {}
  }

  initSurvey() {
    if (!this.user || !this.user.job_title) {
      this.showDetailsEdit = true;
      this.detailsEdit = true;
      let n = new Notify();
      n.warning('Field "Job Title" is required');
      return;
    }
    if (!this.user || !this.user.phone) {
      this.showDetailsEdit = true;
      this.detailsEdit = true;
      let n = new Notify();
      n.warning('Field "Phone" is required');
      return;
    }
    if (!this.data.signature || !this.data.options || this.data.options.length < 1) {
      let n = new Notify();
      n.warning('Please select at least one manufacturers option or let us know that you do not use any of them.');
      return;
    }
    this.saveEdits();
    this.data.job_title = this.user.job_title;
    this.data.phone = this.user.phone;
    this.saving = true;
    let redir = () => {
      this.saving = false;
      if (this.afterVaccinesModule) {
        this.router.navigateByUrl('/vaccines_thanks');
        return;
      }
      if (this.redirectedUser) {
        this.router.navigateByUrl('/signup_benefit_review');
      } else {
        this.showQuestions = false;
      }
    };

    this.rxSrv.initiateRxSurvey(this.data).subscribe(() => {
      this.takeScreenshot(redir, true, false);
    }, (err) => {
      this.saving = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }

  takeScreenshot(callback, waitForFile?, displayErrors?) {
    try {
      if (this.location && this.location.id && html2canvas) {
        html2canvas(document.body).then((canvas) => {
          try {
            let helper = new Helpers();
            let image = helper.canvasToBlob(canvas);
            let filename;
            let toPos = location.href.indexOf('?');
            if (toPos > -1) {
              filename = location.href.substr(8, toPos - 8);
            } else {
              filename = location.href.substr(8);
            }
            this.locSrv.uploadFile(this.location.id, filename + '.png', image, this.config.fileTypeScreenshot).subscribe(() => {
              if (waitForFile) {
                callback();
              }
            }, (err) => {
              if (displayErrors) {
                let n = new Notify();
                n.httpErr(err);
              } else {
                if (waitForFile) {
                  callback();
                }
              }
            });
            if (!waitForFile) {
              callback();
            }
          } catch (e) {
            if (displayErrors) {
              let n = new Notify();
              n.error(e);
            } else {
              callback();
            }
          }
        }, (err) => {
          if (displayErrors) {
            let n = new Notify();
            n.error(err);
          } else {
            callback();
          }
        });
      } else {
        if (displayErrors) {
          let n = new Notify();
          if (!this.location || !this.location.id) {
            n.error('Location is undefined');
          } else {
            n.error('html2canvas is undefined');
          }
        } else {
          callback();
        }
      }
    } catch (e) {
      if (displayErrors) {
        let n = new Notify();
        n.error(e);
      } else {
        callback();
      }
    }
  }

  toggleOption(opt: ISubQuestionOption) {
    let i = this.data.options.indexOf(opt.id);
    if (i > -1) {
      this.data.options.splice(i, 1);
      if (opt.id == this.donotuse_option_id) {
        this.donotuse = false;
      }
    } else {
      if (opt.id == this.donotuse_option_id) {
        this.data.options = [this.donotuse_option_id];
        this.donotuse = true;
      } else {
        this.data.options.push(opt.id);
      }
    }
  }

  refreshTime() {
    setTimeout(() => {
      this.refreshTime();
    }, 1000);
    if (this.stopTime) {
      return;
    }
    let date = new Date();
    this.time = date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', second: '2-digit'});
    this.date = this.helper.getCurrentDateForUI();
  }

  saveEdits() {
    if (!this.location) {
      return false;
    }
    let locSaved = false;
    let userSaved = false;
    let toSendTicket = false;
    let checkSendTicket = () => {
      if (locSaved && userSaved && toSendTicket) {
        this.sendUpdTicket();
      }
    };
    if (this.originLocation) {
      this.editReqSrv.saveRecordEdits(this.location, this.originLocation, 'location', 'id', this.location.__manager_id).subscribe((x) => {
        locSaved = true;
        if (x) {
          let r = parseInt(x.shift());
          if (r > 0) {
            toSendTicket = true;
          }
        }
        checkSendTicket();
      }, () => {
        locSaved = true;
      });
    }
    if (this.user && this.originUser) {
      this.editReqSrv.saveRecordEdits(this.user, this.originUser, 'user', 'id', this.location.__manager_id).subscribe((x) => {
        userSaved = true;
        if (x) {
          let r = parseInt(x.shift());
          if (r > 0) {
            toSendTicket = true;
          }
        }
        checkSendTicket();
      }, () => {
        userSaved = true;
      });
    }
  }

  sendUpdTicket() {
    try {
      let subj = '[Rx Page] Edit Request';
      let u = this.user;
      let loc = this.location;
      let body = `<div>User <a target="_blank" href="https://crm.purchase.guru/user/${u.id}">${u.fname} ${u.lname}</a> (${u.email})</div>
          wants to edit facility <a target="_blank" href="https://crm.purchase.guru/location/${loc.id}">${loc.facility_name}</a> (${loc.pc_id})</div>
          <div><a href="https://crm.purchase.guru/edit_requests">Review</a></div>
          <hr/>
          <div>First name: <b>${u.fname}</b></div>
          <div>Last name: <b>${u.lname}</b></div>
          <div>Email: <b>${u.email}</b></div>
          <div>Facility Name: <b>${this.location.facility_name}</b></div>
          <div>Address: <b>${this.location.address}</b></div>
          <div>Address 2: <b>${this.location.address2}</b></div>
          <div>City: <b>${this.location.city}</b></div>
          <div>State: <b>${this.location.state}</b></div>
          <div>Postal code: <b>${this.location.zip}</b></div>`;
      let customFields = {pc_id: loc.pc_id};
      if (u.__theme && u.__theme.name) {
        customFields['brand'] = u.__theme.name;
      }
      this.fdTickets.create(u.email, subj, body, customFields).subscribe(() => {}, () => {});
    } catch (e) {}
  }
}
