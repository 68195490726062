import {Component} from '@angular/core';
import {Session} from "../../../services/Session";
import {Implementations} from "../../../services/Implementations";
import {Config} from "../../../services/Config";
import {ILocationImplementationVendor} from "../../../interfaces/ILocationImplementationVendor";
import {Notify} from "../../../services/Notify";
import {User} from "../../../services/User";
import {LocationCustomSettings} from "../../../services/LocationCustomSettings";
import {ICustomSettings} from "../../../interfaces/ICustomSettings";
import {Locations} from "../../../services/Locations";
import {ILocation} from "../../../interfaces/ILocation";
import {Router} from "@angular/router";
declare var _:any;
declare var $:any;

@Component({
  selector: 'survey-office-supplies',
  templateUrl: './office-supplies.html',
  styleUrls: ['./office-supplies.css'],
})
export class SurveyOfficeSuppliesCmp {
  public isStaplesAdvCustomer;
  public officeContactUser = {name: '', email: ''};
  public staplesAccountNumber;
  public numberIsSet = false;
  public saving = false;
  public newContactThanks = false;
  public settings:ICustomSettings = <ICustomSettings>{};
  public userLocation:ILocation = <ILocation>{};
  public disclaimed;

  constructor(private session:Session, private implementationsSrv:Implementations,
    public locService:Locations, private router:Router,
    public config:Config, private userSrv:User, private locSettings:LocationCustomSettings) {
    this.session.addOnLoadHandler(() => {
      this.loadUserData();
    });
    this.session.redirectIfNotAuth();
  }

  loadUserData() {
    let u = this.session.user;
    if (u.location_id) {
      this.settings = <ICustomSettings>{location_id: u.location_id};
      this.implementationsSrv.getLocationVendors(u.location_id).subscribe((vendors) => {
        if (vendors && vendors.length) {
          let staples:ILocationImplementationVendor = _.find(vendors, {impl_vendor_id: this.config.staplesVendorID});
          if (staples && staples.account_number) {
            this.staplesAccountNumber = staples.account_number;
            this.isStaplesAdvCustomer = 1;
            this.numberIsSet = true;
          }
        }
      }, () => {});
      this.locSettings.getSettings(u.location_id).subscribe((settings) => {
        this.settings = settings;
      }, () => {});
      this.locService.getLocation(u.location_id).then((loc:ILocation) => {
        if (loc) {
          this.userLocation = loc;
          if (loc.__group_primary_location_id != loc.id) {
            this.locService.getLocation(loc.__group_primary_location_id).then((primaryLoc:ILocation) => {
              if (primaryLoc) {
                this.userLocation = primaryLoc;
              }
            }, () => {});
          }
        }
      }, () => {});
    }
  }

  save() {
    if (this.disclaimed) {
      return this.disclaim();
    }
    let u = this.session.user;
    if (this.officeContactUser.email) {
      this.saving = true;
      this.userSrv.addContactInNonMedSurvey('office supplies', u.id, this.officeContactUser.email, this.officeContactUser.name).subscribe(() => {
        this.locSettings.callZapierNonMedNewContact('office', {
          is_staples_customer: this.isStaplesAdvCustomer,
          account_number: this.staplesAccountNumber,
        }, u, this.officeContactUser.name, this.officeContactUser.email, this.userLocation);
        this.saving = false;
        let n = new Notify();
        n.success('Saved');
        this.newContactThanks = true;
        $('html,body').animate({scrollTop: 0}, 150);
      }, (err) => {
        this.saving = false;
        try {
          let n = new Notify();
          n.error('Error');
        } catch (e) {}
        console.error(err);
      });
    } else {
      if (!u.location_id) {
        let n = new Notify();
        n.error('Please add information about facility into your account');
        return;
      }
      if (this.isStaplesAdvCustomer) {
        let v = new ILocationImplementationVendor();
        v.account_number = this.staplesAccountNumber;
        v.location_id = u.location_id;
        v.impl_vendor_id = this.config.staplesVendorID;
        v.user_id = u.id;
        this.saving = true;
        this.implementationsSrv.setLocationVendors([v]).subscribe(() => {
          this.saving = false;
          if (!this.settings.is_office_supplies_page_filled) {
            this.settings.is_office_supplies_page_filled = true;
            this.locSettings.setSettings(this.settings).subscribe(() => {}, () => {});
          }
          this.locSettings.callZapierNonMedAnswers('office', {'account_number': this.staplesAccountNumber}, u, this.userLocation);
          let n = new Notify();
          n.success('Saved');
          this.router.navigate(['/non_med_savings/office_supplies/thank_you']);
          this.numberIsSet = true;
        }, (err) => {
          this.saving = false;
          let n = new Notify();
          n.error('Server error');
          console.error(err);
        });
      } else {
        this.router.navigate(['/non_med_savings/office_supplies/thank_you']);
      }
    }
    if (u.location_id) {
      this.locService.setVendorInterested(u.location_id, this.config.staplesVendorID);
    }
  }

  disclaim() {
    this.settings.is_office_supplies_page_filled = true;
    this.settings.is_office_supplies_page_disclaimed = true;
    this.locSettings.setSettings(this.settings).subscribe(() => {}, () => {});
    let n = new Notify();
    n.success('Saved');
    this.router.navigate(['/non_med_savings']);
    this.numberIsSet = true;
  }
}
