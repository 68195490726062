export class IReviewSession {
  id:number;
  user_id:number;
  class_of_trade_id:number = 0;
  specialty_id:number = 0;
  distributor_email:string;
  group_tag:string;
  __current_page_index:number;
  //
  __ask_distributor_email:boolean = false;
}
