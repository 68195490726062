import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Session} from "../../../services/Session";
import {ISpecialty} from "../../../interfaces/ISpecialty";
import {Specialties} from "../../../services/Specialties";
import {ICot} from "../../../interfaces/ICot";
import {Cots} from "../../../services/Cots";
import {IUser} from "../../../interfaces/IUser";
import {Helpers} from "../../../services/Helpers";
import {Notify} from "../../../services/Notify";
import {RxSrv, IPreVacAnswers} from "../../../services/RxSrv";

@Component({
  selector: 'pre-vaccines',
  templateUrl: './pre-vaccines.html',
})
export class PreVaccinesCmp {
  @Input() rxCode = '';
  @Input() authCode = '';
  public specialtiesList:ISpecialty[] = [];
  public cotsList:ICot[] = [];
  public specialtyIsPreloaded = false;
  public cotIsPreloaded = false;
  public loading = false;
  public vacAnswer;
  public user:IUser;
  private userOriginal:IUser;
  private n = new Notify();

  constructor(private session:Session, private router:Router, private h:Helpers, private rxSrv:RxSrv, private srvSpec:Specialties, private srvCots:Cots) {
    this.srvCots.loadToList(this.cotsList);
    this.srvSpec.load().then((specs:ISpecialty[]) => {
      if (specs && specs.length) {
        specs.forEach((spec) => {
          if (spec.is_popular > 0) {
            this.specialtiesList.push(spec);
          }
        });
      }
    }, () => {});
  }

  ngOnChanges() {
    this.loadAuthCode();
  }

  loadAuthCode(cb?) {
    if (this.user && this.user.id) {
      if (cb) { cb();}
      return;
    }
    if (this.authCode) {
      this.loading = true;
      this.session.setToken(this.authCode, ()=> {
        this.loading = false;
        this.user = this.session.user;
        if (this.user.specialty_id > 0) {
          this.specialtyIsPreloaded = true;
        } else {
          this.specialtyIsPreloaded = false;
        }
        if (this.user.class_of_trade_id > 0) {
          this.cotIsPreloaded = true;
        } else {
          this.cotIsPreloaded = false;
          if (this.session.crossPageArgs['set_answer_cot_id']) {
            this.user.class_of_trade_id = parseInt(this.session.crossPageArgs['set_answer_cot_id']);
          }
        }
        if (cb) {
          cb();
        }
      }, ()=> {
        this.loading = false;
        this.router.navigate(['/login']);
      });
    }
  }

  saveBeforeRedirect(cb?) {
    if (this.user && this.user.id) {
      this.rxSrv.savePreVaccinesAnswers({
        user_id: this.user.id,
        cot_id: this.user.class_of_trade_id,
        specialty_id: this.user.specialty_id,
        vaccines_use: this.vacAnswer
      }).subscribe(()=> {
        if (cb) {
          cb();
        }
      }, ()=> {
        if (cb) {
          cb();
        }
      });
    } else {
      if (cb) {
        cb();
      }
    }
  }

  continue() {
    if (this.h.isUndefined(this.vacAnswer)) {
      this.n.info("Do any of your locations use vaccines?", "Please select one in order to continue");
      return;
    }
    if (this.vacAnswer > 0 && this.rxCode) {
      this.saveBeforeRedirect();
      this.session.crossPageArgs['rx_redirected_new_member'] = 1;
      this.router.navigateByUrl('/rx/' + this.rxCode);
    } else {
      this.saveBeforeRedirect(()=> {
        this.router.navigateByUrl('/signup_benefit_review');
      });
    }
  }
}
