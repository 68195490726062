import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {INewsletter} from "../interfaces/INewsletter";

export interface IRecommendedContract {
  contract_number?: number;
  vendor_name: string;
  contract_description?: string;
}

export interface IRecommendedContracts {
  contracts: IRecommendedContract[];
}

@Injectable()
export class Newsletter {
  private config:Config;
  private session:Session;
  private http:HttpClient;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getURLofCompiledLetterForLocation(code:string):string {
    return this.config.apiUrl + '/newsletter/' + code + '/html';
  }

  addContactFromNewsletter(main_user_id, email, group_tag:String, name?:string, phone?:string, specialty_id?:number, cot_id?:number):Observable<string> {
    let l = window.location;
    return this.http.post(this.config.apiUrl + '/newsletter/contact', JSON.stringify({
      main_user_id: main_user_id,
      email: email,
      group_tag: group_tag,
      name: name,
      phone: phone,
      specialty_id: specialty_id,
      class_of_trade_id: cot_id,
      return_url: l.href
    }), {responseType: 'text'});
  }

  getRecommendedContracts(locID:number):Observable<IRecommendedContracts> {
    return this.http.get<IRecommendedContracts>(this.config.apiUrl + '/newsletter/recommendations/' + locID);
  }
}
