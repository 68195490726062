import {Component} from '@angular/core';
import {UrlParser} from "../../services/UrlParser";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'survey-ext-distributor',
  templateUrl: 'distr_survey.html'
})
export class SurveyExtDistributorCmp {
  public surveyURI;
  private urlWithUpdates = 'https://expansion.surveysparrow.com/s/Distributor-Survey-UpdatesAdditions/tt-e92ee7?';
  private urlWithoutUpdates = 'https://expansion.surveysparrow.com/s/Distributor-Survey-No-Updates/tt-141155?';

  constructor(private urlParser: UrlParser, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    let params: any = this.urlParser.getParameters();
    if (params) {
      let url;
      if (params.updates) {
        url = this.urlWithUpdates;
      } else {
        url = this.urlWithoutUpdates;
      }
      if (params.channel_email) {
        url = url + 'Channel_Email=' + params.channel_email + '&';
      }
      if (params.user_email) {
        url = url + 'User_Email=' + params.user_email + '&';
      }
      if (params.channel_phone) {
        url = url + 'Channel_Phone=' + params.channel_phone + '&';
      }
      if (params.channel_name) {
        url = url + 'Channel_Name=' + params.channel_name + '&';
      }
      if (params.pcid) {
        url = url + 'PCID=' + params.pcid + '&';
      }
      this.surveyURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.surveyURI = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlWithUpdates)
    }
  }
}
