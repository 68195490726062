<div class="container" *ngIf="loaded">
  <h1 class="pc2-color-x text-center">Take Full Advantage of {{session.user?.__theme?.name||'Purchase Clinic'}} Savings</h1>
  <div class="row pc2-row-pad">
    <div class="col-sm-10">
      <progress class="progress pc2-color-x" value="{{percentage}}" max="100"></progress>
    </div>
    <div class="col-sm-2 text-center">
      <label style="font-weight: bold;font-size: 1.3rem; top: -0.5rem;">{{percentage}}% complete</label>
    </div>
  </div>
  <div class="row icons-row">
    <div class="img-box-fill-1" *ngIf="!displayOfficeSuppliesPage">&nbsp;</div>
    <div class="img-box" [ngClass]="{'active':settings.is_shipping_page_filled}" routerLink="/non_med_savings/shipping">
      <div><i class="material-icons">local_shipping</i></div>
      <div class="step">Shipping</div>
    </div>
    <div *ngIf="displayOfficeSuppliesPage" class="img-box" [ngClass]="{'active':settings.is_office_supplies_page_filled}" routerLink="/non_med_savings/office_supplies">
      <div><i class="material-icons">attach_file</i></div>
      <div class="step">Office Supplies</div>
    </div>
    <div class="img-box" [ngClass]="{'active':settings.is_cellphones_page_filled}" routerLink="/non_med_savings/wireless">
      <div><i class="material-icons">phone_iphone</i></div>
      <div class="step">Wireless Service</div>
    </div>
    <div class="img-box" [ngClass]="{'active':settings.is_car_rental_page_filled}" routerLink="/non_med_savings/rental_cars">
      <div><i class="material-icons">directions_car</i></div>
      <div class="step">Car Rental</div>
    </div>
    <!--<div class="img-box" [ngClass]="{'active':settings.is_utility_bills_page_filled}" routerLink="/non_med_savings/utility_bills">-->
      <!--<div><i class="material-icons">lightbulb_outline</i></div>-->
      <!--<div class="step">Utility Bills</div>-->
    <!--</div>-->
  </div>
  <div class="row" style="margin-top: 3rem" *ngIf="percentage<100">
    <div class="col-12" style="padding-left: 5%;">
      <div>To be completed:</div>
      <ul class="steps-list">
        <li *ngIf="!settings.is_shipping_page_filled">
          <a routerLink="/non_med_savings/shipping">Shipping</a>
        </li>
        <li *ngIf="!settings.is_office_supplies_page_filled && displayOfficeSuppliesPage">
          <a routerLink="/non_med_savings/office_supplies">Office Supplies (Staples)</a>
        </li>
        <li *ngIf="!settings.is_cellphones_page_filled">
          <a routerLink="/non_med_savings/wireless">Wireless Services (AT&T)</a>
        </li>
        <li *ngIf="!settings.is_car_rental_page_filled">
          <a routerLink="/non_med_savings/rental_cars">Car Rental (Enterprise, National Car Rental)</a>
        </li>
        <!--<li *ngIf="!settings.is_utility_bills_page_filled">-->
          <!--<a routerLink="/non_med_savings/utility_bills">Utility Bills (EnergyTrust)</a>-->
        <!--</li>-->
      </ul>
    </div>
  </div>
</div>
