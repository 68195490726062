import {Component, Input} from '@angular/core';
import {Config} from "../../services/Config";
import {ILocGroupFile} from "../../interfaces/ILocGroupFile";

@Component({
  selector: 'account-files',
  templateUrl: './files.html',
})
export class AccountFilesCmp {
  @Input() files:ILocGroupFile[];
  public fileDownloadUrlPrefix:string = '';

  constructor(public config:Config) {
    this.fileDownloadUrlPrefix = this.config.apiUrl + '/file_download/';
  }
}
