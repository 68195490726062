import {Component, ViewChild, ElementRef} from '@angular/core';
import {CellphoneSignup} from "../../../services/CellphoneSignup";
import {ICellphoneSignup} from "../../../interfaces/ICellphoneSignup";
import {Notify} from "../../../services/Notify";
import {LocationCustomSettings} from "../../../services/LocationCustomSettings";
import {Session} from "../../../services/Session";
import {ICustomSettings} from "../../../interfaces/ICustomSettings";
import {Locations} from "../../../services/Locations";
import {ILocation} from "../../../interfaces/ILocation";
import {Config} from "../../../services/Config";
declare var $:any;
declare var ClipboardJS:any;

@Component({
  selector: 'survey-cellphones',
  templateUrl: './cellphones.html',
  styleUrls: ['./cellphones.css']
})
export class SurveyCellPhonesCmp {
  @ViewChild('copy') copyBtn:ElementRef;
  public data:ICellphoneSignup = <ICellphoneSignup>{};
  public saving = false;
  public saved = false;
  public selectCopy = false;
  public settings:ICustomSettings = <ICustomSettings>{};
  public userLocation:ILocation = <ILocation>{};
  public disclaimed;

  constructor(private cellphonesService:CellphoneSignup, private session:Session,
    public locSettings:LocationCustomSettings, private locService:Locations, private config:Config) {
    this.session.addOnLoadHandler(() => {this.loadUserData();});
    this.session.redirectIfNotAuth();
  }

  loadUserData() {
    if (this.session.user) {
      let u = this.session.user;
      if (u.location_id) {
        this.locSettings.getSettings(u.location_id).subscribe((settings) => {
          this.settings = settings;
        }, () => {});
      }

      this.data.fname = u.fname;
      this.data.lname = u.lname;
      this.data.asps_id = u.asps_id;
      this.data.business_email = u.email;
      this.data.phone = u.phone;
      if (u.location_id) {
        this.locService.getLocation(u.location_id).then((loc:ILocation) => {
          if (loc) {
            this.userLocation = loc;
            this.data.facility_name = loc.facility_name;
            this.data.facility_address = loc.address;
            this.data.facility_city = loc.city;
            this.data.facility_state = loc.state;
            this.data.facility_zip = loc.zip;
            this.data.billing_zip = loc.zip;

            if (loc.__group_primary_location_id != loc.id) {
              this.locService.getLocation(loc.__group_primary_location_id).then((primaryLoc:ILocation) => {
                if (primaryLoc) {
                  this.userLocation = primaryLoc;
                }
              }, () => {});
            }
          }
        }, () => {});
      }
    }
  }

  submit() {
    if (this.disclaimed) {
      return this.disclaim();
    }
    let notify = new Notify();
    if (!this.data.fname) {
      notify.error('Please fill field First Name');
      return;
    }
    if (!this.data.lname) {
      notify.error('Please fill field Last Name');
      return;
    }
    if (!this.data.facility_name) {
      notify.error('Please fill field Facility Name');
      return;
    }
    if (!this.data.phone) {
      notify.error('Please fill field Primary Account Cell Phone Number');
      return;
    }
    if (!this.data.personal_wireless_phone) {
      notify.error('Please fill field Personal Wireless #');
      return;
    }
    if (!this.data.business_email || this.data.business_email.indexOf('@') < 0) {
      notify.error('Please fill field Business e-mail address');
      return;
    }
    if (!this.data.billing_zip) {
      notify.error('Please fill field Personal Billing Zip Code');
      return;
    }
    if (!this.data.ssn) {
      notify.error('Please fill field Account Holder\'s SSN');
      return;
    }
    if (this.data.ssn && this.data.ssn.length !== 4) {
      notify.error('SSN should contain only 4 digits');
      return;
    }
    this.saving = true;
    let u = this.session.user;
    try {
      if (u && u.__theme && u.__theme.id) {
        this.data.theme_id = u.__theme.id;
      }
    } catch (e) {}
    this.cellphonesService.submit(this.data).subscribe(() => {
      this.settings.is_cellphones_page_filled = true;
      this.locSettings.setSettings(this.settings).subscribe(() => {}, () => {});
      this.locSettings.callZapierNonMedAnswers('wireless', this.data, u, this.userLocation);
      this.saved = true;
      this.saving = false;
      this.initCopyBtn();
      $('html,body').animate({scrollTop: 0}, 150);
    }, (err) => {
      this.saving = false;
      console.error(err);
      let n = new Notify();
      n.error('Error');
    });
    if (u.location_id) {
      if (this.data.carrier == 'att') {
        this.locService.setVendorInterested(u.location_id, this.config.attVendorID);
      }
    }
  }

  disclaim() {
    this.settings.is_cellphones_page_filled = true;
    this.settings.is_cellphones_page_disclaimed = true;
    this.locSettings.setSettings(this.settings).subscribe(() => {}, () => {});
    this.saved = true;
    this.saving = false;
    this.initCopyBtn();
    $('html,body').animate({scrollTop: 0}, 150);
  }

  initCopyBtn() {
    setTimeout(() => {
      try {
        if (this.copyBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyBtn.nativeElement);
          let u = this.session.user;
          if (u.__theme && u.__theme.landing_domain) {
            this.copyBtn.nativeElement.setAttribute('data-clipboard-text', `http://${u.__theme.landing_domain}/cellphones`);
          }
          clipboard.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyBtnClick($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }
}
