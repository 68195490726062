import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IReviewSession} from "../interfaces/IReviewSession";
import {IReviewAnswer} from "../interfaces/IReviewAnswer";
import {ITheme} from "../interfaces/ITheme";
import {Session} from "./Session";

@Injectable()
export class Questions {
  public sessions: { [id: number]: IReviewSession; } = {};

  constructor(private http: HttpClient, private config: Config, private session: Session) {}

  loadPages(group_tag?: string): Observable<any> {
    let url = this.config.apiUrl + '/questions_page';
    if (group_tag) {
      url = url + '/' + group_tag;
    }
    return this.http.get(url);
  }

  saveAnswers(answers: IReviewAnswer[]): Observable<any> {
    return this.http.post(this.config.apiUrl + '/review_answers', JSON.stringify({answers: answers}));
  }

  getNewReviewAnswersSession(session: IReviewSession): Observable<number> {
    return this.http.post(this.config.apiUrl + '/review_answers_session', JSON.stringify(session), {responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  saveReviewSession(session: IReviewSession): Observable<any> {
    this.sessions[session.id] = session;
    return this.http.put(this.config.apiUrl + '/review_answers_session', JSON.stringify(session));
  }

  getUserReviewSession(user_id: number, group_tag?: string): Observable<IReviewSession> {
    let url = this.config.apiUrl + '/review_session/' + user_id.toString();
    if (group_tag) {
      url = url + '/' + group_tag;
    }
    return this.http.get<IReviewSession>(url);
  }

  getReviewedExternalSurveys(location_id?, pc_id?, email?): Observable<string[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/survey_external/completions?';
    if (location_id) {
      url = url + '&locaton_id=' + location_id;
    }
    if (pc_id) {
      url = url + '&pc_id=' + pc_id;
    }
    if (email) {
      url = url + '&email=' + email;
    }
    return this.http.get<string[]>(url, {headers: headers});
  }

  getExternalSurveysSurveyMonkeyBenefitReviewUrl(hostName: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    const url = `${this.config.apiUrl}/survey_external/surveymonkey/benefit_review_url?domain=${encodeURI(hostName)}`;

    return this.http.get(url, {headers: headers, responseType: 'text'});
  }

  callZapierStartSignupSurvey(email, fname, lname, theme: ITheme, url: string) {
    let l = window.location;
    let d = new Date();
    this.http.post('https://hooks.zapier.com/hooks/catch/266709/ue0o46/',
      JSON.stringify({
        email,
        url,
        datetime: d.toISOString(),
        fname,
        lname,
        theme,
        theme_id: theme.id,
        theme_email_from_name: theme.email_from_name,
        theme_email_from_address: theme.email_from_address,
        theme_name: theme.name,
      })).subscribe(() => {}, () => {});
  }

  callZapierFinishSignupSurvey(email) {
    let d = new Date();
    this.http.post('https://hooks.zapier.com/hooks/catch/266709/ue09v7/',
      JSON.stringify({
        email: email,
        datetime: d.toISOString()
      })).subscribe(() => {}, () => {});
  }

  callZapierNewContactSignupSurvey(name, email, inv_fname, inv_lname, facility, theme: ITheme) {
    return;
    //
    // zap is turned off
    //  let l = window.location;
    //  let d = new Date();
    //  this.http.post('https://hooks.zapier.com/hooks/catch/266709/upc8h2/',
    //    JSON.stringify({
    //      name: name,
    //      email: email,
    //      url: l.protocol + '//' + l.host + '/signup_benefit_review',
    //      datetime: d.toISOString(),
    //      inv_fname: inv_fname,
    //      inv_lname: inv_lname,
    //      theme_id: theme.id,
    //      theme_email_from_name: theme.email_from_name,
    //      theme_email_from_address: theme.email_from_address,
    //      theme_name: theme.name,
    //      facility: facility,
    //    })).subscribe(() => {}, () => {});
  }

  callZapierSurveyDelegatedBy(email: string) {
    let d = new Date();
    this.http.post('https://hooks.zapier.com/hooks/catch/266709/ue09v7/', JSON.stringify({
      email: email,
      datetime: d.toISOString()
    })).subscribe(() => {}, () => {});
  }
}
