<div class="answer-section">
  <div *ngIf="question.title" class="answer-header">{{question.title}}</div>
  <div class="custom-input-group">
    <ng-template ngFor let-option [ngForOf]="question?.__options">
      <div>
        <label><input type="checkbox" #control (change)="emitUpdate(option)"/> {{option.title}}</label>
      </div>
      <div *ngIf="option.is_text_input && control.checked" class="pc2-row-pad">
        <input type="text" class="form-control" [ngModel]="option.user_input" (ngModelChange)="option.user_input = $event; emitUpdate()" placeholder="{{option.hint||option.title}}" [ngModelOptions]="{standalone: true}"/>
      </div>
    </ng-template>
  </div>
</div>
