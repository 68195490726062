<section class="main-content">
  <div class="container account-layout">
    <div class="row">

      <div class="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 personal-info">

        <h3 class="headline">Security Settings</h3>
        <div class="form-horizontal bordered">
          <div class="form-section">
            <h3 class="headline-small upper">Change password</h3>
            <div class="form-group">
              <label class="col-form-label">Current password:</label>
              <input class="form-control custom-form-control" [(ngModel)]="currentPwd" type="password" name="currentPwd"/>
            </div>
            <div class="form-group">
              <label class="col-form-label">New password:</label>
              <input class="form-control custom-form-control" [(ngModel)]="newPwd" type="password" name="newPwd"/>
            </div>
          </div>
          <div class="form-group form-footer">
            <button class="float-right btn btn-primary-action btn-primary" (click)="setNewPassword()" [disabled]="saving || !newPwd || !currentPwd" type="button">Set Password</button>
          </div>
        </div>

        <div class="form-horizontal bordered pc2-row-pad" style="display: none">
          <div class="form-section">
            <h3 class="form-group text-center" [hidden]="!session?.user?.__is_totp || regenerateMode">
              <span class="badge badge-success badge-pill" style="width: 100%;">2-step authentication is turned on!</span>
            </h3>
            <h3 class="form-group text-center" [hidden]="session?.user?.__is_totp || regenerateMode">
              <span class="badge badge-secondary badge-pill" style="width: 100%;">2-step authentication is turned off</span>
            </h3>
            <section *ngIf="!totpSecret">
              <fieldset class="form-group">
                <input id="password" type="password" class="form-control" #password required (keydown)="clearErrors()" placeholder="Password"/>
              </fieldset>
              <fieldset class="form-group">
                <button type="button" class="btn btn-success btn-block" [hidden]="session?.user?.__is_totp || regenerateMode" (click)="turnOnTotp(password)" [disabled]="loading">
                  Turn 2-step authentication on
                </button>
                <button type="button" class="btn btn-success btn-block" [hidden]="!regenerateMode" (click)="turnOnTotp(password)" [disabled]="loading">
                  Reset 2-step authentication
                </button>
                <button type="button" class="btn btn-outline-danger btn-block" [hidden]="!session?.user?.__is_totp || regenerateMode" (click)="turnOffTotp(password)" [disabled]="loading">
                  Turn 2-step authentication off
                </button>
              </fieldset>
              <fieldset class="form-group" [hidden]="!session?.user?.__is_totp || regenerateMode">
                <button type="button" class="btn btn-outline-secondary btn-block" (click)="turnOnTotp(password)" [disabled]="loading">
                  Move to new phone
                </button>
              </fieldset>
            </section>
            <section *ngIf="totpSecret">
              <fieldset class="form-group">
                <p>Please install Google Authenticator (from App Store, Google Play) and scan barcode:</p>
                <div class="clearfix">
                  <img class="img-fluid mx-auto" style="display: block; width: 200px; max-height: 100%;"
                  src="https://quickchart.io/chart?cht=qr&chs=150x150&chl=otpauth://totp/Purchase.Guru?secret={{totpSecret}}">
                </div>
              </fieldset>
              <fieldset class="form-group">
                <input id="totp" type="text" class="form-control" #totp (keydown)="clearErrors()" placeholder="Verification Code"/>
              </fieldset>
              <fieldset class="form-group">
                <button class="btn btn-primary btn-block" [disabled]="loading" type="button" (click)="checkTotp(totp)">Check code</button>
              </fieldset>
              <fieldset class="form-group" [hidden]="!error">
                <button type="button" class="btn btn-outline-secondary btn-block" (click)="regenerateBarcode()" [disabled]="loading">
                  Regenerate barcode
                </button>
              </fieldset>
            </section>
            <fieldset class="form-group">
              <div class="text-danger text-center">
                <span [ngSwitch]="error" [hidden]="!error">
                  <span *ngSwitchCase="404">User not found</span>
                  <span *ngSwitchCase="403">Wrong password</span>
                  <span *ngSwitchCase="401">Wrong password</span>
                  <span *ngSwitchCase="417">2-step verification code required</span>
                  <span *ngSwitchCase="424">Wrong verification code</span>
                  <span *ngSwitchCase="412">2-step authentication should be reconfigured</span>
                  <span *ngSwitchDefault>Server error</span>
                </span>
              </div>
            </fieldset>
            <spinner [hidden]="!loading" spinnerType="dots-loader"></spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
