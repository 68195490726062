import {Component, Input} from '@angular/core';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Locations} from "../../services/Locations";
import {ILocGroupSales} from "../../interfaces/ILocGroupSales";

@Component({
  selector: 'account-sales',
  templateUrl: './account-sales.html',
})
export class AccountSalesCmp {
  @Input() locGroupID:number;
  public salesCSV:string;
  public sales:ILocGroupSales[];
  public loading = false;
  public report_year = '2017';
  public channel = ''

  constructor(private config:Config, private session:Session, private locSrv:Locations) {
    this.session.addOnLoadHandler(()=> {
      let theme = this.session.getCurrentTheme();
      if (theme && theme.name) {
        this.channel = theme.name;
      }
    });
    this.session.redirectIfNotAuth();
    this.setReportYear();
  }

  setReportYear() {
    let d = new Date();
    this.report_year = (d.getFullYear() - 1).toString();
  }

  ngOnChanges() {
    if (this.locGroupID) {
      this.salesCSV = this.config.apiUrl + `/report/location_group_sales/csv/${this.session.getToken()}/${this.locGroupID}`;
      this.loading = true;
      this.locSrv.getSalesOfGroup(this.locGroupID).subscribe((sales)=> {
        this.loading = false;
        this.sales = sales;
        if (sales.length && sales[0].allocation_cycle_month) {
          this.report_year = sales[0].allocation_cycle_month.substring(0, 4);
        } else {
          this.setReportYear();
        }
      }, (err)=> {
        this.loading = false;
        console.error(err);
      });
    }
  }
}
