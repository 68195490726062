import {Component, ElementRef, ViewChild} from '@angular/core';
import {Session} from "../../../services/Session";
import {Config} from "../../../services/Config";
import {Notify} from "../../../services/Notify";
declare var _:any;
declare var $:any;
declare var ClipboardJS:any;

@Component({
  selector: 'survey-office-supplies-thanks',
  templateUrl: './thanks.html',
})
export class SurveyOfficeSuppliesThanks {
  @ViewChild('copy') copyBtn:ElementRef;
  public selectCopy = false;
  public initializedFor = 0;

  constructor(public session:Session, public config:Config) {
  }

  ngDoCheck() {
    if (this.session.user && this.session.user.theme_id && this.initializedFor !== this.session.user.theme_id) {
      this.initializedFor = this.session.user.theme_id;
      this.initCopyBtn();
    }
  }

  initCopyBtn() {
    setTimeout(()=> {
      try {
        if (this.copyBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyBtn.nativeElement);
          clipboard.on('success', (e:any)=> {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', ()=> {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyBtnClick($event:KeyboardEvent) {
    $event.stopPropagation();
    setTimeout(()=> {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e:KeyboardEvent)=> {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(()=> {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }
}
