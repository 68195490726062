<section class="main-content contract-layout">
  <div class="container">

		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a  routerLink="/">Home</a></li>
      <li class="breadcrumb-item active">{{label}}</li>
		</ol>

    <div class="row" *ngFor="let category of categories">
      <div class="col-12">
        <h3 class="headline">
          {{category?.label}}
        </h3>
      </div>

      <contract-list-item class="col-12"
                          [contractId]="contractId"
                          [category]="category?.label"
                          *ngFor="let contractId of category?.contracts">
      </contract-list-item>

      <div class="row">&nbsp;</div>
    </div>

    <div class="row">
      <div class="col-md-12 w-100 ov-h">


      </div>
    </div>
  </div>
	<!-- EOF Container-->
</section>
