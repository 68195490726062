<table class="table table-sm table-hover">
  <thead>
    <tr>
      <th>File Type</th>
      <th>Download File</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let file of files; let f = first">
      <td>{{file.file_type}}</td>
      <td><a href="{{fileDownloadUrlPrefix}}{{file.id}}">{{file.name}} <i class="material-icons material-icons-in-link no-line-height">cloud_download</i></a></td>
    </tr>
  </tbody>
</table>
