import {Component} from '@angular/core';
import {Config} from '../../services/Config';
import {Contracts} from '../../services/Contracts';
import {IFeaturedContractsSet} from '../../interfaces/IFeaturedContractsSet';
import {Router} from '@angular/router';
import {Categories} from "../../services/Categories";
import {Session} from "../../services/Session";
declare var _:any;

@Component({
  selector: 'office-supplies',
  templateUrl: './office-supplies.html',
})
export class OfficeSuppliesCmp {
  public featured:IFeaturedContractsSet;
  public categories = [];
  public specialtyID;
  public label;

  constructor(private session:Session, private categoriesService:Categories, private contracts:Contracts, private router:Router, private config:Config) {
    this.session.addOnLoadHandler(()=> {
      this.specialtyID = config.officeSuppliesSpecialtyID;
      this.load(this.specialtyID);
    });
    this.session.redirectIfNotAuth();
  }

  load(specID) {
    this.categoriesService.getCategoryBySlug('office-supplies').subscribe((categories)=> {
      this.categories = categories;
      if (categories && categories.length) {
        this.label = categories[0].label;
      }
    }, ()=> {});
    this.contracts.listFeatured(specID).subscribe((result)=> {
      this.featured = result;
    }, ()=> {});
  }
}
