<section *ngIf="!showQuestions">
  <div class="container">
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <p>Thank you. A client manager will be in touch with you if we have any additional questions.</p>
      </div>
    </div>
  </div>
</section>
<section *ngIf="showQuestions">
  <div class="pc2-row-pad">
    <h1 style="text-align: center">Please help us identify savings opportunities</h1>
  </div>

  <div class="row pc2-row-pad" *ngIf="showDetailsEdit && user && location">
    <div class="col-md-6 offset-md-3">
      <div class="card pc2-bgcolor-card">
        <div class="card-body font-sm">
          <div class="row">
            <div class="col-6">Please review your contact information:</div>
            <div class="col-6 text-right">
              <span class="pc2-link" (click)="detailsEdit=!detailsEdit">click here to edit contact information</span>
            </div>
          </div>
          <section *ngIf="!detailsEdit">
            <div class="row">
              <div class="col-12">
                <div>{{user.fname}} {{user.lname}} <{{user.email}}></div>
                <div>{{location.facility_name}}</div>
                <div>{{location.address}}</div>
                <div *ngIf="location.address2">{{location.address2}}</div>
                <div>{{[location.city, location.state, location.zip]|arr_join}}</div>
              </div>
            </div>
          </section>
          <section *ngIf="detailsEdit">
            <div class="row pc2-form-group pc2-row-pad">
              <div class="col-sm-6"><input class="form-control" [(ngModel)]="user.fname" placeholder="First Name" type="text"/></div>
              <div class="col-sm-6"><input class="form-control" [(ngModel)]="user.lname" placeholder="Last Name" type="text"/></div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-sm-6">
                <input class="form-control" [(ngModel)]="user.email" placeholder="Email" type="email"/>
              </div>
              <div class="col-sm-6">
                <input class="form-control" [(ngModel)]="user.phone" placeholder="Phone" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="user.job_title" placeholder="Job Title (required)" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="location.facility_name" placeholder="Company Name" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="location.address" placeholder="Address" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="location.address2" placeholder="Address 2" type="text"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4"><input class="form-control" [(ngModel)]="location.city" placeholder="City" type="text"/></div>
              <div class="col-sm-4"><input class="form-control" [(ngModel)]="location.state" placeholder="State" type="text"/></div>
              <div class="col-sm-4"><input class="form-control" [(ngModel)]="location.zip" placeholder="Post code" type="text"/></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3">
      <h4 class="pc2-row-pad"><strong>{{q_sub_title}}</strong></h4>
      <p><strong>{{q_title}}</strong><br/>(may select multiple)</p>
      <div class="pc2-big-checkbox" *ngFor="let opt of options" (click)="toggleOption(opt)">
        <input type="checkbox" id="rxcb{{opt.id}}" [checked]="data.options.indexOf(opt.id)>-1" [disabled]="opt.id!=donotuse_option_id && donotuse"> <label for="rxcb{{opt.id}}">{{opt.title}}</label>
      </div>

      <p style="border-top: 1px solid #ccc; margin: 1em 0 0 0; padding: 1em 0; font-size: 14px; line-height: 1.4em">
        Your electronic signature and profile information will be used to populate the documentation necessary to implement the HealthTrust program for your vaccine purchases. Submissions will not create a purchase obligation but will ensure that purchases access the HealthTrust portfolio. Vaccine manufacturers do not allow customers to access more than one buying group at a time. While you will maintain the right to inactivate your HealthTrust membership at any time, manufactures are unlikely to change your buying group affiliation more than once in a 60 day period. By signing this form, you are acknowledging the choice of the HealthTrust program and realize that manufactures (including: Merck &amp; Co Inc, Pfizer, Sanofi Pasteur, and Seqirus) may remove you from any other affiliations that you are currently associated with (i.e. GPO, PBGs, ect.). Please acknowledge your review of this disclosure, the
        <a data-toggle="modal" data-target="#modalTOSrx" class="tos" id="tos1">attached forms</a> and your consent for use of your electronic signature to ensure access and enrollment as a member of HealthTrust. We will contact you with any questions after reviewing this documentation and will provide training or update on the HealthTrust vaccine portfolio as needed.
      </p>

      <p style="font-size: 24px"><strong>Please type your name here for signature:</strong></p>
      <input type="text" class="pc2-rx-signature" style="width: 100%" [(ngModel)]="data.signature">
    </div>
  </div>
  <div class="row pc2-row-pad">
    <div class="col-md-3 offset-md-3">
      <div class="pc2-pre-text">
        Your IP address: {{ip}}<br/>
        Current time: {{time}} Eastern<br/>
        Current date: {{date}}
      </div>
    </div>
    <div class="col-md-3 text-right">
      <button class="btn btn-primary" (click)="initSurvey()" [disabled]="!data.signature || saving">Continue 》</button>
    </div>
  </div>

  <div class="modal fade" id="modalTOSrx" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Vaccine Manufacturer Information</h4>
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body pc2-align-sub-images">
          <img src="/assets/img/rx/sanofi-ccf.png">
          <br/>
          <img src="/assets/img/rx/merck-dec-form.jpg">
          <br/>
          <div>
            <img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-1.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-2.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-3.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-4.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-5.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-6.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-7.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-8.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-9.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-10.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-11.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-12.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-13.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-14.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-15.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-16.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-17.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-18.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-19.jpg" alt=""/><br/><img src="https://rx-file.s3.amazonaws.com/vbc-loc-merck-20.jpg" alt=""/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</section>
