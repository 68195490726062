declare var $:any;

export class OwlCarousel {
  constructor(private element:any) {}

  addItem(content) {
    $(this.element).data('owlCarousel').addItem(content);
  }

  reinit() {
    try {
      let slidesCount = 100;
      try {
        slidesCount = $(this.element).data('owlCarousel').itemsAmount;
      } catch (e) {}
      for (let i = slidesCount; i > 0; --i) {
        $(this.element).data('owlCarousel').removeItem();
      }
    } catch (e) {}
    $(this.element).data('owlCarousel').reinit();
  }

  checkWidth() {
    if (!this.element) {
      return;
    }
    if ($(window).width() < 768) {
      $(this.element).css('height', 'auto');
    } else {
      $(this.element).height($('aside.menu').height());
    }
  }

  init() {
    if (!this.element) {
      return;
    }
    $(window).resize(()=> {
      this.checkWidth();
    });

    this.build();
    this.checkWidth();
  }

  build() {
    let itemCount = 1;
    $(this.element).owlCarousel({
      autoPlay: 7000,
      itemsDesktopSmall: $(this.element).hasClass('fc-carousel') ? [979, 3] : itemCount,
      itemsTablet: $(this.element).hasClass('fc-carousel') ? [768, 3] : itemCount,
      itemsMobile: $(this.element).hasClass('fc-carousel') ? [479, 2] : itemCount,
      pagination: $(this.element).data('pagination') === undefined ? false : $(this.element).data('pagination'),
      singleItem: $(this.element).data('singleItem') === undefined ? true : $(this.element).data('singleItem'),
      navigation: $(this.element).data('navigation') === undefined ? false : $(this.element).data('navigation'),
      navigationText: [
        "<span class='fa fa-chevron-left'></span>",
        "<span class='fa fa-chevron-right'></span>"
      ],
    });
  }
}
