<div class="main-content">
  <div class="container section-container">
    <section>
      <h2 class="section-header">What's New</h2>

      <div class="particle-container">
        <div>
          <h5 class="particle-header">
            Product Deletion - Multiple Products - SCA Pharmaceuticals
          </h5>

          <p>
            On April 12, 2024, SCA
            Pharmaceuticals (<a
              class="link"
              href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5S-3qgyTW8wLKSR6lZ3lwN5f8YHtS46cWW87l85z9010nbW3ClK214SlztsW1gJFH-6SrbMqW3fBgdg1QlgvHW15k3RD1qYLBRW3N_V4Y37dNYRW6NFgtX7t81W7W8hlM538l7lrrW58cT_6263PC0W6xMXqp1N32ppW3tygPH1P9fbBN8MN3mYRfk5-W2mQkcs7KQDWhW6xhj4T2QmwhFW6wF-S67yw_-4W1RNDJX7X2v1PW2Pj7v79kQSFBVcxghY8b_40gW5vx7QW5ffLlzW5RyLrx66w8JpW10ZXQt8cmGVVW4ZSDWB4gL3bhW6hWmqH7nkSHQMYPSpSkY2xCW6ykP207sTtFVW1HJzPb2dC1zzW78BVwV4Z8FtVf4gDZH404"
              target="_blank"
              >#51953</a
            >) will be discontinuing multiple contracted products from the
            HealthTrust catalog. Please see the
            <a
              class="link"
              href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5S-3qgyTW8wLKSR6lZ3mtW7NWM_s3DjV0hW2m5-w73fWMYyW4HBBk248tvDkW87-sCc5j_fBLW1lvJTY2W_zJkW5Bm5cj4-pZCTW1rgw954H7dj6W4GpJV07-HcdPW3KYpnK2WJZpLW2jdvWY6858f_W3PSmJm6RkcPPW18W35L5-24sqW6_f_-D4-2VsRW5V2g2P6k9PT8VrxwwP4NbWf7W4J4Fdj4zHGXkV-05w9331zqRW15j-lG1ZpF0RW69RCkr3dZ8-WW2dC1wr2PM33NW3cSjpk2pP4nlW74JnWG1jmDc6W5jk3zx4k420xW9lhsxM7W-V__N2DMPF5-xFQdW8Mw1xm2bxD6wW8t52jc2bjQ_wW2055KF4H4xqtf4Xg3xd04"
              target="_blank"
              >Contract Change Report</a
            >
            for a complete list of impacted NDCs.
          </p>
        </div>
      </div>
    </section>

    <section>
      <h2 class="section-header">Alerts and Other Updates</h2>

      <div class="particle-container">
        <div>
          <h5 class="particle-header">
            Manufacturing Discontinuation - Humulin® R (insulin human) 3 mL
            vials - Eli Lilly
          </h5>

          <p>
            Eli Lilly (<a
              class="link"
              href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5S-3qgyTW8wLKSR6lZ3lvW2ct7_-7DBS65W4qvHbw8CvDH1W51qP-12zjDpWW324qMm5NMhVXW2-PVyp8r7RtlW4fm92N2pqdJvW7cvZkF7krmbKW3nXJs28HJ5M-W7Xqcz22dzmTVKP8ZPFG_0W6TTHjn9fDZWvW99pzc86ZH2GpVKnrHm3hYRDlW7fXp_r5B7pS_W7cjZR04HdzmqW4KPHHh5gphpgW4_SMGN8l-WXhW7Qw4dy3_n-pHVHV2Ss948s98W72vRTv80J7W9W16hT9P8LT0ykN71cjlWgF7y_W8zjGll7cQdsQW54QPMy4VhwbcW4wjwnQ6jBXvhW6w7H6c5bQtPhW1C_1Vh5XBc_WW5d93sh5qmrf6f18J_9g04"
              target="_blank"
              >#2423</a
            >) 10 mL & 3 mL vials of Humulin® R (insulin human) updates:
          </p>

          <ul>
            <li>
              10 mL vials of Humulin® R (insulin human) - Last week, Eli Lilly
              had 1-2 weeks of 10 mL vials of Humulin® R (insulin human) in
              their North American warehouse. Product was shipped to replenish
              various distribution centers. Eli Lilly expects to continue to
              increase supply of 10 mL vials.
            </li>
            <li>
              3 mL vials of Humulin® R (insulin human) - Effective March 15,
              2024, Eli Lilly instructed their distribution partners to fill
              orders until inventory is depleted (see
              <a
                class="link"
                href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5Tg3qgyTW95jsWP6lZ3p5W2f8pCg4xfLQnW7tGzGF3lD2T3W73WBKZ1hxKKfW929f2V2J5FV6MWk91lld0nHW8fRW8K8CwwqXW59RZ_l6mwx-xW5NVSLf367QCDW4-WgZ39h7g4tW8f5gDK5MQxvHN5D8KSGP0nP6W7k9pBR9gyKRTVCwBMb71nnV7W3Y2pbw57w-ylW4XVmsy3znbnhW3WtDX42VxhFhW6-kpfS4tjWlGW4YP2jc6ZKkwGW1k3_LH2Kccp_W4HFQX87vhPRRW7P-pFH2FtdMSW8lw4vY4gZt8LW4Zs6XX4d72vsW1kXmX84Z7j1DW34BcZZ76NwfxVk48Tg7-NDBNW7JNBZC1Ws8tFW42YJ8m2FdxF_W4JnD6R1G-HJxW76Xdgq4xs5smf3BK7kg04"
                target="_blank"
                >the attached document</a
              >).
            </li>
          </ul>

          <p>
            HealthTrust is evaluating alternative options and engaging our
            Pharmacy Advisory Board to determine the best options as we navigate
            this announcement. We have also requested that Eli Lilly offer
            HealthTrust members a pricing bridge strategy to lessen the impact
            of 10 mL vials of Humulin® R (insulin human) that many members will
            likely pivot to as a result of the discontinuation of 3 mL vials of
            Humulin® R (insulin human). Eli Lilly is not experiencing a shortage
            or any backorders on their insulin pens.
          </p>
        </div>

        <div>
          <h5 class="particle-header">
            Inflation/Deflation Report (Spring 2024)
          </h5>

          <p>
            The Spring 2024
            <a
              class="link"
              href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5Tg3qgyTW95jsWP6lZ3nBN3KZyT_qcy5wVwnGW891M9RwW8PL7kl2rC5TpW8w3GHR4y5sVRW1Gc9cX1f3xVqW3ZSKSq8BLPnKSdHb1btSfNW8KZRJt72dRv5W5fF7wF84bG4LW4rHSRQ1-hbrzW6tFDgg3D__GSW1mJrl_8yPG2zW1wrZbv1YVXfyN8kSM2gM22JjW19fs2B1wbRyzW7fD7nw2C2rWCW5ChnzS9lS1_tW47yCvc1mfHN1W7l3xVP90fd9CN3S2drqGhHzNW5yW9sX4SY6mkW7s_jk91mWmHhW79XRng4QCwrTW4z2xTr92BNMBW7hK4MG4z1sxQW69N0zs5YDng4V44dvR5zmHvYW2y30YS2PdXY5W31ZFYX3YFj7-W2vXT7t40FB7cd-WB5q04"
              target="_blank"
              >Inflation/Deflation Report</a
            >
            was recently published. To help health systems reduce uncertainty in
            budgeting, HealthTrust provides the Pharmaceutical
            Inflation/Deflation report bi-annually. The next Inflation/Deflation
            report will be issued in August.
          </p>
        </div>

        <div>
          <h5 class="particle-header">Contract Package Updates</h5>

          <p>
            This section highlights updates to HealthTrust Pharmacy contract
            packages. Recently added material, such as program summaries and
            brochures, will be listed with each publication of the Pharmacy
            Response.
          </p>

          <table class="table table-bordered color-inherit">
            <thead>
              <tr>
                <th>Contract Number</th>
                <th>Supplier</th>
                <th>Change Type</th>
                <th>Document Name</th>
                <th>Date Posted</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>---</td>
                <td>---</td>
                <td>Update</td>
                <td>Rx Program Summary - SIMS Autosubstitution Program</td>
                <td>3/20/2024</td>
              </tr>
              <tr>
                <td>
                  <a
                    class="link"
                    href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5S-3qgyTW8wLKSR6lZ3n3W1FGQQr6vqkxrW5drj8M4Vx_3BW7MD_cK1hwNH8VHFwlM6Jx9CJV5fBg37hZG1sW80-qF98BBHDGW93Vgn53jrXJkW1Q6L-B8QvMMZW7Yx9BL5bGWYGW6kP5vZ3b6f10W38d35W7Z6KKWW4QsgMl1CvLjnV49X099l3gctW1X1-SV7hbbB3N1NzrhWn7x-xW52kl1w1znlzyW7Z1-bj1-lhmFN2wHWgJPwMsjW9dRl648z32NqW4fkb1R1csZzCW1-3SDp5pC_g7W5Y35Vc4D7MFRW4gXzj81RWPmLN3ynPMdk5jvdW6CHFfZ47ldpPW1Rj6p61T9qLtW2FRQPP7d2C3gW6m6C-d3Wc2b7f3WXtXT04"
                    target="_blank"
                    >2338</a
                  >
                </td>
                <td>Grifols</td>
                <td>Update</td>
                <td>Rx Program Summary - Rabies Immune Globulin, HyperRAB</td>
                <td>3/20/2024</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h5 class="particle-header">
            New Drug Information Updates and Requests
          </h5>

          <p>
            The HealthTrust Drug Information department seeks to provide value
            by creating drug information documents for use by all members. All
            documents are available in the
            <a
              class="link"
              href="https://go.healthtrustpg.com/e3t/Ctc/2G+113/c2X-304/MWKxtqDNCfFW5L8l8K3zZg4GW5NMkyh5bXQ1MN5GS5S-3qgyTW8wLKSR6lZ3mqW6qnkBx7k4LZ4W2G30xv2Jct-fW1WKDc11jlcyDVcSw-b6-ZYNqW5C4SY_4c_6WCW1-FtH95GWYNCW83jtZx13s7h1W78fkXN5tPNmvW30Qhnk641qM5W94ZS8m1zdvw9W7SX88k96ZYlKW7bFF3M6dyz5vW3pYZ-01l8knwN52X_FF6d1gqW4QZDKg6PFgJmW33l0q96Vt6c1W5YrN1C60NXZsW7Jh8D17mHG1ZV6npL07qkJqLW4vH5f51QpH6-W9ldwQX2lfnJvW1P9Ssf98GQRfW6y0zJQ4mnZK-W42zsWw7g3KX1W3gHfMj7fdJvgW2MP2xd5GxBTjW5vz5Xh5tj_r-W46tvtn6DS8zbd25KjW04"
              target="_blank"
              >Drug Information Section</a
            >
            of the Member Portal and are organized by drug name or drug class.
          </p>

          <p>
            As a member of HealthTrust, you are able to submit any drug
            information request to the clinical pharmacy team. All types of
            requests are welcome, from monographs and class reviews to more
            specific pharmacology and pharmacotherapy-related requests. The form
            to submit requests is linked at the bottom of this page. The
            clinical pharmacy team will review your request and get back to you
            regarding an estimated turn-around time. Any further questions can
            be directed to
            <a
              class="link"
              href="mailto:hpgsvc@healthtrustpg.com"
              target="_blank"
              >hpgsvc@healthtrustpg.com</a
            >.
          </p>
        </div>
      </div>
    </section>
  </div>

  <section class="featured-contracts">
    <div class="container mt2">
      <div class="row pt1">
        <div class="col-md-12 w-100 mxw-100">
          <div class="top-line" *ngIf="featured?.specialty?.id">
            <h5 class="header">
              Featured {{featured.specialty.name}} contracts
            </h5>
            <a [routerLink]="['/specialty', featured.specialty.id]">
              View All {{featured?.specialty?.name}} Contracts
            </a>
          </div>
          <items-slider
            [items]="slider_items"
            [index]="slider_index"
            itemWidthPercent="20"
          ></items-slider>
        </div>

        <div
          class="exclusive-contracts-container col-md-12"
          *ngIf="session?.user?.theme_id==2"
        >
          <div class="top-line">
            <h5 class="header">Exclusive AMP Contracts</h5>
          </div>

          <ul class="exclusive-contracts">
            <li class="item" routerLink="/contract/2">
              <div class="img-holder">
                <img
                  src="//ampurchasing.s3.amazonaws.com/img/amp-medpro.png"
                  alt=""
                />
              </div>
              <div class="item-info">
                <h3 class="subheader">Access MedPro Disposal contract</h3>
              </div>
            </li>

            <li class="item" routerLink="/contract/3">
              <div class="img-holder">
                <img
                  src="//ampurchasing.s3.amazonaws.com/img/amp-cpmedical.png"
                  alt=""
                />
              </div>
              <div class="item-info">
                <h3 class="subheader">Access CP Medical contract</h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
