import {Component} from '@angular/core';
import {Session} from '../../services/Session';
import {Contracts} from '../../services/Contracts';
import {IFeaturedContractsSet} from '../../interfaces/IFeaturedContractsSet';
import {IFeaturedCategory} from '../../interfaces/IFeaturedCategory';
import {IFeaturedContract} from '../../interfaces/IFeaturedContract';
import {Router, ActivatedRoute} from '@angular/router';
declare var _:any;

@Component({
  selector: 'category',
  templateUrl: './category.html',
})
export class CategoryCmp {
  public specialtyId:number;
  public featured:IFeaturedContractsSet;
  public category:IFeaturedCategory;
  public featuredContracts:[IFeaturedContract];

  constructor(private session:Session, private contracts:Contracts, private routeParams:ActivatedRoute, private router:Router) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      let slugName = params['slugName'];
      this.load(slugName);
    });
  }

  load(slugName:string) {
    this.specialtyId = this.session.user.specialty_id || 0;
    this.contracts.listFeatured(this.specialtyId).subscribe((result)=> {
      this.featured = result;
      this.category = _.find(this.featured.categories, {slug: slugName});
      this.featuredContracts = _.filter(_.uniq(this.featured.contracts, 'contract_number'), {slug: slugName});
      _.each(this.featuredContracts, (contract:IFeaturedContract)=> {
        contract.__subcategories = _(contract.product_category.split(',')).uniq().sort().map((name:string)=> {
          return name.trim();
        }).value().sort();
      });
    }, ()=> {});
  }
}
