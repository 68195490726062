import {Component, ViewChild, ElementRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from "../../services/Config";
import {Session} from "../../services/Session";
import {Router} from '@angular/router';
import {Notify} from "../../services/Notify";
import {User} from "../../services/User";
import {IAnnualDisclosureCodeUsage} from "../../interfaces/IAnnualDisclosureCodeUsage";
import {UrlParser} from "../../services/UrlParser";

declare var $: any;

@Component({
  selector: 'login',
  templateUrl: './login.html',
})
export class LoginCmp {
  public email: string;
  public annualReportCode: string;
  public password: string = '';
  public totp: string = '';
  public loading = false;
  public notify = new Notify();
  public require2step = false;
  public magicLinkSending = false;
  public isAccountSubdomainViewMode = false;
  public isLocationLoginMode = false;
  public locID = 0;
  public tab = 1;
  public newContactName = '';
  public newContactEmail = '';
  public newContactCompany = '';
  public addingNewContact = false;
  public letterWelcome = false;
  public wrongTotpEntered = false;
  @ViewChild('loginNewContactModal') loginNewContactModal: ElementRef;

  constructor(private http: HttpClient, private config: Config, public session: Session, private router: Router, private userSrv: User, private urlParser: UrlParser) {
    this.isAccountSubdomainViewMode = this.config.isAccountSubDomain();
    try {
      let opensrv = false;
      if (this.session.crossPageArgs && this.session.crossPageArgs['openAnnualSurveyModal']) {
        opensrv = true;
      }
      if (!opensrv) {
        if (this.session.preAuthRedirectURL && this.session.preAuthRedirectURL.indexOf('?opensrv=1') > -1) {
          if (!this.session.crossPageArgs) {
            this.session.crossPageArgs = {'openAnnualSurveyModal': true};
          } else {
            this.session.crossPageArgs['openAnnualSurveyModal'] = true;
          }
        }
      }
    } catch (e) {}

    let params = this.urlParser.getParameters();
    if (params['adc']) {
      this.annualReportCode = params['adc'];
      this.loginUsingAnnualReportCode();
    }
    if (params['u']) {
      this.email = params['u'];
      this.tab = 2;
    }
    if (params['loc']) {
      this.locID = parseInt(params['loc']);
      if (this.locID > 0) {
        this.isLocationLoginMode = true;
      }
    }
  }

  resetErrMessages() {
    this.wrongTotpEntered = false;
  }

  login() {
    this.loading = true;
    this.resetErrMessages();
    this.http.post(this.config.apiUrl + '/auth', JSON.stringify({username: this.email, password: this.password, totp_code: this.totp}), {responseType: 'text', observe: 'response'}).subscribe((response) => {
      this.email = '';
      this.password = '';
      this.session.setToken(response.body, () => {
        this.session.user._is_login_auto = false;
      }, null, false);
      this.loading = false;
      this.session.navigateToPrevNonLoginPage(true);
    }, (response) => {
      if (response && response.status === 417) {
        this.loading = false;
        this.require2step = true;
        return;
      }
      if (response && response.status === 424) {
        this.loading = false;
        this.require2step = true;
        this.wrongTotpEntered = true;
        this.notify.error(this.config.txt.wrongTotpCode);
        return;
      }
      this.require2step = false;
      if (response && response.status === 422) {
        this.http.put(this.config.apiUrl + '/user/generate_password', JSON.stringify({email: this.email})).subscribe(() => {
          this.loading = false;
          this.notify.warning(`Email with password has been sent to <strong>${this.email}</strong>.`);
        }, () => {
          this.loading = false;
          this.notify.error(this.config.txt.wrongCredentials);
        });
      } else {
        this.loading = false;
        this.notify.error(this.config.txt.wrongCredentials);
      }
    });
  }

  loginUsingAnnualReportCode() {
    if (!this.annualReportCode) {
      return;
    }
    this.loading = true;
    this.http.get(this.config.apiUrl + '/annual_code/' + this.annualReportCode, {responseType: 'text', observe: 'response'}).subscribe((response) => {
      this.session.annualDisclosureCode = this.annualReportCode;
      this.session.setToken(response.body, () => {
        this.session.user._is_login_auto = false;
      }, null, false);
      this.loading = false;
      this.session.navigateToPrevNonLoginPage(true);
    }, (response) => {
      if (response && response.status === 404) {
        this.loading = false;
        this.notify.error(this.config.txt.notFound);
      } else {
        this.loading = false;
        this.notify.error(this.config.txt.error);
      }
    });
  }

  sendMagicLink() {
    if (!this.email) {
      this.notify.warning(this.config.txt.tmpAuthEmailRequired);
      return false;
    }
    this.magicLinkSending = true;
    this.http.get(this.config.apiUrl + '/auth_tmp/' + this.email, {responseType: 'text'}).subscribe(() => {
      this.magicLinkSending = false;
      this.notify.success(this.config.txt.successfully);
      if (this.isAccountSubdomainViewMode) {
        let usage: IAnnualDisclosureCodeUsage = {magic_link: this.email};
        this.userSrv.writeAnnualDisclosureCodeUsage(usage);
      }
    }, (response) => {
      this.magicLinkSending = false;
      if (response && response.status) {
        switch (response.status) {
          case 404:
            this.notify.error(this.config.txt.notFound);
            break;
          case 429:
            this.notify.error(this.config.txt.tmpAuthTooManyRequests);
            break;
          case 424:
            this.notify.error(this.config.txt.emailSendingError);
            break;
          case 400:
            this.notify.error(this.config.txt.wrongEmail);
            break;
          default:
            this.notify.error(this.config.txt.serverError);
        }
      }
    });
  }

  addNewContact() {
    if (!this.newContactCompany || !this.newContactEmail || !this.newContactName || !this.locID) {
      return;
    }
    this.addingNewContact = true;
    this.userSrv.loginNewContact({location_id: this.locID, name: this.newContactName, email: this.newContactEmail, company: this.newContactCompany}).subscribe(() => {
      this.addingNewContact = false;
      try {
        let n = new Notify();
        n.success(this.config.txt.successfully);
        if (this.loginNewContactModal && this.loginNewContactModal.nativeElement) {
          $(this.loginNewContactModal.nativeElement).modal('hide');
        }
      } catch (e) {

      }
    }, () => {
      this.addingNewContact = false;
      try {
        let n = new Notify();
        n.error(this.config.txt.error);
      } catch (e) {

      }
    });
  }
}
