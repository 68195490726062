<a class="btn btn-success btn-custom btn-primary-action" data-toggle="modal" data-target="#modalDistrPriceQuestion">
  <i class="material-icons" style="font-size: 18px; margin-right: 4px;">local_offer</i>
  <span style="position: relative; top: -0.25rem">Work with my distributor to get pricing for this</span>
</a>

<div class="modal fade" id="modalDistrPriceQuestion" tabindex="-1" role="dialog" aria-hidden="true" #haq>
  <form class="form-validate body-form" (ngSubmit)="send()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Interested in pricing for Contract #{{contractNumber}}</h4>
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
          <h5>{{session.user?.fname}} {{session.user?.lname}}</h5>
          <h6>{{session.user?.email}}</h6>
          <div class="input-group vertical custom-input-group required">
            <section *ngIf="!session.user || !session.user.email" class="form-group">
              <h6 class="group-header">Your email:</h6>
              <input type="text" [(ngModel)]="newEmail" placeholder="email" name="email"/>
            </section>
            <section class="form-group">
              <h6 class="group-header">My distributor for this product is:</h6>
              <select [(ngModel)]="distrID" class="form-control" [ngModelOptions]="{standalone: true}">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
              </select>
            </section>
            <section class="form-group">
              <h6 class="group-header">My distributor rep is:</h6>
              <input type="text" [(ngModel)]="repUserEmail" name="distrRep" placeholder="email"/>
            </section>
            <h6 class="group-header">Question or comment:</h6>
            <textarea class="form-control custom-form-control ta-width-100" [(ngModel)]="question" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="send()" [disabled]="saving">Please connect me</button>
        </div>
      </div>
    </div>
  </form>
</div>
