<div class="card">
  <div class="card-header">Based upon your specialty and class of trade, we recommend reviewing the contracted vendors below. For each contract listed, please select from the drop down menu how your client manager can best assist you with each contract.</div>
  <div class="card-body">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let c of contracts">
        <div class="row">
          <div class="col-sm-3 center-content-flex pointer">
            <select class="form-control" [(ngModel)]="c.__selected_id">
              <option value="undefined"></option>
              <option value="1">{{optsMap[1]}}</option>
              <option value="2">{{optsMap[2]}}</option>
              <option value="3">{{optsMap[3]}}</option>
            </select>
          </div>
          <div class="col-sm-9">
            <a *ngIf="c.contract_number" target="_blank" href="/contract/{{c.contract_number}}">{{c.vendor_name}} ({{c.contract_number}})</a>
            <span *ngIf="!c.contract_number">{{c.vendor_name}}</span>
            <span *ngIf="c.contract_description"><br/>{{c.contract_description}}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-sm-2 offset-sm-10">
        <button class="btn btn-primary btn-block" (click)="send()" [disabled]="submitting">Submit</button>
      </div>
    </div>
  </div>
</div>
