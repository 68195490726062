import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

let matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;

export function escapeStringRegexp(str) {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a string');
  }

  return str.replace(matchOperatorsRe, '\\$&');
};

@Directive({
  selector: 'highlight-text'
})
export class HighlightTextCmp {
  @Input() search:string;
  @Input() text:string;
  @Input() classToApply:string;

  constructor(private el:ElementRef, private renderer:Renderer2) { }

  ngOnChanges() {
    if (typeof this.classToApply === 'undefined') {
      this.classToApply = 'highlight';
    }

    if (typeof this.search === 'undefined') {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.text);
      return;
    }

    let search = escapeStringRegexp(this.search.toString());
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.replace(this.text, search));
  }

  replace(txt:string, search:string) {
    if (!txt) {
      return '';
    }
    let searchRgx = new RegExp('(' + search + ')', 'gi');
    return txt.replace(searchRgx, `<span class="${this.classToApply}">$1</span>`);
  }
}
