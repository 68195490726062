<section>
  <div class="container">
    <div class="bordered-container thin-top">
      <div class="row jc-center">
        <div class="col-md-8 col-lg-6" [attr.data-hidden]="messageSent">
          <h1 class="headline">Capital Equipment</h1>
          <p>Our negotiated market-leading pricing on clinical equipment contracts cover everything from central supply and imaging to patient equipment and monitoring. Let us know what capital equipment you are planning on buying, and a Client Manager will be in touch.</p>
          <h4>Please provide the following information</h4>

          <div class="bordered-content clearfix">
            <form (ngSubmit)="submit()"  class="wufoo topLabel page" autocomplete="off" novalidate>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label for="Field1">First Name:</label>
                  <input type="text" id="Field1" class="input-text" [(ngModel)]="formData.Field1" title="First Name" placeholder="First Name" maxlength="100" tabindex="1" name="fname"/>
                </div>

                <div class="col-sm-6 form-group">
                  <label for="Field2">Last Name:</label>
                  <input type="text" id="Field2" class="input-text" [(ngModel)]="formData.Field2" title="Last Name" placeholder="Last Name" maxlength="100" tabindex="2" name="lname"/>
                </div>
              </div>

              <div class="form-group">
                <label for="Field3">Email:</label>
                <input type="email" id="Field3" class="input-text" [(ngModel)]="formData.Field3" title="Email" placeholder="Email" maxlength="255" tabindex="3" name="email"/>
              </div>

              <div class="form-group">
                <label for="Field4">I am in the market for ...</label>
								<textarea id="Field4"
                          [(ngModel)]="formData.Field4"
                          class="form-control custom-form-control"
                          spellcheck="true"
                          rows="5" cols="50"
                          tabindex="4"
                          name="market"></textarea>
              </div>

              <div class="submit-holder">
                <button type="submit" class="btn btn-primary-action btn-primary" id="saveForm" value="Submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-8 col-md-6 text-center" [attr.data-hidden]="!messageSent">
          <h2>Thank you!</h2>
          <h3>Your information has been received. A Client Manager will get back with you shortly.</h3>
        </div>
      </div>
    </div>

  </div>
</section>
