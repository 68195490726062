import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'items-slider',
  templateUrl: './items-slider.html',
  styleUrls: ['./items-slider.css'],
})
export class ItemsSliderCmp {
  @Input() items;
  @Input() index;
  @Input() itemWidthPercent = 20;
  @Output() indexChange = new EventEmitter();
  @ViewChild('area') area:ElementRef;
  public count = 1;
  public max = 1;
  public itemWidth = 200;

  next() {
    this.index++;
    if (this.index > this.max) {
      this.index = this.max;
    }
    this.indexChange.emit(this.index);
  }

  prev() {
    this.index--;
    if (this.index < 0) {
      this.index = 0;
    }
    this.indexChange.emit(this.index);
  }

  ngOnChanges() {
    this.recalculate();
  }

  recalculate() {
    if (this.itemWidthPercent < 1) {
      this.itemWidthPercent = 15;
    }
    this.count = Math.floor(100 / this.itemWidthPercent);
    this.max = this.items.length - this.count;
    if (this.area) {
      this.itemWidth = ((this.area.nativeElement.offsetWidth)) * (this.itemWidthPercent / 100);
    }
  }
}
