import {Component, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Session} from "../../services/Session";

@Component({
  selector: 'savings-report-delivery',
  templateUrl: './savings_report_delivery.html'
})
export class SavingsReportDeliveryCmp {
  @Input() review_session_id;

  constructor(private router:Router, private routeParams:ActivatedRoute, public session:Session) {
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (!this.review_session_id) {
      this.review_session_id = parseInt(params['review_session_id']);
      if (!this.review_session_id) {
        this.router.navigate(['']);
        return;
      }
    }
  }
}
