import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Config} from "../../services/Config";
import {Testimonials} from "../../services/Testimonials";
declare var $:any;

@Component({
  selector: 'news-ticker',
  templateUrl: './news-ticker.html',
  styleUrls: ['./news-ticker.css'],
})
export class NewsTickerCmp {
  @Input() theme_id:number = 0;
  @ViewChild('slide') slide:ElementRef;
  @ViewChild('source') source:ElementRef;
  @ViewChild('target') target:ElementRef;
  public news:string[] = [];
  public bgColor:string = '';

  constructor(private config:Config, private srv:Testimonials) {}

  initPlugin() {
    setTimeout(()=> {
      if (this.slide && this.slide.nativeElement) {
        try {
          this.target.nativeElement.innerHTML = this.source.nativeElement.innerHTML;
        } catch (e) {}
        $(this.slide.nativeElement).newsTicker();
      }
    }, 1);
  }

  ngOnChanges() {
    this.news = [];
    let code = this.config.getTestimonialsCode(this.theme_id);
    if (code) {
      this.srv.getTestimonials(code).subscribe((s)=> {
        if (s && s.testimonials) {
          this.news = [];
          let items = s.testimonials.split("\n");
          items.forEach((item)=> {
            let str = item.trim();
            if (str && str.length > 0) {
              this.news.push(str);
            }
          });
          if (this.news.length > 0) {
            this.bgColor = this.config.getTestimonialsBgColor(this.theme_id);
            this.initPlugin();
          }
        }
      }, ()=> {});
    }
  }
}
