import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {FDTickets} from "../../services/FDTickets";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
declare var $:any;

@Component({
  selector: 'help-access-pricing',
  templateUrl: './modal.html',
})
export class HelpAccessPricingCmp {
  @Input() contractNumber;
  @Input() vendorName;
  @ViewChild('haq') modal:ElementRef;
  public question;
  public saving:boolean = false;
  public pc_id:string;

  constructor(public session:Session, private tickets:FDTickets, private locations:Locations) {}

  ngAfterViewInit() {
    this.session.loadUser(() => {
      if (this.session.user && this.session.user.location_id) {
        this.locations.getLocation(this.session.user.location_id).then((loc:ILocation) => {
          if (loc && loc.pc_id) {
            this.pc_id = loc.pc_id;
          }
        }, () => {});
      }
    }, () => {});
  }

  send() {
    let customFields = {};
    if (this.session && this.session.user && this.session.user.__theme && this.session.user.__theme.name) {
      customFields['brand'] = this.session.user.__theme.name;
    }
    if (this.pc_id) {
      customFields['pc_id'] = this.pc_id;
    }
    this.saving = true;
    let email = this.session.user.email;
    if (!email || email.indexOf('@') < 1) {
      email = 'support@purchaseclinic.com';
    }
    this.tickets.create(email, `Interested in pricing for Contract ${this.vendorName} (#${this.contractNumber})`,
      this.question, customFields).subscribe(() => {
      this.saving = false;
      if (this.modal && this.modal.nativeElement) {
        $(this.modal.nativeElement).modal('hide');
      }
    }, (error) => {
      this.saving = false;
      console.error(error);
    });
  }
}
