import {ElementRef, Injectable} from '@angular/core';

declare var $: any;

@Injectable()
export class Helpers {
  static is_empty(v) {
    if (typeof v === 'undefined') {
      return true;
    }
    if (v === null && typeof v === 'object') {
      return true;
    }
    return false;
  }

  public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  exportCsv(tbl: ElementRef, filename?: string, cbAfter?, cbCellTextHandler?) {
    if (!tbl || !tbl.nativeElement) {
      return;
    }
    setTimeout(() => {
      let csv = '';
      let delimitter = ',';
      for (let i = 0, row; row = tbl.nativeElement.rows[i]; i++) {
        let line = '';
        for (let j = 0, col; col = row.cells[j]; j++) {
          let cellText = col.innerText.replace('drag_handle', '').replace('filter_list', '');
          if (cbCellTextHandler) {
            cellText = cbCellTextHandler(col.innerText, col);
          }
          line = line + '"' + cellText + '"' + delimitter;
          if (col.colSpan > 0) {
            for (let c = 1; c < col.colSpan; c++) {
              line = line + delimitter;
            }
          }
        }
        csv = csv + line.replace(/,(\s+)?$/, '').replace(/;(\s+)?$/, '') + "\n";
      }
      if (!filename || filename.length < 1) {
        filename = 'report.csv';
      }
      let a = this.getCsvExportLink(csv, filename);
      a.click();
      if (cbAfter) {
        setTimeout(() => {
          cbAfter();
        }, 2);
      }
    }, 1);
  }

  getCsvExportLink(csv, fileName): HTMLElement {
    // https://stackoverflow.com/a/23922475/680786
    let pom = document.createElement('a');
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    return pom;
  }

  isValidEmail(email: string): boolean {
    if (!email || email.length < 6) {
      return false;
    }
    let ati = email.indexOf('@');
    if (ati < 1) {
      return false;
    }
    let doti = email.lastIndexOf('.');
    if (doti < 2 || doti < ati || doti == (email.length - 1)) {
      return false;
    }
    return true;
  }

  csvToArray(csv: string, settings?): string[][] {
    // https://code.google.com/archive/p/csv-to-array/
    let a = [['']];
    try {
      let o = settings;
      var od = {
        'fSep': ',',
        'rSep': '\n',
        'quot': '"',
        'head': false,
        'trim': true
      }
      if (o) {
        for (var i in od) {
          if (!o[i]) {
            o[i] = od[i];
          }
        }
      } else {
        o = od;
      }
      var r = 0;
      var f = 0;
      var p = 0;
      var q = 0;
      for (; p < csv.length; p++) {
        var c;
        switch (c = csv.charAt(p)) {
          case o.quot:
            if (q && csv.charAt(p + 1) == o.quot) {
              a[r][f] += o.quot;
              ++p;
            } else {
              q ^= 1;
            }
            break;
          case o.fSep:
            if (!q) {
              if (o.trim) {
                a[r][f] = a[r][f].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
              }
              a[r][++f] = '';
            } else {
              a[r][f] += c;
            }
            break;
          case o.rSep.charAt(0):
            if (!q && (!o.rSep.charAt(1) || (o.rSep.charAt(1) && o.rSep.charAt(1) == csv.charAt(p + 1)))) {
              if (o.trim) {
                a[r][f] = a[r][f].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
              }
              a[++r] = [''];
              a[r][f = 0] = '';
              if (o.rSep.charAt(1)) {
                ++p;
              }
            } else {
              a[r][f] += c;
            }
            break;
          default:
            a[r][f] += c;
        }
      }
      if (o.head) {
        a.shift()
      }
      if (a[a.length - 1].length < a[0].length) {
        a.pop()
      }
      return a;
    } catch (e) {
      console.error(e);
      return a;
    }
  }

  copyObject(obj) {
    return $.extend(true, {}, obj);
  }

  clone(obj) {
    return this.copyObject(obj);
  }

  canvasToBlob(canvas) {
    let url = canvas.toDataURL();
    let p = url.indexOf(';base64,');
    return this.base64toBlob(url.substr(p + 8));
  }

  base64toBlob(base64Data: string, contentType?: string) {
    //https://stackoverflow.com/a/43209768/680786
    contentType = contentType || 'image/png';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  getCurrentDateForUI(dt?): string {
    let d = this.getDateFromValueSafe(dt);
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
  }

  getCurrentDatetimeForUI(dt?): string {
    let d = this.getDateFromValueSafe(dt);
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[d.getMonth()] + " " + d.getDate() + "th, " + d.getFullYear() + " at " + d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
  }

  isUndefined(x) {
    return (typeof x === 'undefined');
  }

  isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  getDateFromValueSafe(dt): Date {
    let d;
    if (!dt) {d = new Date();} else {
      d = new Date(dt);
      if (!this.isValidDate(d)) {
        d = new Date();
      }
    }
    return d;
  }

  currentTimeFormattedStringTicker(cb) {
    if (cb) {
      let d = new Date();
      var formattedDate = this.months[d.getMonth()] + " " + d.getDate() + "th, " + d.getFullYear() + " at " + d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
      cb(formattedDate);
      setTimeout(() => {this.currentTimeFormattedStringTicker(cb);}, 1000);
    }
  }
}
