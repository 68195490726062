import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';

@Component({
  selector: 'form-field-split',
  template: `<div class="form-group row" [ngClass]="{'required':required}" style="display: flex">
        <label *ngIf="label" class="form-control-label {{leftClass}}" style="padding-left: 12px; padding-top: 6px">
          <span class="pull-md-right">{{label}}</span>
        </label>
        <div class="{{rightClass}}">
        <input [type]="type||'text'" [ngModel]="model" #field (keyup)="updateModel(field.value)"
         [attr.required]="required" [attr.name]="name"
        class="form-control" placeholder="{{placeholder||label}}">
        </div>
      </div>`
})
export class FieldSplitCmp {
  @Input() label = '';
  @Input() placeholder:any = false;
  @Input() model;
  @Input() required;
  @Input() name;
  @Output('modelChange') update = new EventEmitter();
  @Input() lw:string;
  @Input() type:string;
  public leftClass:string = '';
  public rightClass:string = '';

  ngOnChanges() {
    this.leftClass = '';
    this.rightClass = '';
    if (this.lw) {
      let leftWidth = parseInt(this.lw);
      if (leftWidth > 0 && leftWidth < 13) {
        this.leftClass = 'col-xs-12 col-sm-' + leftWidth.toString();
        this.rightClass = 'col-xs-12 col-sm-' + (12 - leftWidth).toString();
      }
    }
  }

  updateModel(v) {
    this.update.emit(v);
  }
}
