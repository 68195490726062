import {Component} from '@angular/core';
import {Session} from '../../services/Session';
import {Contracts} from '../../services/Contracts';
import {IFeaturedContractsSet} from '../../interfaces/IFeaturedContractsSet';

@Component({
  selector: 'home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomeCmp {
  public featured:IFeaturedContractsSet;
  public specialtyId:number;
  public slider_items = [];
  public slider_index = 0;
  public isMobileMenuVisible = false;

  constructor(public session:Session, private contracts:Contracts) {
    this.session.redirectIfNotAuth();
    this.loadContractsCategories();
  }

  loadContractsCategories() {
    this.specialtyId = this.session.user.specialty_id || 0;
    this.contracts.listFeatured(this.specialtyId).subscribe((result)=> {
      this.slider_items = [];
      this.slider_index = 0;
      this.featured = result;
      if (this.featured.items && this.featured.items.length) {
        this.featured.items.forEach((item)=> {
          this.slider_items.push({
            route: ['/contract', item.contract_number],
            image_url: item.image_url,
            header: item.vendor_name,
            header_counter: item.contract_number,
            subheader: item.contract_description,
            link_text: 'Learn More'
          });
        });
      }
    }, ()=> {});
  }

  ngAfterContentChecked() {
    if (this.specialtyId !== this.session.user.specialty_id) {
      this.loadContractsCategories();
    }
  }
}
