import {Component} from '@angular/core';
import {ISignupAnswer, ISignupInfoEdit} from "../../interfaces/ISignupWithPA";
import {IDistributor} from "../../interfaces/IDistributor";
import {User} from "../../services/User";
import {Locations} from "../../services/Locations";
import {Distributors} from "../../services/Distributors";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {Router, ActivatedRoute} from '@angular/router';
import {Helpers} from "../../services/Helpers";
import {ITheme} from "../../interfaces/ITheme";
import {IUser} from "../../interfaces/IUser";
import {ILocation} from "../../interfaces/ILocation";

@Component({
  selector: 'signup-optimization-page',
  templateUrl: 'signup_optimization.html'
})
export class SignupOptimizationPageCmp {
  public step = 0;
  public lastStep = 2;
  public lastReachedStep = 0;
  public user: IUser;
  public location: ILocation;
  public distributorsMap: { [id: number]: IDistributor } = {};
  public distributorsList: IDistributor[] = [];
  public saving = false;
  public loading = false;
  public isError = false;
  public errMsg = '';
  public notify;
  public repName = '';
  public distr_id = 0;
  public secondary_distr_id = 0;
  public displayThanks: boolean = false;
  public detailsEdit = false;
  public themeName = 'Purchase Clinic';
  public themeEmail = 'support@purchaseclinic.com';
  public themePhone = '877.659.2159';
  private defaultThemeName = 'Purchase Clinic';
  private defaultThemeEmail = 'support@purchaseclinic.com';
  private defaultThemePhone = '877.659.2159';
  private answerPerPage: { [id: number]: ISignupAnswer } = {
    0: {question: "Please select your practice’s primary medical supply:", answer: null, is_skipped: false, is_displayed: false},
    1: {question: "Does your practice also use any of the distributors below?", answer: null, is_skipped: false, is_displayed: false},
    2: {question: "Please provide your primary distributor rep name below:", answer: null, is_skipped: false, is_displayed: false},
  };
  private infoEdits: ISignupInfoEdit[] = [];
  private infoBeforeEdit: string;

  constructor(private users: User, private locations: Locations, private distributors: Distributors, private router: Router,
    public session: Session, private config: Config, private h: Helpers, public routeParams: ActivatedRoute) {
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
    this.notify = new Notify();
    this.lastReachedStep = 0;
    this.setTheme(this.config.getThemeIDForHost());
    this.loading = true;
    this.session.addOnLoadHandler(() => {
      this.init();
    });
    this.session.redirectIfNotAuth();
  }

  setTheme(tID) {
    if (this.config.themesMap[tID]) {
      let theme: ITheme = this.config.themesMap[tID];
      if (theme.name) {
        this.defaultThemeName = theme.name;
      }
      this.themeName = this.defaultThemeName;

      if (theme.email_from_address || theme.contact_email) {
        this.defaultThemeEmail = theme.email_from_address || theme.contact_email;
      }
      this.themeEmail = this.defaultThemeEmail;

      if (theme.phone) {
        this.defaultThemePhone = theme.phone || this.defaultThemePhone;
      }
      this.themePhone = this.defaultThemePhone;
    }
  }

  toggleDetailsEdit() {
    this.detailsEdit = !this.detailsEdit;
    if (!this.detailsEdit) {
      this.infoEdits.push({before_edit: this.infoBeforeEdit, after_edit: this.getDetailsSnapshot()});
    } else {
      this.infoBeforeEdit = this.getDetailsSnapshot();
    }
  }

  getDetailsSnapshot() {
    try {
      let u = this.user;
      let loc = this.location;
      return JSON.stringify({
        fname: u.fname, lname: u.lname, email: u.email, phone: u.phone, job_title: u.job_title,
        facility_name: loc.facility_name, city: loc.city, address: loc.address, address2: loc.address2, zip: loc.zip, state: loc.state
      });
    } catch (e) {
      return JSON.stringify({"user": this.user, "location": this.location});
    }
  }

  init() {
    if (!this.session || !this.session.user) {
      this.router.navigate(['']);
      return;
    }
    this.user = this.session.user;
    if (this.session.user.__location) {
      this.location = this.session.user.__location;
    }
    setTimeout(() => {this.loading = false;}, 1);
  }

  nextStep(skip?: boolean) {
    if (skip) {
      this.markPageSkipped(this.step);
    }
    this.setAnswerByData(this.step);
    if ((this.step - 0) === this.lastStep) {
      this.lastStepRedirect();
    } else {
      let next = (this.step - 0) + 1;
      if (next === 0 && (!this.distributorsList || !this.distributorsList.length)) {
        next = 2;
      }
      if (next === 1 && (!this.distributorsList || !this.distributorsList.length)) {
        next = 2;
      }
      this.step = next;
      this.markPageDisplayed(this.step);
      if (this.lastReachedStep < this.step) {
        this.lastReachedStep = this.step;
      }
    }
  }

  setAnswerByData(page) {
    switch (page) {
      case 0:
        if (this.distr_id) {
          let id = this.distr_id - 0;
          if (this.distributorsMap[id]) {
            this.setPageAnswer(page, this.distributorsMap[id].name);
          }
        }
        break;
      case 1:
        if (this.secondary_distr_id) {
          let id = this.secondary_distr_id - 0;
          if (this.distributorsMap[id]) {
            this.setPageAnswer(page, this.distributorsMap[id].name);
          }
        }
        break;
      case 2:
        if (this.repName) {
          this.setPageAnswer(page, this.repName);
        }
        break;
      default:
        return;
    }
  }

  sendCurrentAnswers(onlyDisplayed: boolean) {
    try {
      if (this.session?.user?.id) {
        let answers = [];
        for (let i in this.answerPerPage) {
          if (!onlyDisplayed || this.answerPerPage[i].is_displayed) {
            answers.push(this.answerPerPage[i]);
          }
        }
        this.locations.recordSignupAnswers(answers).subscribe(() => {}, () => {});
      }
    } catch (e) {}
  }

  markPageDisplayed(page: number) {
    let p = (page - 0);
    try {
      if (this.answerPerPage[p]) {
        this.answerPerPage[p].is_displayed = true;
      }
    } catch (e) {
    }
  }

  markPageSkipped(page: number) {
    let p = (page - 0);
    try {
      if (this.answerPerPage[p]) {
        this.answerPerPage[p].is_skipped = true;
      }
    } catch (e) {
    }
  }

  setPageAnswer(page: number, answer) {
    let p = (page - 0);
    try {
      if (this.answerPerPage[p]) {
        this.answerPerPage[p].answer = answer;
        this.answerPerPage[p].is_skipped = false;
        this.answerPerPage[p].is_displayed = true;
        this.answerPerPage[p].location_id = this.location.id;
        this.sendCurrentAnswers(true);
      }
    } catch (e) {
    }
  }

  isNextStepDisabled() {
    if ((this.step - 0) === this.lastStep) {
      return true;
    }
    switch (this.step) {
      case 0: // prim distr
        return false;
      case 1: // second distr
        return false;
      case 2: // rep
        return false;
      default:
        return false;
    }
    return false;
  }

  prevStep() {
    let prev = this.step - 1;
    if (prev < 0) {
      return;
    }
    this.step = prev;
  }

  lastStepRedirect() {
    let token = this.session.getToken();
    if (!token) {
      this.redirects();
    } else {
      this.sendAdditionalQuestionsAndRedirect(token);
    }
  }

  sendAdditionalQuestionsAndRedirect(token) {
    this.sendCurrentAnswers(false);
    try {
      if (this.session?.user?.id) {
        if (this.infoEdits?.length) {
          this.locations.recordSignupInfoEdit(this.infoEdits).subscribe(() => {}, () => {});
        }
      }
    } catch (e) {}
    if (this.location?.id && token && (this.distr_id || this.secondary_distr_id)) {
      this.locations.sendMiniSurvey({
        distributor_id: this.distr_id,
        secondary_distributor_id: this.secondary_distr_id,
        rep_name: this.repName,
        loc_id: this.location.id
      }, token).subscribe(() => {
        this.redirects(token);
      }, () => {
        this.redirects(token);
      });
    } else {
      this.redirects(token);
    }
  }

  redirects(token?: string) {
    let theme_id = this.config.getThemeIDForHost();
    if (token) {
      this.displayThanks = true;
    } else {
      this.router.navigate(['/login']);
    }
  }

  getProgressPercent() {
    if (this.lastReachedStep < 1 || this.lastStep < 0) {
      return 0;
    }
    return Math.round((this.lastReachedStep / (this.lastStep + 1)) * 100);
  }
}
