<div class="container pc2-row-pad">
  <div class="question-holder" *ngIf="user && user.id">
    <div class="answer-holder" *ngIf="!specialtyIsPreloaded">
      <label class="col-form-label">What is your specialty?</label>
      <div class="custom-input-group select-group">
        <select class="custom-form-control" [(ngModel)]="user.specialty_id" [ngModelOptions]="{standalone: true}">
          <option value="0" disabled>Please select</option>
          <option *ngFor="let spec of specialtiesList" value="{{spec.id}}">{{spec.name}}</option>
        </select>
      </div>
    </div>

    <div class="answer-holder" *ngIf="!cotIsPreloaded">
      <label class="col-form-label">What is your Class of Trade?</label>
      <div class="custom-input-group select-group">
        <select class="custom-form-control" [(ngModel)]="user.class_of_trade_id" [ngModelOptions]="{standalone: true}">
          <option value="0" disabled>Please select</option>
          <option *ngFor="let cot of cotsList" value="{{cot.id}}">{{cot.name}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="question-holder">
    <div class="answer-holder">
      <label class="col-form-label">Do any of your locations use vaccines?</label>
      <div class="custom-input-group radio-group">
        <label>
          <input type="radio" name="vac" value="2" [(ngModel)]="vacAnswer" class="radio"/><span>Yes</span>
        </label>
        <label>
          <input type="radio" name="vac" value="0" [(ngModel)]="vacAnswer" class="radio"/><span>No</span>
        </label>
        <label>
          <input type="radio" name="vac" value="1" [(ngModel)]="vacAnswer" class="radio"/><span>I don't know</span>
        </label>
      </div>
    </div>
  </div>

  <div class="form-action">
    <button (click)="continue()" class="btn btn-primary" type="button">Continue</button>
  </div>
</div>
