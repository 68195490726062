<section class="main-content msar-layout">

  <div class="container">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-8 mxw-100">
        <div class="bordered-container">
          <h1>Savings Opportunity and Market Report</h1>
          <div class="bordered-content clearfix">
            <form class="body-form" (ngSubmit)="submit()">
              <h2 class="subtitle">Your Report Is Ready!</h2>

              <div class="question-holder">
                <h4 class="header">Report Delivery</h4>
                <span>Thank you for answering these survey questions. Before receiving your free market report and savings analysis, please register your {{cotsMap[review_session.class_of_trade_id]?.content}}.</span>

                <div class="answer-holder row">
                  <div class="col-sm-10">
                    <div class="form-group required">
                      <label class="col-form-label" for="organization">Organization</label>
                      <input type="text" id="organization" [(ngModel)]="signup.location.facility_name" placeholder="Organization" required class="input-text" [ngModelOptions]="{standalone: true}"/>
                    </div>

                    <div class="form-group required">
                      <label class="col-form-label" for="first-name">First Name</label>
                      <input type="text" id="first-name" [(ngModel)]="signup.user.fname" placeholder="First Name" required class="input-text" [ngModelOptions]="{standalone: true}"/>
                    </div>

                    <div class="form-group required">
                      <label class="col-form-label" for="last-name">Last Name</label>
                      <input type="text" id="last-name" [(ngModel)]="signup.user.lname" placeholder="Last Name" required class="input-text" [ngModelOptions]="{standalone: true}"/>
                    </div>

                    <div class="form-group required">
                      <label class="col-form-label" for="zip">Zip Code</label>
                      <input type="text" id="zip" [(ngModel)]="signup.location.zip" placeholder="ZIP Code" required class="input-text" [ngModelOptions]="{standalone: true}"/>
                    </div>

                    <div class="form-group required">
                      <label class="col-form-label" for="email">Email</label>
                      <input type="text" id="email" [(ngModel)]="signup.user.email" placeholder="E-mail" required class="input-text" [ngModelOptions]="{standalone: true}"/>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Medical/Surgical Distributor</label>
                      <select [ngModel]="signup.location.__distributor_id" (change)="signup.location.__distributor_id = $event.target.value" class="form-control" [ngModelOptions]="{standalone: true}">
                        <option value="0" disabled>Please select</option>
                        <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="checkbox" style="margin-left: 1.25rem; margin-top: 2rem">
                  <label class="col-form-label">
                    <input type="checkbox" #tos (change)="signup.signature = tos.checked ? '--electronic signature--':''" class="zoomed-checkbox"/>
                    I agree to the <a href="" data-toggle="modal" data-target="#termsModal">Terms of Service, Privacy Policy and Participation Agreement</a>.
                  </label>
                </div>
              </div>
              <hr/>

              <section [attr.data-hidden]="!review_session.__ask_distributor_email">
                <p class="custom-question">Would you like to send a copy of this report to your distributor?</p>

                <div class="form-group">
                  <label class="col-form-label" for="email-distr">If so, please provide your distributor’s email address</label>
                  <input type="text" id="email-distr" [(ngModel)]="signup.rep_email" placeholder="Distributor’s Email" class="input-text" [ngModelOptions]="{standalone: true}"/>
                </div>
              </section>
              <div class="form-action">
                <button type="submit" class="btn btn-primary" [disabled]="!signup.signature || saving">Send now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Terms of Service, Privacy Policy and Participation Agreement</h4>
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body high-modal">
          <tos-text [user]="signup.user" [loc]="signup.location" [signature]="signup.signature"></tos-text>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

</section>
