import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from './Config';
import {ICellphoneSignup} from "../interfaces/ICellphoneSignup";
import {Observable} from 'rxjs';

@Injectable()
export class CellphoneSignup {
  constructor(private http:HttpClient, private config:Config) {}

  submit(data:ICellphoneSignup):Observable<string> {
    return this.http.post(this.config.apiUrl + '/cellphones', JSON.stringify(data), {responseType: 'text'});
  }
}
