import {Component} from '@angular/core';
import {Session} from "../../../services/Session";
import {Implementations} from "../../../services/Implementations";
import {Config} from "../../../services/Config";
import {ILocationImplementationVendor} from "../../../interfaces/ILocationImplementationVendor";
import {Notify} from "../../../services/Notify";
import {User} from "../../../services/User";
import {LocationCustomSettings} from "../../../services/LocationCustomSettings";
import {ICustomSettings} from "../../../interfaces/ICustomSettings";
import {Locations} from "../../../services/Locations";
import {ILocation} from "../../../interfaces/ILocation";
import {Router} from "@angular/router";
declare var _:any;
declare var $:any;

@Component({
  selector: 'survey-shipping',
  templateUrl: './shipping.html',
  styleUrls: ['./shipping.css'],
})
export class SurveyShippingCmp {
  public isFedexCustomer = 1;
  public officeContactUser = {name: '', email: ''};
  public fedexAccountNumber;
  public numberIsSet = false;
  public saving = false;
  public newContactThanks = false;
  public settings:ICustomSettings;
  public userLocation:ILocation = <ILocation>{};
  public disclaimed;
  public displayOfficeSuppliesPage = true;

  constructor(private session:Session, private implementationsSrv:Implementations,
    public locService:Locations, private router:Router,
    public config:Config, private userSrv:User, private locSettings:LocationCustomSettings) {
    this.session.addOnLoadHandler(() => {
      this.loadUserData();
    });
    this.session.redirectIfNotAuth();
  }

  loadUserData() {
    let u = this.session.user;
    if (u.theme_id == this.config.mrnThemeID) {
      this.displayOfficeSuppliesPage = false;
    }
    if (u.location_id) {
      this.settings = <ICustomSettings>{location_id: u.location_id};
      this.implementationsSrv.getLocationVendors(u.location_id).subscribe((vendors) => {
        if (vendors && vendors.length) {
          let fedex:ILocationImplementationVendor = _.find(vendors, {impl_vendor_id: this.config.fedexVendorID});
          if (fedex && fedex.account_number) {
            this.fedexAccountNumber = fedex.account_number;
            this.isFedexCustomer = 1;
            this.numberIsSet = true;
          }
        }
      }, () => {});
      this.locSettings.getSettings(u.location_id).subscribe((settings) => {
        this.settings = settings;
      }, () => {});
      this.locService.getLocation(u.location_id).then((loc:ILocation) => {
        if (loc) {
          this.userLocation = loc;
          if (loc.__group_primary_location_id != loc.id) {
            this.locService.getLocation(loc.__group_primary_location_id).then((primaryLoc:ILocation) => {
              if (primaryLoc) {
                this.userLocation = primaryLoc;
              }
            }, () => {});
          }
        }
      }, () => {});
    }
  }

  save() {
    if (this.disclaimed) {
      return this.disclaim();
    }
    let u = this.session.user;
    if (this.officeContactUser.email) {
      this.saving = true;
      this.userSrv.addContactInNonMedSurvey('shipping', u.id, this.officeContactUser.email, this.officeContactUser.name).subscribe(() => {
        this.saving = false;
        this.locSettings.callZapierNonMedNewContact('shipping', {
          is_fedex_customer: this.isFedexCustomer,
          account_number: this.fedexAccountNumber,
        }, u, this.officeContactUser.name, this.officeContactUser.email, this.userLocation);
        let n = new Notify();
        n.success('Saved');
        this.newContactThanks = true;
        $('html,body').animate({scrollTop: 0}, 150);
      }, (err) => {
        this.saving = false;
        try {
          let n = new Notify();
          n.error('Error');
        } catch (e) {}
        console.error(err);
      });
    } else {
      if (!u.location_id) {
        let n = new Notify();
        n.error('Please add information about facility into your account');
        return;
      }
      if (this.fedexAccountNumber) {
        let v = new ILocationImplementationVendor();
        v.account_number = this.fedexAccountNumber;
        v.location_id = u.location_id;
        v.impl_vendor_id = this.config.fedexVendorID;
        v.user_id = u.id;
        this.saving = true;
        this.implementationsSrv.setLocationVendors([v]).subscribe(() => {
          this.saving = false;
          if (!this.settings.is_shipping_page_filled) {
            this.settings.is_shipping_page_filled = true;
            this.locSettings.setSettings(this.settings).subscribe(() => {}, () => {});
          }
          this.locSettings.callZapierNonMedAnswers('shipping', {'account_number': this.fedexAccountNumber}, u, this.userLocation);
          let n = new Notify();
          n.success('Saved');
          this.numberIsSet = true;
          this.router.navigate(['/non_med_savings']);
        }, (err) => {
          this.saving = false;
          let n = new Notify();
          n.error('Server error');
          console.error(err);
        });
      }
    }
    if (u.location_id) {
      this.locService.setVendorInterested(u.location_id, this.config.fedexVendorID);
    }
  }

  toggleHaveFedexAccount(cb) {
    this.isFedexCustomer = cb.checked ? 0 : 1;
  }

  disclaim() {
    this.settings.is_shipping_page_filled = true;
    this.settings.is_shipping_page_disclaimed = true;
    this.locSettings.setSettings(this.settings).subscribe(() => {}, () => {});
    let n = new Notify();
    n.success('Saved');
    this.numberIsSet = true;
    this.router.navigate(['/non_med_savings']);
  }
}
