import {Component, ViewChild, ElementRef, Input} from '@angular/core';
import {Specialties} from "../../services/Specialties";
import {OwlCarousel} from "../../services/OwlCarousel";

@Component({
  selector: 'specialty-slider',
  templateUrl: './slider.html',
})
export class SpecialtySliderCmp {
  @Input() id;
  @ViewChild('slider') slider:ElementRef;
  public carousel:OwlCarousel;
  public loading;

  constructor(private specialtiesService:Specialties) {}

  routerCanReuse() {
    return false;
  }

  ngAfterViewInit() {
    if (this.slider && this.slider.nativeElement) {
      this.carousel = new OwlCarousel(this.slider.nativeElement);
      this.carousel.init();
      this.render();
    }
  }

  render() {
    if (this.loading && this.loading.then) {
      this.loading.then(()=> {
        this.render();
      }, ()=> {});
      return;
    }
    this.loading = new Promise((resolve, reject)=> {
      this.carousel.reinit();
      this.specialtiesService.loadSlides(parseInt(this.id) || 0).subscribe((slides) => {
        if (slides && slides.length) {
          slides.forEach((slide)=> {
            this.carousel.addItem(slide.slide_html);
          });
        }
        this.loading = null;
        resolve(null);
      }, ()=> {
        this.loading = null;
        reject();
      });
    });
  }

  ngOnChanges() {
    if (!this.carousel) {
      return;
    }
    this.render();
  }
}
