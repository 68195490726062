import {Routes} from '@angular/router';

import {HomeCmp} from './components/home/home';
import {RepSignupFormCmp} from './components/signup/for_rep/RepSignupFormCmp';
import {SignupReviewCmp} from './components/signup/review/SignupReviewCmp';
import {SignupCmp} from './components/signup/SignupCmp';
import {LoginCmp} from './components/login/LoginCmp';
import {LogoutCmp} from './components/logout/LogoutCmp';
import {ContractCmp} from './components/contract/ContractCmp';
import {CategoryCmp} from './components/category/CategoryCmp';
import {SpecialtyCmp} from './components/specialty/SpecialtyCmp';
import {EmployeePerkCmp} from './components/employee-perk/EmployeePerkCmp';
import {OfficeSuppliesCmp} from './components/office-supplies/OfficeSuppliesCmp';
import {CapitalEquipmentCmp} from './components/capital-equipment/CapitalEquipmentCmp';
import {PharmacyCmp} from './components/pharmacy/PharmacyCmp';
import {ForgotPasswordCmp} from './components/forgot-password/ForgotPasswordCmp';
import {NewPasswordCmp} from './components/forgot-password/NewPasswordCmp';
import {FAQCmp} from './components/faq/faq';
import {PrivacyPolicyCmp} from './components/privacy-policy/PrivacyPolicyCmp';
import {TermsOfUseCmp} from './components/terms-of-use/TermsOfUseCmp';
import {TermsOfServiceCmp} from './components/terms-of-service/TermsOfServiceCmp';
import {ContactCmp} from './components/contact/contactCmp';
import {AccountCmp} from './components/account/account';
import {AccountSecurityCmp} from './components/account-security/AccountSecurityCmp';
import {SavingsReportCmp} from './components/savings_report/SavingsReportCmp';
import {SavingsReportDeliveryCmp} from './components/savings_report/SavingsReportDeliveryCmp';
import {SavingsSignupCmp} from './components/savings_report/SavingsSignupCmp';
import {NewMemberCmp} from './components/new-member/NewMemberCmp';
import {RedirAuthCmp} from "./components/redir_auth/RedirAuthCmp";
import {SearchResultsCmp} from "./components/search-results/SearchResultsCmp";
import {CellPhonesCmp} from "./components/cellphone-signup/CellPhonesCmp";
import {SignupMgmaCmp} from "./components/signup/mgma/SignupMgmaCmp";
import {SurveySignupCmp} from "./components/survey_after_signup/SurveySignupCmp";
import {SurveySignupThanksCmp} from "./components/survey_after_signup/SurveySignupThanksCmp";
import {NonMedSavingsCmp} from "./components/non-med-savings/NonMedSavingsCmp";
import {SurveyOfficeSuppliesCmp} from "./components/non-med-savings/office-supplies/SurveyOfficeSuppliesCmp";
import {SurveyShippingCmp} from "./components/non-med-savings/shipping/SurveyShippingCmp";
import {SurveyCellPhonesCmp} from "./components/non-med-savings/cellphones/SurveyCellPhonesCmp";
import {SurveyUtilityBillsCmp} from "./components/non-med-savings/utility-bills/SurveyUtilityBillsCmp";
import {SurveyCarsCmp} from "./components/non-med-savings/rental-cars/SurveyCarsCmp";
import {CarRentalCmp} from "./components/car-rental/CarRentalCmp";
import {SurveyOfficeSuppliesThanks} from "./components/non-med-savings/office-supplies/SurveyOfficeSuppliesThanks";
import {MgmaSsoCmp} from "./components/mgma_sso/MgmaSsoCmp";
import {NewsletterCmp} from "./components/newsletter/NewsletterCmp";
import {RxPageCmp} from "./components/rx-page/RxPageCmp";
import {FluVaccinesPageCmp} from "./components/flu-vaccines/FluVaccinesPageCmp";
import {VaccinesThanksCmp} from "./components/flu-vaccines/VaccinesThanksCmp";
import {SiimPageCmp} from "./components/siim-page/SiimPageCmp";
import {SiimThanksCmp} from "./components/siim-page/SiimThanksCmp";
import {SurveyExtDistributorCmp} from "./components/survey-ext-distributor/SurveyExtDistributorCmp";
import {SurveyExtThanksCmp} from "./components/survey-ext-thanks/SurveyExtThanksCmp";
import {FluPrebook2020Cmp} from "./components/flu-prebook-2020/FluPrebook2020Cmp";
import {FluPrebookThanksCmp} from "./components/flu-prebook-thanks/FluPrebookThanksCmp";
import {EnrollPageCmp} from "./components/enroll-page/EnrollPageCmp";
import {GetStartedPageCmp} from "./components/get-started-page/GetStartedPageCmp";
import {NoThankYouPageCmp} from "./components/no-thank-you-page/NoThankYouPageCmp";
import {SignupOptimizationPageCmp} from "./components/signup-optimization-page/SignupOptimizationPageCmp";

export const routes: Routes = [
  {path: '', component: HomeCmp},
  {path: 'rep_signup', component: RepSignupFormCmp},
  {path: 'signup/:code', component: SignupReviewCmp},
  {path: 'signup', component: SignupCmp},
  {path: 'enroll', component: EnrollPageCmp},
  {path: 'enroll/:code', component: EnrollPageCmp},
  {path: 'get-started', component: GetStartedPageCmp},
  {path: 'get-started/:code', component: GetStartedPageCmp},
  {path: 'signup-optimization', component: SignupOptimizationPageCmp},
  {path: 'no-thank-you', component: NoThankYouPageCmp},
  {path: 'mgma_signup', component: SignupMgmaCmp},
  {path: 'rx/:code', component: RxPageCmp},
  {path: 'siim/:code', component: SiimPageCmp},
  {path: 'siim_thank_you', component: SiimThanksCmp},
  {path: 'cellphones', component: CellPhonesCmp},
  {path: 'login', component: LoginCmp},
  {path: 'create_account', redirectTo: '/signup'},
  {path: 'logout', component: LogoutCmp},
  {path: 'forgot_pwd', component: ForgotPasswordCmp},
  {path: 'faq', component: FAQCmp},
  {path: 'privacy-policy', component: PrivacyPolicyCmp},
  {path: 'terms-of-use', component: TermsOfUseCmp},
  {path: 'terms-of-service', component: TermsOfServiceCmp},
  {path: 'contact', component: ContactCmp},
  {path: 'about', redirectTo: '/faq'},
  {path: 'account', component: AccountCmp},
  {path: 'security', component: AccountSecurityCmp},
  {path: 'new_pwd/:code', component: NewPasswordCmp},
  {path: 'contract/:number', component: ContractCmp},
  {path: 'newsletter/:code', component: NewsletterCmp},
  {path: 'category/:slugName', component: CategoryCmp},
  {path: 'specialty/:id', component: SpecialtyCmp},
  {path: 'employee-perk', component: EmployeePerkCmp},
  {path: 'office-supplies', component: OfficeSuppliesCmp},
  {path: 'capital-equipment', component: CapitalEquipmentCmp},
  {path: 'pharmacy', component: PharmacyCmp},
  {path: 'savings', component: SavingsReportCmp},
  {path: 'savings_signup/:review_session_id', component: SavingsSignupCmp},
  {path: 'savings_report_delivery/:review_session_id', component: SavingsReportDeliveryCmp},
  {path: 'search/:search', component: SearchResultsCmp},
  {path: 'redir_auth', component: RedirAuthCmp},
  {path: 'mgma_sso', component: MgmaSsoCmp},
  {path: 'new_member', component: NewMemberCmp},
  {path: 'signup_benefit_review', component: SurveySignupCmp},
  {path: 'signup_benefit_review_thanks', component: SurveySignupThanksCmp},
  {path: 'vaccines_thanks', component: VaccinesThanksCmp},
  {path: 'non_med_savings', component: NonMedSavingsCmp},
  {path: 'non_med_savings/office_supplies', component: SurveyOfficeSuppliesCmp},
  {path: 'non_med_savings/office_supplies/thank_you', component: SurveyOfficeSuppliesThanks},
  {path: 'non_med_savings/shipping', component: SurveyShippingCmp},
  {path: 'non_med_savings/wireless', component: SurveyCellPhonesCmp},
  {path: 'non_med_savings/utility_bills', component: SurveyUtilityBillsCmp},
  {path: 'non_med_savings/rental_cars', component: SurveyCarsCmp},
  {path: 'car_rental', component: CarRentalCmp},
  {path: 'flu-vaccines', component: FluVaccinesPageCmp},
  {path: 'vaccines_prebook_2020', component: FluPrebook2020Cmp},
  {path: 'flu_prebook_thank_you', component: FluPrebookThanksCmp},
  {path: 'distributor-survey', component: SurveyExtDistributorCmp},
  {path: 'survey_thank_you', component: SurveyExtThanksCmp},
];
