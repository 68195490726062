<div class="card" *ngIf="(lc.distributor_primary_name && lc.is_distr_primary_popular) || (lc.distributor_secondary_name && lc.is_distr_secondary_popular)" style="font-size: 0.875em;">
  <div class="card-header" *ngIf="location?.facility_name || location?.pc_id || location?.zimmer_id">
    <strong *ngIf="location?.facility_name">{{location.facility_name}}</strong>
    <span *ngIf="location?.pc_id" class="float-right"><strong>PC ID:</strong> {{location.pc_id}}</span>
    <div *ngIf="location?.address || location?.zimmer_id">
      <span *ngIf="location?.address">{{[location.address, location.address2, location.city, location.state, location.zip]|arr_join}}</span>
      <span *ngIf="location?.zimmer_id" class="float-right"><strong>GPO ID:</strong> {{location.zimmer_id}}</span>
    </div>
  </div>
  <div class="card-body">
    <div class="row form-group">
      <div class="col-sm-6" *ngIf="lc.is_distr_primary_popular">
        <div>Primary Distributor</div>
        <img *ngIf="lc.distributor_primary_logo" src="{{lc.distributor_primary_logo}}" class="crm-logo-embedded crm-logo-distr"/>
        <h4 style="padding-top: 0.5rem;" *ngIf="!lc.distributor_primary_logo">{{lc.distributor_primary_name}}</h4>
      </div>
      <div class="col-sm-6" *ngIf="lc.distributor_secondary_name && lc.is_distr_secondary_popular">
        <div>Secondary Distributor</div>
        <img *ngIf="lc.distributor_secondary_logo" src="{{lc.distributor_secondary_logo}}" class="crm-logo-embedded crm-logo-distr"/>
        <h4 *ngIf="!lc.distributor_secondary_logo" style="padding-top: 0.5rem;">{{lc.distributor_secondary_name}}</h4>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-6 text-center" *ngIf="lc.is_distr_primary_popular">
        <div *ngIf="lc.sent_date_primary" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.sent_date_primary" class="material-icons">done</i>
          </div>
          <div>Sent</div>
          <div *ngIf="showCircleDates && lc.sent_date_primary">
            <hr>
            <div>{{lc.sent_date_primary|utc2et|date:'shortDate'}}</div>
          </div>
        </div>
        <div *ngIf="showCircleRep && lc.rep_primary_name" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.rep_primary_name" class="material-icons">done</i>
          </div>
          <div>Has Rep</div>
          <div *ngIf="lc.rep_primary_name">
            <hr>
            <div>{{lc.rep_primary_name}}</div>
          </div>
        </div>
        <div *ngIf="lc.connected_date_primary" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.connected_date_primary" class="material-icons">done</i>
          </div>
          <div>Connected</div>
          <div *ngIf="showCircleDates && lc.connected_date_primary">
            <hr>
            <div>{{lc.connected_date_primary|utc2et|date:'shortDate'}}</div>
          </div>
        </div>
        <div *ngIf="showCircleReporting && lc.distributor_primary_reporting" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.distributor_primary_reporting" class="material-icons">done</i>
          </div>
          <div>Reporting</div>
          <div *ngIf="lc.distributor_primary_reporting">
            <hr>
            <div>{{lc.distributor_primary_reporting}}</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="text-left pc2-row-pad">
          <button class="btn btn-outline-info btn-sm" (click)="noLongerUseModalShow(lc.distributor_primary_name)">No longer use this distributor</button>
        </div>
      </div>
      <div class="col-sm-6 text-center" *ngIf="lc.distributor_secondary_name && lc.is_distr_secondary_popular">
        <div *ngIf="lc.sent_date_secondary" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.sent_date_secondary" class="material-icons">done</i>
          </div>
          <div>Sent</div>
          <div *ngIf="showCircleDates && lc.sent_date_secondary">
            <hr>
            <div>{{lc.sent_date_secondary|utc2et|date:'shortDate'}}</div>
          </div>
        </div>
        <div *ngIf="lc.rep_secondary_name" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.rep_secondary_name" class="material-icons">done</i>
          </div>
          <div>Has Rep</div>
          <div *ngIf="lc.rep_secondary_name">
            <hr>
            <div>{{lc.rep_secondary_name}}</div>
          </div>
        </div>
        <div *ngIf="lc.connected_date_secondary" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.connected_date_secondary" class="material-icons">done</i>
          </div>
          <div>Connected</div>
          <div *ngIf="showCircleDates && lc.connected_date_secondary">
            <hr>
            <div>{{lc.connected_date_secondary|utc2et|date:'shortDate'}}</div>
          </div>
        </div>
        <div *ngIf="lc.distributor_secondary_reporting" class="crm-distr-circle-container">
          <div class="crm-distr-circle">
            <i *ngIf="lc.distributor_secondary_reporting" class="material-icons">done</i>
          </div>
          <div>Reporting</div>
          <div *ngIf="lc.distributor_secondary_reporting">
            <hr>
            <div>{{lc.distributor_secondary_reporting}}</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="text-left pc2-row-pad">
          <button class="btn btn-outline-info btn-sm" (click)="noLongerUseModalShow(lc.distributor_secondary_name)">No longer use this distributor</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" #nolongerusemodal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">No longer use distributor {{noLongerUseDistrName}}</h5>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <div class="input-group vertical custom-input-group required">
          <h6 class="group-header">Commentary:</h6>
          <textarea class="form-control" rows="3" [(ngModel)]="noLongerUseComment"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-sm" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="noLongerUseSubmit()" [disabled]="noLongerUseSubmitting">Submit</button>
      </div>
    </div>
  </div>
</div>

