<div class="container">
  <div class="row pc2-row-pad text-center">
    <div class="col-md-8 offset-md-2">
      <h2><i class="fas fa-bullseye"></i>&nbsp; Sign up a Customer</h2>
      <div>Signing up a customer is easy. Simply provide us with the information below and we
        will generate a customer email - including signup link - that you can send to your
        customer. Upon receiving a link, your customer just needs to electronically sign and
        submit their information. We take care of the rest and you along the way.
      </div>
    </div>
  </div>
  <div class="row pc2-row-pad text-center">
    <form class="col-md-6 offset-md-3 col-lg-4 offset-lg-4"
      action="{{action}}" method="POST" #form (ngSubmit)="submit()">
      <fieldset class="form-group">
        <label>Your name</label>
        <input type="text" class="form-control" name="rep_name" placeholder="Your name" autocomplete="new-password">
      </fieldset>
      <fieldset class="form-group">
        <label>Your email</label>
        <input type="text" class="form-control" name="rep_email" placeholder="Your email" autocomplete="new-password">
      </fieldset>
      <fieldset class="form-group">
        <label>Customer name</label>
        <input type="text" class="form-control" name="customer_name" placeholder="Customer name" autocomplete="new-password">
      </fieldset>
      <fieldset class="form-group">
        <label>Customer email</label>
        <input type="text" class="form-control" name="customer_email" placeholder="Customer email" autocomplete="new-password">
      </fieldset>
      <fieldset class="form-group">
        <label>Facility Name</label>
        <input type="text" class="form-control" name="facility_name" placeholder="Facility Name" autocomplete="new-password">
      </fieldset>
      <input type="hidden" name="form_actions_id" value="1"/>
      <button type="submit" class="btn btn-primary">
        Start Signup Process for Customer &nbsp;
        <span class="fas fa-chevron-circle-right"></span>
      </button>
      <div class="pc2-row-pad">If you have any questions, don't hesitate to call 1.877.659.2159 or
        e-mail us at <a target="_blank" href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a>.
      </div>
    </form>
  </div>
</div>
