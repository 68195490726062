import {ITheme} from "./ITheme";
import {ILocation} from "./ILocation";
export class IUser {
  id:number;
  email:string;
  cm_access_level:number;
  admin_access_level:number;
  distr_access_level:number;
  theme_id:number;
  class_of_trade_id:number;
  honorific:string;
  fname:string;
  mname:string;
  lname:string;
  phone:string;
  region_id:number;
  manager_id:number;
  vp_manager_id:number;
  distributor_id:number;
  specialty_id:number;
  specialty_2_id:number;
  specialty_3_id:number;
  license_number:string;
  dea:string;
  upin:string;
  npi:string;
  position:string;
  job_title:string;
  job_type_id:number;
  skimmer_id:string;
  latitude:number;
  longitude:number;
  address:string;
  address2:string;
  city:string;
  state:string;
  zip:string;
  type_id:number;
  location_id:number;
  location_2_id:number;
  location_3_id:number;
  source_id:number;
  asps_id:string;
  mgma_id:string;
  amp_import_id:number;
  cm_user_id:number;
  cap_id:number;
  is_email_valid:boolean;
  contact_note:string;
  is_email_suppressed:boolean;
  send_newsletter:boolean;
  is_disabled:boolean;
  is_not_usable_as_contact:boolean;
  __is_totp:boolean;
  __is_pwd_empty:boolean;
  __manager_id:number;
  __manager_fname:string;
  __manager_lname:string;
  __region:string;
  __type:string;
  __theme:ITheme;
  __tags_clist:string;
  __location:ILocation;
  //
  _is_login_auto:boolean = true;
}
