import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IUser} from '../interfaces/IUser';
import {Observable} from 'rxjs';
import {ISignupWithPA} from "../interfaces/ISignupWithPA";
import {IAnnualDisclosureCodeUsage} from "../interfaces/IAnnualDisclosureCodeUsage";
import {ILoginNewContact} from "../interfaces/ILoginNewContact";
import {IAnnualSurveyType} from "../interfaces/IAnnualSurveyType";

@Injectable()
export class User {
  constructor(private http: HttpClient, private config: Config, private session: Session) {
  }

  getNewTotpSecret(password): Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/totp', JSON.stringify({password: password}), {headers: headers});
  }

  removeTotpSecret(password): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/totp_remove', JSON.stringify({password: password}), {headers: headers, responseType: 'text', observe: 'response'});
  }

  checkTotpCode(code): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/totp/' + code, {headers: headers, responseType: 'text', observe: 'response'});
  }

  getUserInfo(id) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get<IUser>(this.config.apiUrl + '/user/' + id, {headers: headers}).subscribe(resolve, reject);
    });
  }

  getSignupCodeInfo(code): Observable<any> {
    return this.http.get(this.config.apiUrl + '/user/signup_info/' + code);
  }

  getSignupCodeSession(code): Observable<string> {
    return this.http.get(this.config.apiUrl + '/user/signup_session/' + code, {responseType: 'text'});
  }

  updateUser(user: IUser): Promise<number> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.put(this.config.apiUrl + '/user', JSON.stringify(user), {headers: headers, responseType: 'text'}).subscribe((id) => {
        resolve(parseInt(id));
      }, reject);
    });
  }

  changePassword(userID: number, currentPwd: string, newPwd: string) {
    return this.http.post(this.config.apiUrl + '/change_password', JSON.stringify({
      user_id: userID,
      current_password: currentPwd,
      new_password: newPwd,
    })).toPromise();
  }

  signupReviewed(signup_code, user, location, signature, other_locations, all_locations_managed) {
    let src = 'signup reviewed';
    try {
      src = window.location.href;
    } catch (e) {}
    return this.http.post(this.config.apiUrl + '/signup_reviewed', JSON.stringify({
      signup_token: signup_code,
      user: user,
      location: location,
      all_locations_managed: !!(all_locations_managed),
      other_locations: other_locations,
      signature: signature,
      source: src,
    }), {responseType: 'text'});
  }

  signupWithPA(data: ISignupWithPA): Observable<any> {
    if (!data.source) {
      try {
        data.source = window.location.href;
      } catch (e) {}
    }
    if (data.location && data.location.zip && data.user && !data.user.zip) {
      data.user.zip = data.location.zip;
    }
    return this.http.post<any>(this.config.apiUrl + '/signup_pa', JSON.stringify(data));
  }

  addCustomSignature(authCode: string, distributor: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Authorization', 'Bearer ' + authCode);
    return this.http.post(this.config.apiUrl + '/user/custom_distributor', distributor, {headers: headers});
  }

  addContactInSignupSurvey(main_user_id, email, group_tag: String, name?: string, phone?: string, specialty_id?: number, cot_id?: number): Observable<string> {
    let l = window.location;
    return this.http.post(this.config.apiUrl + '/signup_survey/contact', JSON.stringify({
      main_user_id: main_user_id,
      email: email,
      group_tag: group_tag,
      name: name,
      phone: phone,
      specialty_id: specialty_id,
      class_of_trade_id: cot_id,
      return_url: l.protocol + '//' + l.host + '/signup_benefit_review'
    }), {responseType: 'text'});
  }

  addContactInNonMedSurvey(category, main_user_id, email, name: string, phone?: string): Observable<string> {
    let l = window.location;
    return this.http.post(this.config.apiUrl + '/nonmed_survey/contact', JSON.stringify({
      category: category,
      main_user_id: main_user_id,
      email: email,
      name: name,
      phone: phone,
      return_url: l.protocol + '//' + l.host + '/non_med_savings/'
    }), {responseType: 'text'});
  }

  mgmaSso(token): Observable<string> {
    return this.http.post(this.config.apiUrl + '/mgma/sso', 'as_text=1&token=' + token, {responseType: 'text'});
  }

  getUserInfoByToken(token: string): Observable<IUser> {
    return this.http.get<IUser>(this.config.apiUrl + '/session/' + token);
  }

  getMyRxCode(): Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/rx/my_code', {headers: headers, responseType: 'text'});
  }

  getAuthenticatedAnnualDisclosureReq(location_id?: number): IAnnualDisclosureCodeUsage {
    let r: IAnnualDisclosureCodeUsage = {};
    try {
      if (this.session && this.session.annualDisclosureCode) {
        r.code = this.session.annualDisclosureCode;
      } else {
        if (this.session && this.session.user && this.session.user.id) {
          r.user_id = this.session.user.id;
        } else {
          if (location_id) {
            r.location_id = location_id;
          }
        }
      }
    } catch (e) {
    }
    return r;
  }

  writeAnnualDisclosureCodeUsage(usage: IAnnualDisclosureCodeUsage) {
    this.http.post(this.config.apiUrl + '/log_annual_disclosure_code_usage', JSON.stringify(usage)).subscribe(() => {}, () => {});
  }

  loginNewContact(req: ILoginNewContact): Observable<any> {
    return this.http.post(this.config.apiUrl + '/login_contact', JSON.stringify(req));
  }

  getAnnualSurveyType(): Observable<IAnnualSurveyType> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IAnnualSurveyType>(this.config.apiUrl + '/user/annual_survey_type', {headers: headers});
  }
}
