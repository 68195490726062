<a class="btn btn-info btn-custom btn-primary-action" *ngIf="displayButton" (click)="showModal()">
  <span class="icon-question_mark"></span>
  I have a question
</a>

<a *ngIf="!displayButton && linkText?.length>0" (click)="showModal()" class="pointer">{{linkText}}</a>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" #haq>
  <form class="form-validate body-form" (ngSubmit)="send()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Question about {{vendorName}} ({{contractNumber}})</h4>
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
          <h5>{{session.user?.fname}} {{session.user?.lname}}</h5>
          <h6>{{session.user?.email}}</h6>
          <div class="input-group vertical custom-input-group required">
            <section *ngIf="!session.user || !session.user.email" class="form-group">
              <h6 class="group-header">Your email:</h6>
              <input type="text" [(ngModel)]="newEmail" placeholder="email" name="email"/>
            </section>
            <h6 class="group-header">Your question:</h6>
            <textarea class="form-control custom-form-control ta-width-100" [(ngModel)]="question" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="send()" [disabled]="saving">Send question</button>
        </div>
      </div>
    </div>
  </form>
</div>
