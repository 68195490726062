import {Component} from '@angular/core';
import {SendPostForm} from "../../services/SendPostForm";
import {Router, ActivatedRoute} from '@angular/router';
declare var $:any;

@Component({
  selector: 'contact',
  templateUrl: './contact.html',
})

export class ContactCmp {
  public formData:any = {'idstamp': 'MZu3HU/HChiFNzB0NmfMgn8grvWRS0wN53y3ohrdFAA='};
  public messageSent = false;

  constructor(private routeParams:ActivatedRoute) {}

  ngOnInit() {
    this.routeParams.queryParams.subscribe(params => {
      if (params['category']) {
        if (!this.formData.Field6) {
          this.formData.Field6 = '';
        }
        this.formData.Field6 = 'Category: ' + params['category'] + "\n\n" + this.formData.Field6;
      }
    });
  }

  submit() {
    let sender = new SendPostForm();
    sender.send('https://purchaseclinic.wufoo.com/forms/rilldqz0fbtz96/#public', this.formData);
    this.messageSent = true;
    $('html,body').animate({scrollTop: 0}, 100);
  }
}
