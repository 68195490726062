import {Component, Input} from '@angular/core';
import {Cots} from "../../services/Cots";
import {ICot} from "../../interfaces/ICot";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";

@Component({
  selector: 'tos-text',
  templateUrl: './tos.html',
  styleUrls: ['./tos.css'],
})
export class TosTextCmp {
  @Input() acceptanceDate = '';
  @Input() user = {};
  @Input() loc = {};
  @Input() ip = '';
  @Input() signature = '';
  public cotsMap:{[id:number]:ICot;} = {};

  constructor(private cots:Cots, public session:Session, public config:Config) {
    this.cots.loadToMap(this.cotsMap);
  }

  ngOnChanges() {
    if (!this.acceptanceDate) {
      this.setCurrentDate();
    }
  }

  setCurrentDate() {
    let d = new Date();
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var formattedDate = months[d.getMonth()] + " " + d.getDate() + "th, " + d.getFullYear() + " at " + d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
    this.acceptanceDate = formattedDate;
    setTimeout(()=> {this.setCurrentDate();}, 1000);
  }
}
