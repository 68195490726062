import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";

@Component({
  selector: 'amp-pc-tos-line',
  template: `<div *ngIf="prefix"><p>{{prefix}}</p></div>`
})
export class AmpPcTosLineCmp {
  public prefix = '';

  constructor(private session:Session, private config:Config) {
    this.session.addOnLoadHandler(()=> {
      this.generatePrefix();
    });
  }

  ngAfterViewInit() {
    this.generatePrefix();
  }

  generatePrefix() {
    if (this.session) {
      let st = this.session.getCurrentSubTheme();
      if (st && st.tos) {
        this.prefix = st.tos;
      } else {
        let t = this.session.getCurrentTheme();
        if (t && t.tos) {
          this.prefix = t.tos;
        }
      }
    } else {
      let dt = this.config.defaultTheme;
      if (dt && dt.tos) {
        this.prefix = dt.tos;
      }
    }
  }
}
