import {Component, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Session} from "../../../services/Session";
import {Config} from "../../../services/Config";
import {Notify} from "../../../services/Notify";
import {User} from "../../../services/User";
import {LocationCustomSettings} from "../../../services/LocationCustomSettings";
import {Locations} from "../../../services/Locations";
import {ICustomSettings} from "../../../interfaces/ICustomSettings";
import {Observable, forkJoin} from "rxjs";
import {share} from 'rxjs/operators';
import {ILocation} from "../../../interfaces/ILocation";
declare var $:any;

@Component({
  selector: 'utility-bills',
  templateUrl: './utility-bills.html',
  styleUrls: ['./utility-bills.css'],
})
export class SurveyUtilityBillsCmp {
  public officeContactUser = {name: '', email: ''};
  public saving = false;
  public newContactThanks = false;
  public unsupportedStates = ['1', '12', '18', '26', '28', '45'];
  public stateID = 0;
  public ownership = 0;
  public procure = 0;
  public leaseExpireDate:Date;
  public procureExpireDate:Date;
  public showLeaseExpireDate = new EventEmitter();
  public showProcureExpireDate = new EventEmitter();
  public filesToUpload = [];
  public energyType:string[] = [];
  public userLocation:ILocation = <ILocation>{};
  public disclaimed;

  constructor(private session:Session, private config:Config, private userSrv:User,
    public locSettings:LocationCustomSettings, private locService:Locations, private router:Router) {
    this.session.addOnLoadHandler(()=> {
      this.loadUserData();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.router.navigate(['/non_med_savings']);
  }

  loadUserData() {
    let u = this.session.user;
    if (u.location_id) {
      this.locService.getLocation(u.location_id).then((loc:ILocation)=> {
        if (loc) {
          this.userLocation = loc;
          if (loc.__group_primary_location_id != loc.id) {
            this.locService.getLocation(loc.__group_primary_location_id).then((primaryLoc:ILocation)=> {
              if (primaryLoc) {
                this.userLocation = primaryLoc;
              }
            }, ()=> {});
          }
        }
      }, ()=> {});
    }
  }

  getAnswersObject() {
    let answers:any = {
      state: this.stateID,
      ownership_type: this.ownership == 1 ? 'rent' : (this.ownership == 2 ? 'own' : (this.ownership == 3 ? 'lease' : '')),
      use_procure_energy: this.procure == 2,
      energy_type: this.energyType.join(', '),
    };
    if (this.leaseExpireDate) {
      answers.lease_expire_date = this.leaseExpireDate.toISOString();
    }
    if (this.procureExpireDate) {
      answers.procure_energy_expire_date = this.procureExpireDate.toISOString();
    }
    return answers;
  }

  save() {
    if (this.disclaimed) {
      return this.disclaim();
    }
    let u = this.session.user;
    if (this.officeContactUser.email) {
      this.saving = true;
      this.userSrv.addContactInNonMedSurvey('utility bills', u.id, this.officeContactUser.email, this.officeContactUser.name).subscribe(()=> {
        this.saving = false;
        let answers = this.getAnswersObject();
        this.locSettings.callZapierNonMedNewContact('utility', answers, u, this.officeContactUser.name, this.officeContactUser.email, this.userLocation);
        let n = new Notify();
        n.success('Saved');
        this.newContactThanks = true;
        $('html,body').animate({scrollTop: 0}, 150);
      }, (err)=> {
        this.saving = false;
        try {
          let n = new Notify();
          n.error('Error');
        } catch (e) {}
        console.error(err);
      });
    } else {
      if (!u.location_id) {
        let n = new Notify();
        n.error('Please add information about facility into your account');
        return;
      }
      this.saving = true;
      let files = [];
      try {
        let u = this.session.user;
        let calls = [];
        if (this.filesToUpload.length) {
          this.filesToUpload.forEach((f)=> {
            let upl = this.locService.uploadFile(u.location_id, f.name, f, this.config.fileTypeUtilityBill).pipe(share());
            calls.push(upl);
            upl.subscribe((r:any)=> {
              if (r && r.target && r.target.response) {
                try {
                  let resp = JSON.parse(r.target.response);
                  if (resp && resp.url) {
                    files.push(resp.url);
                  }
                } catch (e) {
                  console.error(e);
                }
              }
            });
          });
        }
        let settings:ICustomSettings = <ICustomSettings>{location_id: u.location_id, is_utility_bills_page_filled: true};
        calls.push(this.locSettings.setSettings(settings));
        let loc = <ILocation>{};
        loc.id = u.location_id;
        if (this.ownership) {
          loc.ownership_type_id = Math.round(this.ownership);
        }
        if (this.leaseExpireDate) {
          this.leaseExpireDate.setHours(10);
          loc.lease_expire_date = this.leaseExpireDate.toISOString();
        }
        if (this.energyType.length) {
          loc.energy_type = this.energyType.join(', ');
        }
        if (this.procureExpireDate) {
          this.procureExpireDate.setHours(10);
          loc.procure_energy_expire_date = this.procureExpireDate.toISOString();
        }
        calls.push(this.locService.updateByUser(loc));
        ///upgrade:test
        forkJoin(calls).subscribe(null, ()=> {
          this.saving = false;
          let n = new Notify();
          n.error('Error');
        }, ()=> {
          this.saving = false;
          let n = new Notify();
          n.success('Saved');
          let answers = this.getAnswersObject();
          answers.files = files;
          answers.files_count = files.length;
          answers.files_tab = this.config.getCrmFilesTab(this.userLocation.id);
          this.locSettings.callZapierNonMedAnswers('utility', answers, u, this.userLocation);
          this.router.navigate(['/non_med_savings']);
        });
      } catch (e) {
        this.saving = false;
        console.error(e);
      }
    }
  }

  addFile($e:File[]) {
    this.filesToUpload = [];
    if ($e && $e.length) {
      for (let i = 0; i < $e.length; i++) {
        this.filesToUpload.push($e[i]);
      }
    }
  }

  toggleEnergy(control) {
    if (control.checked) {
      this.energyType.push(control.value);
    } else {
      let i = this.energyType.indexOf(control.value);
      if (i > -1) {
        this.energyType.splice(i, 1);
      }
    }
  }

  disclaim() {
    if (!this.session.user.location_id) {
      let n = new Notify();
      n.error('Please add information about facility into your account');
      return;
    }
    let settings:ICustomSettings = <ICustomSettings>{};
    settings.location_id = this.session.user.location_id;
    settings.is_utility_bills_page_filled = true;
    settings.is_utility_bills_page_disclaimed = true;
    this.locSettings.setSettings(settings).subscribe(()=> {}, ()=> {});
    let n = new Notify();
    n.success('Saved');
    this.router.navigate(['/non_med_savings']);
  }
}
