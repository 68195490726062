import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Newsletter} from "../../services/Newsletter";
import {INewsletter} from "../../interfaces/INewsletter";
import {DomSanitizer} from '@angular/platform-browser';
import {Notify} from "../../services/Notify";
import {Config} from "../../services/Config";
import {Session} from "../../services/Session";

@Component({
  selector: 'newsletter',
  templateUrl: './newsletter.html',
})
export class NewsletterCmp {
  public letterHTML:string;
  public letterURL:string;
  public newContactEmail:string;
  public newContactName:string;
  public displayNewContactForm = false;
  public sending = false;
  public userID:number;
  private n = new Notify();

  constructor(private newsletter:Newsletter, private routeParams:ActivatedRoute, private router:Router, public sanitizer:DomSanitizer,
    private config:Config, private session:Session) {
    this.session.loadUser(()=> {
      if (this.session.user && this.session.user.id) {
        this.userID = this.session.user.id;
      }
    });
  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      this.load(params['code']);
    });
  }

  load(code) {
    this.letterURL = this.newsletter.getURLofCompiledLetterForLocation(code);
    //this.newsletter.getCompiledLetterForLocation(code).subscribe((letter:INewsletter)=> {
    //  if (letter && letter.email_html) {
    //    this.letterHTML = letter.email_html;
    //  }
    //}, ()=> {});
  }

  addNewContact() {
    if (!this.userID) {
      return;
    }
    if (!this.newContactEmail) {
      this.n.warning(this.config.txt.emailRequired);
      return;
    }
    this.sending = true;
    this.newsletter.addContactFromNewsletter(this.userID, this.newContactEmail, 'newsletter', this.newContactName).subscribe(()=> {
      this.sending = false;
      this.n.success(this.config.txt.successfully);
      this.displayNewContactForm = false;
    }, ()=> {
      this.sending = false;
      this.n.error(this.config.txt.emailSendingError);
    });
  }
}
