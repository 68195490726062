import {Component} from '@angular/core';
import {Specialties} from "../../services/Specialties";
import {Session} from '../../services/Session';
import {ISpecialty} from "../../interfaces/ISpecialty";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";

declare var $: any;
declare var _: any;

@Component({
  selector: 'nav-header',
  templateUrl: './header.html',
})
export class HeaderCmp {
  public specialtiesList: ISpecialty[] = [];
  public logo_img_url: string = '//purchaseclinic.s3.amazonaws.com/logo/pc-logo-white-50.png';
  public themeID = 0;
  public isAccountSubdomainViewMode = false;
  public homeSavingsDomain = '';
  private n = new Notify();

  constructor(private specialties: Specialties, public session: Session, public config: Config) {
    this.isAccountSubdomainViewMode = this.config.isAccountSubDomain();
    this.setHomeSavingsDomain();
    this.session.addOnLoadHandler(() => {
      this.setLogoImg();
    });
    this.specialties.loadToList(this.specialtiesList);
  }

  setHomeSavingsDomain() {
    let host = location.host;
    this.homeSavingsDomain = 'https://' + host.replace('account.', 'savings.') + '/redir_auth?token=' + this.session.getToken();
  }

  ngAfterContentChecked() {
    this.setLogoImg();
  }

  setLogoImg() {
    let t = this.session.getCurrentTheme();
    if (t && t.id) {
      this.themeID = t.id;
    }
    let st = this.session.getCurrentSubTheme();
    if (st && st.logo_url) {
      this.logo_img_url = st.logo_url;
      return;
    }
    let subDomainLogo = this.getLogoUrlBySubdomain();
    if (subDomainLogo) {
      this.logo_img_url = subDomainLogo;
      return;
    }
    if (this.session.user && this.session.user.__theme && this.session.user.__theme.main_logo_url) {
      this.logo_img_url = this.session.user.__theme.main_logo_url;
    } else {
      if (this.session && this.session.user && this.session.user.theme_id === 2) {
        this.logo_img_url = '//ampurchasing.s3.amazonaws.com/img/amp-logo-white.png';
      } else {
        if (this.session && this.session.user && this.session.user.theme_id === 0) {
          this.logo_img_url = '//ascadvantage.s3.amazonaws.com/img/AT_logo_stacked_tag_white_rev21.06.png';
        } else {
          this.logo_img_url = '//purchaseclinic.s3.amazonaws.com/logo/pc-logo-white-50.png';
        }
      }
    }
  }

  getLogoUrlBySubdomain() {
    try {
      let domain = location.host;
      let names = domain.split('.');
      if (names.length > 2) {
        let subdomain = names[0];
        for (let subTheme of this.config.subThemesList) {
          if (subTheme.logo_url && subTheme.label == subdomain) {
            return subTheme.logo_url;
          }
        }
      }
    } catch (e) {
    }
    return null;
  }

  ngOnChanges() {
    this.setLogoImg();
  }

  noGpoWarning() {
    this.n.info(this.config.txt.noGpoWarning);
  }
}
