import {Component, Input, Output, EventEmitter} from '@angular/core';
declare var _:any;
declare var $:any;

@Component({
  selector: 'question-select',
  templateUrl: './q_select.html'
})
export class QuestionSelectCmp {
  @Input() question;
  @Output() update = new EventEmitter();
  @Input() textInput;
  public textInputTitle;
  public showTextInput = false;
  public selected_value;

  emitUpdate($event) {
    let id = parseInt($event);
    this.selected_value = id;
    let option = _.find(this.question.__options, {id: id});
    if (option && option.is_text_input) {
      this.showTextInput = true;
      if (option.hint) {
        this.textInputTitle = option.hint;
      } else {
        this.textInputTitle = option.title;
      }
    } else {
      this.showTextInput = false;
    }
    this.update.emit([{id: id, user_input: this.textInput}]);
  }

}
