import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ICustomSettings} from '../interfaces/ICustomSettings';
import {Observable, Subscription} from "rxjs";
import {IUser} from "../interfaces/IUser";
import {ILocation} from "../interfaces/ILocation";

@Injectable()
export class LocationCustomSettings {
  constructor(private config:Config, private http:HttpClient, private session:Session) {
  }

  callZapierNonMedNewContact(category:string, answers:any, user:IUser, contact_name:string, contact_email:string, location:ILocation):void {
    let url = '';
    switch (category) {
      case 'shipping':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4u8yzn/';
        break;
      case 'office':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4u8oqn/';
        break;
      case 'utility':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4u8upu/';
        break;
    }
    if (!url) {
      return;
    }
    if (!answers) {
      answers = {};
    }

    answers.contact_name = contact_name;
    answers.contact_email = contact_email;

    if (user) {
      answers.user_id = user.id;
      answers.user_fname = user.fname;
      answers.user_lname = user.lname;
      answers.user_email = user.email;
      if (user.__theme) {
        answers.theme_id = user.__theme.id;
        answers.theme_name = user.__theme.name;
      }
    }
    if (location) {
      answers.location_id = location.id;
      answers.facility_name = location.facility_name;
      answers.pcid = location.pc_id;
      if (location.__manager_fname || location.__manager_lname) {
        answers.location_cm_name = location.__manager_fname + ' ' + location.__manager_lname;
      }
      answers.location_cm_email = location.__manager_email;
      answers.location_cm_phone = location.__manager_phone;
      answers.from_email = location.__manager_email;
      if (answers.theme_id > 1 && user.__theme && user.__theme.email_from_address) {
        answers.from_email = user.__theme.email_from_address;
      }
    }

    this.http.post(url, JSON.stringify(answers)).subscribe(()=> {}, ()=> {});
  }

  getSettings(id):Observable<ICustomSettings> {
    return this.http.get<ICustomSettings>(this.config.apiUrl + `/location/${id}/custom_settings`);
  }

  setSettings(settings:ICustomSettings) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/location/custom_settings',
      JSON.stringify(settings), {headers: headers, responseType: 'text'});
  }

  getCompletePercentage(settings:ICustomSettings, themeID:number) {
    let p = 0;
    let d = 25;
    if (themeID == this.config.mrnThemeID) {
      d = 33.3;
    }
    if (settings.is_shipping_page_filled) {
      p += d;
    }
    if (settings.is_office_supplies_page_filled && themeID != this.config.mrnThemeID) {
      p += d;
    }
    if (settings.is_cellphones_page_filled) {
      p += d;
    }
    if (settings.is_car_rental_page_filled) {
      p += d;
    }
    // if (settings.is_utility_bills_page_filled) {
    //   p += d;
    // }
    return Math.round(p);
  }

  callZapierNonMedAnswers(page:string, answers, user:IUser, location:ILocation):void {
    let url;
    switch (page) {
      case 'wireless':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4b5vdg/';
        break;
      case 'shipping':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4b5nn2/';
        break;
      case 'office':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4b5jg2/';
        break;
      case 'utility':
        url = 'https://hooks.zapier.com/hooks/catch/266709/4brbw8/';
        break;
    }
    if (!answers) {
      answers = {};
    }
    if (user) {
      answers.user_fname = user.fname;
      answers.user_lname = user.lname;
      answers.user_email = user.email;
      if (user.__theme) {
        answers.theme_id = user.__theme.id;
        answers.theme_name = user.__theme.name;
      }
    }
    if (location) {
      answers.location_id = location.id;
      answers.location_facility_name = location.facility_name;
      answers.location_pcid = location.pc_id;
      if (location.__manager_fname || location.__manager_lname) {
        answers.location_cm_name = location.__manager_fname + ' ' + location.__manager_lname;
      }
    }
    this.http.post(url, JSON.stringify(answers)).subscribe(()=> {}, (err)=> {
      console.error(err);
    });
  }
}
