import {Component, Input, Output, EventEmitter, ElementRef, ViewChild} from '@angular/core';
import {Session} from "../../services/Session";
import {EditRequests} from "../../services/EditRequests";
import {ILocation} from "../../interfaces/ILocation";
import {Cots} from "../../services/Cots";
import {ICot} from "../../interfaces/ICot";
import {IDistributor} from "../../interfaces/IDistributor";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {Specialties} from "../../services/Specialties";
import {Distributors} from "../../services/Distributors";
import {Notify} from "../../services/Notify";
import {Locations} from "../../services/Locations";
import {IEditRequest} from "../../interfaces/IEditRequest";
import {FDTickets} from "../../services/FDTickets";
import {ICotInternal} from "../../interfaces/ICotInternal";
declare var $:any;

@Component({
  selector: 'account-locations',
  templateUrl: './account-locations.html',
})
export class AccountLocationsCmp {
  @Input() locs:ILocation[];
  @Input() cmUserId:number = 0;
  @Output() locsChange = new EventEmitter();
  @ViewChild('accLocEdit') modal:ElementRef;
  @ViewChild('accLocRemove') modalRemove:ElementRef;
  public editLoc:ILocation = <ILocation>{};
  public saving = false;
  public cotsList:ICot[] = [];
  public cotsInternalMap:{[id: number]: ICotInternal;} = {};
  public distributorsList:IDistributor[] = [];
  public specialtiesList:ISpecialty[] = [];
  private notify:Notify;
  private originalLoc:ILocation;
  public removeLocID = 0;
  public removalReason = '';

  constructor(private session:Session, private editReqSrv:EditRequests, public cots:Cots,
    public distributors:Distributors, public specialties:Specialties, private locSrv:Locations,
    private fdTickets:FDTickets) {
    this.cots.loadToList(this.cotsList);
    this.cots.loadInternalToMap(this.cotsInternalMap);
    this.distributors.loadToList(this.distributorsList);
    this.specialties.loadToList(this.specialtiesList);
    this.notify = new Notify();
    this.session.redirectIfNotAuth();
  }

  setEditLoc(loc:ILocation) {
    this.editLoc = $.extend(true, {}, loc);
    this.originalLoc = $.extend(true, {}, loc);
  }

  newLocation() {
    if (this.session && this.session.user) {
      this.editLoc = <ILocation>{};
      this.editLoc.primary_contact_user_id = this.session.user.id;
      this.editLoc.theme_id = this.session.user.theme_id;
      let mainLoc:ILocation;
      if (this.session.user.location_id && this.locs && this.locs.length > 0) {
        this.locs.forEach((loc)=> {
          if (loc.id == this.session.user.location_id) {
            mainLoc = loc;
          }
        });
      }
      if (mainLoc) {
        this.editLoc.location_group_id = mainLoc.location_group_id;
        this.editLoc.__rep_user_id = mainLoc.__rep_user_id;
        this.editLoc.__rep_secondary_id = mainLoc.__rep_secondary_id;
        this.editLoc.__rep_pharma_id = mainLoc.__rep_pharma_id;
        this.editLoc.class_of_trade_internal_primary_id = mainLoc.class_of_trade_internal_primary_id;
        this.editLoc.class_of_trade_internal_sub_id = mainLoc.class_of_trade_internal_sub_id;
      }
    }
  }

  save() {
    if (this.editLoc) {
      if (!this.editLoc.facility_name) {
        this.notify.error('Facility Name is required field');
        return false;
      }
      if (this.editLoc.id) {
        if (!this.originalLoc) {
          console.error('No location to compare with');
          return false;
        }
        this.saving = true;
        this.editReqSrv.saveRecordEdits(this.editLoc, this.originalLoc, 'location', 'id', this.cmUserId).subscribe(()=> {
          this.saving = false;
          if (this.locs && this.locs.length) {
            this.locs.forEach((loc, i)=> {
              if (loc.id === this.editLoc.id) {
                this.locs[i] = this.editLoc;
              }
            });
          }
          this.modalHide();
          this.notify.success('Edit request has been sent');
          this.sendUpdTicket();
        }, (err)=> {
          this.saving = false;
          this.notify.error('Error');
          console.error(err);
        });
      } else {
        this.saving = true;
        this.locSrv.updateLocation(this.editLoc).then((id)=> {
          this.editLoc.id = id;
          if (!this.locs) {
            this.locs = [];
          }
          this.locs.push($.extend(true, {}, this.editLoc));
          let req = <IEditRequest>{is_new_record: true};
          req.from_user_id = this.session.user.id;
          req.cm_user_id = this.cmUserId;
          req.edit_table = 'location';
          req.record_id_field = 'id';
          req.record_id_value = id.toString();
          this.saving = false;
          this.modalHide();
          this.notify.success('Successfully!');
          this.sendNewLocTicket();
        }, (err)=> {
          this.saving = false;
          this.notify.error('Error');
          console.error(err);
        });
      }
    }
  }

  sendNewLocTicket() {
    try {
      let subj = '[Account Page] New Location';
      let u = this.session.user;
      let loc = this.editLoc;
      let body = `<div>User <a target="_blank" href="https://crm.purchase.guru/user/${u.id}">${u.fname} ${u.lname}</a> (${u.email})</div>
          created new facility: <a target="_blank" href="https://crm.purchase.guru/location/${loc.id}">${loc.facility_name}</a></div>`;
      let customFields = {pc_id: loc.pc_id};
      if (u.__theme && u.__theme.name) {
        customFields['brand'] = u.__theme.name;
      }
      this.fdTickets.create(u.email, subj, body, customFields).subscribe(()=> {}, () => {});
    } catch (e) {}
  }

  sendUpdTicket() {
    try {
      let subj = '[Account Page] Location Edit Request';
      let u = this.session.user;
      let loc = this.editLoc;
      let body = `<div>User <a target="_blank" href="https://crm.purchase.guru/user/${u.id}">${u.fname} ${u.lname}</a> (${u.email})</div>
          wants to edit facility <a target="_blank" href="https://crm.purchase.guru/location/${loc.id}">${loc.facility_name}</a> (${loc.pc_id})</div>
          <div><a href="https://crm.purchase.guru/edit_requests">Review</a></div>`;
      let customFields = {pc_id: loc.pc_id};
      if (u.__theme && u.__theme.name) {
        customFields['brand'] = u.__theme.name;
      }
      this.fdTickets.create(u.email, subj, body, customFields).subscribe(()=> {}, () => {});
    } catch (e) {}
  }

  remove() {
    if (!this.removeLocID || !this.locs || !this.locs.length) {
      return;
    }
    let loc;
    this.locs.every((l)=> {
      if (l.id == this.removeLocID) {
        loc = l;
        return false;
      }
      return true;
    });
    if (!loc || !loc.id) {
      return false;
    }
    let n = new Notify();
    if (!this.removalReason) {
      n.error('Reason field is required');
      return;
    }
    this.saving = true;
    let subj = 'Location Removal Request';
    let u = this.session.user;
    let body = `<div>User <a target="_blank" href="https://crm.purchase.guru/user/${u.id}">${u.fname} ${u.lname}</a> (${u.email})
        wants to remove facility <a target="_blank" href="https://crm.purchase.guru/location/${loc.id}">${loc.facility_name}</a> (${loc.pc_id})</div>
        <div><strong>Reason:</strong>&nbsp;${this.removalReason}</div>`;
    let customFields = {pc_id: loc.pc_id};
    if (u.__theme && u.__theme.name) {
      customFields['brand'] = u.__theme.name;
    }
    this.fdTickets.create(u.email, subj, body, customFields).subscribe(()=> {
      this.saving = false;
      n.success('Request has been sent');
      this.modalRemoveHide();
    }, (err) => {
      this.saving = false;
      console.error(err);
      n.error(JSON.stringify(err), 'Server error');
    });
  }

  modalHide() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('hide');
    }
  }

  modalShow() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('show');
    }
  }

  modalRemoveHide() {
    if (this.modalRemove && this.modalRemove.nativeElement) {
      $(this.modalRemove.nativeElement).modal('hide');
    }
  }

  modalRemoveShow() {
    if (this.modalRemove && this.modalRemove.nativeElement) {
      $(this.modalRemove.nativeElement).modal('show');
    }
  }
}
