import {Component, OnInit} from '@angular/core';
import {Session} from "../../services/Session";
import {interval} from 'rxjs'
import { take } from 'rxjs/operators';

@Component({
  selector: 'nav-footer',
  templateUrl: './footer.html',
})
export class FooterCmp implements OnInit{
  public year = 2024;
  public themeID = 0;

  constructor(public session:Session) {
    let d = new Date();
    this.year = d.getFullYear();
  }
  ngOnInit(){
    this.session.getThemeReloaded().subscribe(res => {
      if(res) {
        let t = this.session.getCurrentTheme();
        if (t && t.id && this.themeID !== t.id) {
          this.themeID = t.id;
        }
      }
    })
    interval(50).pipe(take(15)).subscribe(val => {
      let t = this.session.getCurrentTheme();
      if (t && t.id && this.themeID !== t.id) {
        this.themeID = t.id;
      }
    })
  }
}
