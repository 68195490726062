import {Pipe} from '@angular/core';

// http://stackoverflow.com/a/2901298/680786
function numberWithCommas(x) {
  if (!x) {
    return 0;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
@Pipe({
  name: 'numcomm'
})
export class NumbersCommaSeparatePipe {
  transform(list, args:string[]):any {
    return numberWithCommas(list);
  }
}
