import {Pipe, PipeTransform} from '@angular/core';
import {IFeaturedContract} from '../../interfaces/IFeaturedContract';
import {IFeaturedCategory} from '../../interfaces/IFeaturedCategory';

@Pipe({
  name: 'category',
  pure: false
})
export class CategoryPipe implements PipeTransform {
  transform(contracts:IFeaturedContract[], args:IFeaturedCategory):any {
    return contracts.filter((contract) => {
      return (contract && args && contract.slug === args.slug);
    });
  }
}
