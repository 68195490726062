<table class="table table-sm table-hover">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th class="text-center">Primary Contact</th>
      <th class="text-center">Send the newsletter</th>
      <th></th>
      <th width="10"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of contacts" [hidden]="contact.is_not_usable_as_contact">
      <td>{{contact.fname}} {{contact.lname}}</td>
      <td>{{contact.email}}</td>
      <td>{{contact.phone}}</td>
      <td class="text-center"><span *ngIf="contact.id==location.primary_contact_user_id"><i class="material-icons color-success-bright">check_circle</i></span></td>
      <td class="text-center"><span *ngIf="contact.send_newsletter || (location && contact.id==location.primary_contact_user_id)"><i class="material-icons color-success-bright">check_circle</i></span></td>
      <td class="text-center">
        <button type="button" (click)="setEditContact(contact)" class="btn btn-sm btn-outline-primary">Edit</button>
      </td>
      <td class="text-center">
        <span *ngIf="contact.id!=user.id" (click)="removeContact(contact)" [ngClass]="{'color-danger pointer':!contact.__removing,'color-disabled cursor-wait':contact.__removing}"><i class="material-icons material-icons-in-link">remove_circle_outline</i></span>
      </td>
    </tr>
  </tbody>
</table>
<div class="row form-group">
  <div class="col-sm-4">
    <button type="button" class="btn btn-outline-primary btn-sm btn-block"
      data-toggle="modal" data-target="#changeAccPrimaryContactModal">Request change of Primary Contact
    </button>
  </div>
  <div class="col-sm-2 offset-sm-6">
    <button type="button" class="btn btn-primary btn-sm btn-block" (click)="newContact()">Add New Contact</button>
  </div>
</div>
<p>
  All change requests are reviewed by our client managers.
</p>
<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Edit Contact" aria-hidden="true" #accContactEdit>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="editContact && editContact.id">Edit details of {{editContact.fname}} {{editContact.lname}} record</h5>
        <h5 class="modal-title" *ngIf="!editContact.id">New Contact</h5>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body no-pad-right">
        <div class="container no-pad-right">
          <div class="form-group row">
            <label class="col-form-label col-md-3 text-right">First Name</label>
            <div class="col-md-9">
              <input type="text" [(ngModel)]="editContact.fname" placeholder="First Name" class="form-control"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3 text-right">Last Name</label>
            <div class="col-md-9">
              <input type="text" [(ngModel)]="editContact.lname" placeholder="Last Name" class="form-control"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3 text-right">Phone</label>
            <div class="col-md-9"><input class="form-control" [(ngModel)]="editContact.phone" type="text" placeholder="Phone"/></div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3 text-right">Email</label>
            <div class="col-md-9"><input class="form-control" [(ngModel)]="editContact.email" type="text" placeholder="Email"/></div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3 text-right">Job Title</label>
            <div class="col-md-9"><input class="form-control" [(ngModel)]="editContact.job_title" type="text" placeholder="Job Title"/></div>
          </div>
          <div class="form-group row" *ngIf="location && location.id && editContact.id!=location.primary_contact_user_id">
            <div class="col-9 offset-3">
              <label class="col-form-label pointer">
                <input type="checkbox" [(ngModel)]="editContact.send_newsletter"/>
                &nbsp;&nbsp;Send the newsletter
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="changeAccPrimaryContactModal" tabindex="-1" role="dialog" aria-labelledby="Change Primary Contact" aria-hidden="true" #accPrimaryContactChange>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Which contact should be the new Primary Contact</h4>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="form-group row">
            <label class="col-form-label col-md-3">Contact</label>
            <div class="col-md-9">
              <select class="form-control" [(ngModel)]="newPrimaryContactID">
                <option *ngFor="let c of contacts" [attr.value]="c.id">{{c.fname}} {{c.lname}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="setNewPrimaryContact()" [disabled]="savingPrimaryContact">Submit</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="changeAccPurchasingContactModal" tabindex="-1" role="dialog" aria-labelledby="Change Other Contact" aria-hidden="true" #accPurchasingContactChange>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Which contact should be the new Other Contact</h4>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="form-group row">
            <label class="col-form-label col-md-3">Contact</label>
            <div class="col-md-9">
              <select class="form-control" [(ngModel)]="newPurchasingContactID">
                <option *ngFor="let c of contacts" value="{{c.id}}">{{c.fname}} {{c.lname}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="setNewPurchasingContact()" [disabled]="savingPurchasingContact">Submit</button>
      </div>
    </div>
  </div>
</div>
