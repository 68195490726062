import {Component} from '@angular/core';

@Component({
  selector: 'siim-thanks-page',
  template: `<div class="container">
      <div class="row">
        <div class="col-sm-10 col-sm-offset-1">
          <p>Thank you for completing the surgical instruments module.</p>
          <p>You will receive an email for your records which will also be available through your account filebox.</p>
        </div>
      </div>
    </div>`
})
export class SiimThanksCmp {

}
