import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from 'rxjs';

@Injectable()
export class ForgotPwd {
  constructor(private http:HttpClient, private session:Session, private config:Config) {}

  sendCode(email):Observable<any> {
    return this.http.put(this.config.apiUrl + '/forgot_pwd', email);
  }

  checkCode(code):Observable<any> {
    return this.http.get(this.config.apiUrl + '/forgot_pwd/check/' + code, {responseType: 'text'});
  }

  setNewPassword(code, password):Observable<any> {
    return this.http.put(this.config.apiUrl + '/forgot_pwd/set/' + code, password, {responseType: 'text'});
  }

}
