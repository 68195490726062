import {Component, Output,EventEmitter} from '@angular/core';
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {Session} from "../../services/Session";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {ICot} from "../../interfaces/ICot";

@Component({
  selector: 'spec-settings',
  templateUrl: './spec-settings.html',
})
export class SpecSettingsCmp {
  @Output() settingsSaved:EventEmitter<any> = new EventEmitter();
  public specialtiesMap:{[id:number]:ISpecialty;} = {};
  public specialtiesList:ISpecialty[] = [];
  public cotsMap:{[id:number]:ICot;} = {};
  public cotsList:ICot[] = [];
  public specialty_id:number = 0;
  public cot_id:number = 0;

  constructor(private specialties:Specialties, private cots:Cots, public session:Session) {
    this.specialties.loadToMap(this.specialtiesMap);
    this.specialties.loadToList(this.specialtiesList);
    this.cots.loadToMap(this.cotsMap);
    this.cots.loadToList(this.cotsList);
    if (this.session.user && this.session.user.specialty_id) {
      this.specialty_id = this.session.user.specialty_id;
    }
    if (this.session.user && this.session.user.class_of_trade_id) {
      this.cot_id = this.session.user.class_of_trade_id;
    }
  }

  save() {
    this.session.setCotID(this.cot_id);
    this.session.setSpecialtyID(this.specialty_id);
    this.settingsSaved.emit({
      specialty_id: this.specialty_id,
      cot_id: this.cot_id
    });
  }
}
