<section *ngIf="!welcomeScreenPassed">
  <div class="container">
    <h2 class=" text-center">Welcome to the 2019-2020 Flu Pre-book</h2>
    <h3 class="pc2-row-pad text-center">Confidentiality</h3>
    <p>On the next page you will find confidential flu vaccine pricing available to your facility as a member of this program. This pricing is provided under the confidentiality terms of your participation agreement. Please understand that sharing it with non-contracted distributors, other group purchasing or physician buying groups, or non-members jeopardizes all members' ability to access this pricing in the future. Thank you for helping us protect the market-leading pricing HealthTrust is making available to our membership.</p>
    <h3 class=" text-center">Steps</h3>
    <ol class="text-left">
      <li>Review this introduction carefully.</li>
      <li>Review pricing.</li>
      <li>Confirm your current medical supply distributor, if you are interested in pre-booking or have already pre-booked and wish to access your pricing so that we may inform them of your interest.</li>
      <li>Complete vaccine module.</li>
      <li>We will contact you with any questions after reviewing your documentation.</li>
      <li>You may contact us with questions at any time.</li>
      <li>Upon completion of these step, you will receive an email for your records which will also be available through your account filebox.</li>
    </ol>
    <h3 class=" text-center">FAQ</h3>
    <div>{{contactName}} exclusively utilizes the HealthTrust (HPG) contract portfolio, which includes market-leading pricing for both influenza and non-influenza vaccines. While you may already have access to HealthTrust's 2019-2020 flu pricing, or to one or more of the contracted manufacturers, you must complete the vaccine contract module to confirm access to the entire vaccine portfolio. If you do not intend to purchase flu vaccine, but do purchase non-influenza vaccine you should still complete the module to expedite your realignment from your prior group purchasing or physician buying group.<br/>
      <br/>
      If you have aligned with a different buying group within the last 60 days, most manufacturers will wait for 60 days to expire before updating your pricing. In this case, it may be necessary to resubmit your documentation.<br/>
      <br/>
      Upon completing the vaccine module, your electronic signature and profile information will be used to populate the documentation necessary to implement the HealthTrust program for your vaccine purchases. Submissions will not create a purchase obligation but will ensure that purchases access the HealthTrust portfolio. While you will maintain the right to inactivate your HealthTrust membership, please be aware that vaccine manufacturers do not allow customers to access more than one buying group at a time and are unlikely to change your buying group contract more than once in a 60 day period. Prior to completing the vaccine module, you will be reminded of this and asked to acknowledge your review of the disclosures, attached paperwork, and your consent for use of your electronic signature to ensure access and enrollment as a member of HealthTrust.<br/>
      <br/>
      We will contact you with any questions after reviewing this documentation and will provide training or updates on the HealthTrust vaccine portfolio as needed.<br/>
      Upon completion of the vaccine module, you will receive and email for your records.
    </div>
    <h3 class="pc2-row-pad text-center">Contact</h3>
    <div>Please contact us with any questions:</div>
    <div><a href="mailto:{{contactEmail}}">{{contactEmail}}</a> {{contactPhone}}</div>
    <br/>
    <div class=" text-center">
      <button class="btn btn-primary" type="button" (click)="skipWelcomePage()">Continue to Review Confidential Vaccine Pricing</button>
    </div>
  </div>
</section>
<section *ngIf="welcomeScreenPassed">
  <div class="container">
    <h4 class="text-center">Please see the confidential flu vaccine pricing available to your facility</h4>
    <table class="table table-vaccines table-sm pc2-row-pad font-sm table-striped">
      <tr>
        <th width="130px">Supplier</th>
        <th width="130px">NDC</th>
        <th>Item</th>
        <th>Description</th>
        <th width="150px">Contract Price without Federal Excise Tax (FET)</th>
        <th width="150px">Contract Price with Federal Excise Tax (FET)</th>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0405-65</td>
        <td>Fluzone High Dose PFS</td>
        <td>0.5mL syringes, no needles, 10 per box</td>
        <td class="text-center">US$428.44</td>
        <td class="text-center">US$435.94</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0631-15</td>
        <td>Fluzone Quadrivalent MDV</td>
        <td>5mL multi-dose vial, 10-doses</td>
        <td class="text-center">US$139.67</td>
        <td class="text-center">US$147.17</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0419-10</td>
        <td>Fluzone Quadrivalent SDV</td>
        <td>0.5mL single dose vials, 10 per box</td>
        <td class="text-center">US$149.67</td>
        <td class="text-center">US$157.17</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0419-50</td>
        <td>Fluzone Quadrivalent PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">US$149.67</td>
        <td class="text-center">US$157.17</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0519-25</td>
        <td>Fluzone Quadrivalent Pediatric PFS</td>
        <td>0.25mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">US$149.67</td>
        <td class="text-center">US$157.17</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0719-10</td>
        <td>Flublok Quadrivalent Egg-Free PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">US$428.44</td>
        <td class="text-center">US$435.94</td>
      </tr>
      <tr>
        <th width="130px">Supplier</th>
        <th width="130px">NDC</th>
        <th>Item</th>
        <th>Description</th>
        <th width="150px">Contract Price without Federal Excise Tax (FET)</th>
        <th width="150px">Contract Price with Federal Excise Tax (FET)</th>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>70461-0019-03</td>
        <td>Fluad Trivalent Adjuvanted PFS</td>
        <td>0.5mL pre-filled syringe, no needles, 10 per box</td>
        <td class="text-center">US$380.76</td>
        <td class="text-center">US$388.26</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>70461-0319-03</td>
        <td>Flucelvax Quadrivalent PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">US$160.99</td>
        <td class="text-center">US$168.49</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>70461-0419-10</td>
        <td>Flucelvax Quadrivalent MDV</td>
        <td>5mL multi-dose vial, 10-doses</td>
        <td class="text-center">US$152.30</td>
        <td class="text-center">US$159.80</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>33332-0219-20</td>
        <td>Afluria Quadrivalent Pediatric PFS</td>
        <td>0.25mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">US$136.61</td>
        <td class="text-center">US$144.11</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>33332-0319-01</td>
        <td>Afluria Quadrivalent PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">US$136.61</td>
        <td class="text-center">US$144.11</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>33332-0419-10</td>
        <td>Afluria Quadrivalent MDV</td>
        <td>5mL multi-dose vial, 10-doses</td>
        <td class="text-center">US$127.54</td>
        <td class="text-center">US$135.04</td>
      </tr>
    </table>
    <h5 class="text-center">If you are interested in pre-booking or have already pre-booked and wish to access your pricing, please confirm your current distributor below so that we may inform them of your interest.</h5>
  </div>
  <div class="container">
    <section *ngIf="currentDistrName">
      <div class="row pc2-row-pad-big">
        <div class="col-12 text-center">
          <div class="h3 pc2-color-y">Are you still using <span class="pc2-color-x">{{currentDistrName}}</span> as primary distributor?</div>
        </div>
      </div>
      <div class="row pc2-row-pad">
        <div class="col-sm-3 offset-sm-3">
          <button class="btn btn-block btn-outline-secondary" (click)="stillUse(1)">Yes</button>
        </div>
        <div class="col-sm-3">
          <button class="btn btn-block btn-outline-secondary" (click)="stillUse(0)">No</button>
        </div>
      </div>
    </section>
    <div class="row pc2-row-pad-big" *ngIf="displayDistrs">
      <div class="col-12 text-center">
        <div class="h4 pc2-color-y">Who is your primary distributor?</div>
      </div>
    </div>
    <div class="row" *ngIf="displayDistrs">
      <div class="col-sm-4 offset-sm-4">
        <select [(ngModel)]="newDistrID" class="form-control" [ngModelOptions]="{standalone: true}">
          <option value="0" disabled>Please select</option>
          <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
          <option value="{{otherDistrID}}">Other</option>
        </select>
      </div>
    </div>
    <div class="row pc2-row-pad-sub-input" *ngIf="displayDistrs && newDistrID == otherDistrID">
      <div class="col-sm-4 offset-sm-4">
        <input type="text" class="form-control" [(ngModel)]="otherDistr" placeholder="Primary Distributor"/>
      </div>
    </div>
    <div class="row pc2-row-pad-big" *ngIf="displayDistrs || displayContinue">
      <div class="col-sm-8 offset-sm-2 text-center">
        Please click the continue button below to complete the vaccine module, to provide needed information. Once completed we will work with your distributor representative to ensure you are connected.
      </div>
    </div>
    <div class="row pc2-row-pad" *ngIf="displayDistrs || displayContinue">
      <div class="col-sm-4 offset-sm-4">
        <button class="btn btn-block btn-primary" (click)="continue()">Continue to Vaccine Module</button>
      </div>
    </div>
  </div>
</section>
