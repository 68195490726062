<table class="table table-sm table-hover font-sm">
  <thead>
    <tr>
      <th>GPO ID</th>
      <th>Vendor</th>
      <th>Contract / Description</th>
      <th>Sales</th>
      <th>Rebate</th>
      <th>Reported Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let s of rebates">
      <td>{{s.gpoid}}</td>
      <td>{{s.vendor}}</td>
      <td>{{s.contract_number}} {{s.contract_description}}</td>
      <td class="text-right"><span *ngIf="s.sales_amount">$&nbsp;</span>{{s.sales_amount|numcomm}}</td>
      <td class="text-right"><span *ngIf="s.fee_amount">$&nbsp;</span>{{s.fee_amount|numcomm}}</td>
      <td class="text-right">{{s.allocation_cycle_month}}</td>
    </tr>
  </tbody>
</table>
<div class="col-12" *ngIf="rebatesCSV">
  <a target="_blank" class="btn btn-primary btn-sm float-right" href="{{rebatesCSV}}">Download as CSV</a>
</div>
