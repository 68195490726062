import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ICot} from '../interfaces/ICot';
import {ICotInternal} from "../interfaces/ICotInternal";

@Injectable()
export class Cots {
  private config:Config;
  private session:Session;
  private http:HttpClient;
  private list:ICot[] = [];
  private loadingPromise;
  private listInternal:ICotInternal[] = [];
  private loadingPromiseInternal;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  load() {
    if (this.list && this.list.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.list);
      });
    }
    if (this.loadingPromise) {
      return this.loadingPromise;
    }
    this.loadingPromise = new Promise((resolve, reject)=> {
      this.http.get<ICot[]>(this.config.apiUrl + '/cot').subscribe((objects)=> {
        this.loadingPromise = null;
        if (objects instanceof Array) {
          this.list = objects;
          resolve(this.list);
        } else {
          reject();
        }
      }, ()=> {
        this.loadingPromise = null;
        reject();
      });
    });
    return this.loadingPromise;
  }

  loadToMap(map:{[id:number]:ICot;}) {
    this.load().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          map[s.id] = s;
        });
      }
    }, ()=> {});
  }

  loadToList(list:ICot[]) {
    this.load().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          list.push(s);
        });
      }
    }, ()=> {});
  }

  loadInternal() {
    if (this.listInternal && this.listInternal.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.listInternal);
      });
    }
    if (this.loadingPromiseInternal) {
      return this.loadingPromiseInternal;
    }
    this.loadingPromiseInternal = new Promise((resolve, reject)=> {
      this.http.get<ICotInternal[]>(this.config.apiUrl + '/cot_internal').subscribe((objects)=> {
        this.loadingPromiseInternal = null;
        if (objects instanceof Array) {
          this.listInternal = objects;
          resolve(this.listInternal);
        } else {
          reject();
        }
      }, ()=> {
        this.loadingPromiseInternal = null;
        reject();
      });
    });
    return this.loadingPromiseInternal;
  }

  loadInternalToMap(map:{[id:number]:ICotInternal;}, filter?) {
    this.loadInternal().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          if (filter && !filter(s)) {
            return;
          }
          map[s.id] = s;
        });
      }
    }, ()=> {});
  }

  loadInternalToList(list:[ICotInternal], filter?) {
    this.loadInternal().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          if (filter && !filter(s)) {
            return;
          }
          list.push(s);
        });
      }
    }, ()=> {});
  }
}
