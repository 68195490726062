import { Injectable } from "@angular/core";
@Injectable()
export class ItemsRange {
  getFromRange(list:any[], offset:number, count:number):any {
    if (!list || !(list.length > 0) || !list[offset] || !list[(offset + count - 1)]) {
      return false;
    }
    var r = list.slice(offset, count + offset);
    if (r.length !== count) {
      return false;
    }
    return r;
  }

  addToList(items:any[], list:number, offset:number) {
    let i = 0;
    items.forEach((item)=> {
      list[(i + offset)] = item;
      ++i;
    });
  }

  getFetchLimit(total:number, page:number, itemsLimit:number) {
    let count = itemsLimit;
    if (total && (page * itemsLimit > total)) {
      count = total - Math.max(page - 1, 0) * itemsLimit;
    }
    return count;
  }

  getFetchOffset(page:number, itemsLimit:number) {
    return Math.max(page - 1, 0) * itemsLimit;
  }

  getPreloadOffset(page:number, itemsLimit:number, pages:number = 3) {
    return Math.max(page - pages, 0) * itemsLimit;
  }

  getPreloadLimit(page:number, total:number, itemsLimit:number, pages:number = 3) {
    let offset = Math.max(page - pages, 0) * itemsLimit;
    let preloadCount = itemsLimit * (pages * 2);
    if ((preloadCount + offset) > total) {
      preloadCount = total - offset;
    }
    return preloadCount;
  }
}
