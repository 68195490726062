import {Component, Input, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'unsafe-text',
  template: `<div #target></div>`
})
export class UnsafeTextCmp {
  @Input() text;
  @ViewChild('target') target:ElementRef;

  ngAfterViewInit() {
    this.bind();
  }

  ngOnChanges() {
    this.bind();
  }

  bind() {
    try {
      if (this.text && this.target && this.target.nativeElement) {
        this.target.nativeElement.innerHTML = this.text;
      }
    } catch (e) {}
  }
}
