import {Component, Input} from '@angular/core';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Locations} from "../../services/Locations";
import {ILocGroupSales} from "../../interfaces/ILocGroupSales";

@Component({
  selector: 'account-rebates',
  templateUrl: './account-rebates.html',
})
export class AccountRebatesCmp {
  @Input() locGroupID:number;
  public rebatesCSV:string;
  public rebates:ILocGroupSales[];

  constructor(private config:Config, private session:Session, private locSrv:Locations) {
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (this.locGroupID) {
      this.rebatesCSV = this.config.apiUrl + `/report/location_group_rebates/csv/${this.session.getToken()}/${this.locGroupID}`;
      this.locSrv.getRebatesOfGroup(this.locGroupID).subscribe((rebates)=> {
        this.rebates = rebates;
      }, ()=> {});
    }
  }
}
