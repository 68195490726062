<div class="container">
  <div class="thin-top">
    <div class="row jc-center">
      <div class="col-sm-9 col-md-8">
        <div class="bordered">
          <div class="form-section">
            <h3 class="headline text-center">Save on your Energy Bill with EnergyTrust</h3>
            <h4 class="text-center mb20">Whether you Rent, Lease or Own</h4>
            <p>Independent Physician Offices and Surgery Centers are interested in anything that helps cut operating costs so they can remain independent. EnergyTrust not only can cut utility costs, but can also improve energy efficiency. EnergyTrust saves you money through leveraged buying of deregulated natural gas and electricity, while providing predictable costs to support financial planning.
              <strong>It doesn’t matter if you rent, lease or own.</strong> If you pay a commercial energy bill in one of the states listed below, you are eligible to save!</p>
            <p>To see how much you can save, sign up for a <strong>FREE</strong> energy assessment by completing the form below.</p>
            <p><i>*Please Note: This program is not available for residential energy bills at this time.</i></p>

            <form name="form" (ngSubmit)="save()" *ngIf="!newContactThanks">
              <div class="form-group custom select-group">
                <label class="col-form-label">State:</label>
                <select class="custom-form-control" [(ngModel)]="stateID" [ngModelOptions]="{standalone: true}">
                  <option value="0" disabled="" selected="">Select one</option>
                  <option value="1">Alabama</option>
                  <option value="2">Alaska</option>
                  <option value="3">Arizona</option>
                  <option value="4">Arkansas</option>
                  <option value="5">California</option>
                  <option value="6">Colorado</option>
                  <option value="7">Connecticut</option>
                  <option value="8">Delaware</option>
                  <option value="9">Florida</option>
                  <option value="10">Georgia</option>
                  <option value="11">Hawaii</option>
                  <option value="12">Idaho</option>
                  <option value="13">Illinois</option>
                  <option value="14">Indiana</option>
                  <option value="15">Iowa</option>
                  <option value="16">Kansa</option>
                  <option value="17">Kentucky</option>
                  <option value="18">Louisiana</option>
                  <option value="19">Maine</option>
                  <option value="20">Maryland</option>
                  <option value="21">Massachusetts</option>
                  <option value="22">Michigan</option>
                  <option value="23">Minnesota</option>
                  <option value="24">Mississippi</option>
                  <option value="25">Missouri</option>
                  <option value="26">Montana</option>
                  <option value="27">Nebraska</option>
                  <option value="28">Nevada</option>
                  <option value="29">New Hampshire</option>
                  <option value="30">New Jersey</option>
                  <option value="31">New Mexico</option>
                  <option value="32">New York</option>
                  <option value="33">North Carolina</option>
                  <option value="34">North Dakota</option>
                  <option value="35">Ohio</option>
                  <option value="36">Oklahoma</option>
                  <option value="37">Oregon</option>
                  <option value="38">Pennsylvania</option>
                  <option value="39">Rhode Island</option>
                  <option value="40">South Carolina</option>
                  <option value="41">South Dakota</option>
                  <option value="42">Tennessee</option>
                  <option value="43">Texas</option>
                  <option value="44">Utah</option>
                  <option value="45">Vermont</option>
                  <option value="46">Virginia</option>
                  <option value="47">Washington</option>
                  <option value="48">West Virginia</option>
                  <option value="49">Wisconsin</option>
                  <option value="50">Wyoming</option>
                </select>
                <span *ngIf="unsupportedStates.indexOf(stateID)>-1" class="text-danger">Apologies, this program is currently not available in regulated state energy markets.</span>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  <label class="col-form-label">Ownership type:</label>
                </div>
                <div class="col-sm-9">
                  <label class="checkbox-inline">
                    <input type="radio" name="ownership" value="rent" (change)="ownership = 1"/>
                    Rent
                  </label>
                  <label class="checkbox-inline">
                    <input type="radio" name="ownership" value="own" (change)="ownership = 2"/>
                    Own
                  </label>
                  <label class="checkbox-inline">
                    <input type="radio" name="ownership" value="lease" (change)="ownership = 3"/>
                    Lease
                  </label>
                </div>
              </div>

              <div class="form-group custom" *ngIf="ownership==3">
                <label class="col-form-label">Lease Expire:</label>
                <div class="input-group date datetimepicker">
                  <date-picker cssClass="form-control" (changeDate)="leaseExpireDate = $event" [show]="showLeaseExpireDate"></date-picker>
                  <span class="input-group-append" (click)="showLeaseExpireDate.emit(true)"><i class="input-group-text material-icons" style="font-size: 19px;">date_range</i></span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label">Type of Energy:</label>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #cGas value="Natural gas" (change)="toggleEnergy(cGas)"/>
                    Natural gas
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #cFuel value="Liquid Fuel" (change)="toggleEnergy(cFuel)"/>
                    Liquid Fuel
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #cElectr value="Electricity" (change)="toggleEnergy(cElectr)"/>
                    Electricity
                  </label>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-12">
                  <label class="col-form-label">Do you currently procure energy from a third party supplier? <br>(For example: Do you buy your energy from one company, but another company delivers the energy?)</label>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-12">
                  <label class="checkbox-inline">
                    <input type="radio" name="procure" value="2" (change)="procure = 2"/>
                    I don’t know
                  </label>
                  <label class="checkbox-inline">
                    <input type="radio" name="procure" value="1" (change)="procure = 1"/>
                    Yes
                  </label>
                  <label class="checkbox-inline">
                    <input type="radio" name="procure" value="0" (change)="procure = 0"/>
                    No
                  </label>
                </div>
              </div>

              <div class="form-group custom" *ngIf="procure == 1">
                <label class="col-form-label">Procure energy contract expire:</label>
                <div class="input-group date datetimepicker">
                  <date-picker cssClass="form-control" (changeDate)="procureExpireDate = $event" [show]="showProcureExpireDate"></date-picker>
                  <span class="input-group-append" (click)="showProcureExpireDate.emit(true)"><i class="input-group-text material-icons" style="font-size: 19px;">date_range</i></span>
                </div>
              </div>

              <div class="form-group custom file-upload-holder">
                <label class="col-form-label upload-headline">Upload last month’s utility bill here:</label>
                <div>
                  <small>If you have multiple locations, please upload a separate file for each location.</small>
                </div>
                <div class="upload-block">
                  <div class="file-upload">
                    <button file-upload (filesChange)="addFile($event)" [multiple]="true"
                      class="btn btn-primary btn-primary-action" type="button">select
                    </button>
                    <div class="filenames-list" *ngFor="let f of filesToUpload">{{f.name}}</div>
                  </div>
                </div>
              </div>

              <div class="form-group custom">
                <p>If you’re not the right person to fill out this information, please enter the correct contact’s name and e-mail address in the fields below:</p>
                <label class="col-form-label">New Contact Name:</label>
                <input type="text" class="field text medium" [(ngModel)]="officeContactUser.name" [ngModelOptions]="{standalone: true}"/>
              </div>

              <div class="form-group custom">
                <label class="col-form-label">New Contact E-mail:</label>
                <input type="text" class="field text medium" [(ngModel)]="officeContactUser.email" [ngModelOptions]="{standalone: true}"/>
              </div>

              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #disclaim (change)="disclaimed = disclaim.checked"/>
                    Thank you, but I'm not interested in taking advantage of this money-saving contract at this time.
                  </label>
                </div>
              </div>

              <div class="form-group text-end mt2">
                <button type="submit" class="btn btn-primary-action btn-primary" [disabled]="saving">Submit</button>
              </div>
            </form>
            <div *ngIf="newContactThanks">
              <p>Thank you for providing the contact information for {{officeContactUser.name}}. We have sent an e-mail to {{officeContactUser.email}} with instructions for completing this portion of the setup process.</p>
              <div class="pc2-row-pad text-center">
                <a class="btn btn-primary" routerLink="/non_med_savings">Go to main Non Medical Savings Page</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
