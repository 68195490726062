import {Component, Input, Output, EventEmitter} from '@angular/core';
declare var $:any;

@Component({
  selector: 'question-radio',
  templateUrl: './q_radio.html'
})
export class QuestionRadioCmp {
  @Input() question;
  @Input() textInput = '';
  @Input() selected_value;
  @Output() update = new EventEmitter();

  emitUpdate(option) {
    this.update.emit([{id: option.id, user_input: this.textInput}]);
  }

  ngAfterViewInit() {
    $('input[type="radio"]').attr('checked', false);
  }
}
