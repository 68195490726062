<section class="main-content msar-layout">

  <div class="container">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-8 mxw-100">
        <div class="bordered-container">
          <h1 class="headline">Savings Opportunity and Market Report</h1>

          <div class="bordered-content clearfix">
            <div class="body-form">
              <h2 class="subtitle">{{pages[pageIndex]?.title}}</h2>
              <p *ngIf="pages[pageIndex]?.description" class="subtitle-note">{{pages[pageIndex]?.description}}</p>

              <div class="question-holder" *ngIf="pageIndex == 0">
                <h4 class="header">Question 1</h4>
                Tell us a little bit about who you are. We want to know who we’re comparing you against.

                <div class="answer-holder">
                  <label class="col-form-label">Class of Trade</label>
                  <div class="custom-input-group select-group">
                    <select class="custom-form-control" [ngModel]="cot_id" (change)="cot_id = $event.target.value" [ngModelOptions]="{standalone: true}">
                      <option value="0" disabled>Select one</option>
                      <option value="27">Ambulatory Surgery Center (center)</option>
                      <option value="5">Clinic (practice)</option>
                    </select>
                  </div>
                </div>

                <div class="answer-holder">
                  <label class="col-form-label">Specialty</label>
                  <div class="custom-input-group select-group">
                    <select class="custom-form-control" [ngModel]="specialty_id" (change)="setSpecialty($event.target.value)" [ngModelOptions]="{standalone: true}">
                      <option value="0" disabled>Select one</option>
                      <option *ngFor="let spec of specialtiesList" value="{{spec.id}}">{{spec.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div [attr.data-hidden]="!cot_id" class="question-holder" *ngFor="let q of pages[pageIndex]?.__questions; let i = index">
                <h4 class="header">Question <span>{{((i-0)+prevQuestionsCount+1)}}</span></h4>
                <span>{{q.description}}</span>

                <div class="answer-holder">
                  <div class="row">
                    <section [ngSwitch]="sq.question_type_id" *ngFor="let sq of q?.__questions" [ngClass]="{'col-md-6':sq.question_type_id==2}" class="col-12">
                      <section *ngSwitchDefault>
                        <question-select [question]="sq" (update)="setAnswer($event, sq)"></question-select>
                      </section>
                      <section *ngSwitchCase="2">
                        <question-select-multiple [question]="sq" (update)="setAnswer($event, sq)"></question-select-multiple>
                      </section>
                      <section *ngSwitchCase="3">
                        <question-text [question]="sq" (update)="setAnswer($event, sq)"></question-text>
                      </section>
                      <section *ngSwitchCase="4">
                        <question-radio [question]="sq" (update)="setAnswer($event, sq)"></question-radio>
                      </section>
                    </section>
                  </div>
                </div>
              </div>

              <div class="form-action">
                <button (click)="nextPage()" class="btn btn-primary" [disabled]="!cot_id">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
