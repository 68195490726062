import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ISpecialty} from '../interfaces/ISpecialty';
import {Observable} from 'rxjs';
import {ITestimonialsSet} from "../interfaces/ITestimonialsSet";

@Injectable()
export class Testimonials {
  private config:Config;
  private session:Session;
  private http:HttpClient;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getTestimonials(code:string):Observable<ITestimonialsSet> {
    return this.http.get<ITestimonialsSet>(this.config.apiUrl + `/testimonials/${code}/json`);
  }
}
