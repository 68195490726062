import {Component, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'select-option',
  template: `<div class="sb-select-option" [ngClass]="{'active':active}" #node><ng-content></ng-content></div>`,
})
export class SelectOptionCmp {
  @Input() value;
  @Input() active;
  @Output() select = new EventEmitter();
  @ViewChild('node') node:ElementRef;
  public isDisabled = false;

  constructor(public element:ElementRef) {}

  ngAfterViewInit() {
    if (this.node && this.node.nativeElement) {
      if (this.isDisabled) {
        this.node.nativeElement.setAttribute('disabled', true);
      } else {
        this.node.nativeElement.removeAttribute('disabled');
      }
    }
  }

  ngOnChanges() {
    try {
      if (this.element && this.element.nativeElement) {
        if (this.element.nativeElement.hasAttributes()) {
          let attrs = this.element.nativeElement.attributes;
          for (let i = attrs.length - 1; i >= 0; i--) {
            if (attrs[i].name === 'disabled' && attrs[i].value !== false && attrs[i].value !== 'false' && attrs[i].value !== '0' && attrs[i].value !== 0) {
              this.isDisabled = true;
              return;
            }
          }
        }
      }
    } catch (e) {}
  }

  @HostListener('click')
  onClick() {
    if (this.isDisabled) {
      return;
    }
    this.select.emit(this.value);
  }
}
