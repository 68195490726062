import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from './Config';
import {Observable, Observer, of} from 'rxjs';
import {share} from 'rxjs/operators';
import {IFeaturedContractsSet} from '../interfaces/IFeaturedContractsSet';
import {IContract} from "../interfaces/IContract";
import {IContractItem} from "../interfaces/IContractItem";
import {IContractItemForSearch} from "../interfaces/IContractItemForSearch";
import {ISameVendorContract} from "../interfaces/ISameVendorContract";
import {Session} from "./Session";
declare var _;

@Injectable()
export class Contracts {
  private map = {};
  private loadingMap = {};
  private featuredMap = {};
  private loadingFeaturedMap = {};
  private searchMap = {};
  private searchItemsMap = {};

  constructor(private http:HttpClient, private config:Config, private session:Session) {}

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return headers;
  }

  loadAll():Observable<IContract[]> {
    if (this.map['all']) {
      return of(this.map['all']);
    }
    if (this.loadingMap['all']) {
      return this.loadingMap['all'];
    }
    this.loadingMap['all'] = this.http.get<IContract[]>(this.config.apiUrl + '/contract/page/0/50000', {headers: this.getHeaders()}).pipe(share());
    this.loadingMap['all'].subscribe((result) => {
      this.map['all'] = result;
      if (result && result.length) {
        result.forEach((c:IContract) => {
          this.map[c.contract_number] = c;
        });
      }
    }, () => {});
    return this.loadingMap['all'];
  }

  load(num):Observable<IContract> {
    if (this.map[num]) {
      return of(this.map[num]);
    }
    if (this.loadingMap[num]) {
      return this.loadingMap[num];
    }
    this.loadingMap[num] = this.http.get<IContract>(this.config.apiUrl + '/contract/' + num, {headers: this.getHeaders()}).pipe(share());
    this.loadingMap[num].subscribe((contract) => {
      this.map[num] = contract;
    }, () => {});
    return this.loadingMap[num];
  }

  getItems(contractNumber:number):Observable<IContractItem[]> {
    return this.http.get<IContractItem[]>(this.config.apiUrl + '/contract/' + contractNumber + '/items', {headers: this.getHeaders()});
  }

  getSameVendorContracts(contractNumber:number):Observable<ISameVendorContract[]> {
    return this.http.get<ISameVendorContract[]>(this.config.apiUrl + '/contract/' + contractNumber + '/same_vendor', {headers: this.getHeaders()});
  }

  findContracts(searchTerm, limit, offset:number = 0):Observable<IContract[]> {
    if (this.searchMap[searchTerm + offset + '/' + limit]) {
      return this.searchMap[searchTerm + offset + '/' + limit];
    }
    let query = this.http.get<IContract[]>(this.config.apiUrl + `/contract/${searchTerm}/find/${limit}/${offset}`, {headers: this.getHeaders()}).pipe(share());
    query.subscribe((r) => {
      this.searchMap[searchTerm + offset + '/' + limit] = of(r);
    }, () => {
      this.searchMap[searchTerm + offset + '/' + limit] = null;
    });
    return query;
  }

  findContractItems(searchTerm, limit, offset:number = 0):Observable<IContractItemForSearch[]> {
    searchTerm = searchTerm.toUpperCase();
    if (this.searchItemsMap[searchTerm + offset + '/' + limit]) {
      return this.searchItemsMap[searchTerm + offset + '/' + limit];
    }
    let query = this.http.get<IContractItemForSearch[]>(this.config.apiUrl + `/contract_item/find/${searchTerm}/${limit}/${offset}`, {headers: this.getHeaders()}).pipe(share());
    query.subscribe((r) => {
      this.searchItemsMap[searchTerm + offset + '/' + limit] = of(r);
    }, () => {
      this.searchItemsMap[searchTerm + offset + '/' + limit] = null;
    });
    return query;
  }

  listFeatured(specialtyId):Observable<IFeaturedContractsSet> {
    if (this.featuredMap[specialtyId]) {
      return of(this.featuredMap[specialtyId]);
    }
    if (this.loadingFeaturedMap[specialtyId]) {
      return this.loadingFeaturedMap[specialtyId];
    }
    let url = this.config.apiUrl + '/featured_contracts/' + specialtyId;
    this.loadingFeaturedMap[specialtyId] = this.http.get<IFeaturedContractsSet>(url, {headers: this.getHeaders()}).pipe(share());
    this.loadingFeaturedMap[specialtyId].subscribe((result) => {
      this.featuredMap[specialtyId] = result;
    }, () => {});
    return this.loadingFeaturedMap[specialtyId];
  }

  getSearchFiltered(searchTerm, size?:number, combine?:boolean):Observable<IContract[]> {
    if (!size) {
      size = 10;
    }
    if (!searchTerm) {
      return of([]);
    }
    return Observable.create((observer:Observer<any>) => {
      let filtered:IContract[] = [];
      this.findContracts(searchTerm, size).subscribe(
        (result) => {
          if (result && result.length > 0) {
            filtered = result;
          }
          let filtered2:IContract[] = [];
          let size2 = Math.ceil(size / 2);
          this.findContractItems(searchTerm, size2 * Math.max(result.length, size)).subscribe((items) => {
            if (items && items.length > 0) {
              items.every((c:IContractItemForSearch) => {
                let cc = new IContract();
                cc.contract_number = c.contract_number;
                cc.contract_description = c.description;
                cc.__item_number = c.item_number;
                cc.vendor_name = c.vendor_name;
                if (!combine) {
                  filtered2.push(cc);
                  if (filtered2.length >= size2) {
                    return false;
                  }
                } else {
                  let cfound = false;
                  if (filtered && filtered.length > 0) {
                    filtered.every((fc:IContract) => {
                      if (fc.contract_number == c.contract_number) {
                        cfound = true;
                        if (!fc.__found_items) {
                          fc.__found_items = [c];
                        } else {
                          if (fc.__found_items.length >= size2) {
                            return false;
                          }
                          fc.__found_items.push(c);
                        }
                        return false;
                      }
                      return true;
                    });
                  }
                  if (!cfound) {
                    cc.__found_items = [c];
                    filtered.push(cc);
                  }
                }
                return true;
              });
            }
            if (filtered2.length > 0) {
              let f1_len = Math.min(size - filtered2.length, size);
              filtered = filtered.slice(0, f1_len).concat(filtered2);
            }
            observer.next(filtered);
            observer.complete();
          }, () => {
            observer.next(filtered);
            observer.complete();
          });
        },
        (error) => {
          console.error(error);
          observer.next([]);
          observer.complete();
        }
      );
    });
  }
}
