<div class="custom-input-group select-group">
  <div *ngIf="question.title" class="header">{{question.title}}</div>
  <select class="custom-form-control" [ngModel]="selected_value" (change)="emitUpdate($event.target.value)" [ngModelOptions]="{standalone: true}">
    <option value="undefined" disabled>Please select</option>
    <option *ngFor="let option of question?.__options" [value]="option.id">{{option.title}}</option>
  </select>
  <div *ngIf="showTextInput" class="pc2-row-pad">
    <input type="text" class="form-control" [(ngModel)]="textInput" (change)="emitUpdate(selected_value)" placeholder="{{textInputTitle}}" [ngModelOptions]="{standalone: true}"/>
  </div>
</div>
