<section class="main-content contract-layout">
  <div class="container">

    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active">{{category?.label}}</li>
    </ol>

    <div class="row">
      <div class="col-md-12 w-100 ov-h">
        <h4 style="padding: 1em 0">Contracts for "{{category?.label}}"</h4>
      </div>
    </div>

    <contract-list-item [contract]="contract" [category]="category" *ngFor="let contract of featuredContracts"></contract-list-item>

    <div class="row">&nbsp;</div>

    <div class="row">
      <div class="col-md-12 w-100 ov-h">
        <specialty-slider [id]="specialtyId"></specialty-slider>

      </div>
    </div>
  </div>
</section>
