<div class="container">
  <div class="row">
    <div class="col-lg-8 offset-lg-2">
      <div class="text-center border-bottom border-right" [hidden]="sent">
        <h4 class="color-primary">Questions or account updates, please list below.</h4>
        <p>You may also <a href="/login">CLICK HERE</a> to log into your account.</p>
      </div>
      <div class="row form-group pc2-row-pad-big" [hidden]="sent">
        <div class="col-3 text-right">Your email address:</div>
        <div class="col-9"><input type="text" inputmode="email" [(ngModel)]="email" class="form-control"/></div>
      </div>
      <div class="row form-group" [hidden]="sent">
        <div class="col-12">
          <textarea placeholder="Tell us how we can help:
  • Address change or update
  • Vendor/Supplier Questions
  • New Point of Contact" class="form-control" [(ngModel)]="message" rows="7"></textarea>
        </div>
      </div>
      <div class="row form-group" [hidden]="sent">
        <div class="col-4 offset-4">
          <button type="button" (click)="send()" class="btn btn-primary btn-block" [disabled]="sending">Submit</button>
        </div>
      </div>
      <div class="text-center pc2-row-pad-big">
        <h4 class="color-primary">Thank you for your time!</h4>
        <div>Questions?</div>
        <div>Email us at <a href="mailto:{{channelEmail}}">{{channelEmail}}</a> or call us at {{channelPhone}}</div>
      </div>
    </div>
  </div>
</div>
