import {HttpErrorResponse} from '@angular/common/http';
declare var toastr;

export class Notify {
  public toastr = toastr;

  success(title, message?) {
    toastr.success(message || '', title);
  }

  error(title, message?) {
    toastr.error(message || '', title);
  }

  warning(title, message?) {
    toastr.warning(message || '', title);
  }

  info(title, message?) {
    toastr.info(message || '', title);
  }

  clear() {
    return toastr.clear();
  }

  remove() {
    return toastr.remove();
  }

  httpErr(err:HttpErrorResponse) {
    try {
      this.error(err.statusText || 'Error', err.error || err.message);
    } catch (e) {
      this.error('Error');
    }
  }
}
