import {IContractItem} from "./IContractItem";
import {IContractItemForSearch} from "./IContractItemForSearch";
export class IContract {
  id:number;
  contract_type:string;
  minority:string;
  vendor_name:string;
  vendor_number:string;
  contract_number:number;
  product_category:string;
  product_subcategory:string;
  department:string;
  contract_description:string;
  contract_status:string;
  expiration_date:string;
  rebate:string;
  order_from:string;
  __image_url:string;
  __items:IContractItem[];
  //local
  __subcategories:string[];
  __item_number:string;
  __found_items:IContractItemForSearch[];
}
