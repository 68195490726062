import {Component, Input, Output, EventEmitter} from '@angular/core';
declare var _:any;

@Component({
  selector: 'question-text',
  templateUrl: './q_text.html',
  styleUrls: ['./q_text.css']
})
export class QuestionTextCmp {
  @Input() question;
  @Output() update = new EventEmitter();
  public values = {};

  emitUpdate(option, $event) {
    option.user_input = $event;
    this.values[option.id] = option;
    this.update.emit(_.values(this.values));
  }
}
