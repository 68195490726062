import {Component, Input} from '@angular/core';
import {Session} from "../../services/Session";
import {FDTickets} from "../../services/FDTickets";
import {Notify} from "../../services/Notify";
import {ILocation} from "../../interfaces/ILocation";
import {IUser} from "../../interfaces/IUser";
import {Config} from "../../services/Config";
import {IRecommendedContract, Newsletter} from "../../services/Newsletter";

@Component({
  selector: 'account-recommendations',
  templateUrl: './recommendations.html',
})
export class AccountRecommendationsCmp {
  @Input() contracts:IRecommendedContract[];
  @Input() location:ILocation;
  @Input() user:IUser;
  public submitting = false;
  public optsMap = {
    1: 'Already Use',
    2: 'I want access to this contract',
    3: 'I am interested in learning more'
  };

  constructor(private tickets:FDTickets, private config:Config) {
  }

  send() {
    if (!this.contracts || !this.contracts.length || !this.location || !this.user || !this.user.email) {
      return;
    }
    let email = this.user.email;
    if (!email || email.indexOf('@') < 1) {
      return false;
    }
    let contractsList:IRecommendedContract[] = [];
    this.contracts.forEach((c)=> {
      if (c['__selected_id']) {
        contractsList.push(c);
      }
    });
    if (contractsList.length < 1) {
      return;
    }
    let n = new Notify();
    let customFields = {};
    if (this.user && this.user.__theme && this.user.__theme.name) {
      customFields['brand'] = this.user.__theme.name;
    }
    if (this.location && this.location.pc_id) {
      customFields['pc_id'] = this.location.pc_id;
    }
    this.submitting = true;
    let contractsTxt = '';
    contractsList.forEach((c:IRecommendedContract)=> {
      contractsTxt += `<div>${c.vendor_name}`;
      if (c.contract_number) {
        contractsTxt += ` (${c.contract_number})`;
      }
      contractsTxt += ' : ' + this.optsMap[c['__selected_id']] + '</div>';
    });
    let msg = `<hr><div>Selected contracts on the Recommendations page:</div>${contractsTxt}`;
    msg = `<div>Location: <a href="https://crm.purchase.guru/location/${this.location.id}" target="_blank">${this.location.facility_name} (${this.location.pc_id})</a></div>${msg}`;
    if (this.user) {
      msg = `<div>User: <a href="https://crm.purchase.guru/user/${this.user.id}" target="_blank">${this.user.fname} ${this.user.lname}</a>, email: ${this.user.email}</div>${msg}`;
    }
    this.tickets.create(email, 'Annual Report: Interested in contracts', msg, customFields, this.location.__manager_id).subscribe(() => {
      this.submitting = false;
      n.success('Successfully!');
    }, (error) => {
      this.submitting = false;
      console.error(error);
      n.error('Error, sorry...');
    });
  }
}
