<div class="search-box-holder">
  <div class="input-group custom-input-group search-box"
       [ngClass]="{'d-flex opacity-1 zi-999':isOpen}" [formGroup]="searchGroup">
    <input formControlName="searchInput" (keyup)="searchKey($event)" (blur)="blurSearch($event)"
           [placeholder]="loaded?'Search':'...'"
           type="text" class="form-control custom-form-control search-input" style="padding-left: 1rem"/>
    <a class="input-group-append">
      <span *ngIf="loaded" class="input-group-text text-center" style="width: 50px">
        <i class="material-icons pc2-search-icon color-primary">search</i>
      </span>
      <span *ngIf="!loaded" class="input-group-text text-center" style="width: 50px;padding-left: 16px;">
        <i class="fas fa-spinner fa-spin text-center"></i>
      </span>
    </a>
  </div>

  <div class="search-box-xs" (click)="open()">
    <span><i class="material-icons pc2-search-icon">search</i></span>
  </div>

  <div class="dropdown">
    <div class="dropdown-menu" [ngClass]="{'show':listVisible}">
      <div *ngFor="let c of results; let i = index" (click)="selectContract(c.contract_number, $event)" [ngClass]="{'active':i==(kIndex-1)}" class="dropdown-item pointer">
        <highlight-text [search]="searchTerm" text="{{c.vendor_name}} {{c.__item_number||c.contract_number}}"></highlight-text>
        <span *ngIf="c.contract_description" class="purple"> ({{c.contract_description}})</span>
      </div>
      <div *ngIf="results.length>1" class="dropdown-divider"></div>
      <div *ngIf="results.length>1" class="dropdown-item pointer" [ngClass]="{'active':kIndex==0}"
           (click)="showAllResults($event)">Show all results...
      </div>
    </div>
  </div>
</div>
