import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {LocationCustomSettings} from "../../services/LocationCustomSettings";
import {ICustomSettings} from "../../interfaces/ICustomSettings";
import {Config} from "../../services/Config";

@Component({
  selector: 'non-med-savings',
  templateUrl: './non-med-savings.html',
  styleUrls: ['./non-med-savings.css'],
})
export class NonMedSavingsCmp {
  public percentage = 0;
  public settings:ICustomSettings = <ICustomSettings>{};
  public displayOfficeSuppliesPage = true;
  public loaded = false;

  constructor(public session:Session, private locSettings:LocationCustomSettings, private config:Config) {
    this.session.addOnLoadHandler(()=> {
      this.loadUserData();
    });
    this.session.redirectIfNotAuth();
  }

  ngAfterViewInit() {
    setTimeout(()=> {
      this.loadUserData();
    }, 500);
  }

  loadUserData() {
    if (this.session.user && this.session.user.location_id) {
      if (this.session.user.theme_id == this.config.mrnThemeID) {
        this.displayOfficeSuppliesPage = false;
      }
      this.locSettings.getSettings(this.session.user.location_id).subscribe((settings)=> {
        this.loaded = true;
        this.settings = settings;
        this.percentage = this.locSettings.getCompletePercentage(this.settings, this.session.user.theme_id);
      }, ()=> {
        this.loaded = true;
        this.settings = <ICustomSettings>{location_id: this.session.user.location_id};
      });
    }
  }
}
