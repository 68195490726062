import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { routes } from "./app.routes";

import { HomeCmp } from "./components/home/home";
import { RepSignupFormCmp } from "./components/signup/for_rep/RepSignupFormCmp";
import { SignupReviewCmp } from "./components/signup/review/SignupReviewCmp";
import { SignupCmp } from "./components/signup/SignupCmp";
import { LoginCmp } from "./components/login/LoginCmp";
import { LogoutCmp } from "./components/logout/LogoutCmp";
import { ContractCmp } from "./components/contract/ContractCmp";
import { CategoryCmp } from "./components/category/CategoryCmp";
import { SpecialtyCmp } from "./components/specialty/SpecialtyCmp";
import { EmployeePerkCmp } from "./components/employee-perk/EmployeePerkCmp";
import { OfficeSuppliesCmp } from "./components/office-supplies/OfficeSuppliesCmp";
import { CapitalEquipmentCmp } from "./components/capital-equipment/CapitalEquipmentCmp";
import { PharmacyCmp } from "./components/pharmacy/PharmacyCmp";
import { ForgotPasswordCmp } from "./components/forgot-password/ForgotPasswordCmp";
import { NewPasswordCmp } from "./components/forgot-password/NewPasswordCmp";
import { FAQCmp } from "./components/faq/faq";
import { PrivacyPolicyCmp } from "./components/privacy-policy/PrivacyPolicyCmp";
import { TermsOfUseCmp } from "./components/terms-of-use/TermsOfUseCmp";
import { TermsOfServiceCmp } from "./components/terms-of-service/TermsOfServiceCmp";
import { ContactCmp } from "./components/contact/contactCmp";
import { AccountCmp } from "./components/account/account";
import { SavingsReportCmp } from "./components/savings_report/SavingsReportCmp";
import { SavingsReportDeliveryCmp } from "./components/savings_report/SavingsReportDeliveryCmp";
import { SavingsSignupCmp } from "./components/savings_report/SavingsSignupCmp";
import { NewMemberCmp } from "./components/new-member/NewMemberCmp";
import { RedirAuthCmp } from "./components/redir_auth/RedirAuthCmp";
import { SearchResultsCmp } from "./components/search-results/SearchResultsCmp";
import { CellPhonesCmp } from "./components/cellphone-signup/CellPhonesCmp";
import { SignupMgmaCmp } from "./components/signup/mgma/SignupMgmaCmp";
import { SurveySignupCmp } from "./components/survey_after_signup/SurveySignupCmp";
import { SurveySignupThanksCmp } from "./components/survey_after_signup/SurveySignupThanksCmp";
import { NonMedSavingsCmp } from "./components/non-med-savings/NonMedSavingsCmp";
import { SurveyOfficeSuppliesCmp } from "./components/non-med-savings/office-supplies/SurveyOfficeSuppliesCmp";
import { SurveyShippingCmp } from "./components/non-med-savings/shipping/SurveyShippingCmp";
import { SurveyCellPhonesCmp } from "./components/non-med-savings/cellphones/SurveyCellPhonesCmp";
import { SurveyUtilityBillsCmp } from "./components/non-med-savings/utility-bills/SurveyUtilityBillsCmp";
import { SurveyCarsCmp } from "./components/non-med-savings/rental-cars/SurveyCarsCmp";
import { CarRentalCmp } from "./components/car-rental/CarRentalCmp";
import { HeaderCmp } from "./components/nav-header/header";
import { FooterCmp } from "./components/nav-footer/footer";
import { HeaderSearchBoxCmp } from "./components/header-search-box/HeaderSearchBoxCmp";
import { SpecSettingsCmp } from "./components/spec-settings/SpecSettingsCmp";
import { AmpPcTosLineCmp } from "./components/amp-pc-tos-line/AmpPcTosLineCmp";
import { ContractListItemCmp } from "./components/contract-list-item/ContractListItemCmp";
import { DatePickerCmp } from "./components/date-picker/DatePickerCmp";
import { FileUploadButtonDialog } from "./components/file-upload-button/FileUploadDialogCmp";
import { FieldCmp } from "./components/form-field/FieldCmp";
import { FieldSplitCmp } from "./components/form-field-split/FieldSplitCmp";
import { FormHeaderCmp } from "./components/form-header/FormHeaderCmp";
import { FormSubtitleCmp } from "./components/form-subtitle/FormSubtitleCmp";
import { HaveAQuestionCmp } from "./components/have-a-question/HaveAQuestionCmp";
import { ItemsSliderCmp } from "./components/items-slider/ItemsSliderCmp";
import { NewMemberDistributorCmp } from "./components/new-member-distributor/NewMemberDistributorCmp";
import { QuestionRadioCmp } from "./components/question-radio/QuestionRadioCmp";
import { QuestionSelectCmp } from "./components/question-select/QuestionSelectCmp";
import { QuestionSelectMultipleCmp } from "./components/question-select-multiple/QuestionSelectMultipleCmp";
import { QuestionTextCmp } from "./components/question-text/QuestionTextCmp";
import { ScrollUpCmp } from "./components/scroll-up/ScrollUpCmp";
import { SelectBoxCmp } from "./components/select-box/SelectBoxCmp";
import { SpecialtySliderCmp } from "./components/specialty-slider/SpecialtySliderCmp";
import { Spinner } from "./components/spinner/spinner";
import { TosTextCmp } from "./components/tos-text/TosTextCmp";
import { UnsafeTextCmp } from "./components/unsafe-text/UnsafeTextCmp";
import { CategoryPipe } from "./services/category-pipe/CategoryPipe";
import { AccountSecurityCmp } from "./components/account-security/AccountSecurityCmp";
import { SelectOptionCmp } from "./components/select-box/SelectOptionCmp";
import { NewsTickerCmp } from "./components/news-ticker/NewsTickerCmp";
import { SurveyOfficeSuppliesThanks } from "./components/non-med-savings/office-supplies/SurveyOfficeSuppliesThanks";
import { FilterPipe } from "./pipes/FilterPipe";
import { MgmaSsoCmp } from "./components/mgma_sso/MgmaSsoCmp";
import { HighlightTextCmp } from "./components/highlight-text/HighlightTextCmp";
import { HelpAccessPricingCmp } from "./components/help-access-pricing/HelpAccessPricingCmp";
import { BtnDistrPricingCmp } from "./components/btn-distr-pricing/BtnDistrPricingCmp";
import { NumbersCommaSeparatePipe } from "./pipes/NumbersCommaSeparatePipe";
import { AccountLocationsCmp } from "./components/account-locations/AccountLocationsCmp";
import { AccountContactsCmp } from "./components/account-contacts/AccountContactsCmp";
import { AccountSalesCmp } from "./components/account-sales/AccountSalesCmp";
import { AccountRebatesCmp } from "./components/account-rebates/AccountRebatesCmp";
import { AccountContactUsCmp } from "./components/account-contact-us/AccountContactUsCmp";
import { AccountDistributorCmp } from "./components/account-distributor/AccountDistributorCmp";
import { AccountDistributorsListCmp } from "./components/account-distributors-list/AccountDistributorsListCmp";
import { ArrayJoinNotEmptyPipe } from "./pipes/ArrayJoinNotEmptyPipe";
import { FaqTenetCmp } from "./components/faq/faq-tenet/FaqTenetCmp";
import { NewsletterCmp } from "./components/newsletter/NewsletterCmp";
import { AccountSalesCycleCmp } from "./components/account-sales-cycle/AccountSalesCycleCmp";
import { Utc2EtPipe } from "./pipes/Utc2EtPipe";
import { AccountContactUsAnnualReportCmp } from "./components/account-contact-annual-report/AccountContactUsAnnualReportCmp";
import { AccountFilesCmp } from "./components/account-files/AccountFilesCmp";
import { AccountRecommendationsCmp } from "./components/account-recommendations/AccountRecommendationsCmp";
import { RxPageCmp } from "./components/rx-page/RxPageCmp";
import { FluVaccinesPageCmp } from "./components/flu-vaccines/FluVaccinesPageCmp";
import { VaccinesThanksCmp } from "./components/flu-vaccines/VaccinesThanksCmp";
import { SiimPageCmp } from "./components/siim-page/SiimPageCmp";
import { SiimThanksCmp } from "./components/siim-page/SiimThanksCmp";
import { PreVaccinesCmp } from "./components/new-member/pre-vaccines/PreVaccinesCmp";
import { SurveyExtDistributorCmp } from "./components/survey-ext-distributor/SurveyExtDistributorCmp";
import { SurveyExtThanksCmp } from "./components/survey-ext-thanks/SurveyExtThanksCmp";
import { AutoFocusCmp } from "./components/auto-focus/AutoFocusCmp";
import { FluPrebook2020Cmp } from "./components/flu-prebook-2020/FluPrebook2020Cmp";
import { FluPrebookThanksCmp } from "./components/flu-prebook-thanks/FluPrebookThanksCmp";
import { EnrollPageCmp } from "./components/enroll-page/EnrollPageCmp";
import { CircleProgressCmp } from "./components/circle-progress/CircleProgressCmp";
import { GetStartedPageCmp } from "./components/get-started-page/GetStartedPageCmp";
import { NoThankYouPageCmp } from "./components/no-thank-you-page/NoThankYouPageCmp";
import { SignupOptimizationPageCmp } from "./components/signup-optimization-page/SignupOptimizationPageCmp";
import { OrderByPipe } from "./services/OrderByPipe";
import { NgxCaptchaModule } from "ngx-captcha";
import { NgHcaptchaModule } from 'ng-hcaptcha';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {}),
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgHcaptchaModule.forRoot({
      siteKey: '775ff077-6d84-41c6-aa73-417ec51c7149',
      languageCode: 'en' // optional, will default to browser language
  }),
  ],
  declarations: [
    AccountCmp,
    ArrayJoinNotEmptyPipe,
    AccountLocationsCmp,
    AccountContactsCmp,
    AccountSalesCmp,
    AccountSalesCycleCmp,
    AccountRebatesCmp,
    AccountContactUsCmp,
    AccountContactUsAnnualReportCmp,
    AccountFilesCmp,
    AccountRecommendationsCmp,
    AccountSecurityCmp,
    AmpPcTosLineCmp,
    AppComponent,
    AccountDistributorCmp,
    AccountDistributorsListCmp,
    BtnDistrPricingCmp,
    CapitalEquipmentCmp,
    CarRentalCmp,
    CategoryCmp,
    CellPhonesCmp,
    ContactCmp,
    ContractCmp,
    ContractListItemCmp,
    DatePickerCmp,
    EmployeePerkCmp,
    FAQCmp,
    FaqTenetCmp,
    FileUploadButtonDialog,
    FooterCmp,
    ForgotPasswordCmp,
    FieldCmp,
    FieldSplitCmp,
    FluVaccinesPageCmp,
    FormHeaderCmp,
    FormSubtitleCmp,
    HaveAQuestionCmp,
    HeaderCmp,
    HeaderSearchBoxCmp,
    HomeCmp,
    HighlightTextCmp,
    HelpAccessPricingCmp,
    ItemsSliderCmp,
    LoginCmp,
    LogoutCmp,
    MgmaSsoCmp,
    NewMemberCmp,
    NewMemberDistributorCmp,
    NewPasswordCmp,
    NewsletterCmp,
    NonMedSavingsCmp,
    NumbersCommaSeparatePipe,
    OfficeSuppliesCmp,
    PharmacyCmp,
    PrivacyPolicyCmp,
    QuestionRadioCmp,
    QuestionSelectCmp,
    QuestionSelectMultipleCmp,
    QuestionTextCmp,
    RedirAuthCmp,
    RepSignupFormCmp,
    RxPageCmp,
    SavingsReportCmp,
    FilterPipe,
    SavingsReportDeliveryCmp,
    SavingsSignupCmp,
    ScrollUpCmp,
    SearchResultsCmp,
    SelectBoxCmp,
    SelectOptionCmp,
    SignupMgmaCmp,
    SignupReviewCmp,
    SignupCmp,
    SpecSettingsCmp,
    SpecialtyCmp,
    SpecialtySliderCmp,
    Spinner,
    SurveyCarsCmp,
    SurveyCellPhonesCmp,
    SurveyOfficeSuppliesCmp,
    SurveyOfficeSuppliesThanks,
    SurveyShippingCmp,
    SurveySignupCmp,
    SurveySignupThanksCmp,
    SurveyUtilityBillsCmp,
    TermsOfServiceCmp,
    TermsOfUseCmp,
    TosTextCmp,
    UnsafeTextCmp,
    CategoryPipe,
    NewsTickerCmp,
    Utc2EtPipe,
    VaccinesThanksCmp,
    SiimPageCmp,
    SiimThanksCmp,
    PreVaccinesCmp,
    SurveyExtDistributorCmp,
    SurveyExtThanksCmp,
    AutoFocusCmp,
    FluPrebook2020Cmp,
    FluPrebookThanksCmp,
    EnrollPageCmp,
    CircleProgressCmp,
    GetStartedPageCmp,
    NoThankYouPageCmp,
    SignupOptimizationPageCmp,
    OrderByPipe,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
