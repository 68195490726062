import {Component, ViewChild, ElementRef} from '@angular/core';
import {CellphoneSignup} from "../../services/CellphoneSignup";
import {ICellphoneSignup} from "../../interfaces/ICellphoneSignup";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
declare var $:any;
declare var ClipboardJS:any;

@Component({
  selector: 'cellphones-signup',
  templateUrl: './cellphones.html',
  styleUrls: ['./cellphones.css']
})
export class CellPhonesCmp {
  @ViewChild('copy') copyBtn:ElementRef;
  public data:ICellphoneSignup = <ICellphoneSignup>{};
  public saving = false;
  public saved = false;
  public selectCopy = false;
  public themedDomain = 'savings.purchaseclinic.com';

  constructor(private  cellphonesService:CellphoneSignup, private session:Session, public config:Config) {}

  submit() {
    let notify = new Notify();
    if (!this.data.fname) {
      notify.error('Please fill field First Name');
      return;
    }
    if (!this.data.lname) {
      notify.error('Please fill field Last Name');
      return;
    }
    if (!this.data.facility_name) {
      notify.error('Please fill field Facility Name');
      return;
    }
    if (!this.data.phone) {
      notify.error('Please fill field Primary Account Cell Phone Number');
      return;
    }
    if (!this.data.personal_wireless_phone) {
      notify.error('Please fill field Personal Wireless #');
      return;
    }
    if (!this.data.business_email || this.data.business_email.indexOf('@') < 0) {
      notify.error('Please fill field Business e-mail address');
      return;
    }
    if (!this.data.billing_zip) {
      notify.error('Please fill field Personal Billing Zip Code');
      return;
    }
    if (!this.data.ssn) {
      notify.error('Please fill field Account Holder\'s SSN');
      return;
    }
    if (this.data.ssn && this.data.ssn.length !== 4) {

      notify.error('SSN should contain only 4 digits');
      return;
    }
    this.saving = true;
    try {
      if (this.session.user && this.session.user.__theme && this.session.user.__theme.id) {
        this.data.theme_id = this.session.user.__theme.id;
      }
    } catch (e) {}
    this.cellphonesService.submit(this.data).subscribe(() => {
      this.saved = true;
      this.saving = false;
      $('html,body').animate({scrollTop: 0}, 50);
      this.initCopyBtn();
    }, () => {
      this.saving = false;
    });
  }

  initCopyBtn() {
    setTimeout(() => {
      try {
        if (this.session.user && this.session.user.theme_id) {
          let t = this.config.themesMap[this.session.user.theme_id];
          if (t && t.landing_domain && t.landing_domain.length > 1) {
            this.themedDomain = t.landing_domain;
          }
        }
        if (this.copyBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyBtn.nativeElement);
          clipboard.on('success', (e:any) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyBtnClick($event:KeyboardEvent) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e:KeyboardEvent) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }
}
