import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Contracts} from "../../services/Contracts";
import {FDTickets} from "../../services/FDTickets";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {Notify} from "../../services/Notify";
import {Observable} from "rxjs";
declare var $:any;

@Component({
  selector: 'have-a-question',
  templateUrl: './modal.html',
})
export class HaveAQuestionCmp {
  @Input() contractNumber;
  @Input() vendorName;
  @Input() displayButton:boolean = true;
  @Input() linkText:string;
  @Input() show:Observable<any>;
  @ViewChild('haq') modal:ElementRef;
  public question;
  public saving:boolean = false;
  public pc_id:string;
  public newEmail:string;

  constructor(public session:Session, private contractService:Contracts, private tickets:FDTickets, private locations:Locations) {}

  ngOnInit() {
    if (this.contractNumber && !this.vendorName) {
      this.contractService.load(this.contractNumber).subscribe((contract) => {
        if (contract.vendor_name) {
          this.vendorName = contract.vendor_name;
        }
      });
    }
  }

  ngOnChanges() {
    if (this.show) {
      this.show.subscribe(()=> {
        this.showModal();
      }, ()=> {});
    }
  }

  showModal() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('show');
    }
  }

  hideModal() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('hide');
    }
  }

  ngAfterViewInit() {
    this.session.loadUser(() => {
      if (this.session.user && this.session.user.location_id) {
        this.locations.getLocation(this.session.user.location_id).then((loc:ILocation) => {
          if (loc && loc.pc_id) {
            this.pc_id = loc.pc_id;
          }
        }, () => {});
      }
    }, () => {});
  }

  send() {
    let n = new Notify();
    let email = this.newEmail;
    if (!email && this.session.user && this.session.user.email) {
      email = this.session.user.email;
    }
    if (!email || email.indexOf('@') < 1) {
      n.warning('Please input your email');
      return false;
    }
    let customFields = {};
    if (this.session && this.session.user && this.session.user.__theme && this.session.user.__theme.name) {
      customFields['brand'] = this.session.user.__theme.name;
    }
    if (this.pc_id) {
      customFields['pc_id'] = this.pc_id;
    }
    this.saving = true;
    this.tickets.create(email,
      'Question: ' + this.vendorName + ' (' + this.contractNumber + ')',
      this.question, customFields).subscribe(() => {
      this.saving = false;
      this.hideModal();
      n.success('Successfully!');
    }, (error) => {
      this.saving = false;
      console.error(error);
      n.error('Error, sorry...');
    });
  }
}
