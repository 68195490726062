import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {Session} from "./Session";
import {ISubQuestionOption} from "../interfaces/ISubQuestionOption";
import {IUser} from "../interfaces/IUser";
import {ILocation} from "../interfaces/ILocation";

export interface ISiimAnswers {
  options: number[];
  job_title: string;
}

export interface ISiimQuestion {
  title: string;
  sub_title: string;
  options: ISubQuestionOption[];
}

export interface ISiimUser {
  id: number;
  user_id: number;
  code: string;
  user_ip: string;
  user: IUser;
  location: ILocation;
}

export interface ISiimUserResponse {
  user: ISiimUser;
  token: string;
  questions: ISiimQuestion[];
}

@Injectable()
export class SiimSrv {

  constructor(private http:HttpClient, private config:Config, private session:Session) {}

  initiateSiimSurvey(answers:ISiimAnswers):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/siim/survey', JSON.stringify(answers), {headers: headers});
  }

  loadCode(code:string):Observable<ISiimUserResponse> {
    return this.http.get<ISiimUserResponse>(this.config.apiUrl + '/siim/user/' + code);
  }
}
