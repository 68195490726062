<div class="slider-holder clearfix">
  <div class="scroll-area" #area>
    <ul class="items-slider" [style.width.px]="(items.length+10)*itemWidth">
      <li class="item-container" *ngFor="let item of items; let i = index"
          [style.left.px]="(-index)*itemWidth" [style.width.px]="itemWidth">
        <div class="item">
          <a class="img-holder" [routerLink]="item.route">
            <img src="{{item.image_url}}" alt="">
          </a>
          <a class="item-info" [routerLink]="item.route">
            <div class="item-header">
              <h3 class="header"><strong>{{item.header}}</strong> <span *ngIf="item.header_counter">({{item.header_counter}})</span></h3>
              <div class="subheader">{{item.subheader}}</div>
            </div>
          </a>
          <div class="item-actions">
            <a class="btn item-action" [routerLink]="item.route">
              {{item.link_text}}
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="prev-item" [attr.data-hidden]="index<1"><span (click)="prev()" class="arrow icon-arrow-left"></span></div>
  <div class="next-item" [attr.data-hidden]="index>=max"><span (click)="next()" class="arrow icon-arrow-right"></span></div>
</div>
