import {Component} from '@angular/core';

@Component({
  selector: 'vaccines-thanks',
  template: `<div class="container">
    <h2>Thank you for completing the vaccine module.</h2>
    <ul>
    <li>We will contact you with any questions after reviewing your documentation</li>
    <li>You may contact us with questions at any time</li>
    <li>You will receive an email for your records which will also be available through your account filebox</li>
    </ul>
    </div>`
})
export class VaccinesThanksCmp {
}
