import {Component, Input} from '@angular/core';
import {User} from "../../services/User";

@Component({
  selector: 'new-member-distributor',
  templateUrl: './new-member-distributor.html'
})
export class NewMemberDistributorCmp {
  @Input() authCode:string = '';
  public distributor:string;
  public distributorSubmitted = false;
  public distributorSaving = false;

  constructor(private userSrv:User) {
  }

  saveDistributor() {
    if (!this.authCode || !this.distributor) {
      return;
    }
    this.distributorSaving = true;
    this.userSrv.addCustomSignature(this.authCode, this.distributor).subscribe(()=> {
      this.distributorSubmitted = true;
      this.distributorSaving = false;
    }, ()=> {
      this.distributorSubmitted = true;
      this.distributorSaving = false;
    });
  }
}
