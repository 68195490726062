import {Component, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {LocationCustomSettings} from "../../../services/LocationCustomSettings";
import {Session} from "../../../services/Session";
import {ICustomSettings} from "../../../interfaces/ICustomSettings";
import {Notify} from "../../../services/Notify";
import {Config} from "../../../services/Config";
import {Locations} from "../../../services/Locations";
declare var $:any;
declare var ClipboardJS:any;

@Component({
  selector: 'rental-cars',
  templateUrl: './rental-cars.html',
  styleUrls: ['./rental-cars.css'],
})
export class SurveyCarsCmp {
  @ViewChild('copy') copyBtn:ElementRef;
  public saving = false;
  public published = true; //pc2-tmp-hide
  public leisureCode = '';
  public businessCode = '';
  public selectCopy = false;
  public submitted = false;
  public disclaimed;

  constructor(private locSettings:LocationCustomSettings, private session:Session, private router:Router, private config:Config, private locService:Locations) {
    this.leisureCode = this.config.getRentalCodeLeisure(0);
    this.businessCode = this.config.getRentalCodeBusiness(0);
    this.session.addOnLoadHandler(() => {
      if (this.session.user.theme_id === this.config.ampThemeID || (this.session.user.__theme && this.session.user.__theme.id === this.config.ampThemeID)) {
        this.leisureCode = this.config.getRentalCodeLeisure(this.config.ampThemeID);
        this.businessCode = this.config.getRentalCodeBusiness(this.config.ampThemeID);
      }
      if (this.session.user.theme_id === this.config.capThemeID || (this.session.user.__theme && this.session.user.__theme.id === this.config.capThemeID)) {
        this.leisureCode = this.config.getRentalCodeLeisure(this.config.capThemeID);
        this.businessCode = this.config.getRentalCodeBusiness(this.config.capThemeID);
      }
      if (this.session.user.theme_id === this.config.mgmaThemeID || (this.session.user.__theme && this.session.user.__theme.id === this.config.mgmaThemeID)) {
        this.leisureCode = this.config.getRentalCodeLeisure(this.config.mgmaThemeID);
        this.businessCode = this.config.getRentalCodeBusiness(this.config.mgmaThemeID);
      }
    });
    this.session.redirectIfNotAuth();
  }

  save() {
    try {
      if (this.disclaimed) {
        return this.disclaim();
      }
      if (this.session.user.location_id) {
        let s = <ICustomSettings>{};
        s.location_id = this.session.user.location_id;
        s.is_car_rental_page_filled = true;
        this.saving = true;
        this.locSettings.setSettings(s).subscribe(() => {
          this.saving = false;
          this.submitted = true;
          this.initCopyBtn();
          $('html,body').animate({scrollTop: 0}, 150);
        }, (err) => {
          this.saving = false;
          console.error(err);
          let n = new Notify();
          n.error('Error');
        });
        this.locService.setVendorInterested(this.session.user.location_id, this.config.enterpriseVendorID);
      }
    } catch (err) {
      console.error(err);
    }
  }

  initCopyBtn() {
    setTimeout(() => {
      try {
        if (this.copyBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyBtn.nativeElement);
          let u = this.session.user;
          if (u.__theme && u.__theme.landing_domain) {
            this.copyBtn.nativeElement.setAttribute('data-clipboard-text', `http://${u.__theme.landing_domain}/car_rental`);
          }
          clipboard.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyBtnClick($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }

  disclaim() {
    try {
      let s = <ICustomSettings>{};
      s.is_car_rental_page_filled = true;
      s.is_car_rental_page_disclaimed = true;
      s.location_id = this.session.user.location_id;
      this.locSettings.setSettings(s).subscribe(() => {}, () => {});
      this.saving = false;
      this.submitted = true;
      this.initCopyBtn();
      $('html,body').animate({scrollTop: 0}, 150);
    } catch (e) {
      console.error(e);
    }
  }
}
