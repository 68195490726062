<div class="container">
  <div *ngIf="!saved" class="row">
    <div class="col-sm-10 offset-sm-1">
      <div class="card">
        <div class="card-header solo text-center text-info">
          Enroll and save on your monthly cell phone bill by filling out the fields below.
        </div>
        <form name="form" (ngSubmit)="submit()" class="card-body" #cellForm="ngForm">
          <div class="row">
            <div class="col-sm-4">
              <div class="pull-sm-right"><strong>Cell Phone Carrier</strong></div>
            </div>
            <div class="col-sm-8">
              <div class="radio">
                <label>
                  <input type="radio" name="carrier" (change)="data.carrier='att'">
                  AT&T (up to 25%)
                </label>
              </div>
            </div>
          </div>
          <!--AT&T-->
          <section [hidden]="data.carrier!='att'">
            <form-field-split [(model)]="data.fname" label="First Name" required lw="4" name="fname"></form-field-split>
            <form-field-split [(model)]="data.lname" label="Last Name" required lw="4" name="lname"></form-field-split>
            <form-field-split [(model)]="data.facility_name" label="Facility Name" required lw="4" name="locname"></form-field-split>
            <form-field-split [(model)]="data.phone" label="Primary Account Cell Phone Number" placeholder="Primary Phone Number" required lw="4" name="phone"></form-field-split>
            <form-field-split [(model)]="data.personal_wireless_phone" label="Personal Wireless #" required lw="4" name="personal_phone"></form-field-split>
            <form-field-split [(model)]="data.business_email" type="email" label="Business e-mail address" placeholder="E-mail" required lw="4" name="email"></form-field-split>
            <form-field-split [(model)]="data.billing_zip" label="Personal Billing Zip Code" required lw="4" name="zip"></form-field-split>
            <form-field-split [(model)]="data.ssn" type="number" label="Account Holder's SSN (last four digits)" name="ssn" required lw="4"></form-field-split>
          </section>
          <!--Verizon-->
          <section *ngIf="data.carrier=='verizon'">
            <div style="padding: 0 0 15px 10px">
              Thank you for your interest in Verizon. Upon receiving this information, a Client manager will begin to process your account. Please allow up to two weeks for processing. Once processed, you will receive an e-mail from Verizon to the e-mail address you provided. Upon receiving the e-mail, you will have 4 days to complete and send back. Once sent, Verizon will send confirmation on discount and how other members of your organization can receive the discount.
            </div>
            <form-field-split [(model)]="data.facility_name" label="Facility Name" required lw="4"></form-field-split>
            <div class="card">
              <div class="card-header">
                <label class="text-center">Facility Address</label>
              </div>
              <div class="card-body">
                <form-field-split [(model)]="data.facility_address" label="Street Address" required lw="4"></form-field-split>
                <form-field-split [(model)]="data.facility_city" label="City" required lw="4"></form-field-split>
                <form-field-split [(model)]="data.facility_state" label="State" required lw="4"></form-field-split>
                <form-field-split [(model)]="data.facility_zip" label="ZIP Code" required lw="4"></form-field-split>
              </div>
            </div>
            <form-field-split [(model)]="data.facility_tax_id" label="Facility Tax ID" required lw="4"></form-field-split>
            <form-field-split [(model)]="data.facility_dnb" label="Facility DNB" required lw="4"></form-field-split>
            <form-field-split [(model)]="data.office_contact_name" label="Office Contact Name" required lw="4"></form-field-split>
            <form-field-split [(model)]="data.office_contact_phone" label="Office Contact #" required lw="4"></form-field-split>
            <form-field-split [(model)]="data.fname" label="First Name" required lw="4"></form-field-split>
            <form-field-split [(model)]="data.lname" label="Last Name" required lw="4"></form-field-split>
            <form-field-split [(model)]="data.business_email" type="email" label="E-mail" placeholder="E-mail" required lw="4"></form-field-split>
          </section>

          <div class="form-group row" style="display: flex">
            <div class="col-sm-8 offset-sm-4">
              <button type="submit" [disabled]="saving || !data.carrier"
                class="btn btn-primary btn-block">Submit
              </button>
            </div>
          </div>
        </form>
        <div class="card-footer text-center">
          If you have any questions regarding the required information,
          please contact
          <a href="mailto:{{session.user?.__theme?.email_from_address||'support@purchaseclinic.com'}}">{{session.user?.__theme?.email_from_address||'support@purchaseclinic.com'}}</a> or
          <a href="tel:{{session.user?.__theme?.phone||'1-877-659-2159'}}">{{session.user?.__theme?.phone||'1.877.659.2159'}}</a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="saved" class="row">
    <div class="col-sm-8 offset-sm-2">
      <div *ngIf="data.carrier == 'att'" class="text-center thanks-container">
        <h1><span class="text-primary">Thank You</span></h1>
        <p>Thank you for completing your cell phone discount signup form.</p>
      </div>
      <div *ngIf="data.carrier == 'verizon'" class="text-center thanks-container">
        <h1><span class="text-primary">Thank You</span></h1>
        <p>Thank you for your interest in Verizon. Upon receiving this information, a Client manager will begin to process your account. Please allow up to two weeks for processing. Once processed, you will receive an e-mail from Verizon to the e-mail address you provided. Upon receiving the e-mail, you will have 4 days to complete and send back. Once sent, Verizon will send confirmation on discount and how other members of your organization can receive the discount.</p>
      </div>
    </div>
    <div class="col-sm-8 offset-sm-2 pc2-row-pad">
      <div class="card">
        <div class="card-body text-center copy-bg">
          <p>Did you know your co-workers can save with these discounts too? Pass along the savings by sending them this link:</p>
          <button class="btn btn-link" #copy [attr.data-clipboard-text]="'http://'+themedDomain+'/cellphones'" data-clipboard-action="copy" (click)="copyBtnClick($event)">http://{{themedDomain}}/cellphones</button>
          <div>
            <small>Click to copy</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
