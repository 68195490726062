<div class="q-title" *ngIf="question.title">{{question.title}}</div>
<ng-template ngFor let-option [ngForOf]="question?.__options">
  <div *ngIf="option.title" class="row">
    <label class="col-12 sq-title">{{option.title}}</label>
  </div>
  <div class="row form-group">
    <div class="col-12">
      <input [type]="option.user_input_type || 'text'" class="form-control"
        [ngModel]="option.user_input" (ngModelChange)="emitUpdate(option, $event)"
        [placeholder]="option.hint||option.title" [ngModelOptions]="{standalone: true}"/>
    </div>
  </div>
</ng-template>
