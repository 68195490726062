import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from './Config';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {IUser} from '../interfaces/IUser';
import {Themes} from "./Themes";
import {ITheme} from "../interfaces/ITheme";
import { Subject } from 'rxjs';

@Injectable()
export class Session {
  themeReloaded$ = new Subject<boolean>();
  public user: IUser = new IUser();
  public prev_route: string = '';
  public prev_url: string = '';
  public crossPageArgs = {};
  public annualDisclosureCode = '';
  public preAuthRedirectURL = '';
  private token = '';
  private storage_key_token = 'session';
  private storage_key_prev_route = 'prev_route';
  private storage_key_prev_url = 'prev_url';
  private onLoadHandlers: Function[];
  private storage;
  public signupUrl: string;

  constructor(private config: Config, private router: Router, private http: HttpClient, private themeSrv: Themes) {
    this.storage = window.localStorage;
    setTimeout(() => {
      if (!this.token) {
        this.loadFromStorage();
      }
    }, 200);
    this.reloadTheme();
  }

  loadFromStorage() {
    this.loadTokenFromStorage();
    this.reloadTheme();
  }

  loadTokenFromStorage() {
    try {
      let t = this.storage.getItem(this.storage_key_token);
      if (typeof t === 'string') {
        this.token = t;
      }
    } catch (e) {}
  }

  getThemeReloaded(){
    return this.themeReloaded$.asObservable();
  }

  reloadTheme() {
    if (!this.config.themesAreLoaded) {
      this.config.getActualThemes(() => {
        this.reloadTheme();
      });
    }
    if (!this.user.id || !this.user.__theme || !this.user.theme_id) {
      if (this.user && this.user.__location && this.user.__location.theme_id && this.config.themesMap[this.user.__location.theme_id]) {
        this.user.__theme = this.config.themesMap[this.user.__location.theme_id];
      } else {
        this.user.__theme = this.config.defaultTheme;
      }
      if (this.user.__theme && this.user.__theme.id && !this.user.theme_id) {
        this.user.theme_id = this.user.__theme.id;
        if (!this.user.specialty_id && this.config.defaultTheme.__specialty_id) {
          this.user.specialty_id = this.config.defaultTheme.__specialty_id;
        }
      }
    }
    if (this.user && this.user.__theme && this.user.__theme.window_title) {
      document.title = this.user.__theme.window_title;
    }
    this.themeReloaded$.next(true);
  }

  getToken() {
    return this.token;
  }

  setToken(value, success?, error?, isAutoLogin: boolean = true) {
    if (typeof value !== 'string') {
      console.error('expected string, got', typeof value, value);
      return;
    }
    this.token = value;
    try {
      this.storage.setItem(this.storage_key_token, value);
      this.loadUser(success, error, isAutoLogin);
    } catch (e) {
      console.error(JSON.stringify(e));
    }
  }

  deleteToken() {
    this.token = '';
    try {
      this.storage.removeItem(this.storage_key_token);
    } catch (e) {}
  }

  deleteSpecCot() {
    this.user.specialty_id = 0;
    this.user.class_of_trade_id = 0;
  }

  logout() {
    this.deleteToken();
    this.deleteSpecCot();
    this.user = new IUser();
    this.reloadTheme();
  }

  loadUser(success?, error?, isAutoLogin: boolean = true) {
    if (!this.token) {
      this.loadTokenFromStorage();
    }
    if (!this.token) {
      if (error) {
        error();
      }
      return;
    }
    this.http.get<IUser>(this.config.apiUrl + '/session/' + this.token).subscribe((user: IUser) => {
        if (user && user.id > 0) {
          this.user = user;
          if (!user.is_email_valid) {
            this.user._is_login_auto = isAutoLogin;
          } else {
            this.user._is_login_auto = false;
          }
          this.reloadTheme();
          this.callHandlers(this.onLoadHandlers, this.user);
          this.onLoadHandlers = [];
          if (success) {
            success();
          }
        } else {
          this.deleteToken();
          this.deleteSpecCot();
          this.reloadTheme();
          if (error) {
            error();
          }
        }
      },
      err => {
        this.deleteToken();
        this.deleteSpecCot();
        this.user = new IUser();
        this.reloadTheme();
        if (error) {
          error();
        }
      });
  }

  redirectIfNotAuth(routerRedirectPath?: String) {
    routerRedirectPath = routerRedirectPath || '/login';
    if (this.user && this.user.id) {
      this.callHandlers(this.onLoadHandlers, this.user);
      this.onLoadHandlers = [];
      return;
    }
    this.loadUser(() => {}, () => {
      this.preAuthRedirectURL = window.location.href;
      this.router.navigate([routerRedirectPath]);
    });
  }

  setAuthHeader(headers: HttpHeaders): HttpHeaders {
    if (this.token) {
      return headers.set('Authorization', 'Bearer ' + this.token);
    } else {
      return headers;
    }
  }

  addOnLoadHandler(handler: Function) {
    if (!this.onLoadHandlers) {
      this.onLoadHandlers = [];
    }
    this.onLoadHandlers.push(handler);
  }

  callHandlers(handlersArray: Function[], result?) {
    if (!handlersArray || !handlersArray.length) {
      return;
    }
    handlersArray.forEach((handler) => {handler(result);});
  }

  setCotID(class_of_trade_id: number): void {
    this.user.class_of_trade_id = class_of_trade_id;
  }

  setSpecialtyID(specialty_id: number): void {
    this.user.specialty_id = specialty_id;
  }

  getCurrentTheme(): ITheme {
    if (this.user && this.user.__theme && this.user.__theme.id) {
      return this.user.__theme;
    }
    if (this.user && this.user.theme_id && this.config.themesMap[this.user.theme_id]) {
      return this.config.themesMap[this.user.theme_id]
    }
    if (this.user && this.user.__location && this.user.__location.theme_id && this.config.themesMap[this.user.__location.theme_id]) {
      return this.config.themesMap[this.user.__location.theme_id];
    }
    return this.config.defaultTheme;
  }

  getCurrentSubTheme() {
    if (this.user && this.user.__location && this.user.__location.__sub_theme && this.user.__location.__sub_theme.id) {
      return this.user.__location.__sub_theme;
    }
  }

  setPrevUrl(prev_url?: string, prev_route?: string) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // this block should be first in this function - "return" is possible
    if (prev_route === 'login' || prev_route === 'logout' || prev_route === 'forgot_pwd' || prev_route === 'new_pwd') {
      return;
    }
    // code above should be first in this function - "return" is possible
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (prev_route) {
      this.prev_route = prev_route;
      this.storage.setItem(this.storage_key_prev_route, prev_route);
    }
    if (!prev_url) {
      prev_url = location.pathname + location.search;
    }
    if (prev_url) {
      this.prev_url = prev_url;
      this.storage.setItem(this.storage_key_prev_url, prev_url);
    }
  }

  navigateToPrevNonLoginPage(navigateToHomeOtherwise?: boolean): boolean {
    try {
      let prev_route = this.getPrevRouteFromStorage();
      if (prev_route) {
        setTimeout(() => {
          this.storage.removeItem(this.storage_key_prev_route);
          this.storage.removeItem(this.storage_key_prev_url);
        }, 5);
        this.router.navigate(['/' + prev_route]);
        return true;
      } else {
        let url = this.getPrevUrlFromStorage();
        if (url) {
          setTimeout(() => {
            this.storage.removeItem(this.storage_key_prev_route);
            this.storage.removeItem(this.storage_key_prev_url);
          }, 5);
          this.router.navigateByUrl(url);
          return true;
        }
      }
      if (navigateToHomeOtherwise) {
        this.router.navigate(['']);
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  getPrevRouteFromStorage() {
    try {
      return this.storage.getItem(this.storage_key_prev_route);
    } catch (e) {
      return '';
    }
  }

  getPrevUrlFromStorage() {
    try {
      return this.storage.getItem(this.storage_key_prev_url);
    } catch (e) {
      return '';
    }
  }
}
