<div class="container review-layout">
  <div class="row">
    <div class="col-sm-6 offset-sm-3 mw-100" *ngIf="!displayThanks">
      <div class="bordered-container" *ngIf="!selectedPractice || selectedPractice==''">
        <div>Tell us more about your practice: (select one)</div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="radio" name="p" id="practice1" value="{{p[0]}}" [(ngModel)]="practice"/>
          <label class="form-check-label font-size-1" for="practice1">{{p[0]}}</label>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="radio" name="p" id="practice2" value="{{p[1]}}" [(ngModel)]="practice"/>
          <label class="form-check-label font-size-1" for="practice2">{{p[1]}}</label>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="radio" name="p" id="practice3" value="{{p[2]}}" [(ngModel)]="practice"/>
          <label class="form-check-label font-size-1" for="practice3">{{p[2]}}</label>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="radio" name="p" id="practice4" value="{{p[3]}}" [(ngModel)]="practice"/>
          <label class="form-check-label font-size-1" for="practice4">{{p[3]}}</label>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="radio" name="p" id="practice5" value="{{p[4]}}" [(ngModel)]="practice"/>
          <label class="form-check-label font-size-1" for="practice5">{{p[4]}}</label>
        </div>
        <div class="mt-1" [ngClass]="{'hidden':practice!==p[4],'animated flipInX':practice===p[4]}">
          <textarea class="form-control" rows="4" [(ngModel)]="otherText"></textarea>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-primary" style="min-width: 9em;" type="button" (click)="submit()" [disabled]="!practice || sending">Submit</button>
        </div>
      </div>
      <div class="bordered-container" *ngIf="selectedPractice===p[0]">
        <div>
          {{themeName}} has transitioned to a new group purchasing partner, HealthTrust/HPG, that demonstrated the lowest costs during a rigorous screening process over the last 12 months.<br/>
          The new program no longer restricts members to a single distributor, so it benefits practices that work with Cardinal, Henry Schein, McKesson and Medline.<br/>
          <br/>
          Please confirm the best method of communication to discuss the benefits of the new program.
        </div>
        <div class="row mt-3">
          <div class="col-6"><input class="form-control" [(ngModel)]="fname" placeholder="First Name" type="text"/></div>
          <div class="col-6"><input class="form-control" [(ngModel)]="lname" placeholder="Last Name" type="text"/></div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6"><input class="form-control" [(ngModel)]="email" placeholder="Email" type="email"/></div>
          <div class="col-sm-6"><input class="form-control" [(ngModel)]="phone" placeholder="Phone" type="text"/></div>
        </div>
        <div class="mt-4">
          What is the best time of day to contact you about our program? (Please include timezone)
        </div>
        <div class="mt-1">
          <textarea class="form-control" rows="2" [(ngModel)]="additionalInfo"></textarea>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-primary" style="min-width: 9em;" type="button" (click)="sendNote()" [disabled]="sending">Submit</button>
        </div>
      </div>
      <div class="bordered-container" *ngIf="selectedPractice===p[1]">
        <div>
          It appears you may be part of a health system, and unfortunately this opportunity is only for independent physician offices and surgery centers.<br/>
          Do you have independent or affiliated physician offices and surgery centers that are outside the health system?
        </div>
        <div class="mt-4 align-middle">
          <button class="btn btn-bordered btn-primary mr-2" type="button" (click)="additionalInfo='Yes';sendNote()">Yes</button>
          <button class="btn btn-bordered btn-outline-primary" type="button" (click)="additionalInfo='No';sendNote()">No</button>
        </div>
      </div>
      <div class="bordered-container" *ngIf="selectedPractice===p[2]">
        <div>
          Please confirm the best method of communication:
        </div>
        <div class="row mt-3">
          <div class="col-6"><input class="form-control" [(ngModel)]="fname" placeholder="First Name" type="text"/></div>
          <div class="col-6"><input class="form-control" [(ngModel)]="lname" placeholder="Last Name" type="text"/></div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6"><input class="form-control" [(ngModel)]="email" placeholder="Email" type="email"/></div>
          <div class="col-sm-6"><input class="form-control" [(ngModel)]="phone" placeholder="Phone" type="text"/></div>
        </div>
        <div class="mt-4">
          What is the best time of day to contact you about our program? (Please include timezone)
        </div>
        <div class="mt-1">
          <textarea class="form-control" rows="2" [(ngModel)]="additionalInfo"></textarea>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-primary" style="min-width: 9em;" type="button" (click)="sendNote()" [disabled]="sending">Submit</button>
        </div>
      </div>
      <div class="bordered-container" *ngIf="selectedPractice===p[3]">
        <div>
          With whom in your office should we work with?
        </div>
        <div class="row mt-3">
          <div class="col-6"><input class="form-control" [(ngModel)]="fnameNew" placeholder="First Name" type="text"/></div>
          <div class="col-6"><input class="form-control" [(ngModel)]="lnameNew" placeholder="Last Name" type="text"/></div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6"><input class="form-control" [(ngModel)]="emailNew" placeholder="Email" type="email"/></div>
          <div class="col-sm-6"><input class="form-control" [(ngModel)]="phoneNew" placeholder="Phone" type="text"/></div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-primary" style="min-width: 9em;" type="button" (click)="sendNote()" [disabled]="sending">Submit</button>
        </div>
      </div>
    </div>
    <div class="col-sm-10 offset-sm-1 mw-100" *ngIf="displayThanks">
      <div class="bordered-container">
        <div class="bordered-content clearfix">
          <div class="text-center"><h4 class="color-primary">Thank you for your feedback.</h4></div>
        </div>
      </div>
    </div>
  </div>
</div>
