import {Component} from '@angular/core';
import {UrlParser} from "../../services/UrlParser";
import {Config} from "../../services/Config";
import {FDTickets} from "../../services/FDTickets";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {Distributors} from "../../services/Distributors";
import {Questions} from "../../services/Questions";

@Component({
  selector: 'flu-prebook-thanks',
  templateUrl: 'flu_prebook_thanks.html'
})
export class FluPrebookThanksCmp {
  public channelEmail = 'support@purchaseclinic.com';
  public channelPhone = '877.659.2159';
  public email = '';
  public message = '';
  public sending = false;
  public sent = false;
  public distr_name = '';
  public distr_slug = '';
  public channel_slug = '';
  public pcid = '';
  public hideDistrQuestion = false;

  constructor(private urlParser: UrlParser, private config: Config, private tickets: FDTickets, private session: Session, private distrSrv: Distributors, private qSrv: Questions) {
    let theme = this.config.defaultTheme;
    if (theme) {
      if (theme.email_from_address) {
        this.channelEmail = theme.email_from_address;
      } else {
        if (theme.contact_email) {
          this.channelEmail = theme.contact_email;
        }
      }
      if (theme.phone) {
        this.channelPhone = theme.phone;
      }
    }
    this.session.addOnLoadHandler(() => {
      try {
        if (this.session.user) {
          if (this.session.user.email) {
            this.email = this.session.user.email;
          }
          if (this.session.user.__location) {
            if (this.session.user.__location.pc_id) {
              this.pcid = this.session.user.__location.pc_id;
            }
            if (this.session.user.__location.theme_id) {
              let theme = this.config.themesMap[this.session.user.__location.theme_id];
              if (theme && theme.slug) {
                this.channel_slug = theme.slug;
              }
            }
            if (this.session.user.__location.__distributor_id) {
              this.distrSrv.load().then((distrs) => {
                for (let distr of distrs) {
                  if (distr.id == this.session.user.__location.__distributor_id) {
                    if (distr && distr.slug) {
                      this.distr_slug = distr.slug;
                    }
                    if (distr && distr.name) {
                      this.distr_name = distr.name;
                    }
                    break;
                  }
                }
              });
            }
          }
          let loc_id;
          if (this.session.user.__location && this.session.user.__location.id) {
            loc_id = this.session.user.__location.id;
          }
          let pc_id;
          if (this.session.user.__location && this.session.user.__location.pc_id) {
            pc_id = this.session.user.__location.pc_id;
          }
          this.qSrv.getReviewedExternalSurveys(loc_id, pc_id, this.session.user.email).subscribe((kinds) => {
            if (kinds && kinds.indexOf("distributor_survey") > -1) {
              this.hideDistrQuestion = true;
            }
          }, () => {});
        }
      } catch (e) {}
    });
    this.session.loadUser();
  }

  ngOnInit() {
    let params: any = this.urlParser.getParameters();
    if (params) {
      if (params.channel) {
        this.channel_slug = params.channel;
      }
      if (params.pcid) {
        this.pcid = params.pcid;
      }
      if (params.distr_name) {
        this.distr_name = params.distr_name;
      }
      if (params.distr_slug) {
        this.distr_slug = params.distr_slug;
      }
    }
  }

  send() {
    if (!this.message) {
      return;
    }
    let n = new Notify();
    if (!this.email) {
      n.error(this.config.txt.tmpAuthEmailRequired);
      return;
    }
    this.sending = true;
    this.tickets.create(this.email, 'Survey Questions/Updates', this.message, {}).subscribe(() => {
      this.sending = false;
      this.sent = true;
      n.success(this.config.txt.successfully);
    }, () => {
      this.sending = false;
      n.error(this.config.txt.emailSendingError);
    });
  }
}
