import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Categories } from "../../services/Categories";
import { Config } from "../../services/Config";
import { Cots } from "../../services/Cots";
import { Helpers } from "../../services/Helpers";
import { Locations } from "../../services/Locations";
import { Questions } from "../../services/Questions";
import { Session } from "../../services/Session";
import { Specialties } from "../../services/Specialties";
import { User } from "../../services/User";

declare var window: any;

@Component({
  selector: 'savings-report',
  templateUrl: './questions.html',
})
export class SurveySignupCmp {
  public zapierFinishSent = false;
  public zapierStartSent = false;
  public isCompletedCrmMessageSent = false;
  private benefitReviewUrl = '';

  constructor(private el: ElementRef, private questionsService: Questions,
    public router: Router, public config: Config,
    public session: Session, private route: ActivatedRoute) {

    const email = this.route.snapshot.queryParamMap.get('email');
    
    if (email) {
      this.hitZapierFinishEndpoint(email);
      this.router.navigate(['/signup_benefit_review_thanks']);
    }
    
    this.questionsService.getExternalSurveysSurveyMonkeyBenefitReviewUrl(this.session.signupUrl).subscribe((url) => {
      if (url) {
        this.benefitReviewUrl = url;
      }

      this.session.loadUser(() => {
        this.hitZapierStartEndpoint();
      }, () => {
        this.router.navigate(['/login']);
      });
    }, () => { });
  }

  ngOnInit() {
    try {
      if (this.session.user.email) {
        if (window.localStorage.getItem('zapierStartSent:' + this.session.user.email) === '1') {
          this.zapierStartSent = true;
        }
        if (window.localStorage.getItem('zapierFinishSent:' + this.session.user.email) === '1') {
          this.zapierFinishSent = true;
        }
        if (window.localStorage.getItem('completedCrmMessageSent:' + this.session.user.email) === '1') {
          this.isCompletedCrmMessageSent = true;
        }
      }
    } catch (e) {
      console.error(e);
    }
    try {
      if (window.google_trackConversion) {
        let ga_result = window.google_trackConversion({
          google_conversion_id: 962918315,
          google_conversion_language: "en",
          google_conversion_format: "3",
          google_conversion_color: "ffffff",
          google_conversion_label: "hiuECISqkW8Qq--TywM",
          google_remarketing_only: false
        });
      }
    }
    catch (e) {
      console.error(e);
    }

    if (!this.route.snapshot.queryParamMap.get('email')) {
      setTimeout(() => {
        window.location.href = encodeURI(`${this.benefitReviewUrl}?email=${this.session.user.email}&landing_page=${this.session.signupUrl}`);
      }, 3000);
    }
  }

  ngOnDestroy() {
    try {
      if (this.session.user.email) {
        if (this.zapierStartSent) {
          window.localStorage.setItem('zapierStartSent:' + this.session.user.email, '1');
        }
        if (this.zapierFinishSent) {
          window.localStorage.setItem('zapierFinishSent:' + this.session.user.email, '1');
        }
        if (this.isCompletedCrmMessageSent) {
          window.localStorage.setItem('completedCrmMessageSent:' + this.session.user.email, '1');
        }
      }
    } catch (e) { }
  }

  hitZapierStartEndpoint() {
    try {
      if (this.zapierStartSent) {
        return;
      }
      this.zapierStartSent = true;
      let theme;
      if (this.config.themesMap[this.session.user.theme_id]) {
        theme = this.config.themesMap[this.session.user.theme_id];
      } else {
        theme = this.session.user.__theme;
      }
      this.questionsService.callZapierStartSignupSurvey(this.session.user.email, this.session.user.fname, this.session.user.lname, theme, this.benefitReviewUrl);
    } catch (e) {
      console.error(e);
    }
  }

  hitZapierFinishEndpoint(email: string) {
    try {
      if (this.zapierFinishSent) {
        return;
      }
      this.zapierFinishSent = true;
      this.questionsService.callZapierFinishSignupSurvey(email);
    } catch (e) {
      console.error(e);
    }
  }
}
