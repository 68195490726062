import {Component, Input, Output, EventEmitter} from '@angular/core';
declare var _:any;
declare var $:any;

@Component({
  selector: 'question-select-multiple',
  templateUrl: './q_select_multiple.html'
})
export class QuestionSelectMultipleCmp {
  @Input() question;
  @Input() textInput;
  @Output() update = new EventEmitter();
  public selectedValues = [];

  emitUpdate(option) {
    if (option) {
      let i = _.findIndex(this.selectedValues, {id: option.id});
      if (i > -1) {
        this.selectedValues.splice(i, 1);
      } else {
        this.selectedValues.push(option);
      }
    }
    this.update.emit(this.selectedValues);
  }

  ngAfterViewInit() {
    $('input[type="checkbox"]').attr('checked', false);
  }
}
