import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ILocation} from "../../interfaces/ILocation";
import {ILifecycleData} from "../../interfaces/ILifecycleData";
import {Session} from "../../services/Session";
import {FDTickets} from "../../services/FDTickets";
import {Notify} from "../../services/Notify";

declare var $:any;

@Component({
  templateUrl: './account-distributor.html',
  selector: 'account-distributor',
})
export class AccountDistributorCmp {
  @Input() location:ILocation;
  @Input() lc:ILifecycleData;
  @ViewChild('nolongerusemodal') nolongerusemodal:ElementRef;
  public showCircleDates = false;
  public showCircleRep = false;
  public showCircleReporting = false;
  public noLongerUseComment = '';
  public noLongerUseSubmitting = false;
  public noLongerUseDistrName = '';

  constructor(private session:Session, private tickets:FDTickets) {}

  ngOnChanges() {
    if (!this.lc) {
      return;
    }
    if (this.lc.pa_date) {
      this.lc.pa_date = <any>Date.parse(this.lc.pa_date);
    }
    if (this.lc.zimmer_sent_date) {
      this.lc.zimmer_sent_date = <any>Date.parse(this.lc.zimmer_sent_date);
    }
    if (this.lc.medsurg_eff_date) {
      this.lc.medsurg_eff_date = <any>Date.parse(this.lc.medsurg_eff_date);
    }
    if (this.lc.pharma_eff_date) {
      this.lc.pharma_eff_date = <any>Date.parse(this.lc.pharma_eff_date);
    }
    if (this.lc.connected_date_primary) {
      this.lc.connected_date_primary = <any>Date.parse(this.lc.connected_date_primary);
    }
    if (this.lc.sent_date_primary) {
      this.lc.sent_date_primary = <any>Date.parse(this.lc.sent_date_primary);
    }
    if (this.lc.connected_date_secondary) {
      this.lc.connected_date_secondary = <any>Date.parse(this.lc.connected_date_secondary);
    }
    if (this.lc.sent_date_secondary) {
      this.lc.sent_date_secondary = <any>Date.parse(this.lc.sent_date_secondary);
    }
  }

  noLongerUseModalShow(distrName) {
    this.noLongerUseDistrName = distrName;
    if (this.nolongerusemodal && this.nolongerusemodal.nativeElement) {
      $(this.nolongerusemodal.nativeElement).modal('show');
    }
  }

  noLongerUseModalHide() {
    if (this.nolongerusemodal && this.nolongerusemodal.nativeElement) {
      $(this.nolongerusemodal.nativeElement).modal('hide');
      this.noLongerUseDistrName = '';
    }
  }

  noLongerUseSubmit() {
    let user = this.session.user;
    if (!user || !user.id) {
      return;
    }
    if (!this.location) {
      return;
    }
    let n = new Notify();
    let email = user.email;
    if (!email || email.indexOf('@') < 1) {
      return false;
    }
    let customFields = {};
    if (user && user.__theme && user.__theme.name) {
      customFields['brand'] = user.__theme.name;
    }
    if (this.location && this.location.pc_id) {
      customFields['pc_id'] = this.location.pc_id;
    }
    this.noLongerUseSubmitting = true;
    let msg = `<hr>`;
    if (this.noLongerUseDistrName) {
      msg = msg + '<div>Distributor: ' + this.noLongerUseDistrName + '</div>';
    }
    if (this.noLongerUseComment) {
      msg = msg + `<div>Commentary: ${this.noLongerUseComment}</div>`;
    }
    msg = `<div>Location: <a href="https://crm.purchase.guru/location/${this.location.id}" target="_blank">${this.location.facility_name} (${this.location.pc_id})</a></div>${msg}`;
    if (user) {
      msg = `<div>User: <a href="https://crm.purchase.guru/user/${user.id}" target="_blank">${user.fname} ${user.lname}</a>, email: ${user.email}</div>${msg}`;
    }
    this.tickets.create(email, 'No longer use this distributor', msg, customFields, this.location.__manager_id).subscribe(() => {
      this.noLongerUseSubmitting = false;
      this.noLongerUseComment = '';
      n.success("Thank you. Your request has been submitted and a client manager will be in touch if we have any questions.");
      this.noLongerUseModalHide();
    }, (error) => {
      this.noLongerUseSubmitting = false;
      console.error(error);
      n.error('Error, sorry...');
    });
  }
}
