<section *ngIf="!welcomeScreenPassed">
  <div class="container">
    <h2 class="text-center color-primary">Welcome to the 2020-2021 Flu Pricing/Pre-book Information</h2>
    <h3 class="pc2-row-pad text-center">HealthTrust (HPG) Contract Pricing</h3>
    <p>{{contactName}} exclusively utilizes the HealthTrust (HPG) contract portfolio, which includes market-leading pricing for both influenza and non-influenza vaccines.</p>
    <h3 class="pc2-row-pad text-center">Confidentiality</h3>
    <p>On the next page you will find confidential flu vaccine pricing available to your facility as a member of this program. This pricing is provided under the confidentiality terms of your participation agreement. Please understand that sharing it
      with non-contracted distributors, other group purchasing or physician buying groups, or non-members jeopardizes all members' ability to access this pricing in the future. Thank you for helping us protect the market-leading pricing HealthTrust
      is making available to our membership.</p>
    <h3 class=" text-center">Steps</h3>
    <ol class="text-left">
      <li>Review pricing</li>
      <li>Confirm how you purchase/pre-book your flu vaccines (distributor or direct)</li>
      <li>We will contact you with any questions after reviewing your documentation</li>
      <li>You may contact us with questions at any time</li>
    </ol>
    <br/>
    <div class="text-center">
      <button class="btn btn-primary" type="button" (click)="skipWelcomePage()">Continue to Review Pricing</button>
    </div>
  </div>
</section>
<section *ngIf="welcomeScreenPassed">
  <div class="container">
    <h4 class="text-center">Please see the confidential flu vaccine pricing available to your facility</h4>
    <table class="table table-vaccines table-sm pc2-row-pad font-sm table-striped">
      <tbody>
      <tr>
        <th>Supplier</th>
        <th>NDC</th>
        <th>Item</th>
        <th>Description</th>
        <th class="text-center">Contract Price without Federal Excise Tax (FET)</th>
        <th class="text-center">Contract Price with Federal Excise Tax (FET)</th>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0120-65</td>
        <td>Fluzone High Dose Quadrivalent PFS</td>
        <td>0.7mL syringes, no needles, 10 per box</td>
        <td class="text-center">$467.00</td>
        <td class="text-center">$474.50</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0633-15</td>
        <td>Fluzone Quadrivalent MDV</td>
        <td>5mL multi-dose vial, 10-doses</td>
        <td class="text-center">$141.80</td>
        <td class="text-center">$149.30</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0420-10</td>
        <td>Fluzone Quadrivalent SDV</td>
        <td>0.5mL single dose vials, 10 per box</td>
        <td class="text-center">$151.90</td>
        <td class="text-center">$159.40</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0420-50</td>
        <td>Fluzone Quadrivalent PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">$151.90</td>
        <td class="text-center">$159.40</td>
      </tr>
      <tr>
        <td>Sanofi-Pastuer</td>
        <td>49281-0720-10</td>
        <td>Flublok Quadrivalent Egg-Free PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">$467.00</td>
        <td class="text-center">$474.50</td>
      </tr>
      </tbody>
    </table>
    <table class="pc2-row-pad-big table table-vaccines table-sm pc2-row-pad font-sm table-striped">
      <tbody>
      <tr>
        <th>Supplier</th>
        <th>NDC</th>
        <th>Item</th>
        <th>Description</th>
        <th class="text-center">Contract Price without Federal Excise Tax (FET)</th>
        <th class="text-center">Contract Price with Federal Excise Tax (FET)</th>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>70461-0020-03</td>
        <td>Fluad Trivalent Adjuvanted PFS</td>
        <td>0.5mL pre-filled syringe, no needles, 10 per box</td>
        <td class="text-center">$401.70</td>
        <td class="text-center">$409.20</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>70461-0320-03</td>
        <td>Flucelvax Quadrivalent PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">$167.40</td>
        <td class="text-center">$174.90</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>70461-0420-10</td>
        <td>Flucelvax Quadrivalent MDV</td>
        <td>5mL multi-dose vial, 10-doses</td>
        <td class="text-center">$158.40</td>
        <td class="text-center">$165.90</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>33332-0220-20</td>
        <td>Afluria Quadrivalent Pediatric PFS</td>
        <td>0.25mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">$144.10</td>
        <td class="text-center">$151.60</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>33332-0320-01</td>
        <td>Afluria Quadrivalent PFS</td>
        <td>0.5mL pre-filled syringes, no needles, 10 per box</td>
        <td class="text-center">$144.10</td>
        <td class="text-center">$151.60</td>
      </tr>
      <tr>
        <td>Seqirus</td>
        <td>33332-0420-10</td>
        <td>Afluria Quadrivalent MDV</td>
        <td>5mL multi-dose vial, 10-doses</td>
        <td class="text-center">$134.50</td>
        <td class="text-center">$142.00</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="container mb-2">
    <div class="text-center">
      <a class="btn btn-primary" [href]="surveyUrl">Continue to Step 3</a>
    </div>
  </div>
</section>

