import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ForgotPwd} from "../../services/ForgotPwd";

@Component({
  selector: 'new-password',
  templateUrl: './new-pwd.html',
  viewProviders: [ForgotPwd]
})
export class NewPasswordCmp {
  public pwd:string = '';
  public isSet = false;
  public code:string = '';
  public error;

  constructor(private router:Router, private params:ActivatedRoute, private forgotPwdService:ForgotPwd) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.code = params["code"];
    if (!this.code) {
      this.router.navigate(['/forgot_pwd']);
      return;
    }
    this.forgotPwdService.checkCode(this.code).subscribe(()=> {}, ()=> {
      this.code = '';
      this.router.navigate(['/forgot_pwd']);
    });
  }

  setPwd() {
    if (!this.code || !this.pwd) {
      return;
    }
    this.forgotPwdService.setNewPassword(this.code, this.pwd).subscribe(()=> {
      this.isSet = true;
    }, (response)=> {
      this.error = response.status;
      console.error(response);
    });
  }
}
