import {Component, Input} from '@angular/core';
import {Contracts} from '../../services/Contracts';
import {IFeaturedCategory} from '../../interfaces/IFeaturedCategory';
import {IContract} from "../../interfaces/IContract";
import {Session} from "../../services/Session";

@Component({
  selector: 'contract-list-item',
  templateUrl: './contract-list-item.html',
})
export class ContractListItemCmp {
  @Input() contract:IContract;
  @Input() contractId:number;
  @Input() category:IFeaturedCategory;
  @Input() searchTerm:string = '';
  public subcategory:string = '';
  public displayPrices = false;
  public hasPrices = false;

  constructor(private contracts:Contracts, private session:Session) {}

  ngOnChanges() {
    if (this.contract) {
      if (this.contract.__subcategories && this.contract.__subcategories.length) {
        this.subcategory = this.contract.__subcategories.join('; ');
      }
      this.checkPrices();
    } else {
      if (this.contractId) {
        this.contracts.load(this.contractId).subscribe((contract) => {
          this.contract = contract;
          if (this.contract.product_subcategory) {
            this.subcategory = this.contract.product_subcategory.replace(/\|/g, '; ');
          }
          this.checkPrices();
        }, () => {});
      }
    }
  }

  checkPrices() {
    this.displayPrices = false;
    if (this.session && this.session.user && this.session.user.id && !this.session.user._is_login_auto) {
      this.displayPrices = true;
    }
    this.hasPrices = false;
    if (this.contract && this.contract.order_from == 'Direct') {
      this.hasPrices = true;
    }
  }
}
