import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IDistributor} from '../interfaces/IDistributor';

@Injectable()
export class Distributors {
  private config:Config;
  private session:Session;
  private http:HttpClient;
  private list:IDistributor[] = [];
  private loadingPromise;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  load() {
    if (this.list && this.list.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.list);
      });
    }
    if (this.loadingPromise) {
      return this.loadingPromise;
    }
    this.loadingPromise = new Promise((resolve, reject)=> {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get<IDistributor[]>(this.config.apiUrl + '/distributor', {headers: headers}).subscribe((objects)=> {
        this.loadingPromise = null;
        if (objects instanceof Array) {
          this.list = objects;
          resolve(this.list);
        } else {
          reject();
        }
      }, ()=> {
        this.loadingPromise = null;
        reject();
      });
    });
    return this.loadingPromise;
  }

  loadToMap(map:{[id:number]:IDistributor;}) {
    this.load().then((distributors)=> {
      if (distributors && distributors.length > 0) {
        distributors.forEach((d:IDistributor)=> {
          if (d.is_popular) {
            map[d.id] = d;
          }
        });
      }
    }, ()=> {});
  }

  loadToList(list:IDistributor[], cb?) {
    this.load().then((distributors)=> {
      if (distributors && distributors.length > 0) {
        distributors.forEach((d:IDistributor)=> {
          if (d.is_popular) {
            list.push(d);
          }
        });
        if (cb) {
          cb();
        }
      }
    }, ()=> {});
  }
}
