<div class="card">
  <div class="card-header solo">
  <span class="pc2-pre-text text-muted">
    <span *ngIf="channel">
    Thank you for choosing {{ channel }} as your purchasing partner. Through {{ channel }} you are leveraging the HealthTrust purchasing portfolio; coupled with Purchase Clinic technology and Expansion, LLC group purchasing administration and staffing. These combined resources offer you a unique cost and service structure to ensure access to industry best pricing. The regulations that govern our industry mandate that we report group purchasing contract activity on an annual basis.<br/>
    <br/>
    {{ channel }} and Expansion LLC access this committed contract pricing through the HealthTrust (HPG)/AdvantageTrust contract portfolio. In compliance with the Federal Medicare/Medicaid laws and Safe Harbor Regulations 42 USC § 1320a-7b(b)(3)(C) and 42CFR § 1001.952 (j). The GPO Fees received are used by the service structure including Expansion, LLC and HealthTrust to underwrite the cost of providing the various vendor contracting and related services to your organization.<br/>
    <br/></span>
    Please see the information below listing the group purchasing organization administration fees (“GPO Fees”) received by HealthTrust Purchasing Group,
    LP (“HealthTrust”) from the listed vendors, as well as the Global Sourcing Fees received by HealthTrust, based on purchases by you and your affiliated facilities for calendar year {{report_year}}.
    <br/>
    GPO Fee percentages paid by HealthTrust vendors are equal to or less than 3% of purchases, except for AT&T, which pays fees equal to 4.5% of payments received on its accounts.
    Also, HealthTrust will receive GPO Fees from some vendors that are distributors of products in amounts that are one percent (1%) or less, resulting in a total GPO Fee
    from both the vendor manufacturer and the vendor distributor that may exceed three percent (3%), but which will not exceed four percent (4%) of the purchase price paid by a facility.
    The Global Sourcing Fee ranges from zero to 5% of the sourcing costs of products sourced under HealthTrust’s Global Sourcing Program.<br/>
    <br/>
    For {{report_year}}, HealthTrust did not receive any funding from
    vendors to support HealthTrust education and meeting expenses, but HealthTrust did receive fees for booth rentals and other promotional opportunities charged to vendors at
    HealthTrust seminars, meetings and conferences. HealthTrust also received fees from vendors for advertisements placed in HealthTrust publications.
    (Note that HealthTrust does not require any supplier to purchase exhibition booth
    space, sponsor or otherwise participate in any educational events or conferences sponsored by HealthTrust, or to purchase any advertising in any HealthTrust publication.)
  </span>
  </div>
</div>
<table class="table table-sm table-hover font-sm" *ngIf="sales && sales.length>0">
  <thead>
    <tr>
      <th>GPO ID</th>
      <th>Vendor</th>
      <th>Contract / Description</th>
      <th class="text-right">Sales</th>
      <th class="text-right">Fee</th>
      <th class="text-right">Allocation Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let s of sales">
      <td>{{s.gpoid}}</td>
      <td>{{s.vendor}}</td>
      <td>{{s.contract_number}} {{s.contract_description}}</td>
      <td class="text-right"><span *ngIf="s.sales_amount">$&nbsp;</span>{{s.sales_amount|numcomm}}</td>
      <td class="text-right"><span *ngIf="s.fee_amount">$&nbsp;</span>{{s.fee_amount|numcomm}}</td>
      <td class="text-right">{{s.allocation_cycle_month}}</td>
    </tr>
  </tbody>
</table>
<div class="col-12" *ngIf="salesCSV && sales && sales.length>0">
  <a target="_blank" class="btn btn-primary btn-sm float-right" href="{{salesCSV}}">Download as CSV</a>
</div>
<div *ngIf="!loading && (!sales || sales.length<1)">
  <p class="text-center">There were no admin fees received in the prior year. Please see the Sales report for supplier activity in the previous 12 months.</p>
</div>
