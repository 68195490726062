import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from "./Config";
import {Observable} from "rxjs";

@Injectable()
export class FDTickets {
  constructor(private config:Config, private http:HttpClient) {}

  create(from:string, subject:string, body:string, customFields:Object, assign_cm_id?:number):Observable<string> {
    let cm_id = null;
    if (assign_cm_id > 0) {
      cm_id = assign_cm_id;
    }
    return this.http.post(this.config.apiUrl + '/fd_ticket', JSON.stringify({
      requester_email: from,
      subject: subject,
      body: body,
      custom_fields: customFields,
      assign_cm_id: cm_id
    }), {responseType: 'text'});
  }

  addNote(ticketID: number, note: string): Observable<string> {
    return this.http.post(this.config.apiUrl + '/fd_note', JSON.stringify({ticket_id: ticketID, note: note}), {responseType: 'text'});
  }
}
