import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ILocation} from "../../interfaces/ILocation";
import {FDTickets} from "../../services/FDTickets";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {IDistributor} from "../../interfaces/IDistributor";
import {Distributors} from "../../services/Distributors";

declare var $:any;

@Component({
  templateUrl: './acc-distr-list.html',
  selector: 'account-distributors-list',
})
export class AccountDistributorsListCmp {
  @Input() locations:ILocation[];
  @ViewChild('newDistrModal') newDistrModal:ElementRef;
  public addingNewDistributor = false;
  public nd_note = '';
  public nd_rep_name = '';
  public distributorsList:IDistributor[] = [];
  public distributorsMap:{[id: number]: IDistributor;} = {};
  public nd_distr_id:number = 0;

  constructor(private session:Session, private tickets:FDTickets, private distributors:Distributors) {}

  ngAfterViewInit() {
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
  }

  newDistrModalShow() {
    if (this.newDistrModal && this.newDistrModal.nativeElement) {
      $(this.newDistrModal.nativeElement).modal('show');
    }
  }

  newDistrModalHide() {
    if (this.newDistrModal && this.newDistrModal.nativeElement) {
      $(this.newDistrModal.nativeElement).modal('hide');
    }
  }

  addNewDistributorTicket() {
    let user = this.session.user;
    if (!user || !user.id) {
      return;
    }
    if (!this.nd_distr_id) {
      return;
    }
    let distr;
    try {
      distr = this.distributorsMap[this.nd_distr_id];
    }
    catch (e) {
      return;
    }
    if (!distr) {
      return;
    }
    if (!this.locations || !this.locations[0]) {
      return;
    }
    let location = this.locations[0];
    let n = new Notify();
    let email = user.email;
    if (!email || email.indexOf('@') < 1) {
      return false;
    }
    let customFields = {};
    if (user && user.__theme && user.__theme.name) {
      customFields['brand'] = user.__theme.name;
    }
    if (location && location.pc_id) {
      customFields['pc_id'] = location.pc_id;
    }
    this.addingNewDistributor = true;
    let msg = `<hr>`;
    msg = msg + `<div>Distributor: ${distr.name} (ID: ${this.nd_distr_id})</div>`;
    if (this.nd_rep_name) {
      msg = msg + '<div>Rep Name: ' + this.nd_rep_name + '</div>';
    }
    if (this.nd_note) {
      msg = msg + `<div>Note: ${this.nd_note}</div>`;
    }
    msg = `<div>Location: <a href="https://crm.purchase.guru/location/${location.id}" target="_blank">${location.facility_name} (${location.pc_id})</a></div>${msg}`;
    if (user) {
      msg = `<div>User: <a href="https://crm.purchase.guru/user/${user.id}" target="_blank">${user.fname} ${user.lname}</a>, email: ${user.email}</div>${msg}`;
    }
    this.tickets.create(email, 'Add new distributor', msg, customFields, location.__manager_id).subscribe(() => {
      this.addingNewDistributor = false;
      this.nd_note = '';
      n.success('Successfully!');
      this.newDistrModalHide();
    }, (error) => {
      this.addingNewDistributor = false;
      console.error(error);
      n.error('Error, sorry...');
    });
  }
}
