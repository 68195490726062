<div class="container review-layout">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 mw-100" *ngIf="!displayThanks">
      <div class="bordered-container">
        <spinner *ngIf="loading" spinnerType="inner-circles-loader"></spinner>
        <div class="alert alert-danger alert-dismissible" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <span *ngIf="errMsg">{{errMsg}}</span>
          <span *ngIf="!errMsg">We are sorry, error happened on the server. Please contact us at <a target="_blank" href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a>.</span>
        </div>
        <section [hidden]="loading">
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==0">
            <label class="label-lg">Your email address:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter an email" [(ngModel)]="signup.user.email"/>
            </div>
            <div class="mt-4">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()" [disabled]="!signup.user.email || (signup.user.email?.indexOf('@')<0)">Next &nbsp;<i class="fas fa-angle-right"></i></button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==1">
            <label class="label-lg">Your name:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter your name" [(ngModel)]="name"/>
            </div>
            <div class="mt-4">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==2">
            <label class="label-lg">Facility Name:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter your response" [(ngModel)]="signup.location.facility_name"/>
            </div>
            <div class="mt-4">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==3">
            <label class="label-lg">Your phone number:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter your phone number" [(ngModel)]="signup.user.phone"/>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()" [disabled]="!signup.user.phone">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStepUI()">Skip</button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==4">
            <label class="label-lg">Facility Zip Code:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter your response" [(ngModel)]="signup.location.zip"/>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()" [disabled]="!signup.location.zip">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStepUI()">Skip</button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==5">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-6"><span class="align-middle">Please review your contact information:</span></div>
                  <div class="col-6">
                    <button type="button" class="btn btn-sm btn-outline-info float-right" (click)="detailsEdit=!detailsEdit"><i class="fas fa-pen-square"></i>&nbsp;&nbsp;Edit</button>
                  </div>
                </div>
                <section *ngIf="!detailsEdit">
                  <div class="row">
                    <div class="col-12">
                      <div>{{name}}</div>
                      <div>{{signup.user.email}}</div>
                      <div>{{signup.location.facility_name}}</div>
                      <div>{{signup.location.address}}</div>
                      <div *ngIf="signup.location.address2">{{signup.location.address2}}</div>
                      <div>{{[signup.location.city, signup.location.state, signup.location.zip]|arr_join}}</div>
                    </div>
                  </div>
                </section>
                <section *ngIf="detailsEdit">
                  <div class="row pc2-form-group pc2-row-pad">
                    <div class="col-12"><input class="form-control" [(ngModel)]="name" placeholder="Name" type="text"/></div>
                  </div>
                  <div class="row pc2-form-group">
                    <div class="col-sm-6">
                      <input class="form-control" [(ngModel)]="signup.user.email" placeholder="Email" type="email"/>
                    </div>
                    <div class="col-sm-6">
                      <input class="form-control" [(ngModel)]="signup.user.phone" placeholder="Phone" type="text"/>
                    </div>
                  </div>
                  <div class="row pc2-form-group">
                    <div class="col-12">
                      <input class="form-control" [(ngModel)]="signup.user.job_title" placeholder="Job Title" type="text"/>
                    </div>
                  </div>
                  <div class="row pc2-form-group">
                    <div class="col-12">
                      <input class="form-control" [(ngModel)]="signup.location.facility_name" placeholder="Company Name" type="text"/>
                    </div>
                  </div>
                  <div class="row pc2-form-group">
                    <div class="col-12">
                      <input class="form-control" [(ngModel)]="signup.location.address" placeholder="Address" type="text"/>
                    </div>
                  </div>
                  <div class="row pc2-form-group">
                    <div class="col-12">
                      <input class="form-control" [(ngModel)]="signup.location.address2" placeholder="Address 2" type="text"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4"><input class="form-control" [(ngModel)]="signup.location.city" placeholder="City" type="text"/></div>
                    <div class="col-sm-4"><input class="form-control" [(ngModel)]="signup.location.state" placeholder="State" type="text"/></div>
                    <div class="col-sm-4"><input class="form-control" [(ngModel)]="signup.location.zip" placeholder="ZIP Code" type="text"/></div>
                  </div>
                </section>
              </div>
            </div>
            <div *ngIf="displayTos" class="container-fluid p-0">
              <div>
                I agree to the Terms of Service, Privacy Policy and Participation Agreement provided below.
              </div>
              <div>
                <a href="" class="pc2-terms-link" data-toggle="modal" data-target="#termsModal">Read our Terms of Service, Privacy Policy and Participation Agreement</a>
              </div>
              <div class="mt-4 align-middle">
                <button class="btn btn-lg btn-outline-primary" type="button" (click)="submit()" *ngIf="!saving">Yes <i class="far fa-thumbs-up"></i></button>
                <button class="btn btn-lg btn-outline-primary pc2-wait" type="button" *ngIf="saving" disabled>Yes <i class="far fa-thumbs-up"></i></button>
                <button class="ml-2 btn btn-lg btn-outline-primary" type="button" (click)="displayTosIsRequired =  !!1" [disabled]="saving">No <i class="far fa-thumbs-down"></i></button>
              </div>
              <div class="alert alert-warning mt-3" *ngIf="displayTosIsRequired">
                You must agree with the Terms of Service to continue.
              </div>
            </div>
            <div class="container-fluid p-0" *ngIf="!displayTos">
              <button type="button" class="float-right btn btn-primary" (click)="submit()" *ngIf="!saving">Submit</button>
              <button type="button" class="float-right btn btn-primary pc2-wait" disabled *ngIf="saving">Submit</button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==6">
            <label class="label-lg">Please select your practice’s primary medical supply:</label>
            <div class="mt-4" *ngIf="distributorsList.length">
              <select [ngModel]="distr_id" (change)="distr_id = $event.target?.value" class="form-control" [ngModelOptions]="{standalone: true}">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let dist of distributorsList | orderBy:'survey_order'" value="{{dist.id}}">{{dist.name}}</option>
              </select>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStepUI()">Skip</button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==7">
            <label class="label-lg">Does your practice also use any of the distributors below?</label>
            <div class="mt-4" *ngIf="distributorsList.length">
              <select [ngModel]="secondary_distr_id" (change)="secondary_distr_id = $event.target?.value" class="form-control" [ngModelOptions]="{standalone: true}">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let dist of distributorsSecondaryList | orderBy:'survey_order_secondary'" value="{{dist.id}}">{{dist.name}}</option>
              </select>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStepUI()">Skip</button>
            </div>
          </div>
          <div class="bordered-content clearfix animated slideInRight faster" style="min-height: 200px" [hidden]="step!==8">
            <label class="label-lg">Please provide your primary distributor rep name below:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter your response" [(ngModel)]="repName"/>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStepUI()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStepUI()">Skip</button>
            </div>
          </div>
        </section>
      </div>
      <div class="row" [hidden]="loading">
        <div class="col-10 d-flex align-items-center">
          <circle-progress [percent]="getProgressPercent()" size="36"></circle-progress>
        </div>
        <div class="col-2 d-flex justify-content-end">
          <button class="btn btn-outline-secondary" [disabled]="step<1" (click)="prevStep()"><i class="fas fa-angle-left"></i></button>
          <button class="btn btn-outline-secondary ml-2" [disabled]="isNextStepDisabled()" (click)="nextStepUI()"><i class="fas fa-angle-right"></i></button>
        </div>
      </div>
    </div>
    <div class="col-sm-10 offset-sm-1 mw-100" *ngIf="displayThanks">
      <div class="bordered-container">
        <div class="bordered-content clearfix" style="min-height: 200px">
          <div class="text-center"><h4 class="color-primary">Signup Successful!</h4></div>
          <div class="mt-3 text-nowrap">
            Thank you for signing up for {{themeName}}.<br/>
            Our client managers will review your information and reach out to you with any additional questions to optimize your savings.<br/>
            If you have any questions, please email us at <a href="mailto:{{themeEmail}}">{{themeEmail}}</a> or call us at {{themePhone}}.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Terms of Service, Privacy Policy and Participation Agreement</h4>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body high-modal">
        <tos-text [user]="signup.user" [loc]="signup.location" [signature]="signup.signature"></tos-text>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
