<section class="main-content contract-layout">
  <div class="container">

    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a  routerLink="/">Home</a></li>
      <li class="breadcrumb-item active">{{featured?.specialty?.name}}</li>
    </ol>

    <div class="row">
      <div class="col-md-12 w-100 ov-h">
        <h1>Contracts for "{{featured?.specialty?.name}}"</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 w-100 ov-h">
        <specialty-slider [id]="specialtyID"></specialty-slider>
      </div>
      <div class="col-md-4 w-100" [attr.data-hidden]="session.user?.__theme?.id == 2">

      </div>
    </div>

    <div class="row" *ngFor="let category of featured?.categories">
      <div class="col-12">
        <h2>
          {{category?.label}}
        </h2>
        <a *ngIf="(featured?.contracts | category:category).length===0" href="/contact?category={{category?.label}}">Contact us for pricing</a>

      <contract-list-item [contract]="contract" [category]="category"
        *ngFor="let contract of featured?.contracts | category:category" class="w-100">
      </contract-list-item>
      </div>

    </div>


  </div>
  <!-- EOF Container-->
</section>
