import {Component} from '@angular/core';
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Router} from "@angular/router";
import {ITextInput} from "../../interfaces/ITextInput";

@Component({
  selector: 'account',
  templateUrl: './settings.html'
})
export class AccountSecurityCmp {
  public notify = new Notify();
  public user:IUser = <IUser>{};
  public saving = false;
  public error = 0;
  public totpSecret = '';
  public loading = false;
  public regenerateMode = false;
  public newPwd = '';
  public currentPwd = '';

  constructor(private specialties:Specialties, private cots:Cots, public session:Session,
    public config:Config, private userService:User, private router:Router) {
    this.session.addOnLoadHandler(()=> {
      this.user = this.session.user;
    });
    this.session.redirectIfNotAuth();
  }

  setNewPassword():void {
    if (!this.newPwd) {
      return;
    }
    this.saving = true;
    this.userService.changePassword(this.session.user.id, this.currentPwd, this.newPwd).then(()=> {
      this.notify.success(this.config.txt.passwordSaved);
      this.saving = false;
    }, ()=> {
      this.notify.error(this.config.txt.wrongPassword);
      this.saving = false;
    });
  }

  clearErrors() {
    this.error = 0;
  }

  turnOnTotp(password:ITextInput) {
    if (!password || !password.value) {
      return;
    }
    this.loading = true;
    this.userService.getNewTotpSecret(password.value).subscribe((response)=> {
      if (response.status > 199 && response.status < 300 && response.text()) {
        this.totpSecret = response.text();
        if (this.session && this.session.user) {
          this.session.user.__is_totp = true;
        }
        this.regenerateMode = false;
      } else {
        this.error = response.status;
      }
      this.loading = false;
      password.value = '';
    }, (response)=> {
      this.error = parseInt(response.status);
      this.loading = false;
      password.value = '';
    });
  }

  turnOffTotp(password:ITextInput):void {
    if (!password || !password.value) {
      return;
    }
    this.loading = true;
    this.userService.removeTotpSecret(password.value).subscribe((response)=> {
      if (response.status > 199 && response.status < 300) {
        if (this.session && this.session.user) {
          this.session.user.__is_totp = false;
        }
        this.totpSecret = '';
      } else {
        this.error = response.status;
      }
      this.loading = false;
      password.value = '';
    }, (response)=> {
      this.error = parseInt(response.status);
      this.loading = false;
      password.value = '';
    });
  }

  checkTotp(totp:ITextInput):void {
    if (!totp || !totp.value) {
      return;
    }
    this.userService.checkTotpCode(totp.value).subscribe((response)=> {
      if (response.status > 199 && response.status < 300) {
        this.totpSecret = '';
        this.notify.success('Code is correct');
      } else {
        this.notify.error('Code is wrong');
      }
      this.loading = false;
      totp.value = '';
    }, (response)=> {
      this.error = parseInt(response.status);
      this.loading = false;
      totp.value = '';
    });
  }

  regenerateBarcode() {
    this.totpSecret = '';
    this.clearErrors();
    this.regenerateMode = true;
  }
}
