<section class="main-content contract-layout">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active">Search</li>
    </ol>
    <spinner [spinnerType]="'throbber-loader'" *ngIf="loading"></spinner>
    <contract-list-item [contract]="contract" class="w-100" *ngFor="let contract of results" [searchTerm]="searchTerm"></contract-list-item>
  </div>
</section>
