<div class="container review-layout">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 mw-100" *ngIf="!displayThanks">
      <div class="bordered-container">
        <spinner *ngIf="loading" spinnerType="inner-circles-loader"></spinner>
        <div class="bordered-content clearfix" style="min-height: 200px" [hidden]="loading">
          <div class="alert alert-danger alert-dismissible" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <span *ngIf="errMsg">{{errMsg}}</span>
            <span *ngIf="!errMsg">We are sorry, error happened on the server. Please contact us at <a target="_blank" href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a>.</span>
          </div>

          <div [hidden]="step!==0">
            <label class="label-lg">Please select your practice’s primary medical supply:</label>
            <div class="mt-4" *ngIf="distributorsList.length">
              <select [ngModel]="distr_id" (change)="distr_id = $event.target?.value" class="form-control" [ngModelOptions]="{standalone: true}">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
              </select>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStep()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStep(!!1)">Skip</button>
            </div>
          </div>
          <div [hidden]="step!==1">
            <label class="label-lg">Does your practice also use any of the distributors below?</label>
            <div class="mt-4" *ngIf="distributorsList.length">
              <select [ngModel]="secondary_distr_id" (change)="secondary_distr_id = $event.target?.value" class="form-control" [ngModelOptions]="{standalone: true}">
                <option value="0" disabled>Please select</option>
                <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
              </select>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStep()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStep(!!1)">Skip</button>
            </div>
          </div>
          <div [hidden]="step!==2">
            <label class="label-lg">Please provide your primary distributor rep name below:</label>
            <div class="mt-4">
              <input class="form-control single-border-input" placeholder="Please enter your response" [(ngModel)]="repName"/>
            </div>
            <div class="mt-4 align-middle">
              <button class="btn btn-lg btn-bordered btn-primary" type="button" (click)="nextStep()">Next &nbsp;<i class="fas fa-angle-right"></i></button>
              <button class="ml-2 btn btn-sm btn-bordered btn-outline-secondary" type="button" (click)="nextStep(!!1)">Skip</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="loading">
        <div class="col-10 d-flex align-items-center">
          <circle-progress [percent]="getProgressPercent()" size="36"></circle-progress>
        </div>
        <div class="col-2 d-flex justify-content-end">
          <button class="btn btn-outline-secondary" [disabled]="step<1" (click)="prevStep()"><i class="fas fa-angle-left"></i></button>
          <button class="btn btn-outline-secondary ml-2" [disabled]="isNextStepDisabled()" (click)="nextStep()"><i class="fas fa-angle-right"></i></button>
        </div>
      </div>
    </div>
    <div class="col-sm-10 offset-sm-1 mw-100" *ngIf="displayThanks">
      <div class="bordered-container">
        <div class="bordered-content clearfix" style="min-height: 200px">
          <div class="text-center"><h4 class="color-primary">Signup Successful!</h4></div>
          <div class="mt-3 text-nowrap">
            Thank you for signing up for {{themeName}}.<br/>
            Our client managers will review your information and reach out to you with any additional questions to optimize your savings.<br/>
            If you have any questions, please email us at <a href="mailto:{{themeEmail}}">{{themeEmail}}</a> or call us at {{themePhone}}.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
