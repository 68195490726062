<div class="container">
  <form class="row bordered-container" (ngSubmit)="setPwd()">
    <div class="offset-sm-3 col-sm-6 bordered-content">
      <div class="row pc2-form-group" [attr.data-hidden]="isSet">
        <label class="col-form-label col-sm-4 text-sm-right">New password:</label>
        <div class="col-sm-8">
          <input type="password" class="form-control custom-form-control" [(ngModel)]="pwd" [ngModelOptions]="{standalone: true}"/>
        </div>
      </div>
      <div class="row pc2-form-group" [attr.data-hidden]="isSet">
        <div class="offset-sm-4 col-sm-8">
          <button class="btn btn-primary btn-block" type="submit">Set Password</button>
        </div>
      </div>
      <div class="row pc2-form-group" [attr.data-hidden]="!isSet">
        <h4 class="text-info text-center col-12">
          Password has been set successfully!
        </h4>
        <div class="text-center col-12">
          <button class="btn btn-primary" routerLink="/login">Login</button>
        </div>
      </div>
    </div>
  </form>
</div>
