import {Pipe} from '@angular/core';

@Pipe({
  name: 'utc2et'
})
export class Utc2EtPipe {
  transform(dt, onlyDate:boolean = false):any {
    try {
      if (dt === '0000-00-00' || dt === '0000-00-00 00:00:00') {
        return null;
      }
      if (dt == null || dt === '' || dt === 0) {
        return dt;
      }
      let d = new Date(dt);
      if (!this.isValidDate(d)) {
        return dt;
      }
      let curr = new Date();
      let pos = curr.getMonth() * 30 + curr.getDate();
      if (pos > 70 && pos < 303) { //EDT
        d.setHours(d.getHours() - 4);
      } else { //EST
        d.setHours(d.getHours() - 5);
      }
      let date_ = d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
      if (onlyDate) {
        return date_;
      }
      let time_ = d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
      return date_ + ' ' + time_;
    } catch (e) {
      return dt;
    }
  }

  isValidDate(d:any) {
    return d instanceof Date && !isNaN(d.getTime());
  }
}
