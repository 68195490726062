import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {Session} from "./Session";
import {ISubQuestionOption} from "../interfaces/ISubQuestionOption";
import {IUser} from "../interfaces/IUser";
import {ILocation} from "../interfaces/ILocation";

export interface IRxAnswers {
  options: number[];
  signature: string;
  job_title: string;
  phone: string;
}

export interface IRxQuestion {
  title: string;
  sub_title: string;
  options: ISubQuestionOption[];
}

export interface IPreVacAnswers {
  user_id: number;
  cot_id?: number;
  specialty_id?: number;
  vaccines_use: number;
}

export interface IRxUser {
  id: number;
  user_id: number;
  code: string;
  signature: string;
  signature_time: string;
  user_ip: string;
  user: IUser;
  location: ILocation;
}

export interface IRxUserResponse {
  user: IRxUser;
  token: string;
  questions: IRxQuestion[];
  answers: number[];
}

@Injectable()
export class RxSrv {

  constructor(private http:HttpClient, private config:Config, private session:Session) {}

  initiateRxSurvey(answers:IRxAnswers):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/rx/survey', JSON.stringify(answers), {headers: headers});
  }

  savePreVaccinesAnswers(answers:IPreVacAnswers):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/rx/pre_vac', JSON.stringify(answers), {headers: headers});
  }

  loadCode(code:string):Observable<IRxUserResponse> {
    return this.http.get<IRxUserResponse>(this.config.apiUrl + '/rx/user/' + code);
  }
}
