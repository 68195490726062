import {Component} from '@angular/core';
import {Config} from '../../services/Config';
import {Contracts} from '../../services/Contracts';
import {IFeaturedContractsSet} from '../../interfaces/IFeaturedContractsSet';
import {ContractListItemCmp} from '../contract-list-item/ContractListItemCmp';
import {Router} from '@angular/router';
import {Categories} from "../../services/Categories";
import {SpecialtySliderCmp} from "../specialty-slider/SpecialtySliderCmp";
import {Session} from "../../services/Session";
declare var _:any;

@Component({
  selector: 'employee-perk',
  templateUrl: './employee-perk.html',
})
export class EmployeePerkCmp {
  public featured:IFeaturedContractsSet;
  public categories = [];
  public specialtyID;
  public label;

  constructor(private session: Session, private categoriesService:Categories, private contracts:Contracts, private router:Router, private config:Config) {
    this.session.addOnLoadHandler(()=> {
      this.specialtyID = config.employeePerkSpecialtyID;
      this.load(this.specialtyID);
    });
    this.session.redirectIfNotAuth();
  }

  load(specialtyId) {
    this.categoriesService.getCategoryBySlug('employee-perks').subscribe((categories)=> {
      this.categories = categories;
      if (categories && categories.length) {
        this.label = categories[0].label;
      }
    }, ()=> {});
    this.contracts.listFeatured(specialtyId).subscribe((result)=> {
      this.featured = result;
    }, ()=> {});
  }
}
