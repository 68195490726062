<div *ngIf="locations && locations.length>0">
  <ng-template ngFor let-location [ngForOf]="locations">
    <account-distributor *ngIf="location.id == location.__group_primary_location_id" [location]="location" [lc]="location._lc_data"></account-distributor>
  </ng-template>
</div>

<div class="row" *ngIf="locations && locations.length>0 && distributorsList && distributorsList.length>0">
  <div class="col-12">
    <button class="btn btn-success" (click)="newDistrModalShow()">Add New Distributor</button>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" #newDistrModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add New Distributor</h5>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="form-group">
            <div class="row">
              <label class="col-form-label col-sm-3 text-right">Distributor</label>
              <div class="col-sm-9">
                <select [(ngModel)]="nd_distr_id" class="form-control">
                  <option value="0" disabled>Please select</option>
                  <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-form-label col-sm-3 text-right">Rep Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" [(ngModel)]="nd_rep_name"/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-form-label col-sm-3 text-right">Note</label>
              <div class="col-sm-9">
                <textarea rows="3" class="form-control" [(ngModel)]="nd_note"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-sm" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="addNewDistributorTicket()" [disabled]="!nd_distr_id || addingNewDistributor">Submit</button>
      </div>
    </div>
  </div>
</div>
