<section *ngIf="distributorSubmitted">
  <h2 class="pc2-row-pad text-center text-primary">Thank you for submitting your distributor information</h2>
</section>
<section *ngIf="!distributorSubmitted && authCode.length>0">
  <h4 class="pc2-row-pad">Help us help you. Share your distributor to confirm eligibility and guarantee results.</h4>
  <form (ngSubmit)="saveDistributor()" class="pc2-row-pad">
    <div class="row">
      <label class="col-12"><strong>My distributor is:</strong></label>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <input type="text" [(ngModel)]="distributor" class="form-control" [ngModelOptions]="{standalone: true}"/>
      </div>
      <div class="col-sm-3">
        <button type="submit" class="btn btn-primary btn-block" [disabled]="!distributor || distributorSaving">Submit</button>
      </div>
    </div>
  </form>
</section>
<div class="pc2-row-pad">
  <p>With the information you provided, we will work with your distributor to help you discover an easy and proven way to guarantee savings on your medical supplies.
    You’ll love the savings, and your employees will thank you with the benefits of our employee perks contracts.
    Login is required to enter. An e-mail has been sent with your credentials.
  </p>
  <p>If you have any questions, don’t hesitate to contact one of our Client Managers at <a href="mailto:support@PurchaseClinic.com">support@PurchaseClinic.com</a> or <a href="tel:1-877-659-2159">1.877.659.2159</a>.</p>
</div>
