<section class="contact-layout">
  <div class="container">
    <div class="bordered-container thin-top">
      <div class="row jc-center">
        <div class="col-sm-8 col-md-6" [attr.data-hidden]="messageSent">
          <h1 class="headline">Contact Information</h1>

          <div class="bordered-content clearfix">
            <form (ngSubmit)="submit()" class="wufoo topLabel page" autocomplete="off" novalidate>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label for="Field1">First Name:</label>
                  <input type="text" id="Field1" class="input-text" [(ngModel)]="formData.Field1" title="First Name" placeholder="First Name" maxlength="100" tabindex="1" name="fname"/>
                </div>

                <div class="col-sm-6 form-group">
                  <label for="Field2">Last Name:</label>
                  <input type="text" id="Field2" class="input-text" [(ngModel)]="formData.Field2" title="Last Name" placeholder="Last Name" maxlength="100" tabindex="2" name="lname"/>
                </div>
              </div>

              <div class="form-group">
                <label for="Field3">Email:</label>
                <input type="email" id="Field3" class="input-text" [(ngModel)]="formData.Field3" title="Email" placeholder="Email" maxlength="255" tabindex="3" name="email">
              </div>

              <div class="phone-holder">
                <div class="form-group">
                  <label for="Field4">Phone Number:</label>
                  <input type="text" id="Field4" class="input-text" [(ngModel)]="formData.Field4" title="" placeholder="###" size="3" maxlength="3" tabindex="4" [ngModelOptions]="{standalone: true}">
                  <input type="text" id="Field4-1" class="input-text" [(ngModel)]="formData['Field4-1']" title="" placeholder="###" size="3" maxlength="3" tabindex="5" [ngModelOptions]="{standalone: true}">
                  <input type="text" id="Field4-2" class="input-text" [(ngModel)]="formData['Field4-2']" title="" placeholder="####" size="4" maxlength="4" tabindex="6" [ngModelOptions]="{standalone: true}">
                </div>
              </div>


              <div class="form-group">
                <label for="Field6">Your Question:</label>
                <textarea id="Field6"
                  [(ngModel)]="formData.Field6"
                  class="form-control custom-form-control"
                  spellcheck="true"
                  rows="10" cols="50"
                  tabindex="7"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Please tell us your question or comment."></textarea>
              </div>

              <div class="submit-holder">
                <button type="submit" class="btn btn-primary-action btn-primary" id="saveForm" value="Submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-8 col-md-6 text-center" [attr.data-hidden]="!messageSent">
          <h2>Thank you!</h2>
          <h3>Your information has been received. A client manager will get back with you shortly.</h3>
        </div>
      </div>
    </div>
  </div>
</section>
