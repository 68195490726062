import {Component, ViewChild, ElementRef} from '@angular/core';
import {Config} from "../../../services/Config";

@Component({
  selector: 'rep-signup',
  templateUrl: './signup_for_rep.html'
})
export class RepSignupFormCmp {
  @ViewChild('form') form:ElementRef;
  public action;

  constructor(private config:Config) {
    this.action = config.apiUrl + '/signup_by_rep';
  }

  submit() {
    this.form.nativeElement.submit();
  }
}
