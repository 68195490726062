import {Observable, of} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {Config} from "./Config";
import {Session} from "./Session";
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ISpecialtyCategory} from "../interfaces/ISpecialtyCategory";
declare var _;

@Injectable()
export class Categories {
  private map = {};
  private loadingMap = {};

  constructor(private config:Config, private http:HttpClient, private session:Session) {}

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return headers;
  }

  getCategory(num):Observable<ISpecialtyCategory[]> {
    if (this.map[num]) {
      return of(this.map[num]);
    }
    if (this.loadingMap[num]) {
      return this.loadingMap[num];
    }
    this.loadingMap[num] = this.http.get<ISpecialtyCategory[]>(this.config.apiUrl + '/specialty/' + num + '/categories', {headers: this.getHeaders()}).pipe(map((r:ISpecialtyCategory[]) => {
      let c = r;
      if (c && c.length) {
        c.forEach((cat)=> {
          try {
            cat.contracts = JSON.parse("[" + cat.contracts + "]");
          } catch (e) {}
        });
      }
      return c;
    })).pipe(share());
    this.loadingMap[num].subscribe((cat)=> {
      this.map[num] = cat;
    }, ()=> {});
    return this.loadingMap[num];
  }

  getCategoryBySlug(slug):Observable<ISpecialtyCategory[]> {
    if (this.map[slug]) {
      return of(this.map[slug]);
    }
    if (this.loadingMap[slug]) {
      return this.loadingMap[slug];
    }
    this.loadingMap[slug] = this.http.get<ISpecialtyCategory[]>(this.config.apiUrl + '/specialty/categories/' + slug, {headers: this.getHeaders()}).pipe(map((r:ISpecialtyCategory[]) => {
      let c = r;
      if (c && c.length) {
        c.forEach((cat)=> {
          try {
            cat.contracts = JSON.parse("[" + cat.contracts + "]");
          } catch (e) {}
        });
      }
      return c;
    })).pipe(share());
    this.loadingMap[slug].subscribe((cat)=> {
      this.map[slug] = cat;
    }, ()=> {});
    return this.loadingMap[slug];
  }
}
