<div class="container">

  <div [ngSwitch]="session.user?.__theme?.id" *ngIf="!isAccountSubdomainViewMode">

    <div *ngSwitchCase="1">
      <div class="alert alert-info alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <p>Do you have a username/password for a PurchaseClinic account that you set up prior to January 27, 2016? Effective January 27, 2016, PurchaseClinic became a group purchasing affiliate of HealthTrust Purchasing Group, L.P. under the
          AdvantageTrust program.</p>

        <p>HealthTrust does not allow or accept customers with multiple GPO affiliations. If you are interested in participating in the AdvantageTrust program and have determined that you are eligible to do so, you can sign up for a new
          PurchaseClinic account by
          <a *ngIf="!session.user?.__theme?.signup_url" routerLink="/signup">clicking here</a>
          <a *ngIf="session.user?.__theme?.signup_url" href="{{session.user?.__theme?.signup_url}}">clicking here</a>
          .
        </p>

        <p>If you cannot remember your password for an account established on or after January 27, 2016, please use the password recovery tool below.</p>
      </div>
    </div>
    <div *ngSwitchCase="2">
      <div class="alert alert-info alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        Do you have a username/password for Access Medical Purchasing that you set up prior to January 27, 2016? Effective January 27, 2016,
        Access Medical Purchasing became a group purchasing affiliate of HealthTrust Purchasing Group, L.P. under the AdvantageTrust program.
        <br/>
        HealthTrust does not allow or accept customers with multiple GPO affiliations.<br/>
        If you are interested in participating in the AdvantageTrust program and have determined that you are eligible to do so, you can
        sign up for a new Access Medical Purchasing account by
        <a *ngIf="!session.user?.__theme?.signup_url" href="http://accessmedicalpurchasing.com/signup">clicking here</a>
        <a *ngIf="session.user?.__theme?.signup_url" href="{{session.user?.__theme?.signup_url}}">clicking here</a>
        .<br/>
        If you cannot remember your password for an account established on or after January 27, 2016, please use the password recovery tool below.
      </div>
    </div>

  </div>
  <form [attr.data-hidden]="isSent" class="row bordered-container" (ngSubmit)="remind()">
    <div class="offset-1 col-10 offset-sm-3 col-sm-6 bordered-content">
      <div class="alert alert-info" *ngIf="notFound">
        We're sorry, but that email address was not found in our system. Please double-check the address and try again.
        If you would like to sign up for an account, please
        <a *ngIf="!session.user?.__theme?.signup_url" routerLink="/signup">click here</a>
        <a *ngIf="session.user?.__theme?.signup_url" href="{{session.user?.__theme?.signup_url}}">click here</a>
        .
        If you are having trouble logging in, please contact us at


        <a href="mailto:{{session.user?.__theme?.email_from_address||'support@purchaseclinic.com'}}" target="_blank">{{session.user?.__theme?.email_from_address || 'support@purchaseclinic.com'}}</a>.
        <button type="button" class="close" (click)="notFound = false" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>

      <div class="form-group">
        <label class="col-form-label">Your email:</label>
        <input type="email" class="form-control custom-form-control" [(ngModel)]="email" required [ngModelOptions]="{standalone: true}"/>
      </div>
      <div class="form-footer centered">
        <button class="btn btn-primary btn-primary-action" type="submit" [disabled]="sending">Reset Password</button>
      </div>
    </div>
  </form>
  <div [attr.data-hidden]="!isSent" class="row bordered-container">
    <div class="offset-sm-3 col-sm-6 bordered-content text-center">
      <h4 class="text-info">The link to reset your password has been sent to you via email.</h4>
      <button class="btn btn-primary-action btn-primary" routerLink="/login">Login</button>
    </div>
  </div>
</div>
