<div class="container">
  <div class="text-center">
    <h4 class="color-primary">Thank you for reviewing the flu pre-book pricing</h4>
    <p>We will forward this information to you preferred purchase source</p>
    <div class="alert alert-info">
      <strong>Note:</strong>&nbsp;You will still need to pre-book with your preferred purchase source
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6" *ngIf="distr_name && !hideDistrQuestion">
      <div class="text-center mb-3 border-bottom">
        <h4 class="color-primary">Account Optimization</h4>
      </div>
      <div class="text-center mb-3">
        Please take a moment to review this additional information to help us ensure you are taking full advantage of the program:
      </div>
      <div class="text-center">
        <div>Is <strong>{{distr_name}}</strong> still your primary medical supply distributor?</div>
        <a href="https://expansion.surveysparrow.com/s/Distributor-Survey----No-Changes-to-Dist/tt-c52f17?Channel_Slug={{channel_slug}}&Distributor={{distr_slug}}&PCID={{pcid}}&Email={{email}}">Yes</a>&nbsp;&nbsp;
        <a href="https://expansion.surveysparrow.com/s/distributor-survey-updatesadditions/tt-e92ee7?Channel_Slug={{channel_slug}}&Distributor={{distr_slug}}&PCID={{pcid}}&Email={{email}}">No</a>
      </div>
    </div>
    <div [ngClass]="{'col-md-6':(distr_name && !hideDistrQuestion),'col-md-8 offset-md-2':(!distr_name || hideDistrQuestion)}">
      <div class="text-center border-bottom" [hidden]="sent">
        <h4 class="color-primary">Questions or account updates, please list below</h4>
      </div>
      <div class="row form-group" [hidden]="sent">
        <div class="col-12">
          <textarea placeholder="Tell us how we can help:
  • Address change or update
  • Vendor/Supplier Questions
  • New Point of Contact" class="form-control" [(ngModel)]="message" rows="7"></textarea>
        </div>
      </div>
      <div class="row form-group" [hidden]="sent">
        <div class="col-4 offset-4">
          <button type="button" (click)="send()" class="btn btn-primary btn-block" [disabled]="sending">Submit</button>
        </div>
      </div>
      <div class="text-center pc2-row-pad-big" [hidden]="!sent">
        <h4 class="color-primary">Thank you for your time!</h4>
        <div>Questions?</div>
        <div>Email us at <a href="mailto:{{channelEmail}}">{{channelEmail}}</a> or call us at {{channelPhone}}</div>
      </div>
    </div>
  </div>
</div>
