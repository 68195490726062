import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {IImplementationVendor} from "../interfaces/IImplementationVendor";
import {IImplementationStep} from "../interfaces/IImplementationStep";
import {ILocationImplementationVendor} from "../interfaces/ILocationImplementationVendor";
import {ILocationImplementationStep} from "../interfaces/ILocationImplementationStep";

@Injectable()
export class Implementations {
  private config:Config;
  private session:Session;
  private http:HttpClient;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getVendors():Observable<IImplementationVendor[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IImplementationVendor[]>(this.config.apiUrl + '/implementation/vendor', {headers: headers});
  }

  getSteps():Observable<IImplementationStep[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IImplementationStep[]>(this.config.apiUrl + '/implementation/step', {headers: headers});
  }

  getLocationVendors(location_id):Observable<ILocationImplementationVendor[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocationImplementationVendor[]>(this.config.apiUrl + '/implementation/location/' + location_id + '/vendor', {headers: headers});
  }

  getLocationSteps(location_id):Observable<ILocationImplementationStep[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocationImplementationStep[]>(this.config.apiUrl + '/implementation/location/' + location_id + '/step', {headers: headers});
  }

  setLocationVendors(vendors):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/implementation/location/vendors', JSON.stringify(vendors), {headers: headers});
  }

  setLocationSteps(steps):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/implementation/location/steps', JSON.stringify(steps), {headers: headers});
  }

  setStepsByZimmerIDs(zimmer_ids:string[], step_id:number, datetime?:string):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/implementation/zimmer/steps', JSON.stringify({
      zimmer_ids: zimmer_ids,
      step_id: step_id,
      datetime: datetime
    }), {headers: headers});
  }
}
