import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {ITheme} from "../../interfaces/ITheme";
import {DomSanitizer} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'flu-prebook-2020',
  templateUrl: 'flu_prebook_2020.html'
})
export class FluPrebook2020Cmp {
  public welcomeScreenPassed = false;
  public contactName = 'Purchase Clinic';
  public contactEmail = 'support@purchaseclinic.com';
  public contactPhone = '1.877.659.2159';
  public surveyUrl;
  private surveyUrlOrigin = 'https://expansion.surveysparrow.com/s/flu-vaccine-2020/tt-a43cdc?';

  constructor(private session: Session, private sanitizer: DomSanitizer, private config: Config) {
    this.session.addOnLoadHandler(() => {
      let theme = this.session.getCurrentTheme();
      if (theme) {
        this.setContactsByTheme(theme);
      } else {
        this.setContactsByHost();
      }
      let url = this.surveyUrlOrigin + 'Email=' + this.session.user.email;
      if (this.session && this.session.user && this.session.user.__location && this.session.user.__location.pc_id) {
        this.surveyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url + '&PCID=' + this.session.user.__location.pc_id);
      } else {
        this.surveyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    });
    this.session.redirectIfNotAuth();
  }

  setContactsByHost() {
    let themeId = this.config.getThemeIDForHost();
    if (themeId && this.config.themesMap && this.config.themesMap[themeId]) {
      let theme = this.config.themesMap[themeId];
      this.setContactsByTheme(theme);
    }
  }

  setContactsByTheme(theme: ITheme) {
    if (theme.email_from_address) {
      this.contactEmail = theme.email_from_address;
    }
    if (theme.name) {
      this.contactName = theme.name;
    } else {
      if (theme.email_from_name) {
        this.contactName = theme.email_from_name;
      }
    }
    if (theme.phone) {
      this.contactPhone = theme.phone;
    }
  }

  skipWelcomePage() {
    this.welcomeScreenPassed = true;
    $('html,body').animate({scrollTop: 0}, 10);
  }

}
