import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {FDTickets} from "../../services/FDTickets";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {IDistributor} from "../../interfaces/IDistributor";
import {Distributors} from "../../services/Distributors";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Notify} from "../../services/Notify";
declare var $:any;

@Component({
  selector: 'btn-distr-pricing',
  templateUrl: './modal.html',
})
export class BtnDistrPricingCmp {
  @Input() contractNumber;
  @Input() vendorName;
  @ViewChild('haq') modal:ElementRef;
  public question;
  public saving:boolean = false;
  public location:ILocation;
  public distributorsList:IDistributor[] = [];
  public distributorsMap:{[id: number]: IDistributor;} = {};
  public distrID:number;
  public repUserEmail:string;
  public newEmail:string;

  constructor(public session:Session, private tickets:FDTickets, private locations:Locations, private distributors:Distributors, private users:User) {}

  ngAfterViewInit() {
    this.session.loadUser(() => {
      if (this.session.user && this.session.user.location_id) {
        this.locations.getLocation(this.session.user.location_id).then((loc:ILocation) => {
          this.location = loc;
          if (loc.__distributor_id) {
            this.distrID = loc.__distributor_id;
          }
          if (loc.__rep_user_id) {
            this.users.getUserInfo(loc.__rep_user_id).then((rep:IUser) => {
              if (rep && rep.email) {
                this.repUserEmail = rep.email;
              }
            }, (e) => {console.error(e);}).catch((e) => {console.error(e);});
          }
        }, () => {});
      }
    }, () => {});
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
  }

  send() {
    let n = new Notify();
    let email = this.newEmail;
    if (!email && this.session.user && this.session.user.email) {
      email = this.session.user.email;
    }
    if (!email || email.indexOf('@') < 1) {
      n.warning('Please input your email');
      return false;
    }
    let customFields = {};
    if (this.session && this.session.user && this.session.user.__theme && this.session.user.__theme.name) {
      customFields['brand'] = this.session.user.__theme.name;
    }
    if (this.location && this.location.pc_id) {
      customFields['pc_id'] = this.location.pc_id;
    }
    this.saving = true;
    let add = "";
    if (this.distrID && this.distributorsMap && this.distributorsMap[this.distrID]) {
      add = add + "<br/><div><b>Distributor:</b> " + this.distributorsMap[this.distrID].name + "</div>";
    }
    if (this.repUserEmail) {
      add = add + "<div><b>Distributor Rep:</b> " + this.repUserEmail + "</div>";
    }
    this.tickets.create(email, `Interested in pricing for Contract ${this.vendorName} (#${this.contractNumber})`,
      this.question + add, customFields).subscribe(() => {
      this.saving = false;
      if (this.modal && this.modal.nativeElement) {
        $(this.modal.nativeElement).modal('hide');
      }
    }, (error) => {
      this.saving = false;
      console.error(error);
    });
  }
}
