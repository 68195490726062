import {Component, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {User} from "../../services/User";
import {ISignupWithPA} from "../../interfaces/ISignupWithPA";
import {Questions} from "../../services/Questions";
import {IReviewSession} from "../../interfaces/IReviewSession";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {ICot} from "../../interfaces/ICot";
import {Cots} from "../../services/Cots";
import {Distributors} from "../../services/Distributors";
import {IDistributor} from "../../interfaces/IDistributor";
declare var analytics:any;

@Component({
  selector: 'savings-signup',
  templateUrl: './savings_signup.html',
})
export class SavingsSignupCmp {
  @Input() review_session_id;
  public signup = new ISignupWithPA();
  public saving = false;
  public notify = new Notify();
  public review_session:IReviewSession = <IReviewSession>{};
  public cotsMap:{[id: number]: ICot;} = {};
  public cotsList:ICot[] = [];
  public distributorsList:IDistributor[] = [];

  constructor(private router:Router, private routeParams:ActivatedRoute, private userService:User,
    public qService:Questions, private session:Session, private config:Config, private cots:Cots, private distributors:Distributors) {}

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (!this.review_session_id) {
      this.review_session_id = parseInt(params['review_session_id']);
      if (!this.review_session_id) {
        this.router.navigate(['']);
        return;
      }
    }
    this.review_session = this.qService.sessions[this.review_session_id] || <IReviewSession>{};
    if (this.review_session.class_of_trade_id) {
      this.signup.user.class_of_trade_id = this.review_session.class_of_trade_id;
    }
    if (this.review_session.specialty_id) {
      this.signup.user.specialty_id = this.review_session.specialty_id;
    }
    this.signup.review_session_id = this.review_session_id;
    this.cots.loadToMap(this.cotsMap);
    this.cots.loadToList(this.cotsList);
    this.distributors.loadToList(this.distributorsList);
  }

  submit() {
    if (!this.signup.signature) {
      return;
    }
    this.saving = true;
    this.userService.signupWithPA(this.signup).subscribe((response)=> {
      this.session.signupUrl = window.location.hostname + window.location.pathname;
      if (response && response.id) {
        let RS = new IReviewSession();
        RS.id = this.review_session_id;
        RS.user_id = response.id;
        RS.distributor_email = this.signup.rep_email;
        this.qService.saveReviewSession(RS).subscribe(()=> {}, ()=> {});
      }
      if (response && response.token) {
        this.session.setToken(response.token, ()=> {
          this.saving = false;
          this.router.navigate(['/savings_report_delivery', this.review_session_id]);
        });
      } else {
        this.saving = false;
        this.router.navigate(['/savings_report_delivery', this.review_session_id]);
      }
    }, ()=> {
      this.notify.error(this.config.txt.error);
      this.saving = false;
    });
    this.trackButtonClick();
  }

  trackButtonClick() {
    try {
      if (analytics && analytics.track) {
        analytics.track("SavingsQuestionsSignup", {
          "reviewSessionID": this.review_session_id
        });
      }
    } catch (e) {}
  }
}
