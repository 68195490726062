import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {Session} from "../../services/Session";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {ICot} from "../../interfaces/ICot";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Locations} from "../../services/Locations";
import {UrlParser} from "../../services/UrlParser";
import {ILocation} from "../../interfaces/ILocation";
import {ILocationContact} from "../../interfaces/ILocationContact";
import {ILocGroupFile} from "../../interfaces/ILocGroupFile";
import {EditRequests} from "../../services/EditRequests";
import {ILifecycleData} from "../../interfaces/ILifecycleData";
import {IEditRequest} from "../../interfaces/IEditRequest";
import {FDTickets} from "../../services/FDTickets";
import {ILocationWithLC} from "../../interfaces/ILocationWithLC";
import {IRecommendedContract, Newsletter} from "../../services/Newsletter";
import {ITheme} from "../../interfaces/ITheme";

declare var $: any;

@Component({
  selector: 'account',
  templateUrl: './account.html'
})
export class AccountCmp {
  // suspended for now by request of Ben Thole
  public displayAccountSubdomainHeaderSection = false;
  //////////////////////
  public currentYear = 2020;
  public prevYear = 2019;
  public specialtiesMap: { [id: number]: ISpecialty; } = {};
  public specialtiesList: ISpecialty[] = [];
  public cotsMap: { [id: number]: ICot; } = {};
  public cotsList: ICot[] = [];
  public errorAlert: boolean = false;
  public successAlert: boolean = false;
  public notify = new Notify();
  public user: IUser = <IUser>{};
  public saving = false;
  public location: ILocation;
  public relatedLocs: ILocation[] = [];
  public contacts: ILocationContact[];
  public files: ILocGroupFile[];
  public tab = 1;
  public theme: ITheme;
  public pendingReqsLocsHandled = false;
  public pendingReqsContactsHandled = false;
  public cm_user_id: number = 0;
  public lc: ILifecycleData;
  public recommendedContracts: IRecommendedContract[] = [];
  public clinicCotID;
  public isAccountSubdomainViewMode = false;
  public hideTabMemberInfoInAccountMode = true;
  public activeLocationsCount = 0;
  public updAccClinicURI;
  public displayUpdAccClinic = false;
  public updAccSurgeryURI;
  public displayUpdAccSurgery = false;
  public annualSurveyType: string;
  public annualSurveyClinicBtnLabel = 'Update Clinic Profile';
  public annualSurveySurgeryBtnLabel = 'Update Surgery Center Profile';
  public annualSurveyClinicCompleted = false;
  public annualSurveySurgeryCompleted = false;
  public annualSurveyCompleted = false;
  private annualSurveyClinicLabel = 'Update Clinic Profile';
  private annualSurveySurgeryLabel = 'Update Surgery Center Profile';
  private annualSurveyClinicLabelCompleted = 'Update Clinic Profile (completed)';
  private annualSurveySurgeryLabelCompleted = 'Update Surgery Center Profile (completed)';
  private annualSurveyLabel = 'Start account optimization >>';
  private annualSurveyLabelCompleted = '2020 Optimization completed';
  private groupPrimaryLoc: ILocation;
  private originalLoc: ILocation;
  private userOrigin: IUser;
  private prevEmailChange = '';

  constructor(private specialties: Specialties, private cots: Cots, private session: Session,
    public config: Config, private userService: User, private locSrv: Locations, private sanitizer: DomSanitizer,
    public urlParser: UrlParser, private editReqSrv: EditRequests, private fdTickets: FDTickets, private newsletterSrv: Newsletter) {
    this.currentYear = new Date().getFullYear();
    this.prevYear = this.currentYear - 1;
    this.isAccountSubdomainViewMode = this.config.isAccountSubDomain();
    if (this.tab == 1 && this.isAccountSubdomainViewMode && this.hideTabMemberInfoInAccountMode) {
      this.tab = 4;
    }
    this.session.addOnLoadHandler(() => {
      this.setTheme(this.session.user);
      this.init();
    });
    this.setTheme(this.session.user);
    this.parseUrlParams();
    this.urlParser.onUrlChange(() => {
      this.parseUrlParams();
    });
    this.session.redirectIfNotAuth();
    this.specialties.loadToMap(this.specialtiesMap);
    this.specialties.loadToList(this.specialtiesList);
    this.cots.loadToMap(this.cotsMap);
    this.cots.loadToList(this.cotsList);
    this.clinicCotID = this.config.cotIDClinic;
  }

  setTheme(user?: IUser) {
    try {
      if (user) {
        if (user.__theme && user.__theme.landing_domain) {
          this.theme = user.__theme;
        } else {
          this.theme = this.config.themesMap[user.theme_id];
        }
      } else {
        this.theme = this.config.themesMap[this.config.getThemeIDForHost()];
      }
    } catch (e) {}
  }

  hideWhenNoGpoId() {
    if (this.tab == 8) {
      return;
    }
    let displayNoGpoWarning = false;
    if (this.session.user && this.session.user.__location && !this.session.user.__location.zimmer_id) {
      displayNoGpoWarning = true;
      this.tab = 8;
    }
    if (this.tab != 8 && this.session.user && (!this.session.user.__location || !this.session.user.__location.zimmer_id)) {
      displayNoGpoWarning = true;
      this.tab = 8;
    }
    if (displayNoGpoWarning) {
      this.notify.info(this.config.txt.noGpoWarning);
    }
  }

  init() {
    this.hideWhenNoGpoId();
    this.user = this.session.user;
    this.userOrigin = $.extend(true, {}, this.user);
    if (this.user.manager_id > 0) {
      this.cm_user_id = this.user.manager_id;
    }
    if (this.user && this.user.location_id) {
      this.locSrv.getLocation(this.user.location_id).then((loc: ILocation) => {
        if (loc) {
          this.location = loc;
          this.originalLoc = $.extend(true, {}, loc);
          if (!this.cm_user_id && loc.__manager_id) {
            this.cm_user_id = loc.__manager_id;
          }
          if (loc.location_group_id) {
            this.locSrv.getContactsOfGroup(loc.location_group_id).subscribe((contacts) => {
              this.contacts = [];
              if (contacts && contacts.length > 0) {
                this.contacts = contacts;
              }
              this.setPendingValues();
            }, () => {});
            this.locSrv.getFilesOfGroup(loc.location_group_id).subscribe((files) => {
              this.files = files;
            }, () => {});
          }
          this.generateUpdAccURI();
        }
      }, () => {});
      this.locSrv.getRelatedLocationsWithLC(this.user.location_id).subscribe((locs: ILocationWithLC[]) => {
        let zimmers = [];
        if (locs && locs.length) {
          this.relatedLocs = [];
          locs.forEach((l) => {
            let lnew = l.location;
            lnew._lc_data = l.lifecycle;
            this.relatedLocs.push(lnew);
            if (lnew.zimmer_id && zimmers.indexOf(lnew.zimmer_id) < 0) {
              zimmers.push(lnew.zimmer_id);
            }
            if (lnew.id == lnew.__group_primary_location_id && l.lifecycle) {
              this.lc = l.lifecycle;
            }
            if (lnew.id == lnew.__group_primary_location_id) {
              this.groupPrimaryLoc = l.location;
              this.generateUpdAccURI();
            }
          });
          this.setPendingValues();
        }
        this.activeLocationsCount = zimmers.length;
      }, () => {});
      this.loadAnnualSurveyType();
      this.newsletterSrv.getRecommendedContracts(this.user.location_id).subscribe((response) => {
        if (response && response.contracts && response.contracts.length > 0) {
          this.recommendedContracts = response.contracts;
        } else {
          this.recommendedContracts = [];
        }
        this.recommendedContracts.push({vendor_name: 'Staples Business Advantage'});
        this.recommendedContracts.push({vendor_name: 'FedEx'});
        this.recommendedContracts.push({vendor_name: 'Enterprise'});
      }, () => {});
    }
  }

  loadAnnualSurveyType(recDepth?: number) {
    let nextRecDepth = ((recDepth || 0) - 0) + 1;
    if (nextRecDepth > 9999) {
      return;
    }
    this.userService.getAnnualSurveyType().subscribe((t) => {
      if (!t) {
        setTimeout(() => {
          this.loadAnnualSurveyType(nextRecDepth);
        }, 1000);
        return;
      }
      this.annualSurveyClinicCompleted = false;
      this.annualSurveySurgeryCompleted = false;
      this.annualSurveyType = t.type_name;
      if (!recDepth) {
        this.generateUpdAccURI();
        let opensrv = false;
        if (window.location.href.indexOf('?opensrv=1') > -1) {
          opensrv = true;
        }
        if (this.session.crossPageArgs && this.session.crossPageArgs['openAnnualSurveyModal']) {
          opensrv = true;
        }
        if (opensrv) {
          if (!(this.displayUpdAccClinic && this.displayUpdAccSurgery)) {
            setTimeout(() => {
              try {
                if (this.displayUpdAccClinic && !this.annualSurveyClinicCompleted) {
                  $('#updAccClinicWindow').modal('show');
                  if (this.session.crossPageArgs && this.session.crossPageArgs['openAnnualSurveyModal']) {
                    delete this.session.crossPageArgs['openAnnualSurveyModal'];
                  }
                } else {
                  if (this.displayUpdAccSurgery && !this.annualSurveySurgeryCompleted) {
                    $('#updAccSurgeryWindow').modal('show');
                    if (this.session.crossPageArgs && this.session.crossPageArgs['openAnnualSurveyModal']) {
                      delete this.session.crossPageArgs['openAnnualSurveyModal'];
                    }
                  }
                }
              } catch (e) {}
            }, 60);
          }
        }
      }

      if (t.is_clinic_completed) {
        this.annualSurveyClinicCompleted = true;
        setTimeout(() => {
          try {
            $('#updAccClinicWindow').modal('hide');
          } catch (e) {}
        }, 1000);
      }
      if (t.is_asc_completed) {
        this.annualSurveySurgeryCompleted = true;
        setTimeout(() => {
          try {
            $('#updAccSurgeryWindow').modal('hide');
          } catch (e) {}
        }, 1000);
      }

      let recheck = false;
      if (t.type_name && t.type_name.toLowerCase().indexOf('both') > -1) {
        if (!t.is_asc_completed || !t.is_clinic_completed) {
          recheck = true;
        }
      } else {
        if (!t.is_asc_completed && !t.is_clinic_completed) {
          recheck = true;
        }
      }
      if (recheck) {
        setTimeout(() => {
          this.loadAnnualSurveyType(nextRecDepth);
        }, 1000);
      }
    }, () => {});
  }

  save() {
    this.saving = true;
    this.userService.updateUser(this.session.user).then(() => {
      this.session.setSpecialtyID(this.session.user.specialty_id);
      let fin = () => {
        this.notify.success(this.config.txt.settingsSaved);
        this.saving = false;
      };
      if (this.userOrigin.email != this.user.email || (this.prevEmailChange && this.prevEmailChange != this.user.email)) {
        let r: IEditRequest = <IEditRequest>{
          from_user_id: this.user.id,
          edit_table: 'user',
          edit_field: 'email',
          record_id_field: 'id',
          record_id_value: this.user.id.toString(),
          is_new_record: false,
          old_value: this.userOrigin.email,
          new_value: this.user.email
        };
        this.editReqSrv.addRequest(r).subscribe(() => {
          this.prevEmailChange = this.user.email;
          fin();
        }, (err) => {
          fin();
          console.error(err);
        });
      } else {
        fin();
      }
      if (this.config.isAccountSubDomain()) {
        let x_location_id = null;
        if (this.location && this.location.id) {
          x_location_id = this.location.id;
        }
        let usage = this.userService.getAuthenticatedAnnualDisclosureReq(x_location_id);
        usage.address_update = true;
        this.userService.writeAnnualDisclosureCodeUsage(usage);
      }
    }, () => {
      this.notify.error(this.config.txt.error);
      this.saving = false;
    });
    if (this.location && this.location.id && this.originalLoc && this.originalLoc.id && this.cm_user_id) {
      this.editReqSrv.saveRecordEdits(this.location, this.originalLoc, 'location', 'id', this.cm_user_id).subscribe(() => {}, (err) => {
        console.error(err);
      });
    }
  }

  setTab(i) {
    let t = Math.max(parseInt(i), 1);
    if (this.tab == t) {
      return;
    }
    this.urlParser.setParameter('tab', t);
    this.tab = t;
    this.hideWhenNoGpoId();
    this.scrollTop();
    if (this.tab == 1) {
      this.logMyInfoViewed();
    }
    if (this.tab == 10) {
      this.logP12moViewed();
    }
    if (this.tab == 12) {
      this.logRecommendationsViewed();
    }
    if (this.tab == 4) {
      this.logSafeHarborViewed();
    }
  }

  logMyInfoViewed() {
    if (this.config.isAccountSubDomain()) {
      let x_location_id = null;
      if (this.location && this.location.id) {
        x_location_id = this.location.id;
      }
      let usage = this.userService.getAuthenticatedAnnualDisclosureReq(x_location_id);
      if (!usage.code && !usage.user_id && !usage.location_id) {
        setTimeout(() => {
          this.logMyInfoViewed();
        }, 2000);
        return;
      }
      usage.my_info_page = true;
      this.userService.writeAnnualDisclosureCodeUsage(usage);
    }
  }

  logRecommendationsViewed() {
    if (this.config.isAccountSubDomain()) {
      let x_location_id = null;
      if (this.location && this.location.id) {
        x_location_id = this.location.id;
      }
      let usage = this.userService.getAuthenticatedAnnualDisclosureReq(x_location_id);
      if (!usage.code && !usage.user_id && !usage.location_id) {
        setTimeout(() => {
          this.logRecommendationsViewed();
        }, 2000);
        return;
      }
      usage.recommendations_page = true;
      this.userService.writeAnnualDisclosureCodeUsage(usage);
    }
  }

  logP12moViewed() {
    if (this.config.isAccountSubDomain()) {
      let x_location_id = null;
      if (this.location && this.location.id) {
        x_location_id = this.location.id;
      }
      let usage = this.userService.getAuthenticatedAnnualDisclosureReq(x_location_id);
      if (!usage.code && !usage.user_id && !usage.location_id) {
        setTimeout(() => {
          this.logP12moViewed();
        }, 2000);
        return;
      }
      usage.p12mo_page = true;
      this.userService.writeAnnualDisclosureCodeUsage(usage);
    }
  }

  logSafeHarborViewed() {
    if (this.config.isAccountSubDomain()) {
      let x_location_id = null;
      if (this.location && this.location.id) {
        x_location_id = this.location.id;
      }
      let usage = this.userService.getAuthenticatedAnnualDisclosureReq(x_location_id);
      if (!usage.code && !usage.user_id && !usage.location_id) {
        setTimeout(() => {
          this.logSafeHarborViewed();
        }, 2000);
        return;
      }
      usage.safe_harbor_page = true;
      this.userService.writeAnnualDisclosureCodeUsage(usage);
    }
  }

  scrollTop() {
    try {
      $('html,body').animate({scrollTop: 0}, 150);
    } catch (e) {}
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams['tab']) {
      this.setTab(Math.max(parseInt(urlParams['tab']), 1));
    }
  }

  setPendingValues() {
    if (this.pendingReqsLocsHandled && this.pendingReqsContactsHandled) {
      return false;
    }
    let locsEdited = false;
    let contactsEdited = false;
    this.editReqSrv.getPendingRequests().subscribe((reqs) => {
      if (reqs && reqs.length > 0) {
        reqs.forEach((req) => {
          if (req.edit_table == 'location' && this.location && this.location.id.toString() === req.id) {
            locsEdited = true;
            this.location[req.edit_field] = req.new_value;
          }
          if (req.edit_table == 'location' && !this.pendingReqsLocsHandled && this.relatedLocs && this.relatedLocs.length > 0) {
            locsEdited = true;
            this.relatedLocs.forEach((rl) => {
              if (rl.id.toString() == req.id) {
                rl[req.edit_field] = req.new_value;
              }
            });
          }
          if (req.edit_table == 'user' && !this.pendingReqsContactsHandled && this.contacts && this.contacts.length > 0) {
            contactsEdited = true;
            this.contacts.forEach((contact) => {
              if (contact.id.toString() == req.id) {
                contact[req.edit_field] = req.new_value;
              }
            });
          }
        });
        if (locsEdited) {
          this.pendingReqsLocsHandled = true;
        }
        if (contactsEdited) {
          this.pendingReqsContactsHandled = true;
        }
      }
    }, () => {});
  }

  generateUpdAccURI() {
    let specialtyName = '';
    if (this.location && this.location.specialty_id && this.specialtiesMap && this.specialtiesMap[this.location.specialty_id] && this.specialtiesMap[this.location.specialty_id].name) {
      specialtyName = this.specialtiesMap[this.location.specialty_id].name;
    }
    let fname = '';
    if (this.user && this.user.fname) {
      fname = this.user.fname;
    }
    let pcID = '';
    let primLocID: number;
    if (this.groupPrimaryLoc) {
      pcID = this.groupPrimaryLoc.pc_id;
      primLocID = this.groupPrimaryLoc.id;
    }
    let baseUriClinic = '';
    let baseUriSurgery = '';
    this.displayUpdAccClinic = false;
    this.displayUpdAccSurgery = false;
    if (this.annualSurveyType) {
      switch (this.annualSurveyType) {
        case 'Both + Multi':
          this.displayUpdAccClinic = true;
          this.displayUpdAccSurgery = true;
          baseUriClinic = 'https://expansion.surveysparrow.com/s/Profile-Update---Clinic-multi-specialty/tt-a0aaad?';
          baseUriSurgery = 'https://expansion.surveysparrow.com/s/Profile-Update---ASC-multi-specialty/tt-b986d7?';
          break;
        case 'Both + Single':
          this.displayUpdAccClinic = true;
          this.displayUpdAccSurgery = true;
          baseUriClinic = 'https://expansion.surveysparrow.com/s/Profile-Update---Clinic-single-specialty/tt-8786be?';
          baseUriSurgery = 'https://expansion.surveysparrow.com/s/Profile-Update---ASC-single-specialty/tt-a9177c?';
          break;
        case 'Clinic + Multi':
          this.displayUpdAccClinic = true;
          baseUriClinic = 'https://expansion.surveysparrow.com/s/Profile-Update---Clinic-multi-specialty/tt-a0aaad?';
          break;
        case 'Clinic + Single':
          this.displayUpdAccClinic = true;
          baseUriClinic = 'https://expansion.surveysparrow.com/s/Profile-Update---Clinic-single-specialty/tt-8786be?';
          break;
        case 'ASC + Multi':
          this.displayUpdAccSurgery = true;
          baseUriSurgery = 'https://expansion.surveysparrow.com/s/Profile-Update---ASC-multi-specialty/tt-b986d7?';
          break;
        case 'ASC + Single':
          this.displayUpdAccSurgery = true;
          baseUriSurgery = 'https://expansion.surveysparrow.com/s/Profile-Update---ASC-single-specialty/tt-a9177c?';
          break;
        default:
          this.displayUpdAccClinic = false;
          this.displayUpdAccSurgery = false;
      }
    }
    if (this.displayUpdAccClinic && this.displayUpdAccSurgery) {
      if (this.annualSurveyClinicCompleted) {
        this.annualSurveyClinicBtnLabel = this.annualSurveyClinicLabelCompleted;
      } else {
        this.annualSurveyClinicBtnLabel = this.annualSurveyClinicLabel;
      }
      if (this.annualSurveySurgeryCompleted) {
        this.annualSurveySurgeryBtnLabel = this.annualSurveySurgeryLabelCompleted;
      } else {
        this.annualSurveySurgeryBtnLabel = this.annualSurveySurgeryLabel;
      }
    } else {
      this.annualSurveyCompleted = false;
      if (this.displayUpdAccClinic && this.annualSurveyClinicCompleted) {
        this.annualSurveyCompleted = true;
      }
      if (this.displayUpdAccSurgery && this.annualSurveySurgeryCompleted) {
        this.annualSurveyCompleted = true;
      }
      if (this.annualSurveyCompleted) {
        this.annualSurveyClinicBtnLabel = this.annualSurveyLabelCompleted;
        this.annualSurveySurgeryBtnLabel = this.annualSurveyLabelCompleted;
      } else {
        this.annualSurveyClinicBtnLabel = this.annualSurveyLabel;
        this.annualSurveySurgeryBtnLabel = this.annualSurveyLabel;
      }
    }

    if (baseUriClinic) {
      let uriClinic = encodeURI(`${baseUriClinic}specialty=${specialtyName}&fname=${fname}&pcid=${pcID}&loc=${primLocID}&type=Clinic`);
      this.updAccClinicURI = this.sanitizer.bypassSecurityTrustResourceUrl(uriClinic);
    }
    if (baseUriSurgery) {
      let uriSurgery = encodeURI(`${baseUriSurgery}specialty=${specialtyName}&fname=${fname}&pcid=${pcID}&loc=${primLocID}&type=ASC`);
      this.updAccSurgeryURI = this.sanitizer.bypassSecurityTrustResourceUrl(uriSurgery);
    }
    if (specialtyName == '' || fname == '' || (!this.displayUpdAccClinic && !this.displayUpdAccSurgery)) {
      setTimeout(() => {
        this.generateUpdAccURI();
      }, 500);
    }
  }
}
