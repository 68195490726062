<section class="main-content">
  <div class="container-fluid account-layout">
    <div class="row" *ngIf="location && location.__group_company && !isAccountSubdomainViewMode">
      <div class="col-12">
        <h3 class="headline">Account: {{location.__group_company}}</h3>
      </div>
    </div>
    <div class="row" *ngIf="location && location.__group_company && isAccountSubdomainViewMode && tab!=4">
      <div class="col-12">
        <h3 class="headline">
          <span *ngIf="tab==10">Purchase Review</span>
          <span *ngIf="tab!=10">Annual Review</span>
          <span *ngIf="location && location.__group_company">: {{location.__group_company}}</span></h3>
      </div>
    </div>
    <div class="row" *ngIf="isAccountSubdomainViewMode && displayAccountSubdomainHeaderSection">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body pc2-bgcolor-card pc2-tight-card-body">
            <p *ngIf="location">Not the right point of contact for {{location.__group_company}}? <a class="pointer" (click)="setTab(3)">Click here to add new Point of Contact</a></p>
            <p><a class="pointer" (click)="setTab(3)">Review/Update your contact information</a></p>
            <p *ngIf="recommendedContracts && recommendedContracts.length>0">
              <strong>We have recommendations!</strong> <a class="pointer" (click)="setTab(12)">View contracts for additional savings</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="location && location.id">
        <div class="card">
          <div class="card-body pc2-tight-card-body">
            <div class="row">
              <div class="col-sm-5">
                <div><strong>{{activeLocationsCount}}</strong> active <span *ngIf="activeLocationsCount!=1">locations</span><span *ngIf="activeLocationsCount==1">location</span></div>
                <div class="pc2-row-pad"><span class="pc2-pre-text">Specialty</span><br/><strong>{{specialtiesMap[location.specialty_id]?.name}}</strong></div>
                <div class="pc2-row-pad"><span class="pc2-pre-text">Class of trade</span><br/><strong>{{cotsMap[location.class_of_trade_id]?.name}}</strong></div>
              </div>
              <div class="col-sm-7">
                <div>
                  <i class="material-icons material-icons-in-link" *ngIf="!location.pa_date">check_box_outline_blank</i>
                  <i class="material-icons material-icons-in-link" *ngIf="location.pa_date">check_box</i>
                  Signed up
                </div>
                <div *ngIf="lc">
                  <i class="material-icons material-icons-in-link" *ngIf="!lc.sent_date_primary && !lc.sent_date_secondary">check_box_outline_blank</i>
                  <i class="material-icons material-icons-in-link" *ngIf="lc.sent_date_primary || lc.sent_date_secondary">check_box</i>
                  Sent to distributor for coordination
                </div>
                <div *ngIf="lc">
                  <i class="material-icons material-icons-in-link" *ngIf="!lc.connected_date_primary && !lc.connected_date_secondary">check_box_outline_blank</i>
                  <i class="material-icons material-icons-in-link" *ngIf="lc.connected_date_primary || lc.connected_date_secondary">check_box</i>
                  Distributor connection confirmed
                </div>
                <div *ngIf="lc && lc.distributor_primary_name" class="pc2-row-pad">
                  Primary distributor: <strong>{{lc.distributor_primary_name}}</strong>
                </div>
                <div *ngIf="lc && lc.rep_primary_name">
                  Rep: <strong>{{lc.rep_primary_name}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!annualSurveyCompleted">
      <div class="col-12">
        <strong>Please answer the following questions to optimize your account</strong>
      </div>
    </div>
    <div class="row" *ngIf="displayUpdAccClinic && displayUpdAccSurgery">
      <div *ngIf="updAccClinicURI" class="col-md-6 pc2-form-group">
        <button class="btn btn-block btn-lg" [ngClass]="{'btn-outline-secondary bg-color-disabled':annualSurveyClinicCompleted,'btn-pc2':!annualSurveyClinicCompleted}" [disabled]="annualSurveyClinicCompleted" type="button" data-toggle="modal" data-target="#updAccClinicWindow">{{annualSurveyClinicBtnLabel}}</button>
      </div>
      <div *ngIf="updAccSurgeryURI" class="col-md-6 pc2-form-group">
        <button class="btn btn-block btn-lg" [ngClass]="{'btn-outline-secondary bg-color-disabled':annualSurveySurgeryCompleted,'btn-pc2':!annualSurveySurgeryCompleted}" [disabled]="annualSurveySurgeryCompleted" type="button" data-toggle="modal" data-target="#updAccSurgeryWindow">{{annualSurveySurgeryBtnLabel}}</button>
      </div>
    </div>
    <div *ngIf="!displayUpdAccClinic || !displayUpdAccSurgery">
      <div *ngIf="displayUpdAccClinic && updAccClinicURI" class="pc2-form-group">
        <button class="btn btn-lg" [ngClass]="{'btn-outline-secondary bg-color-disabled':annualSurveyClinicCompleted,'btn-pc2':!annualSurveyClinicCompleted}" [disabled]="annualSurveyClinicCompleted" type="button" data-toggle="modal" data-target="#updAccClinicWindow">{{annualSurveyClinicBtnLabel}}</button>
      </div>
      <div *ngIf="displayUpdAccSurgery && updAccSurgeryURI" class="pc2-form-group">
        <button class="btn btn-lg" [ngClass]="{'btn-outline-secondary bg-color-disabled':annualSurveySurgeryCompleted,'btn-pc2':!annualSurveySurgeryCompleted}" [disabled]="annualSurveySurgeryCompleted" type="button" data-toggle="modal" data-target="#updAccSurgeryWindow">{{annualSurveySurgeryBtnLabel}}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs font-sm">
          <li class="nav-item" *ngIf="!isAccountSubdomainViewMode || tab==1"><a class="nav-link" [class.active]="tab==1" (click)="setTab(1)">Your Member Info</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==2" (click)="setTab(2)">Locations</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==3" (click)="setTab(3)">Contacts</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==10" (click)="setTab(10)">{{currentYear}} Purchases</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==9" (click)="setTab(9)">Rebates</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==5" (click)="setTab(5)">Filebox</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==4" (click)="setTab(4)">{{prevYear}} Safe Harbor Report</a></li>
          <li class="nav-item"><a class="nav-link" [class.active]="tab==7" (click)="setTab(7)">Distributors</a></li>
          <li class="nav-item" *ngIf="isAccountSubdomainViewMode && recommendedContracts && recommendedContracts.length>0"><a class="nav-link" [class.active]="tab==12" (click)="setTab(12)">Recommendations</a></li>
          <!--<li class="nav-item" *ngIf="isAccountSubdomainViewMode"><a class="nav-link" [class.active]="tab==11" (click)="setTab(11)">Update POC</a></li>-->
          <li class="nav-item"><a class="nav-link" [class.active]="tab==8" (click)="setTab(8)"><i class="material-icons">comment</i> Contact us</a></li>
        </ul>
        <div class="tab-content tab-borderd">
          <div role="tabpanel" class="tab-pane" [class.active]="tab==1">
            <div class="card"><div class="card-header solo">
              Please confirm your information below:
            </div></div>
            <!--Member Info-->
            <div>
              <section *ngIf="location && (!location.medsurg_eff_date || location.medsurg_eff_date.length<5) && !location.is_on_hold">
                <div class="alert" role="alert">
                  <strong><i class="material-icons material-icons-in-link">lock</i></strong>
                  This information will be displayed once your enrollment process is complete.
                </div>
              </section>
              <section *ngIf="location && location.medsurg_eff_date && location.medsurg_eff_date.length>5">
                <div class="form-group">Member Since: <strong>{{location.medsurg_eff_date|utc2et|date:'longDate'}}</strong></div>
              </section>
              <section *ngIf="location && (!location.medsurg_eff_date || location.medsurg_eff_date.length<5) && location.is_on_hold">
                <div>Member Since: <strong>On Hold</strong></div>
              </section>
            </div>
            <form class="form-horizontal pc2-row-pad" role="form" (ngSubmit)="save()">
              <div class="form-section">
                <div class="form-group">
                  <label class="col-form-label">First Name:</label>
                  <input class="form-control custom-form-control" [(ngModel)]="user.fname" type="text" name="fname">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Last Name:</label>
                  <input class="form-control custom-form-control" [(ngModel)]="user.lname" type="text" name="lname">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Phone:</label>
                  <input class="form-control custom-form-control" [(ngModel)]="user.phone" type="text" name="phone">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Email:</label>
                  <input class="form-control custom-form-control" [(ngModel)]="user.email" type="text" name="email">
                </div>
              </div>

              <h3 class="headline-small">Customize your view of the website</h3>
              <div class="form-section">
                <div class="form-group">
                  <label class="col-form-label">Specialty</label>
                  <select class="form-control custom-form-control" [(ngModel)]="user.specialty_id" name="spec">
                    <option value="0" disabled>Specialty</option>
                    <option *ngFor="let spec of specialtiesList" value="{{spec.id}}">{{spec.name}}</option>
                  </select>
                  <small>We use your specialty to customize what you see on the website. This will not affect the specialty assigned to your location(s). If the specialty needs to be reclassified for your location(s), please
                    <a (click)="setTab(8)">contact us</a>.
                  </small>
                </div>
              </div>

              <!--submit button-->
              <div class="form-group form-footer">
                <input class="float-right btn btn-primary-action btn-primary" value="Save Changes" type="submit" [disabled]="saving">
              </div>
            </form>
            <section *ngIf="location && location.medsurg_eff_date && location.medsurg_eff_date.length>5">
              <div *ngIf="user" class="form-group">
                <div class="h5">Car Rental Codes (Enterprise, National)</div>
                <div class="h6"><a href="https://www.nationalcar.com/en_US/car-rental/reservation/startReservation.html">National Car Rental</a></div>
                <div class="h6"><a href="https://partners.rentalcar.com/purchase-clinic">Enterprise Rent-A-Car</a></div>
                <ul class="compact-ul">
                  <li>Leisure Travel<sup><strong>*</strong></sup> - Contract Code <strong>{{config.getRentalCodeLeisure(user.theme_id)}}</strong></li>
                  <li>Business Travel<sup><strong>**</strong></sup> - Contract Code <strong>{{config.getRentalCodeBusiness(user.theme_id)}}</strong></li>
                </ul>
                <small><strong>*</strong>&nbsp;Leisure Travel code does not included manage waiver or limited liability protection.</small>
                <br/>
                <small><strong>**</strong>&nbsp;For Business Travel code, Damage Waiver and Limited Liability Protection are included.</small>
                <div class="pc2-row-pad">Click on the link below to tie your discount to your Emerald Club, the loyalty program of National Car Rental, members enjoy expedited service, greater ease and exclusive rewards when renting at both National and Enterprise worldwide.</div>
                <div *ngIf="user.theme_id==config.ampThemeID" class="pc2-row-pad">
                  For AMP Members to sign up to become and Emerald for Business Travel<br/>
                  <a target="_blank" rel="noopener" href="http://www.nationalcar.com/offer/XZWCAMP">http://www.nationalcar.com/offer/XZWCAMP</a><br/>
                  For AMP Members to sign up to become and Emerald for Leisure Travel<br/>
                  <a target="_blank" rel="noopener" href="http://www.nationalcar.com/offer/XZNCAMP">http://www.nationalcar.com/offer/XZNCAMP</a>
                </div>
                <div *ngIf="user.theme_id!=config.ampThemeID" class="pc2-row-pad">
                  For Purchase Clinic Members to sign up to become and Emerald for Business Travel<br/>
                  <a target="_blank" rel="noopener" href="http://www.nationalcar.com/offer/XZWCPC1">http://www.nationalcar.com/offer/XZWCPC1</a><br/>
                  For Purchase Clinic Members to sign up to become and Emerald for Leisure Travel<br/>
                  <a target="_blank" rel="noopener" href="http://www.nationalcar.com/offer/XZNCPC1">http://www.nationalcar.com/offer/XZNCPC1</a>
                </div>
              </div>
              <div *ngIf="user" class="pc2-row-pad">
                <div class="h5">Cell Phone Savings (AT&T)</div>
                <div>As an AT&T Signature Program member, you'll receive:
                  <ul>
                    <li>25% off qualified wireless plans, including Mobile Share Advantage®</li>
                    <li>25% off eligible wireless accessories</li>
                    <li>Waived activation fees with select activations</li>
                    <li>Waived upgrade fees with select upgrades</li>
                  </ul>
                  <div>To take advantage of this program, please click the link below:</div>
                  <a href="https://{{theme.landing_domain}}/cellphones">https://{{theme.landing_domain}}/cellphones</a>
                </div>
                <div>AT&T Fan #: <strong>{{config.getAttFanCode(user.theme_id)}}</strong></div>
              </div>
            </section>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==2">
            <div class="card">
              <div class="card-header solo">
                These are the locations we have active under {{user?.__theme?.name}} membership. Please review and make changes.<br/>
                <ul>
                  <li>Click "Edit" to make any changes/edit name or address of locations listed</li>
                  <li>Click "Remove Location" if you have moved or no longer practice any address listed</li>
                  <li>Click "Add New Location" to add a new address or facility name</li>
                </ul>
              </div>
            </div>
            <account-locations [locs]="relatedLocs" [cmUserId]="cm_user_id"></account-locations>
            <div class="pc2-row-pad float-right">If the class of trade designation or specialty for any of your locations needs to be updated, please <a (click)="setTab(8)">contact us</a></div>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==3">
            <div class="card">
              <div class="card-header solo">
                Below are all the contacts we have listed on {{location?.facility_name}}. Please review and make changes as necessary.<br/>
                <ul>
                  <li>Click “Edit” to make any changes/edits to the contacts listed below</li>
                  <li>Click “Request change of Primary Contact” if you would like to update the main contact for your facility</li>
                  <li>Click “Add new contact” to add a new contact to receive information on behalf of your facility</li>
                </ul>
              </div>
            </div>
            <account-contacts [contacts]="contacts" [location]="location" [cmUserId]="cm_user_id"></account-contacts>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==4" *ngIf="location && location.location_group_id">
            <div class="card" *ngIf="user && (user.theme_id==config.ampThemeID || user.theme_id==config.capThemeID || user.theme_id==config.mgmaThemeID || user.theme_id==config.tenetThemeID)">
              <div class="card-header">
              <span class="text-muted pc2-pre-text">
                <span *ngIf="user && user.theme_id==config.ampThemeID">AMP utilizes Expansion, LLC and Purchase Clinic technology to help administer Access Medical Purchasing, a member benefit provided through the American Society of Plastic Surgeons.</span>
                <span *ngIf="user && user.theme_id==config.capThemeID">CAP Purchasing Alliance (CPA) is provided as a free member benefit through membership in the Cooperative of American Physicians. CPA accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, CPA leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.</span>
                <span *ngIf="user && user.theme_id==config.mgmaThemeID">MGMA BestPrice is provided as a free member benefit through membership in the MGMA. MGMA BestPrice accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, MGMA BestPrice leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.</span>
                <span *ngIf="user && user.theme_id==config.tenetThemeID">Tenet’s group purchasing program is provided as a free member benefit through membership in Tenet’s group purchasing program. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.</span>
              </span>
              </div>
            </div>
            <account-sales [locGroupID]="location.location_group_id"></account-sales>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==10" *ngIf="location && location.location_group_id">
            <div class="card"><div class="card-header solo">
              The information below includes reported contract utilization for the past 12 months. Reporting trails by an average of 90 days.<br/>
              Please <a class="pointer" (click)="setTab(8)">contact us</a> if there are purchases made in the past 12 months that are not represented below, to allow us to ensure you are receiving the full benefit of your membership.
            </div></div>
            <account-sales-cycle [locGroupID]="location.location_group_id"></account-sales-cycle>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==9" *ngIf="location && location.location_group_id">
            <div class="card"><div class="card-header solo font-sm">
              The information below includes rebates reported by vendors for the past 12 months. Please see the list of rebated vendors
              <a target="_blank" rel="noopener" href="https://annual-disclosure.s3-us-west-2.amazonaws.com/rebate-vendors.csv">attached here</a>.<br/>
              <br/>
              Through Purchase Clinic you are leveraging the HealthTrust purchasing portfolio; coupled with Purchase Clinic technology and Expansion, LLC group purchasing administration and staffing. These combined resources offer you a unique cost and service structure to ensure access to industry best pricing. From a portfolio perspective, HealthTrust’s first objective is to obtain the best invoice price. However, there may be situations where the inclusion of rebates in a contract allows for additional value we would not otherwise achieve.<br/>
              <br/>
              Rebates are reported and received based on past purchases, therefore distribution of earned rebates to HealthTrust facilities may take six to nine months following the actual purchase period. 100% of rebates are passed through to members ensuring their full benefit. HealthTrust’s audit team routinely reviews contract prices and rebates to ensure rates have been properly calculated and paid. For additional information regarding rebates, please
              <a class="pointer" (click)="setTab(8)">contact us</a>.
            </div></div>
            <account-rebates [locGroupID]="location.location_group_id"></account-rebates>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==5">
            <div class="card"><div class="card-header solo">
              Below please find shared files between {{user?.__theme?.name}} and {{location?.facility_name}}.
            </div></div>
            <account-files [files]="files"></account-files>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==7">
            <div class="card">
              <div class="card-header solo">
                Our records indicate you use the following distributors in your practice, please review and make changes as necessary.<br/>
                <ul>
                  <li>Click “No longer use this distributor” if you are no longer using the distributor listed below.</li>
                  <li>Click “Add new distributor” if you are currently working with a distributor (medical, pharmacy, lab, etc) that is not listed.</li>
                  <li>Need to change or update any information related to your distributor? Please <a (click)="setTab(8)">contact us</a>.</li>
                </ul>
              </div>
            </div>
            <account-distributors-list [locations]="relatedLocs"></account-distributors-list>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==8">
            <account-contact-us [user]="user" [location]="location"></account-contact-us>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="tab==11">
            <account-contact-us-annual-report [user]="user" [location]="location"></account-contact-us-annual-report>
          </div>
          <div *ngIf="recommendedContracts && recommendedContracts.length>0" role="tabpanel" class="tab-pane" [class.active]="tab==12">
            <account-recommendations [contracts]="recommendedContracts" [user]="user" [location]="location"></account-recommendations>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="updAccClinicWindow" tabindex="-1" role="dialog" aria-labelledby="Update Clinic Profile" aria-hidden="true">
  <div class="modal-dialog force-wide-100-high-80">
    <div class="modal-content">
      <div class="modal-body" *ngIf="displayUpdAccClinic">
        <iframe [src]="updAccClinicURI" frameborder="0" width="100%" height="100%" class="force-wide-100-high-80-sub"></iframe>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updAccSurgeryWindow" tabindex="-1" role="dialog" aria-labelledby="Update Surgery Center Profile" aria-hidden="true">
  <div class="modal-dialog force-wide-100-high-80">
    <div class="modal-content">
      <div class="modal-body" *ngIf="displayUpdAccSurgery">
        <iframe [src]="updAccSurgeryURI" frameborder="0" width="100%" height="100%" class="force-wide-100-high-80-sub"></iframe>
      </div>
    </div>
  </div>
</div>
