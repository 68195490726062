<div class="container">
  <h2>Thank You</h2>
  <p>Thank you for signing up for Staples! Pass this benefit on to your employees by sending them the link below. After registering their credit card, they will be able to access the Staples discount when shopping at any Staples location using this card.</p>
  <div class="col-sm-10 offset-sm-1 pc2-row-pad">
    <div class="card" [ngSwitch]="session.user?.theme_id">
      <div *ngSwitchCase="config.ampThemeID" class="card-body text-center copy-bg">
        <p>
          <a target="_blank" rel="noopener" href="https://accessmedicalpurchasing.com/staples">https://accessmedicalpurchasing.com/staples</a>
        </p>
        <button class="btn btn-primary" #copy data-clipboard-text="https://accessmedicalpurchasing.com/staples"
          data-clipboard-action="copy" (click)="copyBtnClick($event)">Click to copy
        </button>
      </div>
      <div *ngSwitchCase="config.capThemeID" class="card-body text-center copy-bg">
        <p>
          <a target="_blank" rel="noopener" href="http://cappurchasingalliance.com/staples">http://cappurchasingalliance.com/staples</a>
        </p>
        <button class="btn btn-primary" #copy data-clipboard-text="http://cappurchasingalliance.com/staples"
          data-clipboard-action="copy" (click)="copyBtnClick($event)">Click to copy
        </button>
      </div>
      <div *ngSwitchCase="config.mgmaThemeID" class="card-body text-center copy-bg">
        <p>
          <a target="_blank" rel="noopener" href="http://mgmabestprice.org/staples">http://mgmabestprice.org/staples</a>
        </p>
        <button class="btn btn-primary" #copy data-clipboard-text="http://mgmabestprice.org/staples"
          data-clipboard-action="copy" (click)="copyBtnClick($event)">Click to copy
        </button>
      </div>
      <div *ngSwitchDefault class="card-body text-center copy-bg">
        <p>
          <a target="_blank" rel="noopener" href="http://purchaseclinic.com/staples">http://purchaseclinic.com/staples</a>
        </p>
        <button class="btn btn-primary" #copy data-clipboard-text="http://purchaseclinic.com/staples"
          data-clipboard-action="copy" (click)="copyBtnClick($event)">Click to copy
        </button>
      </div>
    </div>
  </div>
</div>
