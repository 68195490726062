<section *ngIf="page==0">
  <div class="pc2-row-pad">
    <h1 style="text-align: center">Please help us identify savings opportunities</h1>
  </div>

  <div class="row pc2-row-pad" *ngIf="showDetailsEdit && user && location">
    <div class="col-md-6 offset-md-3">
      <div class="card pc2-bgcolor-card">
        <div class="card-body font-sm">
          <div class="row">
            <div class="col-6">Please review your contact information:</div>
            <div class="col-6 text-right">
              <span class="pc2-link" (click)="detailsEdit=!detailsEdit">click here to edit contact information</span>
            </div>
          </div>
          <section *ngIf="!detailsEdit">
            <div class="row">
              <div class="col-12">
                <div>{{user.fname}} {{user.lname}} <{{user.email}}></div>
                <div>{{location.facility_name}}</div>
                <div>{{location.address}}</div>
                <div *ngIf="location.address2">{{location.address2}}</div>
                <div>{{[location.city, location.state, location.zip]|arr_join}}</div>
              </div>
            </div>
          </section>
          <section *ngIf="detailsEdit">
            <div class="row pc2-form-group pc2-row-pad">
              <div class="col-sm-6"><input class="form-control" [(ngModel)]="user.fname" placeholder="First Name" type="text"/></div>
              <div class="col-sm-6"><input class="form-control" [(ngModel)]="user.lname" placeholder="Last Name" type="text"/></div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-sm-6">
                <input class="form-control" [(ngModel)]="user.email" placeholder="Email" type="email"/>
              </div>
              <div class="col-sm-6">
                <input class="form-control" [(ngModel)]="user.phone" placeholder="Phone" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="user.job_title" placeholder="Job Title (required)" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="location.facility_name" placeholder="Company Name" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="location.address" placeholder="Address" type="text"/>
              </div>
            </div>
            <div class="row pc2-form-group">
              <div class="col-12">
                <input class="form-control" [(ngModel)]="location.address2" placeholder="Address 2" type="text"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4"><input class="form-control" [(ngModel)]="location.city" placeholder="City" type="text"/></div>
              <div class="col-sm-4"><input class="form-control" [(ngModel)]="location.state" placeholder="State" type="text"/></div>
              <div class="col-sm-4"><input class="form-control" [(ngModel)]="location.zip" placeholder="Post code" type="text"/></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3">
      <h4 class="pc2-row-pad"><strong>{{q_sub_title}}</strong></h4>
      <p><strong>{{q_title}}</strong><br/>(may select multiple)</p>
      <div class="pc2-big-checkbox" *ngFor="let opt of options" (click)="toggleOption(opt)">
        <input type="checkbox" id="siimcb{{opt.id}}" [checked]="data.options.indexOf(opt.id)>-1" [disabled]="opt.id!=donotuse_option_id && donotuse"> <label for="siimcb{{opt.id}}">{{opt.title}}</label>
      </div>
    </div>
  </div>
  <div class="row pc2-row-pad">
    <div class="col-md-4 offset-md-4">
      <button class="btn btn-primary btn-block" (click)="initSurvey()" [disabled]="saving">Continue for further savings opportunities</button>
    </div>
  </div>
</section>
<section *ngIf="page==1">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <strong>Based upon your vendor selections, there are possible further discounts available.<br/>
          Please review the questions below to help us identify additional savings.
        </strong>
      </div>
    </div>
    <div class="row pc2-row-pad-bigger">
      <div class="col-md-6 offset-md-3">
        <div class="pc2-big-checkbox" *ngFor="let copt of chosen">
          <div style="text-decoration: underline; font-weight: bold">{{copt.title}}</div>
          <div class="font-sm">Do you meet the below purchasing requirements from this vendor?</div>
          <label for="siimchb{{copt.id}}" (click)="toggleChosenConfirmed(copt)">
            <input type="checkbox" id="siimchb{{copt.id}}" [checked]="chosenConfirmed.indexOf(copt.id)>-1">
            {{copt.hint}}
          </label>
        </div>
      </div>
    </div>
    <div class="row pc2-row-pad-big">
      <div class="col-md-9 offset-md-3">
        <label for="siimdontmeetreqs">
          <input type="checkbox" id="siimdontmeetreqs" [(ngModel)]="dontMeetReqs">
          I do not meet the above requirements, please contact me identify additional savings
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 offset-md-3">
        <label for="siimcontactfored">
          <input type="checkbox" id="siimcontactfored" [(ngModel)]="contactForEducation">
          Contact me for education on vendor requirements available to my facility
        </label>
      </div>
    </div>
    <div class="row pc2-row-pad-big">
      <div class="col-md-4 offset-md-4">
        <button class="btn btn-primary btn-block" (click)="confirm()" [disabled]="saving">Continue</button>
      </div>
    </div>
  </div>
</section>
