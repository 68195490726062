import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {IDistributor} from "../../interfaces/IDistributor";
import {Distributors} from "../../services/Distributors";
import {FDTickets} from "../../services/FDTickets";
import {Router} from '@angular/router';
import {User} from "../../services/User";
import {Notify} from "../../services/Notify";
import {Config} from "../../services/Config";
import {ITheme} from "../../interfaces/ITheme";

declare var $:any;

@Component({
  selector: 'flu-vaccines',
  templateUrl: 'fluvaccines.html'
})
export class FluVaccinesPageCmp {
  public distributorsList:IDistributor[] = [];
  public distributorsMap:{[id: number]: IDistributor} = {};
  public currentDistrName:string;
  public displayDistrs = false;
  public displayContinue = false;
  public newDistrID = 0;
  public otherDistrID = 9999999;
  public otherDistr = '';
  public welcomeScreenPassed = false;
  public contactName = 'Purchase Clinic';
  public contactEmail = 'support@purchaseclinic.com';
  public contactPhone = '1.877.659.2159';
  private stillUseAnswer = 0;
  private rxCode;

  constructor(private session:Session, private distributors:Distributors, private tickets:FDTickets,
    private router:Router, private userSrv:User, private config:Config) {
    this.session.addOnLoadHandler(()=> {
      let theme = this.session.getCurrentTheme();
      if (theme) {
        this.setContactsByTheme(theme);
      } else {
        this.setContactsByHost();
      }
      this.loadDistrs();
      this.userSrv.getMyRxCode().subscribe((rxCode)=> {
        this.rxCode = rxCode;
      }, ()=> {});
    });
    this.session.redirectIfNotAuth();
  }

  setContactsByHost() {
    let themeId = this.config.getThemeIDForHost();
    if (themeId && this.config.themesMap && this.config.themesMap[themeId]) {
      let theme = this.config.themesMap[themeId];
      this.setContactsByTheme(theme);
    }
  }

  setContactsByTheme(theme:ITheme) {
    if (theme.email_from_address) {
      this.contactEmail = theme.email_from_address;
    }
    if (theme.name) {
      this.contactName = theme.name;
    } else {
      if (theme.email_from_name) {
        this.contactName = theme.email_from_name;
      }
    }
    if (theme.phone) {
      this.contactPhone = theme.phone;
    }
  }

  loadDistrs() {
    this.distributorsMap[this.otherDistrID] = {
      id: this.otherDistrID, name: 'Other',
      logo_url: '', is_popular: 0, domain_list: '', export_label: ''
    };
    let distr_id = 0;
    if (this.session.user && this.session.user.__location && this.session.user.__location.__distributor_id) {
      distr_id = this.session.user.__location.__distributor_id;
    }
    if (distr_id == 0 || distr_id == 7 || distr_id == 8) {
      distr_id = 0;
      this.stillUseAnswer = 0;
      this.displayDistrs = true;
    }
    this.distributors.load().then((distributors)=> {
      if (distributors && distributors.length > 0) {
        distributors.forEach((d:IDistributor)=> {
          if (d.is_popular && d.id != distr_id) {
            this.distributorsList.push(d);
            this.distributorsMap[d.id] = d;
          }
          if (distr_id > 0 && d.id == distr_id) {
            this.currentDistrName = d.name;
          }
        });
      }
    }, ()=> {});
  }

  stillUse(answerIsYes) {
    if (answerIsYes) {
      this.displayContinue = true;
      this.displayDistrs = false;
      this.stillUseAnswer = 1;
    } else {
      this.displayDistrs = true;
      this.stillUseAnswer = 0;
    }
  }

  redirectToRxPA() {
    if (this.rxCode) {
      this.session.crossPageArgs['rx_redirected_new_member'] = 1;
      this.session.crossPageArgs['rx_vaccines'] = 1;
      this.router.navigateByUrl('/rx/' + this.rxCode);
    } else {
      this.router.navigateByUrl('/vaccines_thanks');
    }
  }

  continue() {
    if (this.stillUseAnswer < 1 && !this.newDistrID) {
      let n = new Notify();
      n.warning('Please select your primary distributor');
      return;
    }
    this.sendNewDistrData();
    this.redirectToRxPA();
  }

  sendNewDistrData() {
    let user = this.session.user;
    if (!user || !user.id) {
      return;
    }
    let distr = '';
    let distrPrefix = this.stillUseAnswer > 0 ? 'Still use' : 'New';
    if (!this.newDistrID || !this.distributorsMap || !this.distributorsMap[this.newDistrID]) {
      distr = (this.stillUseAnswer > 0 ? this.currentDistrName : '[not selected]') || '[not selected]';
    } else {
      distr = this.distributorsMap[this.newDistrID].name;
      if (this.newDistrID == this.otherDistrID && this.otherDistr) {
        distr = this.otherDistr;
      }
    }
    let location = user.__location;
    let email = user.email;
    if (!email || email.indexOf('@') < 1) {
      return false;
    }
    let customFields = {};
    if (user && user.__theme && user.__theme.name) {
      customFields['brand'] = user.__theme.name;
    }
    if (location && location.pc_id) {
      customFields['pc_id'] = location.pc_id;
    }
    let msg = `<hr>`;
    msg = msg + `<div>${distrPrefix} distributor: ${distr}</div>`;
    msg = `<div>Location: <a href="https://crm.purchase.guru/location/${location.id}" target="_blank">${location.facility_name} (${location.pc_id})</a></div>${msg}`;
    if (user) {
      msg = `<div>User: <a href="https://crm.purchase.guru/user/${user.id}" target="_blank">${user.fname} ${user.lname}</a>, email: ${user.email}</div>${msg}`;
    }
    this.tickets.create(email, `[Flu Vaccines] ${distrPrefix} distributor`, msg, customFields, location.__manager_id).subscribe(() => {}, () => {});
  }

  skipWelcomePage() {
    this.welcomeScreenPassed = true;
    $('html,body').animate({scrollTop: 0}, 10);
  }
}
