<div class="container">

<h2>Thank You</h2>
  <p>Thank you for answering the survey questions.  You can download your free Savings Opportunity and Market Report below. You will also receive an e-mail with your full report, login information to PurchaseClinic.com, as well as the participation agreement and terms and services for {{session.user?.__theme?.name||'Purchase Clinic'}}.</p>

  <p><a href="http://purchaseclinic.com/pdf/somr/?id=0610{{review_session_id}}8"><img src="//purchaseclinic.s3.amazonaws.com/report/somr-thumnail.png" alt="Report" /></a></p>

  <p><a href="http://purchaseclinic.com/pdf/somr/?id=0610{{review_session_id}}8">Click here to download your custom report</a>.</p>

  <p>If you have any questions, don’t hesitate to contact our office at <a href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a> or 1.877.659.2159.</p>

</div>
