<section class="main-content signup-layout" *ngIf="!isAccountSubdomainViewMode && !isLocationLoginMode">
  <section class="bordered-container">
    <h1 class="headline">
      <span *ngIf="!session.user?.__theme?.login_heading && !session.user?.__theme?.login_heading_img_url">Log in</span>
      <span *ngIf="!session.user?.__theme?.login_heading_img_url && session.user?.__theme?.login_heading">{{session.user?.__theme?.login_heading}}</span>
      <span *ngIf="session.user?.__theme?.login_heading_img_url">
        <img src="{{session.user?.__theme?.login_heading_img_url}}" alt=""/>
      </span>
    </h1>

    <div class="row">
      <div class="bordered-content col-sm-12 col-md-5">
        <form class="form-validate body-form" (ngSubmit)="login()">
          <ul class="form-list list-unstyled">
            <li class="form-group" *ngIf="!require2step">
              <label class="col-form-label">Email</label>
              <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email" auto-focus/>
            </li>
            <li class="form-group" *ngIf="!require2step">
              <label class="col-form-label">Password</label>
              <input type="password" placeholder="Password" [(ngModel)]="password" class="form-control" name="password"/>
            </li>
            <li class="form-group" *ngIf="require2step">
              <label class="col-form-label" for="totp">Verification code</label>
              <input id="totp" type="text" class="form-control" [(ngModel)]="totp" name="totp" auto-focus/>
            </li>
            <li class="form-footer">
              <a class="forgot-pwd-link" routerLink="/forgot_pwd">Forgot Password?</a>
              <button type="submit" class="btn btn-primary btn-primary-action" [disabled]="loading">Login</button>
            </li>
          </ul>
        </form>
      </div>
      <div class="text-center d-none d-md-block col-md-2" *ngIf="!require2step" style="padding-top: 140px">
        &mdash; OR &mdash;
      </div>
      <div class="bordered-content  col-sm-12 col-md-5" *ngIf="!require2step">
        <ul class="form-list list-unstyled">
          <li class="form-group">
            <p>Cannot remember your username or password?<br/>Enter your email address and we will send you a single-use link.<br/>
              <small>(Existing accounts only, link expires in 24 hours)</small>
            </p>
            <label class="col-form-label">Email</label>
            <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email"/>
          </li>
          <li>
            <div class="btn-block">
              <button (click)="sendMagicLink()" type="button" class="btn btn-outline-primary btn-block" [disabled]="magicLinkSending">Send link to email</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</section>

<section class="main-content signup-layout" *ngIf="isAccountSubdomainViewMode && !isLocationLoginMode && letterWelcome">
  <section class="bordered-container">
    <h1 class="headline">
      Log in to view your Annual Group Purchasing Report
    </h1>


    <div class="row">
      <div class="col-sm-12 col-md-5">
        <div class="bordered-content">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link" [class.active]="tab==1" (click)="tab=1">Log in with code</a></li>
            <li class="nav-item"><a class="nav-link" [class.active]="tab==2" (click)="tab=2">Username/Password</a></li>
          </ul>
          <div class="tab-content tab-borderd">
            <div role="tabpanel" class="tab-pane" [class.active]="tab==1">
              <form class="form-validate body-form" (ngSubmit)="loginUsingAnnualReportCode()">
                <ul class="form-list list-unstyled">
                  <li class="form-group">
                    <label class="col-form-label">Enter 10-digit code from your letter</label>
                    <input type="text" placeholder="XXX-XXX-XXXX" [(ngModel)]="annualReportCode" class="form-control" name="annualReportCode"/>
                  </li>
                  <li class="form-footer">
                    <button type="submit" class="btn btn-primary btn-primary-action" [disabled]="loading">Access Report</button>
                  </li>
                </ul>
              </form>
            </div>
            <div role="tabpanel" class="tab-pane" [class.active]="tab==2">
              <form class="form-validate body-form" (ngSubmit)="login()">
                <ul class="form-list list-unstyled">
                  <li class="form-group" *ngIf="!require2step">
                    <label class="col-form-label">Email</label>
                    <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email" auto-focus/>
                  </li>
                  <li class="form-group" *ngIf="!require2step">
                    <label class="col-form-label">Password</label>
                    <input type="password" placeholder="Password" [(ngModel)]="password" class="form-control" name="password"/>
                  </li>
                  <li class="form-group" *ngIf="require2step">
                    <label class="col-form-label" for="totp">Verification code</label>
                    <input type="text" class="form-control" [(ngModel)]="totp" name="totp" auto-focus/>
                    <div class="alert alert-danger" *ngIf="wrongTotpEntered">Wrong verification code.</div>
                  </li>
                  <li class="form-footer">
                    <a class="forgot-pwd-link" routerLink="/forgot_pwd">Forgot Password?</a>
                    <button type="submit" class="btn btn-primary btn-primary-action" [disabled]="loading">Login</button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center d-none d-md-block col-md-2" style="padding-top: 140px;">
        &mdash; OR &mdash;
      </div>
      <div class="bordered-content  col-sm-12 col-md-5">
        <ul class="form-list list-unstyled">
          <li class="form-group">
            <p>Looking for your code? Check the letter you received for the code (see below)</p>
            <img class="img-fluid mx-auto" src="/assets/img/annual-code.png"/>
            <p>If you can't find the code, enter your email address below for an auto-login link.<br/>
              <small>(Existing accounts only, link expires in 24 hours)</small>
            </p>
            <label class="col-form-label">Email</label>
            <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email"/>
          </li>
          <li>
            <div class="btn-block">
              <button (click)="sendMagicLink()" type="button" class="btn btn-outline-primary btn-block" [disabled]="magicLinkSending">Send link to email</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</section>

<section class="main-content signup-layout" *ngIf="isAccountSubdomainViewMode && !isLocationLoginMode && !letterWelcome">
  <section class="bordered-container">
    <h1 class="headline">
      Log in to view your Annual Group Purchasing Report
    </h1>

    <div class="row">
      <div class="bordered-content col-sm-12 col-md-5">
        <form class="form-validate body-form" (ngSubmit)="login()">
          <ul class="form-list list-unstyled">
            <li class="form-group" *ngIf="!require2step">
              <label class="col-form-label">Email</label>
              <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email" auto-focus/>
            </li>
            <li class="form-group" *ngIf="!require2step">
              <label class="col-form-label">Password</label>
              <input type="password" placeholder="Password" [(ngModel)]="password" class="form-control" name="password"/>
            </li>
            <li class="form-group" *ngIf="require2step">
              <label class="col-form-label" for="totp">Verification code</label>
              <input type="text" class="form-control" [(ngModel)]="totp" name="totp" auto-focus/>
            </li>
            <li class="form-footer">
              <a class="forgot-pwd-link" routerLink="/forgot_pwd">Forgot Password?</a>
              <button type="submit" class="btn btn-primary btn-primary-action" [disabled]="loading">Login</button>
            </li>
          </ul>
        </form>
      </div>
      <div class="text-center d-none d-md-block col-md-2" *ngIf="!require2step" style="padding-top: 140px">
        &mdash; OR &mdash;
      </div>
      <div class="bordered-content  col-sm-12 col-md-5" *ngIf="!require2step">
        <ul class="form-list list-unstyled">
          <li class="form-group">
            <p>Don't feel like dealing with a password right now?<br/>Enter your email address and we will send you a single-use link.<br/>
              <small>(Existing accounts only, link expires in 24 hours)</small>
            </p>
            <label class="col-form-label">Email</label>
            <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email"/>
          </li>
          <li>
            <div class="btn-block">
              <button (click)="sendMagicLink()" type="button" class="btn btn-outline-primary btn-block" [disabled]="magicLinkSending">Send link to email</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</section>

<section class="main-content signup-layout" *ngIf="isLocationLoginMode">
  <section class="bordered-container">
    <h1 class="headline text-center">Annual Group Purchasing and Safe Harbor</h1>

    <div class="row">
      <div class="bordered-content col-sm-12 col-md-5">
        <form class="form-validate body-form" (ngSubmit)="login()">
          <ul class="form-list list-unstyled">
            <li class="form-group" *ngIf="!require2step">
              <label class="col-form-label">Email</label>
              <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email" auto-focus/>
            </li>
            <li class="form-group" *ngIf="!require2step">
              <label class="col-form-label">Password</label>
              <input type="password" placeholder="Password" [(ngModel)]="password" class="form-control" name="password"/>
            </li>
            <li class="form-group" *ngIf="require2step">
              <label class="col-form-label" for="totp">Verification code</label>
              <input type="text" class="form-control" [(ngModel)]="totp" name="totp" auto-focus/>
            </li>
            <li class="form-footer">
              <a class="forgot-pwd-link" routerLink="/forgot_pwd">Forgot Password?</a>
              <button type="submit" class="btn btn-primary btn-primary-action" [disabled]="loading">Login</button>
            </li>
          </ul>
        </form>
      </div>
      <div class="text-center d-none d-md-block col-md-2" *ngIf="!require2step" style="padding-top: 140px">
        &mdash; OR &mdash;
      </div>
      <div class="bordered-content  col-sm-12 col-md-5" *ngIf="!require2step">
        <ul class="form-list list-unstyled">
          <li class="form-group">
            <p>Don't feel like dealing with a password right now?<br/>Enter your email address and we will send you a single-use link.<br/>
              <small>(Existing accounts only, link expires in 24 hours)</small>
            </p>
            <label class="col-form-label">Email</label>
            <input type="text" placeholder="Email" [(ngModel)]="email" class="form-control" name="email"/>
          </li>
          <li>
            <div class="btn-block">
              <button (click)="sendMagicLink()" type="button" class="btn btn-outline-primary btn-block" [disabled]="magicLinkSending">Send link to email</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row pc2-row-pad">
      <div class="col-12 pc2-row-pad">
        <strong>Need to add an additional email to your account?</strong> &nbsp;
        <button class="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#loginNewContactModal">Contact Us</button>
      </div>
    </div>
  </section>

  <div class="modal fade" id="loginNewContactModal" tabindex="-1" role="dialog" aria-labelledby="loginNewContactModal" aria-hidden="true" #loginNewContactModal>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Additional Contact</h5>
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="form-group">
              <div class="row">
                <label class="col-form-label col-sm-3 text-right">Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [(ngModel)]="newContactName"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-form-label col-sm-3 text-right">Email</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [(ngModel)]="newContactEmail"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-form-label col-sm-3 text-right">Company name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [(ngModel)]="newContactCompany"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary float-left" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-success col-sm-2 btn-block float-right" (click)="addNewContact()" [disabled]="addingNewContact">Add</button>
        </div>
      </div>
    </div>
  </div>
</section>
