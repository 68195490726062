<table class="table table-sm table-hover font-sm" *ngIf="sales && sales.length>0">
  <thead>
    <tr>
      <th>GPO ID</th>
      <th>Vendor</th>
      <th>Contract / Description</th>
      <th class="text-right">Sales</th>
      <th class="text-right">Quarter</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let s of sales">
      <td>{{s.gpoid}}</td>
      <td>{{s.vendor}}</td>
      <td>{{s.contract_number}} {{s.contract_description}}</td>
      <td class="text-right"><span *ngIf="s.sales_amount">$&nbsp;</span>{{s.sales_amount|numcomm}}</td>
      <td class="text-right">{{s.quarter}}</td>
    </tr>
  </tbody>
</table>
<div class="col-12" *ngIf="salesCSV && sales && sales.length>0">
  <a target="_blank" class="btn btn-primary btn-sm float-right" href="{{salesCSV}}">Download as CSV</a>
</div>
<div *ngIf="!loading && (!sales || sales.length<1)">
  <p class="text-center">There are no recorded purchases in this calendar year</p>
</div>
