import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Session} from "../../services/Session";
import {Questions} from "../../services/Questions";
import {ISiimAnswers, SiimSrv} from "../../services/SiimSrv";
import {Notify} from "../../services/Notify";
import {ISubQuestionOption} from "../../interfaces/ISubQuestionOption";
import {IUser} from "../../interfaces/IUser";
import {ILocation} from "../../interfaces/ILocation";
import {Helpers} from "../../services/Helpers";
import {FDTickets} from "../../services/FDTickets";
import {EditRequests} from "../../services/EditRequests";
import {Locations} from "../../services/Locations";
import {Config} from "../../services/Config";

@Component({
  selector: 'siim-page',
  templateUrl: 'siim_page.html',
})
export class SiimPageCmp {
  public data:ISiimAnswers = {
    options: [],
    job_title: '',
  };
  public ip:string = '';
  public saving = false;
  public options:ISubQuestionOption[];
  public donotuse = false;
  public q_title = 'Which suppliers do you purchase from?';
  public q_sub_title = 'Surgical Instrument Implementation Module';
  public donotuse_option_id = 0;
  public showDetailsEdit = false;
  private redirectedUser = false;
  public page = 0;
  public detailsEdit = false;
  public user:IUser;
  public location:ILocation;
  public chosen:ISubQuestionOption[] = [];
  public chosenConfirmed = [];
  public dontMeetReqs = false;
  public contactForEducation = false;
  private originUser:IUser;
  private originLocation:ILocation;
  private afterVaccinesModule = false;

  constructor(private routeParams:ActivatedRoute, private session:Session, private router:Router,
    private siimSrv:SiimSrv, private helper:Helpers, private fdTickets:FDTickets,
    private editReqSrv:EditRequests, private locSrv:Locations, private config:Config) {}

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      if (this.session.crossPageArgs && this.session.crossPageArgs['siim_vaccines']) {
        this.afterVaccinesModule = true;
      }
      if (this.session.crossPageArgs && this.session.crossPageArgs['siim_redirected_new_member']) {
        this.redirectedUser = true;
        this.showDetailsEdit = false;
      } else {
        this.showDetailsEdit = true;
      }
      this.load(params['code']);
    });
  }

  load(code:string) {
    this.siimSrv.loadCode(code).subscribe((siimResponse)=> {
      let showDetailsOrig = this.showDetailsEdit;
      let detailsEditOrig = this.detailsEdit;
      this.showDetailsEdit = true;
      this.detailsEdit = true;
      if (siimResponse) {
        if (siimResponse.token) {
          this.session.setToken(siimResponse.token);
        }
        if (siimResponse.user) {
          if (siimResponse.user.user_ip) {
            this.ip = siimResponse.user.user_ip;
          }
          if (siimResponse.user.user) {
            this.user = siimResponse.user.user;
            this.originUser = this.helper.clone(siimResponse.user.user);
            if (this.user.job_title) {
              this.showDetailsEdit = showDetailsOrig;
              this.detailsEdit = detailsEditOrig;
            }
          }
          if (siimResponse.user.location) {
            this.location = siimResponse.user.location;
            this.originLocation = this.helper.clone(siimResponse.user.location);
          }
        }
        if (siimResponse.questions) {
          try {
            this.options = siimResponse.questions[0].options;
            this.q_title = siimResponse.questions[0].title;
            this.q_sub_title = siimResponse.questions[0].sub_title;
          } catch (e) {}
          if (this.options && this.options.length) {
            this.options.forEach((opt)=> {
              if (opt.user_input_type == 'number') {
                this.donotuse_option_id = opt.id;
              }
            });
          }
        }
      }
    }, (err)=> {
      let n = new Notify();
      n.httpErr(err);
      this.router.navigateByUrl('/login');
    });
  }

  initSurvey() {
    if (!this.user || !this.user.job_title) {
      this.showDetailsEdit = true;
      this.detailsEdit = true;
      this.page = 0;
      let n = new Notify();
      n.warning('Field "Job Title" is required');
      return;
    }
    this.saveEdits();
    this.data.job_title = this.user.job_title;
    this.saving = true;
    this.siimSrv.initiateSiimSurvey(this.data).subscribe(()=> {
      this.saving = false;
      if (this.data.options[0] && this.data.options[0] == this.donotuse_option_id) {
        this.finalize();
      } else {
        this.page = 1;
        this.fillChosen();
      }
    }, (err)=> {
      this.saving = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }

  confirm() {
    this.finalize();
  }

  finalize() {
    this.sendChosenTicket();
    this.locSrv.sendSiimCompletedEmail().subscribe(()=> {}, ()=> {});
    this.router.navigateByUrl('/siim_thank_you');
  }

  fillChosen() {
    if (!this.data.options || !this.data.options.length) {
      this.chosen = [];
      return;
    }
    this.options.forEach((opt)=> {
      if (opt.id != this.donotuse_option_id && opt.hint && this.data.options.indexOf(opt.id) > -1) {
        this.chosen.push(opt);
      }
    });
  }

  toggleOption(opt:ISubQuestionOption) {
    let i = this.data.options.indexOf(opt.id);
    if (i > -1) {
      this.data.options.splice(i, 1);
      if (opt.id == this.donotuse_option_id) {
        this.donotuse = false;
      }
    } else {
      if (opt.id == this.donotuse_option_id) {
        this.data.options = [this.donotuse_option_id];
        this.donotuse = true;
      } else {
        this.data.options.push(opt.id);
      }
    }
  }

  toggleChosenConfirmed(opt:ISubQuestionOption) {
    let i = this.chosenConfirmed.indexOf(opt.id);
    if (i > -1) {
      this.chosenConfirmed.splice(i, 1);
    } else {
      this.chosenConfirmed.push(opt.id);
    }
  }

  saveEdits() {
    if (!this.location) {
      return false;
    }
    let locSaved = false;
    let userSaved = false;
    let toSendTicket = false;
    let checkSendTicket = ()=> {
      if (locSaved && userSaved && toSendTicket) {
        this.sendUpdTicket();
      }
    };
    if (this.originLocation) {
      this.editReqSrv.saveRecordEdits(this.location, this.originLocation, 'location', 'id', this.location.__manager_id).subscribe((x)=> {
        locSaved = true;
        if (x) {
          let r = parseInt(x.shift());
          if (r > 0) {
            toSendTicket = true;
          }
        }
        checkSendTicket();
      }, ()=> {
        locSaved = true;
      });
    }
    if (this.user && this.originUser) {
      this.editReqSrv.saveRecordEdits(this.user, this.originUser, 'user', 'id', this.location.__manager_id).subscribe((x)=> {
        userSaved = true;
        if (x) {
          let r = parseInt(x.shift());
          if (r > 0) {
            toSendTicket = true;
          }
        }
        checkSendTicket();
      }, ()=> {
        userSaved = true;
      });
    }
  }

  sendChosenTicket() {
    try {
      let subj = '[Surgical Instruments Module]';
      let u = this.user;
      let loc = this.location;
      let body = `<div>User: <a target="_blank" href="https://crm.purchase.guru/user/${u.id}">${u.fname} ${u.lname}</a> (${u.email})</div>`;
      if (!this.data.options || (this.data.options[0] && this.data.options[0] == this.donotuse_option_id)) {
        body = body + '<div>User doesn\'t use any vendor</div>';
      } else {
        body = body + '<div>Vendors</div><ul>';
        this.chosen.forEach((opt)=> {
          body = body + '<li>' + opt.title;
          if (this.chosenConfirmed && this.chosenConfirmed.indexOf(opt.id) > -1) {
            body = body + ' <strong>(requirements confirmed)</strong>';
          }
          body = body + '</li>';
        });
        body = body + '</ul>';
      }
      if (this.dontMeetReqs) {
        body = body + '<div>Selected option: <strong>I do not meet the above requirements, please contact me identify additional savings</strong></div>';
      }
      if (this.contactForEducation) {
        body = body + '<div>Selected option: <strong>Contact me for education on vendor requirements available to my facility</strong></div>';
      }
      body = body + `<hr/><a target="_blank" href="https://crm.purchase.guru/location/${loc.id}">${loc.facility_name}</a> (${loc.pc_id})</div>`;
      let customFields = {pc_id: loc.pc_id};
      if (u.__theme && u.__theme.name) {
        customFields['brand'] = u.__theme.name;
      }
      this.fdTickets.create(u.email, subj, body, customFields).subscribe(()=> {}, () => {});
    } catch (e) {}
  }

  sendUpdTicket() {
    try {
      let subj = '[Surgical Instruments Module] Edit Request';
      let u = this.user;
      let loc = this.location;
      let body = `<div>User <a target="_blank" href="https://crm.purchase.guru/user/${u.id}">${u.fname} ${u.lname}</a> (${u.email})</div>
          wants to edit facility <a target="_blank" href="https://crm.purchase.guru/location/${loc.id}">${loc.facility_name}</a> (${loc.pc_id})</div>
          <div><a href="https://crm.purchase.guru/edit_requests">Review</a></div>
          <hr/>
          <div>First name: <b>${u.fname}</b></div>
          <div>Last name: <b>${u.lname}</b></div>
          <div>Email: <b>${u.email}</b></div>
          <div>Facility Name: <b>${this.location.facility_name}</b></div>
          <div>Address: <b>${this.location.address}</b></div>
          <div>Address 2: <b>${this.location.address2}</b></div>
          <div>City: <b>${this.location.city}</b></div>
          <div>State: <b>${this.location.state}</b></div>
          <div>Postal code: <b>${this.location.zip}</b></div>`;
      let customFields = {pc_id: loc.pc_id};
      if (u.__theme && u.__theme.name) {
        customFields['brand'] = u.__theme.name;
      }
      this.fdTickets.create(u.email, subj, body, customFields).subscribe(()=> {}, () => {});
    } catch (e) {}
  }
}
